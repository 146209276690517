import React, { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import FormItemText from '../../../utils/widgets/forms/form-item-text';
import useForm from '../../../utils/hooks/use-form';
import { ApiAuthService, TokenResource, LoginRequest } from '../../../api/main';
import useFetch from '../../../utils/hooks/use-fetch';
import { useAppDispatch } from '../../../store/hooks';
import { storeActions } from '../../../store';
import InputPassword from '../../../utils/widgets/forms/input-password';
import { Box, Stack, Typography, styled } from '@mui/material';
import Button from '../../../utils/widgets/button';
import { appRoutes } from '../../../providers/routes';
import Checkbox from '../../../utils/widgets/forms/checkbox';
import { LOCALSTORAGEKEYS } from '../../../constants/local-storage-keys';
import { useSnackbar } from 'notistack';
import { ApiErrorResponseType } from '../../../utils/hooks/use-fetch/component.types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Meta from '../../../utils/widgets/meta/component';
import useErrorHandler from '../../../utils/hooks/use-error-handler';
import DevolonLogo from '../../../assets/img/favicon.png';
import { Info } from '@mui/icons-material';
import PageLoader from '../../../utils/widgets/page-loader';

const loginSchema = Yup.object().shape({
  password: Yup.string().min(8).max(50).required(),
  email: Yup.string().email().required(),
  remember: Yup.boolean(),
});

interface LoginSchema extends LoginRequest {
  remember: boolean;
}
const DevolonImg = styled('img')({
  width: '25px',
  height: '25px',
  position: 'absolute',
  left: '16px',
  bottom: 0,
  top: 0,
  margin: 'auto',
});
const Login: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [shouldRegister, setShouldRegister] = useState(false);
  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'login',
    schema: loginSchema,
  });
  const { control } = formProps;
  const handleError = useErrorHandler();
  const loginApi = useFetch<LoginRequest, TokenResource>(ApiAuthService.apiAuthLogin);
  const loginByGoogle = useFetch(ApiAuthService.apiAuthGoogleRedirect);
  const apiAuthGoogleGetToken = useFetch(ApiAuthService.apiAuthGoogleGetToken);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const handleLogin = (result: TokenResource) => {
    localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE_KEY}${LOCALSTORAGEKEYS.USER}`, JSON.stringify(result));
    enqueueSnackbar(t('form:login-submission.success'), {
      variant: 'success',
    });
    dispatch(storeActions.user.setUserData(result));
    const { nextPathName = '' } = (location.state as Record<string, string>) || { nextPathName: '' };
    navigate(nextPathName || appRoutes.dashboard, { replace: true });
    return Promise.resolve(result);
  };
  const onSubmit = (data: LoginSchema) => {
    const { remember, ...otherValues } = data;
    setShouldRegister(false);

    return loginApi
      .call(otherValues)
      .then(handleLogin)
      .catch((e: ApiErrorResponseType) => {
        let message = t('form:login-submission.failed');

        if (e?.body?.message) {
          message = e.body.message;
        }

        enqueueSnackbar(message, {
          variant: 'error',
        });
        return Promise.reject(e);
      });
  };
  useEffect(() => {
    if (code) {
      const scope = searchParams.get('scope') || '';
      const authuser = searchParams.get('authuser') || '';
      const prompt = searchParams.get('prompt') || '';
      apiAuthGoogleGetToken
        .call({ scope, authuser, code, prompt })
        .then(handleLogin)
        .catch((err: ApiErrorResponseType) => {
          if (err?.status === 401) setShouldRegister(true);
          return handleError(err);
        });
    }
  }, [code]);
  return (
    <PageLoader isLoading={apiAuthGoogleGetToken.loading}>
      <Stack>
        <form aria-label="login" onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)} id="login-form">
          {formDebugger()}

          <Meta>
            <title>
              {t('common:app-name')} | {t('common:app-name')}
            </title>
          </Meta>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '30vw',
              maxWidth: '440px',
              minWidth: '360px',
              padding: '40px',
            }}
          >
            <Typography sx={{ marginBottom: '40px' }} variant="h2" component="p">
              {t('common:app-name')}
            </Typography>
            {shouldRegister && (
              <Stack direction="row" alignItems="center" marginBottom="20px">
                <Info color="error" sx={{ marginRight: '10px' }} />
                <Typography color="error">There are no accounts with your email. Please register first.</Typography>
              </Stack>
            )}
            <Button
              variant="outlined"
              sx={{ marginBottom: '30px', position: 'relative' }}
              onClick={() => {
                setShouldRegister(false);
                loginByGoogle
                  .call()
                  .then(res => res.redirectUrl && window.location.replace(res.redirectUrl + `&id=${Math.random()}`))
                  .catch(handleError);
              }}
            >
              <DevolonImg src={DevolonLogo} alt="Devolon Logo" width={25} height={25} />
              Log in with Devolon account
            </Button>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              marginBottom="40px"
              sx={{
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  height: '1px',
                  left: '0px',
                  right: '0px',
                  top: '0px',
                  bottom: '0px',
                  margin: 'auto',
                  background: 'black',
                },
              }}
            >
              <Typography
                variant="caption"
                component="p"
                fontWeight="normal"
                sx={{ background: '#fff', position: 'relative', zIndex: 2, width: '165px', textAlign: 'center' }}
              >
                or log in with Email
              </Typography>
            </Stack>
            <FormItemText defaultValue="" control={control} required name="email" label={t('form:email-address')}>
              <TextField type="email" />
            </FormItemText>

            <FormItemText defaultValue="" control={control} required label={t('form:password')} name="password">
              <InputPassword />
            </FormItemText>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '40px',
              }}
            >
              <Checkbox name="remember" label={t('form:remember-me')} control={control} />
              <Box sx={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  sx={{
                    textDecoration: 'underline',
                    marginRight: '-30px',
                  }}
                  variant="text"
                  href={appRoutes.auth.forgetPassword}
                >
                  <Typography variant="overline">
                    {t('form:forgot-password')}
                    {t('symbol:question')}
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Button loading={loginApi.loading} type="submit">
              {t('form:login')}
            </Button>
          </Paper>
        </form>
      </Stack>
    </PageLoader>
  );
};

export default memo(Login);
