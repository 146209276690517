import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker, CalendarPickerProps } from '@mui/x-date-pickers/CalendarPicker';
import { enGB } from 'date-fns/locale';

const CalendarPickerBox: React.FC<CalendarPickerProps<Date>> = props => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <CalendarPicker {...props} dayOfWeekFormatter={e => e} showDaysOutsideCurrentMonth />
    </LocalizationProvider>
  );
};

export default CalendarPickerBox;
