/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, MenuItem } from '@mui/material';
import DashboardLayout from '../../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../../utils/widgets/layouts/user-page-header';
import SearchInputBox from '../../../../utils/widgets/search-input-box';
import { debounce } from '../../../../utils/tools/debounce';
import { GridHeader, StyledPaper } from '../../../../utils/widgets/layouts/reports-layout';
import VacationsGrid from '../../../../utils/widgets/grids/vacations-grid/component';
import useTable from '../../../../utils/hooks/use-table';
import {
  ApiEmployeeService,
  EmployeeWithTimeOffDetailsCollection,
  EmployeeWithTimeOffDetailsResource,
} from '../../../../api/main';
import { TableFilters, TimeOffsRequest, UpdateTimeOffRequest } from '../component.types';
// eslint-disable-next-line max-len
import { RemainedVacationsFormData } from '../../../../utils/widgets/vacations/remained-vacations-modal/component.types';
import useFetch from '../../../../utils/hooks/use-fetch/component';
import CustomSelect from '../../../../utils/widgets/forms/custom-select';
import { EMPLOYEESTYPE } from '../../../../constants/common';
import useErrorHandler from '../../../../utils/hooks/use-error-handler';
import { GridSortModel } from '@mui/x-data-grid';
import RemainedVacationsForm from '../../../../utils/widgets/vacations/remained-vacations-modal/component';
import { useSnackbar } from 'notistack';
import { convertDaysAndHoursToHours } from '../../../../utils/tools/date';

const VacationsReports = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const [isOpen, setIsOpen] = useState(false);
  const selectedItemRef = useRef<(EmployeeWithTimeOffDetailsResource & { id: number }) | null>(null);

  const { page, setPage, setData, pageSize, filters, setFilters } = useTable<
    EmployeeWithTimeOffDetailsCollection,
    TableFilters
  >({
    initialData: { meta: { total: 0 } },
    initialFilters: { status: 'Active', sortModel: [] },
    initialPage: 0,
  });
  const getReports = useFetch<TimeOffsRequest, EmployeeWithTimeOffDetailsCollection>(
    ({ page: newPage, query, status, order, orderBy }) =>
      ApiEmployeeService.apiEmployeesListWithTimeOffs(Number(newPage), pageSize, query, status, order, orderBy)
  );
  const updateTimeOff = useFetch<UpdateTimeOffRequest, unknown>(({ requestBody, userId }) =>
    ApiEmployeeService.apiUpdateEmployeeActivityBanks(userId, requestBody)
  );

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setFilters({ ...filters, sortModel: [...sortModel] });
  }, []);

  useEffect(() => {
    const [sortOption] = filters.sortModel;
    const { field, sort } = sortOption || {};
    getReports
      .call({
        page: page + 1,
        order: sort || undefined,
        orderBy: field,
        query: filters.query ? filters.query : undefined,
        status: filters.status ? (filters.status === 'Active' ? '1' : '2') : undefined,
      })
      .catch(handleError);
    return () => {
      getReports.cancel();
    };
  }, [page, filters, setData]);

  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, query: event.target.value }));
  }, 300);

  const handleUpdateTimeOff = (formData: RemainedVacationsFormData) => {
    if (selectedItemRef.current) {
      const {
        remained_vacation_days: vacationDays,
        remained_vacation_hours: vacationHours,
        remained_day_off_days: dayOffDays,
        remained_day_off_hours: dayOffHours,
      } = formData;
      const { id } = selectedItemRef.current;

      return updateTimeOff
        .call({
          userId: id,
          requestBody: {
            remained_vacations: convertDaysAndHoursToHours(vacationDays, vacationHours),
            remained_day_offs: convertDaysAndHoursToHours(dayOffDays, dayOffHours.value),
          },
        })
        .then(item => {
          setFilters({ ...filters });
          setIsOpen(false);
          selectedItemRef.current = null;
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          return Promise.resolve(item);
        })
        .catch(handleError);
    }
  };

  const handleEdit = (item: unknown) => {
    setIsOpen(true);
    selectedItemRef.current = item as EmployeeWithTimeOffDetailsResource & { id: number };
  };
  const computedInitialData = useMemo(() => {
    if (selectedItemRef.current) {
      const { remained_vacation_hours: vacationsValue, remained_day_off_hours: dayOffsValue } = selectedItemRef.current;
      const remained_vacation_days = vacationsValue ? Number.parseInt(String(vacationsValue / 8)) : 0;
      let remained_vacation_hours = vacationsValue ? Number.parseInt(String(vacationsValue % 8)) : 0;
      const remained_day_off_days = dayOffsValue ? Number.parseInt(String(dayOffsValue / 8)) : 0;
      let remained_day_off_hours = dayOffsValue ? Number.parseInt(String(dayOffsValue % 8)) : 0;
      remained_vacation_hours =
        remained_vacation_hours < 0 ? Math.abs(remained_vacation_hours) : remained_vacation_hours;
      remained_day_off_hours = remained_day_off_days < 0 ? Math.abs(remained_day_off_hours) : remained_day_off_hours;
      return {
        remained_vacation_days,
        remained_vacation_hours,
        remained_day_off_days,
        remained_day_off_hours: { value: remained_day_off_hours, label: remained_day_off_hours },
      } as RemainedVacationsFormData;
    }
    return undefined;
  }, [selectedItemRef.current]) as RemainedVacationsFormData;
  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:employees')} />
      <StyledPaper>
        <GridHeader>
          <SearchInputBox onChange={debouncedChangeHandler} />
          <CustomSelect
            value={filters.status || ''}
            onChange={e => setFilters({ ...filters, status: e.target.value || undefined })}
          >
            <MenuItem value="">All Employees</MenuItem>
            {Object.values(EMPLOYEESTYPE).map(option => (
              <MenuItem key={option} value={option}>
                {t('form:report.filter-employees-option', { value: option })}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridHeader>
        <Divider sx={{ margin: '30px 0' }} />
        <VacationsGrid
          data={getReports?.result as EmployeeWithTimeOffDetailsCollection}
          onSort={handleSortModelChange}
          onPageChange={setPage}
          handleEdit={handleEdit}
          isLoading={getReports.loading}
          page={page}
        />
        <RemainedVacationsForm
          isLoading={updateTimeOff.loading}
          key={String(isOpen)}
          onSubmit={handleUpdateTimeOff}
          onCancel={() => {
            setIsOpen(false);
          }}
          open={isOpen}
          initialData={computedInitialData || undefined}
        />
      </StyledPaper>
    </DashboardLayout>
  );
};

export default VacationsReports;
