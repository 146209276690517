import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues, SubmitHandler, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { VacationModalFormProps } from './component.types';
import { Alert, Box, Stack, Typography } from '@mui/material';
import InputDatePicker from '../../forms/input-date-picker';
import { isWeekend } from 'date-fns';
import { VACATIONSTYPE } from '../../../../constants/report';
import useFetch from '../../../hooks/use-fetch';
import { UserCollection, ApiUserService, UserResource, ApiEmployeeService } from '../../../../api/main';
import { ApiErrorType } from '../../../hooks/use-fetch/component.types';
import { HOURS, PART_OF_DAY } from '../../../../constants/common';
import When from '../../when';
import Autocomplete from '../../forms/autocomplete';
import { UsersRequest } from '../../../../components/admin/reports/component.types';
import { useUser } from '../../../hooks/use-user';
import Info from '@mui/icons-material/Info';
import CollidingBox from '../../colliding-box';
import { useAppConfig } from '../../../hooks/use-app-config';
import useErrorHandler from '../../../hooks/use-error-handler';

const VacationModalForm = <T,>({
  onSubmit,
  open,
  onCancel,
  title = '',
  vacationType,
  dayOffRemained,
  collidingInfo,
}: VacationModalFormProps<T>) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { isHR } = useUser();
  const config = useAppConfig();
  const handleError = useErrorHandler();
  const [error, setError] = useState('');

  const getVacationsInfo = useFetch(ApiEmployeeService.apiEmployeeRemainedBreaks);
  const { handleSubmit, formDebugger, control } = useForm({
    name: 'vacation-modal-form',
    schema: Yup.object().shape({
      start_time: Yup.date().required(),
      end_time: Yup.date().required(),
      ...(isHR ? { employee: Yup.object().shape({ id: Yup.number().required() }) } : {}),
      ...(vacationType === VACATIONSTYPE.DayOffs
        ? {
            hours: Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            }),
            end_time: Yup.date(),
            part_of_day: Yup.object().when({
              is: PART_OF_DAY[1],
              then: Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.number().required(),
              }),
            }),
          }
        : {}),
      ...(vacationType === VACATIONSTYPE.SpecialVacation
        ? {
            special_vacation_type: Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.number().required(),
            }),
          }
        : {}),
    }),
  });

  const getAllUsersApi = useFetch<UsersRequest, UserCollection>(({ page, perPage, query }) =>
    ApiUserService.apiUserList(perPage, page, query)
  );

  const usersOptionsRef = useRef<Omit<UserResource, 'location' | 'status' | 'role'>[]>([]);

  const onSearchUser = useCallback(
    (searchValue: string) =>
      getAllUsersApi
        .call({ query: searchValue, page: 1, perPage: 20 })
        .then((result: UserCollection) => {
          if (result?.data) {
            usersOptionsRef.current = result.data;
          }
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorType) => {
          usersOptionsRef.current = [];

          return Promise.reject(e);
        }),
    [getAllUsersApi]
  );
  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const watchEmployee = useWatch({
    control,
    name: `employee`,
  }) as { id: number };
  useEffect(() => {
    setError('');
    if (watchEmployee?.id && vacationType === VACATIONSTYPE.DayOffs) {
      getVacationsInfo
        .call(watchEmployee.id)
        .then(info => {
          const { day_off_remained: hours } = info;
          if (hours === 4) {
            setError('This employee has 4 more hours left. please submit a half-day-off or vacation instead.');
          }
          if (hours === 0) {
            setError('This employee has no more day-offs left. please submit a vacation instead.');
          }
          return;
        })
        .catch(handleError)
        .finally(() => {});
    }
  }, [watchEmployee, vacationType]);

  const watchHours = useWatch({
    control,
    name: `hours`,
  }) as { value: number; label: string };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={title}
      onOkText="common:submit"
      width="24%"
      isOkDisabled={Boolean(error) && !error.includes('4')}
    >
      <form
        ref={formRef}
        aria-label="vacation-modal-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        {error && <Alert severity="warning">{error}</Alert>}
        <CollidingBox data={collidingInfo} />
        <When condition={isHR}>
          <Autocomplete
            required
            name="employee"
            label={t('form:employee-name')}
            control={control}
            optionKeys={{
              label: ['first_name', 'last_name'],

              value: 'id',
            }}
            placeholder={t('form:employee-placeholder')}
            options={usersOptionsRef.current}
            loading={getAllUsersApi.loading}
            onSearch={onSearchUser}
          />
        </When>
        {vacationType === VACATIONSTYPE.SpecialVacation && (
          <Autocomplete
            label={'Type'}
            name="special_vacation_type"
            control={control}
            required
            optionKeys={{
              label: ['label'],
              value: 'value',
            }}
            placeholder={t('form:choose-vacation-type')}
            options={
              config ? config.special_vacation_types?.split(',').map((val, idx) => ({ label: val, value: idx })) : []
            }
            loading={false}
            onSearch={() => {}}
          />
        )}
        {dayOffRemained === 4 && !isHR && (
          <Stack direction="row" alignItems="center" marginBottom="15px">
            <Info color="error" sx={{ marginRight: '10px' }} />
            <Typography color="error">You may only take 4 hours day-off.</Typography>
          </Stack>
        )}

        <Box>
          <InputDatePicker
            fullWidth
            control={control}
            required
            name={`start_time`}
            label={vacationType === VACATIONSTYPE.DayOffs ? t('form:date') : t('form:cv.start-date')}
            shouldDisableDate={day => {
              return isWeekend(new Date(day));
            }}
          />
        </Box>
        <Box>
          {vacationType === VACATIONSTYPE.DayOffs ? (
            <>
              <Autocomplete
                label={t('form:hours')}
                name="hours"
                control={control}
                required
                optionKeys={{
                  label: ['label'],
                  value: 'value',
                }}
                placeholder={t('form:choose-hours')}
                options={dayOffRemained === 4 || (Boolean(error) && error.includes('4')) ? HOURS.slice(0, 1) : HOURS}
                loading={false}
                onSearch={() => {}}
              />
              {watchHours && watchHours.value === 4 && (
                <Autocomplete
                  label={t('form:part-of-the-day')}
                  name="part_of_day"
                  control={control}
                  required
                  optionKeys={{
                    label: ['label'],
                    value: 'value',
                  }}
                  placeholder={t('form:choose-part-of-the-day')}
                  options={PART_OF_DAY}
                  loading={false}
                  onSearch={() => {}}
                />
              )}
            </>
          ) : (
            <InputDatePicker
              fullWidth
              control={control}
              required
              name={`end_time`}
              label={t('form:cv.end-date')}
              shouldDisableDate={day => {
                return isWeekend(new Date(day));
              }}
            />
          )}
        </Box>
      </form>
    </ConfirmationDialog>
  );
};

export default VacationModalForm;
