import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ProfileBoardProps } from './component.types';
import ProfileInformationForm, { profileInformationFormSchema } from '../profile-information-form';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';
import { ActionsWrapper, SectionTitle } from '../styled';
import Button from '../../button';
import { useAppSelector } from '../../../../store/hooks';
import { storeActions } from '../../../../store';
import { LoggedInUserTypes } from '../../../../store/redux/user/user.types';
import { useWatch } from 'react-hook-form';
import { UserCollection, UserResource, ApiUserService } from '../../../../api/main';
import useFetch from '../../../hooks/use-fetch';
import { UsersRequest } from '../../../../components/admin/reports/component.types';
import { ApiErrorType } from '../../../hooks/use-fetch/component.types';
import { USERTYPE } from '../../../../constants/common';
import When from '../../when';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ProfileBoard = ({ control, onCancel, onNext, trigger, setValue, loading }: ProfileBoardProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(storeActions.user.getUser);
  const userType = (user.userType === USERTYPE.PM ? USERTYPE.EMPLOYEE : user.userType) as LoggedInUserTypes;
  const isHR = userType === USERTYPE.HR;

  const selectedEmployee = useWatch({
    control,
    name: 'employee',
  }) as UserResource;
  const noEmployee = useWatch({
    control,
    name: 'noEmployee',
  }) as boolean;

  const fillForm = useCallback(() => {
    setValue?.('first_name', selectedEmployee.first_name);
    setValue?.('last_name', selectedEmployee.last_name);
    setValue?.('location', selectedEmployee.location);
  }, [selectedEmployee, setValue]);

  const fieldsName = useMemo(
    () => [
      ...Object.keys(profileInformationFormSchema[userType].fields),
      // ...Object.keys(contactDetailsFormSchema[userType].fields), // deprecated
    ],
    [userType]
  );

  const usersOptionsRef = useRef<Omit<UserResource, 'location'>[]>([]);

  const getAllUsersApi = useFetch<UsersRequest, UserCollection>(({ page, perPage, query }) =>
    ApiUserService.apiUserList(perPage, page, query)
  );

  const onSearchUser = useCallback(
    (searchValue: string) =>
      getAllUsersApi
        .call({ query: searchValue, page: 1, perPage: 20 })
        .then((result: UserCollection) => {
          if (result?.data) {
            usersOptionsRef.current = result.data;
          }
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorType) => {
          usersOptionsRef.current = [];
          return Promise.reject(e);
        }),
    [getAllUsersApi]
  );
  const handleNextStep = async () => {
    const result = await trigger(fieldsName);
    if (result) {
      onNext();
    }
  };

  useEffect(() => {
    if (setValue && selectedEmployee) {
      fillForm();
    }
  }, [fillForm, selectedEmployee, setValue]);

  return (
    <Container aria-label="profile-board">
      <SectionTitle sx={{ marginBottom: '16px' }}>{t('form:profile-information')}</SectionTitle>
      <When condition={isHR}>
        <Typography sx={{ marginBottom: '40px' }} variant="overline" paragraph>
          {t('form:profile-information.subtitle')}
        </Typography>
      </When>
      <ProfileInformationForm
        loading={getAllUsersApi.loading}
        userOptions={usersOptionsRef.current}
        onSearchUser={onSearchUser}
        disabled={!selectedEmployee}
        userType={userType}
        control={control}
      />
      {/*<Divider />*/}
      {/*<SectionTitle>{t('form:contact-details')}</SectionTitle> --- deprecated */}
      {/*<ContactDetailsForm userType={userType} control={control} /> --- deprecated */}
      <ActionsWrapper>
        <Button onClick={onCancel} disabled={loading} variant="outlined" color="inherit">
          {t('common:cancel')}
        </Button>
        <Button onClick={handleNextStep} disabled={!selectedEmployee && isHR && !noEmployee} loading={loading}>
          {t('common:next')}
        </Button>
      </ActionsWrapper>
    </Container>
  );
};

export default ProfileBoard;
