import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import useFetch from '../../../utils/hooks/use-fetch';
import useErrorHandler from '../../../utils/hooks/use-error-handler';
import Button from '../../../utils/widgets/button';
import { Add } from '@mui/icons-material';
import DateYearMonthPicker from '../../../utils/widgets/date-year-month-picker';
import {
  ApiEmployeeService,
  ApiWorkingHourService,
  WorkingHourResource,
  EmployeeOverviewResource,
  WorkingHourRequest,
  ApiProjectService,
  AdminProjectResource,
  UserResource,
  ApiEmployeeActivityService,
  AggregatedEmployeeActivityCollection,
} from '../../../api/main';
import { EmployeeOverviewRequest, EmployeeWorkingHoursIndexRequest } from './component.types';
import { addMonths, format, isAfter, startOfMonth, subMonths } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { GridHeader, StyledPaper } from '../../../utils/widgets/layouts/reports-layout';
import WorkHourModalForm from '../../../utils/widgets/employee/workhour-modal-form';
import { WorkHourForm } from '../../../utils/widgets/employee/workhour-modal-form/component.types';
import { formatDate, formatHoursToDayAndHours, formatMinutes } from '../../../utils/tools/date';
import { ReactComponent as LocationOrangeIcon } from '../../../assets/icons/location-orange.svg';
import { ReactComponent as VacationsIcon } from '../../../assets/icons/vacations-blue.svg';
import { ReactComponent as SpecialVacationsIcon } from '../../../assets/icons/special-vacation.svg';
import { ReactComponent as SickDaysIcon } from '../../../assets/icons/sick-day.svg';
import { ReactComponent as WorkLoadIcon } from '../../../assets/icons/work-load.svg';
import { ReactComponent as DateGreenIcon } from '../../../assets/icons/date-green.svg';
import { ReactComponent as ClockPurpleIcon } from '../../../assets/icons/clock-purple.svg';
import { ReactComponent as BalanceIcon } from '../../../assets/icons/balance.svg';
import { ReactComponent as DayOffIcon } from '../../../assets/icons/day-off.svg';
import { ButtonText, CardBox, CardHeading, CardItem, CardItemValueText, IconBox } from './styled';
import { useUser } from '../../../utils/hooks/use-user';
import DashboardLayout from '../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../utils/widgets/layouts/user-page-header';
import PageContainer from '../../../utils/widgets/layouts/page-container';
import PageHeader from '../../../utils/widgets/layouts/page-header';
import { useSnackbar } from 'notistack';
import ActivitesGrid from '../../../utils/widgets/grids/activites-grid';
import { useCollidingBox } from '../../../utils/hooks/use-colliding-box';

const EmployeeOverview = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { clientProfile, isHR } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { userId = clientProfile?.id } = useParams();

  const handleError = useErrorHandler();
  const { clearError, checkForCollding, collidingInfo } = useCollidingBox();
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<{ date: Date }>({
    date: new Date(),
  });
  const [workingHours, setWorkingHours] = useState<AggregatedEmployeeActivityCollection>({ data: [] });
  const [selectedWorkingHour, setSelectedWorkingHour] = useState<WorkingHourResource | undefined>(undefined);
  const getEmployeeWorkingHours = useFetch<EmployeeWorkingHoursIndexRequest, AggregatedEmployeeActivityCollection>(
    ({ from, to }) => ApiEmployeeActivityService.apiEmployeeActivityAggregated(Number(userId), from, to)
  );
  const fetchData = useRef(getEmployeeWorkingHours.call);
  const getEmployee = useFetch(ApiEmployeeService.apiEmployeeShow);

  const getEmployeeOverview = useFetch<EmployeeOverviewRequest, EmployeeOverviewResource>(
    ({ yearNumber, monthNumber }) => ApiEmployeeService.apiEmployeeOverview(Number(userId), monthNumber, yearNumber)
  );
  const fetchEmployeeOverview = useRef(getEmployeeOverview.call);
  const createWorkHour = useFetch<WorkingHourRequest, WorkingHourResource>(payload =>
    ApiWorkingHourService.apiWorkingHourCreate({ ...payload, user_id: Number(userId) })
  );
  const updateWorkHour = useFetch<WorkingHourRequest & { id: number }, void>(body => {
    const { id, ...payload } = body;
    return ApiWorkingHourService.apiWorkingHourUpodate(id, payload);
  });
  const employeeData = useMemo(() => {
    return getEmployee.loaded ? (getEmployee.result as UserResource) : clientProfile;
  }, [getEmployee.loaded, getEmployee.result]);

  const overViewData = useMemo(() => {
    return getEmployeeOverview.loaded ? (getEmployeeOverview.result as EmployeeOverviewResource) : {};
  }, [getEmployeeOverview.loaded, getEmployeeOverview.result]);

  const getAllProjects = useFetch(ApiProjectService.appProjectAssigned);
  const projectsOptionsRef = useRef<AdminProjectResource[]>([]);

  useEffect(() => {
    if (!getEmployee.loaded && !getEmployee.loading && isHR) {
      void getEmployee.call(Number(userId));
    }
  }, [getEmployee, userId]);

  useEffect(() => {
    const { date } = filters;
    const from = startOfMonth(date);
    const endDate = endOfMonth(date);
    const to = isAfter(endDate, new Date()) ? new Date() : endDate;
    if (userId) {
      fetchData
        .current({
          from: formatDate(from),
          to: formatDate(to),
          userId: Number(userId),
        })
        .then(data => setWorkingHours(data))
        .catch(handleError);
      fetchEmployeeOverview
        .current({
          yearNumber: Number(format(from, 'yyyy')),
          monthNumber: Number(format(from, 'M')),
          userId: Number(userId),
        })
        .catch(handleError);
    }
  }, [filters, userId, handleError, clientProfile]);

  useEffect(() => {
    if (!projectsOptionsRef.current.length && userId && !getAllProjects.loading) {
      getAllProjects
        .call(Number(userId))
        .then(({ data = [] }) => {
          projectsOptionsRef.current = data.map(p => ({ ...p, users: [] }));
        })
        .catch(handleError);
    }
  }, [handleError, employeeData?.id, userId]);

  const handleSubmit = (values: WorkHourForm) => {
    const { date, start_time: startTime, end_time: endTime, projects } = values;
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const selectedDate = new Date(date);

    [startDate, endDate].forEach(d => {
      d.setFullYear(selectedDate.getFullYear());
      d.setMonth(selectedDate.getMonth());
      d.setDate(selectedDate.getDate());
    });
    clearError();
    if (selectedWorkingHour) {
      return updateWorkHour
        .call({
          id: Number(selectedWorkingHour?.id),
          start_time: format(startDate, 'Y-MM-dd HH:mm'),
          end_time: format(endDate, 'Y-MM-dd HH:mm'),
          user_id: Number(userId),
          projects: projects.map(p => ({
            id: p.project.id,
            hours: format(p.hours, 'HH:mm'),
          })),
        })
        .then(() => {
          setFilters({ ...filters });
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          setIsOpen(false);
          setSelectedWorkingHour(undefined);
        })
        .catch(checkForCollding);
    } else {
      return createWorkHour
        .call({
          start_time: format(startDate, 'Y-MM-dd HH:mm'),
          end_time: format(endDate, 'Y-MM-dd HH:mm'),
          user_id: Number(userId),
          projects: projects.map(p => ({
            id: Number(p.project.id),
            hours: format(p.hours, 'HH:mm'),
          })),
        })
        .then(() => {
          setFilters({ ...filters });
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          setIsOpen(false);
        })
        .catch(checkForCollding);
    }
  };

  const handleEdit = (workItem: WorkingHourResource) => {
    setSelectedWorkingHour(workItem);
    setIsOpen(true);
  };
  const handleDelete = (id: number) => {
    setWorkingHours({ data: workingHours.data?.filter(h => h.id !== id) });
  };
  const Container = isHR ? PageContainer : DashboardLayout;
  const containerProps = isHR ? { sx: { height: '100vh', boxSizing: 'border-box' } } : {};
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/naming-convention
  const approved_special_vacations = (overViewData as any)?.approved_special_vacations;
  const overviewFields = [
    {
      backgroundColor: theme.palette.yellow.ultraLight,
      title: t('common:location'),
      value: employeeData?.location?.name,
      icon: <LocationOrangeIcon />,
    },
    {
      backgroundColor: theme.palette.green.ultraLight,
      title: t('common:working-hours'),
      value: formatMinutes((overViewData.base_working_hours_for_month || 0) * 60).formatted,
      icon: <DateGreenIcon />,
    },
    {
      backgroundColor: theme.palette.purple.ultraLight,
      title: t('common:normal-hours'),
      value: formatMinutes((overViewData.expected_hours_for_month || 0) * 60).formatted,
      icon: <ClockPurpleIcon />,
    },
    {
      backgroundColor: theme.palette.green.ultraLight,
      title: t('common:hours-worked'),
      value: formatMinutes((overViewData.worked_hours || 0) * 60).formatted,
      icon: <DateGreenIcon />,
    },
    {
      backgroundColor: theme.palette.red.ultraLight,
      color: (overViewData.balance || 0) < 0 ? theme.palette.red.main : 'unset',
      title: t('common:balance'),
      value: formatMinutes((overViewData.balance || 0) * 60).formatted,
      icon: <BalanceIcon />,
    },
    {
      backgroundColor: theme.palette.primary.ultraLight,
      title: t('common:vacation-days'),
      value: formatHoursToDayAndHours((overViewData.approved_vacations || 0) * 8, false),
      icon: <VacationsIcon />,
      color:
        formatHoursToDayAndHours(overViewData.approved_vacations || 0, false) === '0 day'
          ? theme.palette.midGray.main
          : undefined,
    },
    {
      backgroundColor: theme.palette.lightBrown.ultraLight,
      title: t('common:day-offs'),
      value: formatHoursToDayAndHours(overViewData.day_off_hours || 0, false),
      icon: <DayOffIcon />,
      color:
        formatHoursToDayAndHours(overViewData.day_off_hours || 0, false) === '0 day'
          ? theme.palette.midGray.main
          : undefined,
    },
    {
      backgroundColor: theme.palette.pink.ultraLight,
      title: 'Special vacations',
      value: formatHoursToDayAndHours((approved_special_vacations || 0) * 8, false),
      icon: <SpecialVacationsIcon />,
      color:
        formatHoursToDayAndHours((approved_special_vacations as number) || 0, false) === '0 day'
          ? theme.palette.midGray.main
          : undefined,
    },
    {
      backgroundColor: theme.palette.lightBlue.ultraLight,
      title: t('common:sick-days'),
      color: overViewData.sick_days === 0 ? theme.palette.midGray.main : undefined,
      value: formatHoursToDayAndHours((overViewData.sick_days || 0) * 8),
      icon: <SickDaysIcon />,
    },

    {
      backgroundColor: theme.palette.pink.ultraLight,
      title: t('common:work-load'),
      value: `${overViewData.work_load}%`,
      icon: <WorkLoadIcon />,
    },
  ];

  return (
    <Container {...containerProps}>
      {isHR ? (
        <PageHeader onBack={() => navigate(-1)} />
      ) : (
        <UserPageHeader title={t('common:work-hours')}></UserPageHeader>
      )}

      <StyledPaper>
        <GridHeader>
          <Typography variant="h3" sx={{ visibility: isHR ? 'visible' : 'hidden' }}>
            {employeeData?.first_name} {employeeData?.last_name}
          </Typography>
          <Stack direction="row">
            <Button
              variant="outlined"
              color="inherit"
              hasIcon
              sx={{ marginRight: '10px', visibility: isHR ? 'visible' : 'hidden' }}
              onClick={() => setIsOpen(true)}
            >
              <Stack direction="row" alignItems="center">
                <Add sx={{ marginRight: '5px' }} />
                <ButtonText variant="subtitle2">{t('common:add-work-hour')}</ButtonText>
              </Stack>
            </Button>
            <DateYearMonthPicker
              handleNext={() => {
                setFilters({ ...filters, date: addMonths(new Date(filters.date), 1) });
              }}
              handlePrev={() => {
                setFilters({ ...filters, date: subMonths(new Date(filters.date), 1) });
              }}
              onChange={() => {}}
              onMonthChange={value => {
                if (value) {
                  setFilters({ ...filters, date: value });
                }
              }}
              onClear={() => {}}
              value={filters.date}
              renderInput={() => <div />}
            />
          </Stack>
        </GridHeader>
        <Divider sx={{ margin: '30px 0' }} />
        <Stack direction="row" flex="1">
          <CardBox sx={{ flex: 1, marginRight: '20px', minWidth: '432px' }}>
            <CardHeading variant="h4">{format(filters.date, 'MMMM')} Overview</CardHeading>
            <Divider sx={{ margin: '20px 0' }} />
            {getEmployeeOverview.loaded &&
              overviewFields.map(({ backgroundColor, title, value, icon, color }) => (
                <CardItem key={title}>
                  <IconBox
                    sx={{
                      backgroundColor,
                    }}
                  >
                    {icon}
                  </IconBox>
                  <Typography variant="subtitle2" component="span">
                    {title} :
                  </Typography>
                  <CardItemValueText variant="body1" sx={{ color }}>
                    {value}
                  </CardItemValueText>
                </CardItem>
              ))}
          </CardBox>
          <CardBox sx={{ flex: 2 }}>
            <CardHeading variant="h4" marginBottom="20px">
              {format(filters.date, 'MMMM yyyy')}
            </CardHeading>
            <ActivitesGrid
              data={workingHours}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              isLoading={getEmployeeWorkingHours.loading}
            />
          </CardBox>
        </Stack>
      </StyledPaper>

      <WorkHourModalForm
        key={String(isOpen)}
        projects={projectsOptionsRef.current}
        initialData={selectedWorkingHour}
        title={selectedWorkingHour ? t('common:update-work-hour') : t('common:add-work-hour')}
        onSubmit={handleSubmit}
        onCancel={() => {
          setSelectedWorkingHour(undefined);
          setIsOpen(false);
          clearError();
        }}
        open={isOpen}
        collidingInfo={collidingInfo}
      />
    </Container>
  );
};

export default EmployeeOverview;
