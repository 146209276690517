import React, { memo, useState } from 'react';
import { CVDetailsBoardProps } from './component.types';
import { ActionsWrapper, FormWrapper, SectionTitle } from '../styled';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../button';
import DynamicForm from '../../forms/dynamic-form';
import ExperienceForm from '../experience-form';
import EducationForm from '../education-form';
import CertificateForm from '../certificate-form';
import Choose from '../../choose';
import When from '../../when';
import SkillsForm from '../skills-form';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const CVDetailsBoard = ({ control, userType }: CVDetailsBoardProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const actions = [
    {
      label: 'form:cv.add-experience',
      action: () => setStep(0),
    },
    {
      label: 'form:cv.add-education',
      action: () => setStep(1),
    },
    {
      label: 'form:cv.add-certification',
      action: () => setStep(2),
    },
    {
      label: 'form:cv.add-skills',
      action: () => setStep(3),
    },
  ];

  return (
    <Container>
      <SectionTitle>{t('form:cv.cv-details-title')}</SectionTitle>
      <ActionsWrapper
        sx={{
          marginTop: '40px',
        }}
      >
        {actions.map((item, idx) => (
          <Button
            disableRipple
            key={item.label}
            onClick={item.action}
            variant="outlined"
            color={idx !== step ? 'inherit' : 'secondary'}
          >
            {t(item.label)}
          </Button>
        ))}
      </ActionsWrapper>
      <Choose>
        <When condition={step === 0}>
          <DynamicForm
            control={control}
            name="work_experiences"
            key="work_experiences"
            title="common:experience"
            render={({ prefix }) => (
              <FormWrapper>
                <ExperienceForm prefix={prefix} control={control} />
              </FormWrapper>
            )}
            initialData={{
              company_name: '',
              position: '',
              technologies: '',
              description: '',
              start_date: '',
              end_date: '',
              currently_working: false,
            }}
          />
        </When>
        <When condition={step === 1}>
          <DynamicForm
            control={control}
            name="educations"
            key="educations"
            title="common:education"
            render={({ prefix }) => (
              <FormWrapper>
                <EducationForm prefix={prefix} control={control} />
              </FormWrapper>
            )}
            initialData={{
              university: '',
              field_of_study: '',
              description: '',
              start_date: '',
              end_date: '',
              degree: '',
              currently_studying: false,
            }}
          />
        </When>
        <When condition={step === 2}>
          <DynamicForm
            control={control}
            key="certifications"
            name="certifications"
            title="common:certification"
            render={({ prefix }) => (
              <FormWrapper>
                <CertificateForm prefix={prefix} control={control} />
              </FormWrapper>
            )}
            initialData={{
              university: '',
              field_of_study: '',
              description: '',
              start_date: '',
              end_date: '',
              degree: '',
              currently_studying: false,
            }}
          />
        </When>
        <When condition={step === 3}>
          <SkillsForm control={control} userType={userType} />
        </When>
      </Choose>
    </Container>
  );
};

export default memo(CVDetailsBoard);
