import { MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationRequest, ApiLocationService, LocationsCollection } from '../../../api/main';
import useFetch from '../../hooks/use-fetch';
import CustomSelect from '../forms/custom-select';
import { UserSelectProps } from './component.type';

const LocationSelect: React.FC<UserSelectProps> = ({ value, onChange, label = '' }) => {
  const { t } = useTranslation();
  const locationRequest = ({ name }: Partial<LocationRequest> = {}) => ApiLocationService.apiLocationIndex(name);
  const locationApi = useFetch<LocationRequest, LocationsCollection>(locationRequest);

  useEffect(() => {
    void locationApi.call();
  }, []);

  return (
    <CustomSelect value={value} onChange={onChange}>
      <MenuItem value="">{label || t('form:filter-by-location')}</MenuItem>
      {(locationApi.result as LocationsCollection)?.data?.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};
export default LocationSelect;
