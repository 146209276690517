import React from 'react';
import { ProfileInformationFormProps, ProfileInformationFormSchemaTypes } from './component.types';
import { Box, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { USERTYPE } from '../../../../constants/common';
import When from '../../when';
import Autocomplete from '../../forms/autocomplete/component';
import { useWatch } from 'react-hook-form';
import Checkbox from '../../forms/checkbox';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 400,
});

export const profileInformationFormSchema: ProfileInformationFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape({
    title: Yup.string()
      .ensure()
      .matches(/\D+/, { excludeEmptyString: true, message: 'The title must be a string' })
      .required(),
    first_name: Yup.string().ensure().required(),
    last_name: Yup.string().ensure().required(),
  }),
  [USERTYPE.EMPLOYEE]: Yup.object().shape({
    title: Yup.string()
      .ensure()
      .matches(/\D+/, { excludeEmptyString: true, message: 'The title must be a string' })
      .required(),
    first_name: Yup.string(),
    last_name: Yup.string(),
  }),
};

const ProfileInformationForm = ({
  control,
  userType,
  userOptions,
  onSearchUser,
  loading,
  disabled,
}: ProfileInformationFormProps) => {
  const { t } = useTranslation('form');
  const isClient = userType === USERTYPE.EMPLOYEE;

  const noEmployee = useWatch({
    control,
    name: 'noEmployee',
  }) as boolean;

  return (
    <Container>
      <When condition={userType === USERTYPE.HR}>
        <>
          <Autocomplete
            disabled={noEmployee}
            name="employee"
            label={t('form:employee')}
            control={control}
            required
            optionKeys={{
              label: ['first_name', 'last_name'],
              value: 'id',
            }}
            placeholder={t('form:employee-placeholder')}
            options={userOptions}
            loading={!!loading}
            onSearch={onSearchUser}
            errorMessage={noEmployee ? ' ' : undefined}
          />
          <Checkbox name="noEmployee" label="Candidate" control={control} />
        </>
      </When>
      <FormItemText defaultValue="" control={control} required name="title" label={t('job-title')}>
        <TextField placeholder={t('form:job-title-example')} />
      </FormItemText>
      <FormItemText
        defaultValue=""
        control={control}
        disabled={(disabled || isClient) && !noEmployee}
        required
        name="first_name"
        label={t('first-name')}
      >
        <TextField />
      </FormItemText>
      <FormItemText
        defaultValue=""
        control={control}
        disabled={(disabled || isClient) && !noEmployee}
        required
        name="last_name"
        label={t('last-name')}
      >
        <TextField />
      </FormItemText>
    </Container>
  );
};

export default ProfileInformationForm;
