/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminProjectResource } from '../models/AdminProjectResource';
import type { AdminProjectsCollection } from '../models/AdminProjectsCollection';
import type { AssignedProjectResource } from '../models/AssignedProjectResource';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { ProjectsWorkLogCollection } from '../models/ProjectsWorkLogCollection';
import type { ProjectUserRequest } from '../models/ProjectUserRequest';
import type { RemoveProjectUserRequestBody } from '../models/RemoveProjectUserRequestBody';
import type { SingleProjectWorkLogDetailsResource } from '../models/SingleProjectWorkLogDetailsResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiProjectService {

    /**
     * Assign user to project
     * Assign user to project.
     * @param projectId Project identifier.
     * @param requestBody Assign project user request
     * @returns any response with no content
     * @throws ApiError
     */
    public static apiProjectAssignUser(
        projectId: number,
        requestBody: ProjectUserRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/assign-user',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Projects List
     * Paginated list of all projects.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param name
     * @param status ONGOING or CLOSED
     * @param sort eg. sort[][by]=created_at, sort[][by]=desc
     * @returns AdminProjectsCollection Api project collection response
     * @throws ApiError
     */
    public static apiProjectIndex(
        page?: number,
        pageSize?: number,
        name?: string,
        status?: string,
        sort?: string,
    ): CancelablePromise<AdminProjectsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects',
            query: {
                'page': page,
                'pageSize': pageSize,
                'name': name,
                'status': status,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Project
     * This endpoint take project data and create it.
     * @param requestBody Api create/update project request
     * @returns AdminProjectResource Admin create/update project response
     * @throws ApiError
     */
    public static apiProjectCreate(
        requestBody: ProjectRequest,
    ): CancelablePromise<AdminProjectResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Project (Softly)
     * This endpoint take project identifier and delete it softly.
     * @param projectId Project identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiProjectDelete(
        projectId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Update Project
     * This endpoint take project data and update it.
     * @param projectId Project identifier.
     * @param requestBody Api create/update project request
     * @returns AdminProjectResource Admin create/update project response
     * @throws ApiError
     */
    public static apiProjectUpdate(
        projectId: number,
        requestBody: ProjectRequest,
    ): CancelablePromise<AdminProjectResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Assigned & Ongoing Projects List
     * List of all assigned and ongoing projects.
     * @param userId
     * @returns any Return list of all projects.
     * @throws ApiError
     */
    public static appProjectAssigned(
        userId?: number,
    ): CancelablePromise<{
        data?: Array<AssignedProjectResource>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/assigned',
            query: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
    }

    /**
     * Get Project Working Hours Over Months
     *
     * * This endpoint take number og months and generate working hours report for project in this last months.
     * @param lastNthMonths Last nth months.
     * @returns any Report excel file
     * @throws ApiError
     */
    public static apiProjectWorkingHoursOverMonths(
        lastNthMonths: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/projects/working-hours-over-months/{last_nth_months}',
            path: {
                'last_nth_months': lastNthMonths,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Projects List with work-log deitals
     * Projects List with work-log deitals
     * @param startDate
     * @param endDate
     * @param projectId
     * @returns ProjectsWorkLogCollection Api project work log collection response
     * @throws ApiError
     */
    public static apiProjectsWorkLogs(
        startDate?: string,
        endDate?: string,
        projectId?: number,
    ): CancelablePromise<ProjectsWorkLogCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/work-log-details',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Remove user from project
     * Remove user from project
     * @param projectId Project identifier.
     * @param requestBody Remove project user request
     * @returns any response with no content
     * @throws ApiError
     */
    public static apiProjectRemoveUser(
        projectId: number,
        requestBody: RemoveProjectUserRequestBody,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/projects/{project_id}/remove-user',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Single projects work-log deitals
     * Single projects work-log deitals
     * @param projectId Project identifier.
     * @param startDate
     * @param endDate
     * @returns SingleProjectWorkLogDetailsResource Single Project work-log details response
     * @throws ApiError
     */
    public static apiSingleProjectWorkLogs(
        projectId: number,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<SingleProjectWorkLogDetailsResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/work-log-details',
            path: {
                'project_id': projectId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
