/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmployeeActivityStatusRequest } from '../models/ChangeEmployeeActivityStatusRequest';
import type { EmployeeActivitiesCollection } from '../models/EmployeeActivitiesCollection';
import type { EmployeeActivityResource } from '../models/EmployeeActivityResource';
import type { SpecialVacationRequest } from '../models/SpecialVacationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSpecialVacationService {

    /**
     * Change Special Vacation Status
     * This endpoint take special vacation identifier and change its status.
     * @param specialVacationId Special vacation identifier.
     * @param requestBody change employee activity status request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static specialVacationChangeStatus(
        specialVacationId: number,
        requestBody: ChangeEmployeeActivityStatusRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/special-vacations/{special_vacation_id}/change-status',
            path: {
                'special_vacation_id': specialVacationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Special Vacations List
     * Paginated list of all special vacations.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param userId
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @returns EmployeeActivitiesCollection employee activity collection response
     * @throws ApiError
     */
    public static specialVacationIndex(
        page?: number,
        pageSize?: number,
        userId?: number,
        status?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<EmployeeActivitiesCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/special-vacations',
            query: {
                'page': page,
                'pageSize': pageSize,
                'user_id': userId,
                'status': status,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Special Vacation
     * This endpoint take special vacation data and create it.
     * @param requestBody Create special vaation request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static specialVacationCreate(
        requestBody: SpecialVacationRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/special-vacations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
