import React from 'react';
import { styled, Box, Select, SelectProps } from '@mui/material';
import { ReactComponent as FilterIconLight } from '../../../../assets/icons/filter-light.svg';
import { ReactComponent as FilterIconDark } from '../../../../assets/icons/filter-dark.svg';
import RenderBasedOnTheme from '../../render-based-on-theme';
import { CustomSelectType } from './component.types';

const StyledSelect = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.fieldsBorder.main}`,
  padding: '0px 15px',
  '& > svg': {
    flexShrink: 0,
  },
}));

const CustomSelect = React.forwardRef(function CustomSelect<TValue>(
  props: SelectProps<TValue>,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  return (
    <StyledSelect>
      <RenderBasedOnTheme dark={<FilterIconDark />} light={<FilterIconLight />} />
      <Select
        {...props}
        displayEmpty
        ref={ref}
        variant="standard"
        sx={{
          width: '230px',
          marginLeft: '10px',
          '&::before,&::after': {
            display: 'none',
          },
        }}
      >
        {props.children}
      </Select>
    </StyledSelect>
  );
}) as CustomSelectType;

export default CustomSelect;
