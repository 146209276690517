import React from 'react';
import { SkillsPreviewProps } from './component.types';
import { ListText, PageContainer, SectionTitle, StyledChip } from '../styled';
import { useTranslation } from 'react-i18next';
import { COMMON } from '../../../../constants/common';
import { Skills } from '../skills-form';
import { Box, IconButton, styled } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import When from '../../when';

const ListView = styled(Box)({
  justifyContent: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  margin: '20px 0',
});

const SkillsPreview = ({ data, onEdit, isLoading = false }: SkillsPreviewProps) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      sx={{
        padding: 0,
        marginBottom: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <SectionTitle sx={{ marginBottom: '0' }}>{t('form:cv.skills')}</SectionTitle>
        <When condition={!!onEdit}>
          <IconButton
            disabled={isLoading}
            size="small"
            sx={{ margin: '0 10px' }}
            {...(onEdit && data ? { onClick: () => onEdit?.(data) } : {})}
          >
            <EditOutlinedIcon />
          </IconButton>
        </When>
      </Box>
      <ListText>
        {t('form:cv.core-competencies')}
        {COMMON.COLON}
      </ListText>
      <ListView>
        {data?.hard_skills?.map((option: Skills) => (
          <StyledChip
            sx={{ margin: '5px' }}
            key={`${option.name}-${option.id}`}
            label={option.name}
            /*{...(onEdit ? { onDelete: () => {} } : {})}*/
          />
        ))}
      </ListView>
      <ListText>
        {t('form:cv.soft-skills')}
        {COMMON.COLON}
      </ListText>
      <ListView>
        {data?.soft_skills?.map((option: Skills) => (
          <StyledChip
            sx={{ margin: '5px' }}
            key={`${option.name}-${option.id}`}
            label={option.name}
            /*{...(onEdit ? { onDelete: () => {} } : {})}*/
          />
        ))}
      </ListView>
    </PageContainer>
  );
};

export default SkillsPreview;
