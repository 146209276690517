/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CVCertificationResource } from './CVCertificationResource';
import type { CVEducationResource } from './CVEducationResource';
import type { CVSkillResource } from './CVSkillResource';
import type { CVWorkExperienceResource } from './CVWorkExperienceResource';
import type { LocationResource } from './LocationResource';
import type { UserResource } from './UserResource';

export type CVResource = {
    id?: number;
    title?: string;
    first_name?: string;
    last_name?: string;
    introduction?: string;
    location?: LocationResource;
    status_id?: CVResource.status_id;
    comment?: string;
    user?: UserResource;
    soft_skills?: Array<CVSkillResource>;
    hard_skills?: Array<CVSkillResource>;
    work_experiences?: Array<CVWorkExperienceResource>;
    educations?: Array<CVEducationResource>;
    certifications?: Array<CVCertificationResource>;
    /**
     * Initial creation timestamp
     */
    created_at?: string;
    /**
     * Last update timestamp
     */
    updated_at?: string;
};

export namespace CVResource {

    export enum status_id {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}

