import React, { useState } from 'react';
import { InputDatePickerProps, NullishString } from './component.types';
import { useController } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import FormInputLabel from '../form-input-label';
import { getDateOnlyDashed } from '../../../tools/date';
import { debounce } from '../../../tools/debounce';
import { enGB } from 'date-fns/locale';

const InputDatePicker = ({
  defaultValue = null,
  label,
  disabled = false,
  fullWidth = false,
  required = false,
  control,
  formatter = getDateOnlyDashed,
  format = 'yyyy-MM-dd',
  mask = '____-__-__',
  name,
  shouldDisableDate = () => false,
  maxDate = undefined,
  minDate = undefined,
}: InputDatePickerProps) => {
  const [open, setOpen] = useState(false);
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue ?? '',
    name: name,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onChange, ref, value: fieldValue, onBlur } = field;
  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  const [pickerValue, setPickerValue] = useState<NullishString>((fieldValue as string) || '');

  const handleOnchange = debounce((newValue: NullishString) => {
    let value = newValue;
    if (!value) {
      value = '';
    }
    setPickerValue(value);
    if (formatter?.(value) || !value) {
      onChange(formatter?.(value));
    }
  });

  // const handleDisable = useCallback(() => {
  //   if (disabled && pickerValue) {
  //     setPickerValue(null);
  //     onChange(null);
  //   }
  // }, [disabled, onChange, pickerValue]);

  // useEffect(() => {
  //   handleDisable();
  // }, [disabled, handleDisable]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DatePicker
        open={open}
        onOpen={() => !disabled && setOpen(true)}
        inputRef={ref}
        value={pickerValue}
        mask={mask}
        maxDate={maxDate}
        minDate={minDate}
        inputFormat={format}
        disabled={disabled}
        toolbarTitle={`${htmlFor}-picker`}
        onChange={handleOnchange}
        onClose={() => {
          setOpen(false);
        }}
        shouldDisableDate={shouldDisableDate}
        renderInput={params => (
          <FormInputLabel htmlFor={htmlFor} required={required} disabled={!!disabled} label={label}>
            <TextField
              {...params}
              data-testid={htmlFor}
              onBlur={() => {
                if (open) return;
                return onBlur();
              }}
              name={name}
              onClick={() => !disabled && setOpen(true)}
              aria-label={htmlFor}
              error={invalid}
              required={required}
              helperText={!disabled && error?.message}
              fullWidth={fullWidth}
              type="date"
            />
          </FormInputLabel>
        )}
      />
    </LocalizationProvider>
  );
};
export default InputDatePicker;
