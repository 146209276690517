import React, { useCallback, useRef, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { AddRemoveProjectRequest, CreateProjectForm, CreateProjectModalProps, ProjectUser } from './component.types';
import { Delete } from '@mui/icons-material';
import { Box, DialogActions, Divider, Stack, styled, TextField, Typography } from '@mui/material';
import InputDatePicker from '../../forms/input-date-picker';
import { format } from 'date-fns';
import Autocomplete from '../../forms/autocomplete';
import useErrorHandler from '../../../hooks/use-error-handler';
import useFetch from '../../../hooks/use-fetch';
import {
  ApiUserService,
  UserCollection,
  ApiProjectService,
  ProjectUserResource,
  AdminProjectResource,
} from '../../../../api/main';
import FormItemText from '../../forms/form-item-text';
import { EMPLOYEE_ROLES_OPTIONS, PROJECT_STATUSES_OPTIONS } from '../../../../constants/server-constants';
import { UsersRequest } from '../../../../components/admin/reports/component.types';
import { ApiErrorType } from '../../../hooks/use-fetch/component.types';
import Button from '../../button';
import Checkbox from '../../forms/checkbox';
import { LoadingButton } from '@mui/lab';

const createProjectFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  status: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  start_date: Yup.string().ensure().dashedDateOnly().required(),
  end_date: Yup.string().ensure().dashedDateOnly(),
  target: Yup.string().nullable(true).optional(),
  is_public: Yup.boolean().required(),
});

const ActionButton = styled(LoadingButton)({
  marginLeft: '12px',
  marginTop: '37px',
  minWidth: '40px',
  height: '40px',
  padding: 0,
  '&.Mui-disabled': {
    color: 'unset',
    background: 'unset',
    opacity: 0.5,
  },
});

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  onSubmit,
  open,
  onCancel,
  title = '',
  initialData,
  onUpdate,
  isLoading,
}) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();
  const [data, setData] = useState<AdminProjectResource | undefined>({
    ...initialData,
    users: initialData?.users?.map(u => ({
      ...u,
      user: { ...u.user, statusText: (u.user as ProjectUser).status.id === 2 ? '( Archived)' : '' },
    })),
  });
  const getAllUsersApi = useFetch<UsersRequest, UserCollection>(({ page, perPage, query }) =>
    ApiUserService.apiUserList(perPage, page, query)
  );
  const usersOptionsRef = useRef<ProjectUser[]>([]);
  const [formValues, setFormValues] = useState<CreateProjectForm | null>(null);
  const assignUserProject = useFetch<AddRemoveProjectRequest, unknown>(({ projectId, requestBody }) =>
    ApiProjectService.apiProjectAssignUser(projectId, requestBody)
  );
  const removeUserProject = useFetch<AddRemoveProjectRequest, unknown>(({ projectId, requestBody }) =>
    ApiProjectService.apiProjectRemoveUser(projectId, requestBody)
  );
  const pendingIdx = useRef<number | undefined>(undefined);
  const onSearchUser = useCallback(
    (searchValue: string) =>
      getAllUsersApi
        .call({ query: searchValue, page: 1, perPage: 20 })
        .then((result: UserCollection) => {
          if (result?.data) {
            usersOptionsRef.current = result.data.map(
              user =>
                ({ ...user, statusText: (user as ProjectUser).status.id === 2 ? '( Archived)' : '' } as ProjectUser)
            );
          }
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorType) => {
          usersOptionsRef.current = [];
          return Promise.reject(e);
        }),
    [getAllUsersApi]
  );

  const { handleSubmit, formDebugger, control, trigger } = useForm({
    name: 'create-project-form-modal',
    schema: createProjectFormSchema,
    defaultValues: {
      name: data?.name,
      status: data?.status ? { label: data?.status, value: data?.status } : PROJECT_STATUSES_OPTIONS[0],
      start_date: data?.start_date ? format(new Date(data.start_date), 'yyyy-MM-dd') : '',
      end_date: data?.end_date ? format(new Date(data.end_date), 'yyyy-MM-dd') : '',
      target: data?.target || undefined,
      users: data?.users?.length
        ? data?.users.map(item => ({ ...item, position: { value: item.position, label: item.position } }))
        : [],
      is_public: data?.is_public,
    },
  });
  const { fields, remove, append } = useFieldArray({ control, name: 'users' });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const users = useWatch({
    control,
    name: 'users',
  }) as Array<ProjectUserResource>;

  return (
    <>
      <ConfirmationDialog
        open={open}
        onCancel={onCancel}
        onOk={handleOnSubmit}
        title={title}
        onOkText="common:submit"
        width="80%"
        maxWidth="700px"
        showActions={false}
      >
        <>
          <form
            ref={formRef}
            aria-label="create-proejct-form"
            onSubmit={handleSubmit(
              values => {
                const { is_public: isPublic } = values as CreateProjectForm;
                if (isPublic && initialData) {
                  setFormValues(values as CreateProjectForm);
                  return Promise.resolve();
                } else return onSubmit(values as CreateProjectForm);
              },
              false,
              err => {
                if (typeof err === 'string') {
                  void handleError(err);
                }
              }
            )}
          >
            {formDebugger()}
            <FormItemText
              defaultValue={initialData?.name}
              control={control}
              required
              name="name"
              label={t('table:projects.name')}
            >
              <TextField type="text" />
            </FormItemText>
            <Stack direction="row" alignItems="center">
              <Autocomplete
                label={t('common:project-status')}
                name="status"
                defaultValue={
                  initialData?.status ? { label: initialData.status, value: initialData.status } : undefined
                }
                control={control}
                required
                optionKeys={{
                  label: ['label'],
                  value: 'value',
                }}
                placeholder={t('form:choose-project-status')}
                options={PROJECT_STATUSES_OPTIONS.map(d => d)}
                loading={false}
                onSearch={() => {}}
              />
              <Box sx={{ width: '20px' }} />
              <FormItemText
                defaultValue={initialData?.target || undefined}
                control={control}
                name="target"
                label={t('table:projects.target-hours')}
              >
                <TextField type="text" />
              </FormItemText>

              <Stack direction="row" sx={{ marginLeft: '30px', width: '100%' }}>
                <Checkbox checked={false} name="is_public" label="Public" control={control} />
              </Stack>
            </Stack>
            <Stack direction="row" marginBottom={'24px'}>
              <InputDatePicker fullWidth control={control} required name="start_date" label={t('form:cv.start-date')} />
              <Box sx={{ width: '20px' }} />
              <InputDatePicker fullWidth control={control} name="end_date" label={t('form:cv.end-date')} />
            </Stack>

            <DialogActions>
              <Button onClick={handleOnSubmit} loading={isLoading}>
                Submit
              </Button>
            </DialogActions>
          </form>
          <Divider sx={{ marginTop: '24px' }} />
          <Typography display="inline-block" variant="h2" paragraph margin="24px 0">
            Employees
          </Typography>
          {initialData &&
            data &&
            fields.map((field, idx) => {
              const userProject = users[idx];
              const isUpadting = pendingIdx.current === idx;
              const position = (userProject?.position as unknown as { label: string })?.label;
              const disabled = Boolean(data.users && data.users[idx]);
              return (
                <Stack direction="row" key={userProject?.id || field.id} position="relative">
                  <Autocomplete
                    disabled={disabled}
                    name={`users[${idx}].user`}
                    label={t('form:assign-employee')}
                    control={control}
                    optionKeys={{
                      label: ['first_name', 'last_name', 'statusText'],
                      value: 'id',
                    }}
                    placeholder={t('form:employee-placeholder')}
                    options={usersOptionsRef.current as unknown as Record<string, string | number>[]}
                    loading={getAllUsersApi.loading}
                    hasSpinner={false}
                    onSearch={onSearchUser}
                    errorMessage="Please choose an employee"
                  />
                  <Box sx={{ width: '20px' }} />
                  <Autocomplete
                    disabled={disabled}
                    label={t('form:employee-role')}
                    name={`users[${idx}].position`}
                    control={control}
                    optionKeys={{
                      label: ['label'],
                      value: 'value',
                    }}
                    placeholder={t('form:choose-project-status')}
                    options={EMPLOYEE_ROLES_OPTIONS.map(d => d)}
                    loading={false}
                    onSearch={() => {}}
                    errorMessage="Please choose an employee role"
                  />
                  <ActionButton
                    disabled={assignUserProject.loading && isUpadting}
                    loading={removeUserProject.loading && isUpadting}
                    onClick={() => {
                      if (initialData.id && userProject.position && userProject.user?.id) {
                        pendingIdx.current = idx;
                        removeUserProject
                          .call({
                            projectId: initialData.id,
                            requestBody: { position: String(userProject.position), user_id: userProject.user?.id },
                          })
                          .then(() => {
                            setData({ ...data, users: data?.users?.filter((_, index) => index !== idx) });
                            pendingIdx.current = undefined;
                            remove(idx);
                            onUpdate();
                          })
                          .catch(handleError);
                      } else {
                        remove(idx);
                      }
                    }}
                  >
                    <Delete color="error" />
                  </ActionButton>

                  {/* <ActionButton
                    sx={{ opacity: disabled ? '0' : '1' }}
                    disabled={removeUserProject.loading && isUpadting}
                    loading={assignUserProject.loading && isUpadting}
                    onClick={() => {
                      void trigger();

                      if (initialData.id && userProject.position && userProject.user?.id) {
                        pendingIdx.current = idx;
                        assignUserProject
                          .call({
                            projectId: initialData.id,
                            requestBody: { position, user_id: userProject.user?.id },
                          })
                          .then(() => {
                            pendingIdx.current = undefined;
                            onUpdate();
                            setData({ ...data, users: data?.users?.concat(userProject) });
                          })
                          .catch(handleError);
                      }
                    }}
                  >
                    <Check color="success" />
                  </ActionButton> */}
                  <Button
                    sx={{
                      opacity: disabled ? '0' : '1',
                      marginLeft: '12px',
                      marginTop: '38px',
                      minWidth: '40px',
                      height: '40px',
                    }}
                    disabled={removeUserProject.loading && isUpadting}
                    loading={assignUserProject.loading && isUpadting}
                    onClick={() => {
                      void trigger();

                      if (initialData.id && userProject.position && userProject.user?.id) {
                        pendingIdx.current = idx;
                        assignUserProject
                          .call({
                            projectId: initialData.id,
                            requestBody: { position, user_id: userProject.user?.id },
                          })
                          .then(() => {
                            pendingIdx.current = undefined;
                            onUpdate();
                            setData({ ...data, users: data?.users?.concat(userProject) });
                          })
                          .catch(handleError);
                      }
                    }}
                  >
                    Add
                  </Button>
                </Stack>
              );
            })}
          {initialData && (
            <Box sx={{ margin: '20px 0 10px' }}>
              <Button
                variant="outlined"
                color="inherit"
                hasIcon
                sx={{ marginRight: '10px' }}
                onClick={() => append({ id: Math.random(), user: {}, position: undefined })}
              >
                New User
              </Button>
            </Box>
          )}
        </>
      </ConfirmationDialog>
      <ConfirmationDialog
        width="30%"
        padding="10px"
        onOk={() => {
          onSubmit(formValues as CreateProjectForm)
            .then(() => setFormValues(null))
            .catch(() => setFormValues(null));
        }}
        onCancel={() => {
          setFormValues(null);
        }}
        title=""
        open={Boolean(formValues)}
      >
        <Typography variant="h4" paragraph>
          All employees will have access,are you sure?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default CreateProjectModal;
