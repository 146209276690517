import { useEffect, useRef, useState } from 'react';
import { PAGINATION } from '../../../constants/common';
import { useSearchParams } from 'react-router-dom';

const useTable = <Data, Filters extends Record<string, unknown>>(props: {
  initialData?: Data;
  initialFilters?: Filters;
  initialPage?: number;
  pageSize?: number;
  isInQuery?: boolean;
}) => {
  const [query, setQuery] = useSearchParams();
  const { initialData, initialPage, initialFilters, isInQuery } = props;
  //    Local states
  const [data, setData] = useState<Data>(props.initialData || ({} as Data));
  const [filters, setFilters] = useState<Filters>(() => {
    const queryFilters = query.get('filters') as string;
    if (queryFilters && isInQuery) return JSON.parse(queryFilters) as Filters;
    return props.initialFilters || ({} as Filters);
  });
  const [page, setPage] = useState(() => {
    const queryPage = query.get('page') as string;
    if (queryPage && isInQuery) return Number(queryPage);
    return props.initialPage;
  });

  const reset = useRef(() => {
    setPage(initialPage || 0);
    setData(initialData || ({} as Data));
    setFilters(initialFilters || ({} as Filters));
  });

  useEffect(() => {
    if (isInQuery) setQuery({ filters: JSON.stringify(filters), page: String(page) }, { replace: true });
  }, [filters, page]);

  return {
    pageSize: PAGINATION.PAGE_SIZE,
    page: page || 0,
    data,
    filters,
    setData,
    setPage,
    setFilters,
    reset: reset.current,
  };
};

export default useTable;
