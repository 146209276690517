import React, { useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarMonth, ChevronLeft } from '@mui/icons-material';
import { styled, Box, Typography } from '@mui/material';
import { DateYearMonthPickerProps } from './component.types';
import { enGB } from 'date-fns/locale';

import { DatePicker } from '@mui/x-date-pickers';

const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.fieldsBorder.main}`,
  padding: '0px 15px',
  position: 'relative',
  '& > *': {
    flexShrink: 0,
  },
  '> :first-child, > :last-child': {
    margin: '0 4px',
    cursor: 'pointer',
  },
}));
const PickerTrigger = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: '45px',
  right: '42px',
  bottom: 0,
  '*': {
    width: '100%',
    height: '40px',
    opacity: 0,
  },
});
// const PickerInput = styled(TextField)({})

const DateYearMonthPicker: React.FC<DateYearMonthPickerProps<Date, Date>> = ({ handleNext, handlePrev, ...props }) => {
  const ref = useRef(null);

  const handleClick = () => {};

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DateRangeBox ref={ref}>
        <ChevronLeft onClick={handlePrev} />
        <CalendarMonth onClick={handleClick} />
        <DatePicker
          {...props}
          views={['year', 'month']}
          label="Year and Month"
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div ref={inputRef}>
              <Typography variant="subtitle2" component="span" sx={{ marginLeft: '10px' }}>
                {inputProps?.value}
              </Typography>
              <PickerTrigger>{InputProps?.endAdornment}</PickerTrigger>
            </div>
          )}
        />
        <ChevronLeft sx={{ transform: 'rotate(180deg)' }} onClick={handleNext} />
      </DateRangeBox>
    </LocalizationProvider>
  );
};

export default DateYearMonthPicker;
