import React from 'react';
import { UserPageHeaderProps } from './component.types';
import { Box, Paper, Stack, styled, Typography } from '@mui/material';
import ToggleThemeSwitch from '../toggle-theme-switch/component';
import When from '../../when';

const StyledPaper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 82,
  padding: '30px',
  boxSizing: 'border-box',
});

const UserPageHeader = ({ title, children }: UserPageHeaderProps) => {
  return (
    <StyledPaper>
      <Typography aria-label="page-header" variant="h3">
        {title}
      </Typography>
      <Stack direction="row" alignItems="center">
        <When condition={Boolean(children)}>
          <Box sx={{ marginRight: '15px' }}>{children}</Box>
        </When>
        <ToggleThemeSwitch />
      </Stack>
    </StyledPaper>
  );
};

export default UserPageHeader;
