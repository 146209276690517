import DashboardLayout from '../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../utils/widgets/layouts/user-page-header';
import { Box, styled } from '@mui/material';
import { StyledPaper } from '../../../utils/widgets/layouts/reports-layout';

import TimeSheet from '../../../utils/widgets/dashboard/timesheet';
import Statistics from '../../../utils/widgets/dashboard/statistics';
import UserCalendar from '../../../utils/widgets/dashboard/calendar';
import WorkingHoursGrid from '../../../utils/widgets/grids/working-hours-grid';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { CardHeading } from '../../admin/employee-overview/styled';
import { useTranslation } from 'react-i18next';
import useForceUpdate from '../../../utils/hooks/use-force-update';
import DailyRecords from '../../../utils/widgets/dashboard/daily-records';
import { useRef, useState } from 'react';

const DashboardLayoutBox = styled(Box)({
  display: 'grid',
  gridTemplate: `"timesheet statistics calendar"
  "monthly-balance monthly-balance daily-records"
  `,
  gap: '20px',
  gridTemplateColumns: '1fr 1fr auto',
  gridTemplateRows: 'auto 1fr',
  height: '100%',
});

const GridItem = styled(Box)<{ areaName: string }>(({ areaName, theme }) => ({
  gridArea: areaName,
  padding: '20px',
  paddingBottom: '5px',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '10px',
  '> :nth-child(2)': {
    marginTop: '20px',
  },
}));

export type TimerInfo = {
  currentHours: number;
  startTime: Date;
};

const ClientDashboard = () => {
  const { forceUpdate, value } = useForceUpdate();
  const { t } = useTranslation();
  const [timerInfo, setTimerInfo] = useState<TimerInfo>({ currentHours: 0, startTime: new Date() });

  const fitlers = useRef({
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  return (
    <DashboardLayout>
      <div hidden>Deploy Test</div>
      <UserPageHeader title="Dashboard" />
      <StyledPaper>
        <DashboardLayoutBox>
          <GridItem areaName="timesheet" sx={{ minHeight: '422px' }}>
            <TimeSheet handleChange={forceUpdate} onTimerUpdate={setTimerInfo} />
          </GridItem>
          <GridItem areaName="statistics ">
            <Statistics key={String(value)} timerInfo={timerInfo} />
          </GridItem>
          <GridItem areaName="calendar">
            <UserCalendar />
          </GridItem>
          <GridItem areaName="monthly-balance">
            <CardHeading variant="h4">{t('common:monthly-balance')}</CardHeading>
            <WorkingHoursGrid
              key={String(value)}
              filters={fitlers.current}
              hasPagination={false}
              sendUserId
              pageSize={100}
            />
          </GridItem>
          <GridItem areaName="daily-records">
            <DailyRecords />
          </GridItem>
        </DashboardLayoutBox>
      </StyledPaper>
    </DashboardLayout>
  );
};

export default ClientDashboard;
