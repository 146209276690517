import React, { memo, ReactElement, useMemo, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { appThemeDark, appThemeLight } from '../styles/themes';
import { StyleProviderContext } from '../styles';
import { LOCALSTORAGEKEYS } from '../constants/local-storage-keys';
import { THEME } from '../constants/theme';

export type StyleProviderProps = {
  children: ReactElement | ReactElement[];
  appMode?: string;
};

const localstorageKey = process.env.REACT_APP_THEME_KEY || LOCALSTORAGEKEYS.APP_THEME;
const defaultTheme = localStorage.getItem(localstorageKey) || THEME.LIGHT;

function StyleProvider({ children, appMode }: StyleProviderProps) {
  const [mode, setMode] = useState(appMode ?? defaultTheme);

  const appTheme = useMemo(() => {
    localStorage.setItem(localstorageKey, mode);
    if (mode === THEME.LIGHT) {
      return appThemeLight;
    }
    return appThemeDark;
  }, [mode]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.theme = appTheme;
    }
  }, [appTheme]);

  useEffect(() => {
    if (appMode) {
      setMode(appMode);
    }
  }, [appMode]);

  return (
    <StyleProviderContext.Provider
      value={{
        mode,
        setMode,
      }}
    >
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            a: { all: 'unset' },
            'html, body': { all: 'unset' },
            p: { all: 'unset' },
            body: {
              backgroundColor: appTheme.palette.appBackground.main,
              overflowX: 'hidden',
              width: '100vw',
              minWidth: '100vw',
              maxWidth: '100vw',
            },
          }}
        />
        {children}
      </ThemeProvider>
    </StyleProviderContext.Provider>
  );
}

export default memo(StyleProvider);
