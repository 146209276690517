import React, { useMemo, useState } from 'react';
import { CreateCVProps } from './component.types';
import { Box } from '@mui/material';
import Stepper from '../stepper';
import * as Yup from 'yup';
import useForm from '../../../hooks/use-form';

import CVBoard from '../cv-board';
import ProfileBoard from '../profile-board';
import When from '../../when';
import Choose from '../../choose';
import { ActionsWrapper, PageBody, PageContainer, PageWrapper } from '../styled';
import { profileInformationFormSchema } from '../profile-information-form';
// import { contactDetailsFormSchema } from '../contact-details-form';  // deprecated
import { cvInformationFormSchema } from '../cv-information-form';
import { cvDetailsExperienceFormSchema } from '../experience-form';
import { cvDetailsEducationFormSchema } from '../education-form';
import { cvDetailsCertificateFormSchema } from '../certificate-form';
import PreviewCV from '../preview-cv';
import Button from '../../button';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useAppSelector } from '../../../../store/hooks';
import { LoggedInUserTypes } from '../../../../store/redux/user/user.types';
import { storeActions } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../providers/routes';
import { EducationForm } from '../education-form/component.types';
import { ExperienceForm } from '../experience-form/component.types';
import { CertificationForm } from '../certificate-form/component.types';
import { Skills, skillsFormSchema } from '../skills-form';
import { USERTYPE } from '../../../../constants/common';
import PageHeader from '../../layouts/page-header';

const steps = ['form:cv.steps.step-1', 'form:cv.steps.step-2', 'form:cv.steps.step-3'];

const CreateCV = <T,>({ onCreate, loading, initialData, initialState = 1 }: CreateCVProps<T>) => {
  const user = useAppSelector(storeActions.user.getUser);
  const userType = (user.userType === USERTYPE.PM ? USERTYPE.EMPLOYEE : user.userType) as LoggedInUserTypes;
  const navigate = useNavigate();

  const cvFormSchema = Yup.object().shape({
    ...(userType === USERTYPE.HR
      ? {
          noEmployee: Yup.boolean().optional(),
          employee: Yup.object({
            id: Yup.number().required(),
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
          }).when('noEmployee', {
            is: true,
            otherwise: Yup.object({
              id: Yup.number().required(),
              first_name: Yup.string().required(),
              last_name: Yup.string().required(),
            }).required(),
            then: Yup.object({}).nullable(),
          }),
        }
      : {}),
    ...profileInformationFormSchema[userType].fields,
    // ...contactDetailsFormSchema[userType].fields,     // deprecated
    ...cvInformationFormSchema[userType].fields,
    ...cvDetailsExperienceFormSchema[userType].fields,
    ...cvDetailsEducationFormSchema[userType].fields,
    ...cvDetailsCertificateFormSchema[userType].fields,
    ...skillsFormSchema.fields,
  });

  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(initialState);

  const { handleSubmit, formDebugger, trigger, control, getValues, setValue } = useForm({
    name: 'cv-form',
    schema: cvFormSchema,
    defaultValues: {
      first_name: initialData?.profileInformation?.firstName,
      last_name: initialData?.profileInformation?.lastName,
    },
  });

  const { workExperiences, educations, certifications, profileInformationData, cvInformation, skills } = useMemo(() => {
    if (currentStep === 3) {
      return {
        educations: getValues('educations') as EducationForm[],
        certifications: getValues('certifications') as CertificationForm[],
        workExperiences: getValues('work_experiences') as ExperienceForm[],
        skills: {
          soft_skills: getValues('soft_skills') as Skills[],
          hard_skills: getValues('hard_skills') as Skills[],
        },
        profileInformationData: {
          first_name: getValues('first_name') as string,
          last_name: getValues('last_name') as string,
        },
        cvInformation: {
          title: getValues('title') as string,
          introduction: getValues('introduction') as string,
        },
      };
    }
    return {
      educations: [],
      certifications: [],
      workExperiences: [],
      skills: {
        soft_skills: [],
        hard_skills: [],
      },
      cvInformation: {
        title: '',
        introduction: '',
      },
      profileInformationData: {
        first_name: '',
        last_name: '',
        location: {
          id: 0,
          name: '',
        },
      },
    };
  }, [getValues, currentStep]);

  const onCancel = () => {
    navigate(appRoutes.cvList);
  };

  return (
    <PageContainer>
      <PageHeader onBack={onCancel} backTitle={t('common:back-to-cvs')} />
      <PageBody>
        <form aria-label="create-cv" onSubmit={handleSubmit(onCreate as SubmitHandler<FieldValues>)}>
          {formDebugger()}
          <PageWrapper>
            <Box
              sx={{
                marginBottom: '80px',
              }}
            >
              <Stepper steps={steps} active={currentStep} />
            </Box>
            <Choose>
              <When condition={currentStep === 1}>
                <ProfileBoard
                  setValue={setValue}
                  loading={loading}
                  onCancel={onCancel}
                  onNext={() => setCurrentStep(2)}
                  control={control}
                  trigger={trigger}
                />
              </When>
              <When condition={currentStep === 2}>
                <CVBoard
                  loading={loading}
                  onCancel={onCancel}
                  onNext={() => setCurrentStep(3)}
                  onBack={() => setCurrentStep(1)}
                  control={control}
                  trigger={trigger}
                />
              </When>
              <When unless>
                <>
                  <PreviewCV
                    userType={userType}
                    experiences={workExperiences}
                    educations={educations}
                    certifications={certifications}
                    profileInformation={profileInformationData}
                    cvInformation={cvInformation}
                    skills={skills}
                  />
                  <ActionsWrapper>
                    <Button disabled={loading} onClick={onCancel} variant="outlined" color="inherit">
                      {t('common:cancel')}
                    </Button>
                    <Button disabled={loading} onClick={() => setCurrentStep(2)} variant="outlined" color="primary">
                      {t('common:previous')}
                    </Button>
                    <Button loading={loading} type="submit">
                      {t('form:submit')}
                    </Button>
                  </ActionsWrapper>
                </>
              </When>
            </Choose>
          </PageWrapper>
        </form>
      </PageBody>
    </PageContainer>
  );
};

export default CreateCV;
