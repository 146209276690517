import { configureStore } from '@reduxjs/toolkit';
import userReducer, { deleteUser, setUserData, setUserType, getUser } from './redux/user';
import clientProfileReducer, { deleteClientProfile, getClientProfile, setClientProfile } from './redux/client-profile';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

export const persistConfig = {
  key: 'counter',
  storage,
};

export const reducer = combineReducers({
  user: userReducer,
  clientProfile: clientProfileReducer,
});

export const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export const storeActions = {
  user: {
    deleteUser,
    setUserData,
    setUserType,
    getUser,
  },
  clientProfile: {
    deleteClientProfile,
    getClientProfile,
    setClientProfile,
  },
};
