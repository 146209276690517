import React, { useMemo, useState } from 'react';
import { Body, Container, Footer, ToggleButton } from './styled';

import MenuItem from './menu-item';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/dashboard.svg';
import { ReactComponent as MyCVIcon } from '../../../../assets/icons/my-cv.svg';
import { ReactComponent as WorkHoursIcon } from '../../../../assets/icons/work-hours.svg';
import { ReactComponent as VacationsIcon } from '../../../../assets/icons/vacations.svg';
import { ReactComponent as EmployeesIcon } from '../../../../assets/icons/employee.svg';
import { ReactComponent as ProjectsIcon } from '../../../../assets/icons/projects.svg';
import { ReactComponent as ReportsIcon } from '../../../../assets/icons/reports.svg';
import { ReactComponent as VacationsReportsIcon } from '../../../../assets/icons/vacations-report.svg';
// import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/log-out.svg';
import { useLocation } from 'react-router-dom';
import { appRoutes } from '../../../../providers/routes';
import { ReactComponent as SliderToggle } from '../../../../assets/slider-toggle.svg';
import { useTranslation } from 'react-i18next';
import { USERTYPE } from '../../../../constants/common';
import UserInfo from '../../user-info';

import { NavItem } from './menu-item/component.types';
import { useUser } from '../../../hooks/use-user';

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { user, isHR } = useUser();
  const { t } = useTranslation();
  const EMPLOYEES_MENUS: Array<NavItem> = [
    {
      icon: <DashboardIcon />,
      href: appRoutes.dashboard,
      label: t('common:dashboard'),
    },
    {
      icon: <WorkHoursIcon />,
      href: appRoutes.employeeOverview,
      label: t('common:work-hours'),
    },
    {
      icon: <VacationsIcon />,
      href: appRoutes.vacations,
      label: t('common:vacations'),
    },
  ];
  const HR_MENUS: Array<NavItem> = [
    {
      icon: <DashboardIcon />,
      href: appRoutes.dashboard,
      label: t('common:dashboard'),
    },
    {
      icon: <VacationsIcon />,
      href: appRoutes.vacations,
      label: t('common:vacations'),
    },
    {
      icon: <MyCVIcon />,
      href: isHR ? '' : appRoutes.cvList,
      label: t('common:cvs'),
      children: isHR
        ? [
            { icon: <MyCVIcon />, href: appRoutes.cvList, label: 'Resumes' },
            {
              icon: <MyCVIcon />,
              href: appRoutes.skills,
              label: 'Skills',
            },
          ]
        : undefined,
    },
    {
      icon: <EmployeesIcon />,
      href: appRoutes.employees,
      label: t('common:employees'),
    },
    {
      icon: <ProjectsIcon style={{ color: 'white' }} />,
      href: appRoutes.projects,
      label: t('common:projects'),
    },

    {
      icon: <ReportsIcon />,
      href: '',
      label: t('common:reports'),
      children: [
        {
          icon: <VacationsReportsIcon />,
          href: appRoutes.reportsType.replace(':reportType', 'vacations'),
          label: t('common:vacations'),
        },
        {
          icon: <ProjectsIcon style={{ color: 'white' }} />,
          href: appRoutes.projectsReport,
          label: 'Projects',
        },
      ],
    },
  ];
  const navs = useMemo(
    () => ({
      [USERTYPE.EMPLOYEE]: EMPLOYEES_MENUS,
      [USERTYPE.HR]: HR_MENUS,
      [USERTYPE.PM]: [EMPLOYEES_MENUS[0], HR_MENUS[4]],
      admin: HR_MENUS,
      client: EMPLOYEES_MENUS,
    }),
    [t]
  );

  const userType = user.userType as keyof typeof navs;

  const location = useLocation();

  return (
    <Container
      aria-label="side-menu"
      sx={{
        width: isOpen ? '190px' : '63px',
      }}
    >
      <ToggleButton aria-label="toggle-menu" onClick={() => setIsOpen(prevState => !prevState)} isOpen={isOpen}>
        <SliderToggle />
      </ToggleButton>
      <UserInfo isOpen={isOpen} />
      <Body data-testid="menu-body">
        {navs[userType].map(nav => (
          <MenuItem
            key={`${nav.href}-${nav.label}`}
            // This regX only care checks the two level path name
            isActive={'/' + location.pathname.split('/')[1] === nav.href}
            item={nav}
            isOpen={isOpen}
          />
        ))}
      </Body>
      <Footer aria-label="menu-footer">
        <MenuItem
          isOpen={isOpen}
          item={{
            href: appRoutes.logout,
            icon: <LogOutIcon />,
            label: t('common:log-out'),
          }}
        />
      </Footer>
    </Container>
  );
};

export default SideNav;
