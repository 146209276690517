import LoadingButton from '@mui/lab/LoadingButton';
import { styled, Typography } from '@mui/material';
import { createLighterColor } from '../../tools/styles/create-lighter-color';
import { Link } from 'react-router-dom';
import { ButtonProps, LinkWrapperProps } from './component.types';

const StyledButton = styled(LoadingButton, {
  shouldForwardProp: propName => propName !== 'rounded',
})<ButtonProps>(({ rounded, hasIcon, color = 'primary', variant, theme }) => {
  const hasColor = color !== 'inherit';
  let borderRadius = '20px';
  let padding = '10px 30px';

  if (hasIcon) {
    padding = '10px 20px';
  }
  if (!rounded) {
    borderRadius = '10px';
  }

  if (hasColor && variant === 'contained') {
    return {
      padding,
      borderRadius,
      color: theme.palette.common.white,
      '&.MuiLoadingButton-loading , &.Mui-disabled': {
        background: createLighterColor(theme.palette[color].main),
        '.MuiLoadingButton-loadingIndicator': {
          color: theme.palette.common.white,
        },
      },
      '&:not(.MuiLoadingButton-loading)': {
        '&.Mui-disabled': {
          color: theme.palette.common.white,
        },
      },
    };
  }

  if (hasColor && variant === 'outlined') {
    return {
      padding,
      borderRadius,
      color: theme.palette[color].main,
      borderColor: theme.palette[color].main,
      '&.MuiLoadingButton-loading': {
        borderColor: createLighterColor(theme.palette[color].main),
        '.MuiLoadingButton-loadingIndicator': {
          color: createLighterColor(theme.palette[color].main),
        },
      },
    };
  }

  if (hasColor && variant === 'text') {
    return {
      padding,
      borderRadius,
      '&.MuiLoadingButton-loading': {
        '.MuiLoadingButton-loadingIndicator': {
          color: createLighterColor(theme.palette[color].main),
        },
        '&.Mui-disabled': {
          background: 'transparent',
          color: 'transparent',
        },
      },
    };
  }

  return {
    padding,
    borderRadius,
  };
});

const LinkWrapper = ({ children, href }: LinkWrapperProps) => {
  if (href) {
    return <Link to={href}>{children}</Link>;
  }
  return children;
};

const Button = ({ children, href, rounded = true, variant, ...otherProps }: ButtonProps) => {
  return (
    <LinkWrapper href={href}>
      <StyledButton {...otherProps} rounded={rounded} variant={variant ?? 'contained'}>
        <Typography variant="button">{children}</Typography>
      </StyledButton>
    </LinkWrapper>
  );
};

export default Button;
