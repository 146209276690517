import React, { useMemo } from 'react';
import { CVBoardProps } from './component.types';
import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';
import { Divider, ActionsWrapper, SectionTitle } from '../styled';
import CVInformationForm, { cvInformationFormSchema } from '../cv-information-form';
import Button from '../../button';
import CVDetailsBoard from '../cv-details-board';
import { cvDetailsExperienceFormSchema } from '../experience-form';
import { cvDetailsEducationFormSchema } from '../education-form';
import { cvDetailsCertificateFormSchema } from '../certificate-form';
import { useAppSelector } from '../../../../store/hooks';
import { storeActions } from '../../../../store';
import { LoggedInUserTypes } from '../../../../store/redux/user/user.types';
import { skillsFormSchema } from '../skills-form';
import { USERTYPE } from '../../../../constants/common';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const CVBoard = ({ loading, onCancel, control, trigger, onNext, onBack }: CVBoardProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(storeActions.user.getUser);
  const userType = (user.userType === USERTYPE.PM ? USERTYPE.EMPLOYEE : user.userType) as LoggedInUserTypes;

  const fieldsName = useMemo(
    () => [
      ...Object.keys(cvInformationFormSchema[userType].fields),
      ...Object.keys(cvDetailsExperienceFormSchema[userType].fields),
      ...Object.keys(cvDetailsEducationFormSchema[userType].fields),
      ...Object.keys(cvDetailsCertificateFormSchema[userType].fields),
      ...Object.keys(skillsFormSchema.fields),
    ],
    [userType]
  );

  const handleNextStep = async () => {
    const requiredFiledPassed = await trigger(fieldsName);
    if (requiredFiledPassed) {
      onNext();
    }
  };

  return (
    <Container aria-label="cv-board">
      <SectionTitle>{t('form:cv-information')}</SectionTitle>
      <CVInformationForm control={control} />
      <Divider />
      <CVDetailsBoard userType={userType} control={control} />
      <ActionsWrapper>
        <Button onClick={onCancel} disabled={loading} variant="outlined" color="inherit">
          {t('common:cancel')}
        </Button>
        <Button onClick={onBack} disabled={loading} variant="outlined" color="primary">
          {t('common:previous')}
        </Button>
        <Button loading={loading} onClick={handleNextStep}>
          {t('form:next-preview')}
        </Button>
      </ActionsWrapper>
    </Container>
  );
};

export default CVBoard;
