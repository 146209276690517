/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProjectUserHistoryLogResource } from './ProjectUserHistoryLogResource';
import type { ProjectUserResource } from './ProjectUserResource';

export type AdminProjectResource = {
    id?: number;
    name?: string;
    start_date?: string;
    end_date?: string;
    target?: number;
    status?: AdminProjectResource.status;
    minutes?: number;
    total_logged_hours?: number;
    is_public?: boolean;
    users?: Array<ProjectUserResource>;
    /**
     * Initial creation timestamp
     */
    created_at?: string;
    /**
     * Last update timestamp
     */
    updated_at?: string;
    user_history_logs?: Array<ProjectUserHistoryLogResource>;
};

export namespace AdminProjectResource {

    export enum status {
        ONGOING = 'ONGOING',
        CLOSED = 'CLOSED',
    }


}

