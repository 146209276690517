import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageBody, PageContainer, PageWrapper } from '../../utils/widgets/cv/styled';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import {
  ApiAuthService,
  ApiEmployeeService,
  ApiUserService,
  ChangePasswordRequest,
  ChangeUserPasswordRequest,
  EmployeeProfilePhotoResource,
  UpdateEmployeePhotoRequest,
  UserRequest,
  UserResource,
} from '../../api/main';

import useFetch from '../../utils/hooks/use-fetch';
import PageLoader from '../../utils/widgets/page-loader';
import { useSnackbar } from 'notistack';
import useErrorHandler from '../../utils/hooks/use-error-handler';
import ChangePasswordForm from '../../utils/widgets/employee/change-password-form';
import EmployeeProfileForm from '../../utils/widgets/employee/profile-form';
import PageHeader from '../../utils/widgets/layouts/page-header';
import { UploadProfile } from '../../utils/widgets/upload-profile';
import { useAppDispatch } from '../../store/hooks';
import { storeActions } from '../../store';
import { useUser } from '../../utils/hooks/use-user';
import { OptionType } from '../../utils/widgets/users-select/component.types';

const UserProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const { isHR, clientProfile } = useUser();
  const getEmployee = useFetch(ApiAuthService.apiAuthMe);
  const updateEmployee = useFetch(ApiAuthService.apiAuthMeUpdate);
  const updateEmployeeAdmin = useFetch<UserRequest, UserResource>(payload =>
    ApiEmployeeService.apiEmployeeUpdate(Number(clientProfile?.id), payload)
  );
  const employeeData = useMemo(() => {
    return getEmployee.loaded ? (getEmployee.result as UserResource) : null;
  }, [getEmployee.loaded, getEmployee.result]);
  const [profileImage, setProfileImage] = useState<string | undefined>(undefined);

  const changeEmployeePassword = useFetch(ApiAuthService.apiAuthPasswordChange);
  const changeEmployeePasswordAdmin = useFetch<ChangeUserPasswordRequest, void>(payload =>
    ApiUserService.apiUserChangePassword(Number(clientProfile?.id), payload)
  );
  const uploadProfilePhoto = useFetch<
    { userId: number; formData: UpdateEmployeePhotoRequest },
    EmployeeProfilePhotoResource
  >(({ formData, userId: id }) => ApiEmployeeService.apiEmployeeUploadProfilePhoto(id, formData));
  const removeProfilePhoto = useFetch(ApiEmployeeService.apiEmployeeDeleteProfilePhoto);

  useEffect(() => {
    if (!getEmployee.loading) {
      void getEmployee.call();
    }
  }, [userId]);
  useEffect(() => {
    if (employeeData) {
      setProfileImage(employeeData?.profile_photo);
    }
  }, [employeeData]);

  const onSubmit = (payload: UserRequest & Pick<UserResource, 'location'>) => {
    const { slack_id: slackID } = payload;

    if (isHR) {
      return updateEmployeeAdmin
        .call({
          ...payload,
          slack_id: slackID || null,
          start_date: payload.start_date || undefined,
          status: (payload.status as unknown as { value: number })?.value || undefined,
          location_id: payload.location?.id,
          phone: payload.phone || null,
          role: String((payload.role as unknown as OptionType).value),
          birthday: payload.birthday,
        } as UserRequest)
        .then(() => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          dispatch(storeActions.clientProfile.setClientProfile({ location: payload.location }));
          return Promise.resolve(payload);
        })
        .catch(handleError);
    } else {
      return updateEmployee
        .call({
          first_name: payload.first_name,
          last_name: payload.last_name,
          phone: payload.phone || null,
          slack_id: slackID || null,
          birthday: payload.birthday,
          location_id: payload.location?.id,
        } as UserRequest)
        .then(() => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          dispatch(storeActions.clientProfile.setClientProfile({ location: payload.location }));
          return Promise.resolve(payload);
        })
        .catch(handleError);
    }
  };
  const onChangePassword = (payload: FieldValues) => {
    const { new_password: newPass, old_password: oldPass } = payload as ChangePasswordRequest;
    if (isHR) {
      changeEmployeePasswordAdmin
        .call({ new_password: (payload as ChangeUserPasswordRequest).new_password })
        .then(() => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          return Promise.resolve(payload);
        })
        .catch(handleError);
    } else {
      changeEmployeePassword
        .call({ new_password: newPass, old_password: oldPass })
        .then(() => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          return Promise.resolve(payload);
        })
        .catch(handleError);
    }
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => navigate(-1)} />
      <PageBody>
        <PageWrapper>
          <PageLoader isLoading={!employeeData}>
            <>
              <EmployeeProfileForm
                userId={Number(clientProfile?.id)}
                onSubmit={onSubmit}
                data={employeeData}
                isLoading={updateEmployee.loading}
              />
              <Divider />

              <Stack direction="row" sx={{ ' > *': { flexGrow: 1 } }}>
                <ChangePasswordForm onSubmit={onChangePassword} isLoading={changeEmployeePassword.loading} />
                <Divider orientation="vertical" sx={{ flexGrow: '0' }} />
                <UploadProfile
                  image={profileImage ?? employeeData?.profile_photo}
                  onUpload={image => {
                    if (image) {
                      uploadProfilePhoto
                        .call({
                          formData: { photo: image, file: image } as UpdateEmployeePhotoRequest,
                          userId: Number(userId || employeeData?.id),
                        })
                        .then(data => {
                          setProfileImage(data.url);

                          dispatch(
                            storeActions.clientProfile.setClientProfile({ ...employeeData, profile_photo: data.url })
                          );
                        })
                        .catch(handleError);
                    }
                  }}
                  onRemove={() => {
                    removeProfilePhoto
                      .call(Number(userId || employeeData?.id))
                      .then(() => {
                        setProfileImage('');
                        dispatch(storeActions.clientProfile.setClientProfile({ ...employeeData, profile_photo: '' }));
                      })
                      .catch(handleError);
                  }}
                  isLoading={uploadProfilePhoto.loading}
                />
              </Stack>
            </>
          </PageLoader>
        </PageWrapper>
      </PageBody>
    </PageContainer>
  );
};

export default UserProfilePage;
