import appColor, { appColorOverrides, colorMap } from './app-colors';
import globalOverridesStyle from './global-overrides-style';
import { createMuiColor } from '../../utils/tools/styles/create-mui-color';
import deepmerge from 'deepmerge';

const darkThemeOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderColor: colorMap.dark.fieldsBorder,
        color: colorMap.dark.text1,
      },
      text: {
        color: colorMap.dark.text1,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&:not(.Mui-checked) .MuiSvgIcon-root': {
          position: 'relative',
          '& path': {
            fill: colorMap.dark.fieldsBorder,
          },
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& fieldset': {
          backgroundColor: colorMap.dark.fields,
          borderColor: colorMap.dark.fieldsBorder,
          zIndex: 1,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        '& fieldset': {
          backgroundColor: colorMap.dark.fields,
          borderColor: colorMap.dark.fieldsBorder,
          zIndex: 1,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: colorMap.dark.paperBackground,
        '& MuiAutocomplete': {
          backgroundColor: 'red',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: appColor.darkBlue,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        backgroundColor: appColor.darkBlue,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        backgroundColor: appColor.darkBlue,
      },
      option: {
        backgroundColor: appColor.darkBlue,
      },
      paper: {
        backgroundColor: appColor.darkBlue,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '.MuiDataGrid-row.odd': {
          background: appColor.indigo,
        },
      },
    },
  },
};

export const dark = {
  status: {
    active: appColor.lavender,
  },
  palette: {
    mode: 'dark',
    ...createMuiColor(appColor),
    ...appColorOverrides,
    ...createMuiColor(colorMap.dark),
  },
  components: {
    ...deepmerge(globalOverridesStyle, darkThemeOverrides),
  },
};

export default dark;
