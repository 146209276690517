import { useRef } from 'react';
import { ApiCvService } from '../../../api/main';
import useFetch from '../use-fetch';

const usePDFGenerator = () => {
  const pdfUrlRef = useRef<string | undefined>();

  const getCvPDFApi = useFetch(ApiCvService.apiCvGeneratePdf);

  const getPDFUri = (cvID: number) =>
    getCvPDFApi.call(cvID).then(result => {
      pdfUrlRef.current = result.uri;
      return result.uri;
    });

  return { pdfUri: pdfUrlRef.current, getPDFUri, loading: getCvPDFApi.loading };
};

export default usePDFGenerator;
