import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store.types';
import { fetchClientProfile } from './asyncActions';
import { UserResource } from '../../../api/main';

export interface ClientProfileState {
  loading: boolean;
  loaded: boolean;
  data: UserResource | null;
}

export const initialState: ClientProfileState = {
  loading: false,
  loaded: false,
  data: null,
};

export const authSlice = createSlice({
  name: 'clientProfile',
  initialState,
  reducers: {
    setClientProfile: (state, action: PayloadAction<UserResource>) => {
      state.data = { ...state.data, ...action.payload };
      state.loaded = true;
      state.loading = false;
    },
    deleteClientProfile: state => {
      state.data = null;
    },
    setLoading: (state, action: PayloadAction<ClientProfileState['loading']>) => {
      state.loading = action.payload;
      state.loaded = false;
      state.data = null;
    },
  },
});

export const { deleteClientProfile, setClientProfile } = authSlice.actions;

const getClientProfile = (state: RootState): ClientProfileState['data'] => state.clientProfile.data;

export { fetchClientProfile, getClientProfile };

export default authSlice.reducer;
