import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { USERTYPE } from '../../../constants/common';
import { appRoutes } from '../../../providers/routes';
import { storeActions } from '../../../store';
import { useAppSelector } from '../../../store/hooks';
import AuthLayout from '../../../utils/widgets/auth/auth-layout';

const AuthGuard = () => {
  const navigate = useNavigate();
  const user = useAppSelector(storeActions.user.getUser);
  useEffect(() => {
    if (user.userType !== USERTYPE.GUEST) {
      navigate(appRoutes.dashboard, { replace: true });
    }
  }, []);

  return (
    <AuthLayout>
      <pre hidden>{JSON.stringify(user, undefined, 2)}</pre>
      <Outlet />
    </AuthLayout>
  );
};

export default AuthGuard;
