import { useEffect, useState } from 'react';
import useFetch from '../use-fetch/component';
import { ApiConfigService, ConfigsCollection } from '../../../api/main';

let config: null | Record<string, string> = null;

export const useAppConfig = () => {
  const [configData, setConfigData] = useState(config);
  const getConfigData = useFetch<
    { page?: number; pageSize?: number; locationId?: number; key?: string },
    ConfigsCollection
  >(({ page, pageSize, locationId, key }) => ApiConfigService.apiConfigIndex(page, pageSize, locationId, key));
  useEffect(() => {
    config = {};
    getConfigData
      .call({ page: 1, pageSize: 10 })
      .then(data => {
        config =
          data.data?.reduce((acc, val) => {
            acc[String(val.key)] = String(val.value) || '';
            return acc;
          }, {} as Record<string, string>) || {};
        setConfigData(config);
      })
      .catch(() => {});
  }, []);
  return configData;
};
