import React, { useEffect, useRef } from 'react';
import { ExperienceModalFormProps } from './component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import ExperienceForm, { experienceFormSchema } from '../experience-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

const ExperienceModalForm = <T,>({ initialData, onSubmit, open, onCancel, userType }: ExperienceModalFormProps<T>) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, formDebugger, control } = useForm({
    name: 'experience-modal-form',
    schema: experienceFormSchema[userType],
    defaultValues: {
      company: initialData?.company,
      position: initialData?.position,
      technologies: initialData?.technologies,
      description: initialData?.description,
      start_date: initialData?.start_date,
      end_date: initialData?.end_date,
      currently_working: !!initialData?.currently_working,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  useEffect(() => {}, [open]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('common:experience')}
      onOkText={initialData ? 'common:edit' : 'common:add'}
      onOKIcon={initialData ? <EditOutlinedIcon /> : <AddIcon />}
    >
      <form
        ref={formRef}
        aria-label="experience-modal-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        <ExperienceForm control={control} />
      </form>
    </ConfirmationDialog>
  );
};

export default ExperienceModalForm;
