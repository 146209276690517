import { createLighterColor } from '../../utils/tools/styles/create-lighter-color';

type AppColorType = Record<string, string>;

type ColorMapProps = {
  light: AppColorType;
  dark: AppColorType;
};

export const appColor: AppColorType = {
  black: '#000000',
  midBlack: 'rgba(0, 0, 0, 0.5)',
  lightBlack: 'rgba(0, 0, 0, 0.25)',
  organzaViolet: '#131A36',
  midnightBlue: '#1D2440',
  darkBlue: '#202952',
  spaceCadet: '#262D49',
  darkPurple: '#1E2D73',
  waterloo: '#757C98',
  lightBlue: '#1890FF',
  turquoise: '#20B8CE',
  green: '#1ECD26',
  yellow: '#FBBB00',
  purple: '#AC4FC6',
  pink: '#FB76A3',
  red: '#EE2728',
  lightPurple: '#E9E8ED',
  gray: '#F5F5F5',
  midGray: '#cdcccc',
  softGray: '#D9D9D9',
  ultraLightGray: '#FBFBFB',
  lightGray: '#EAE7E7',
  primary: '#20B8CE',
  secondary: '#AC4FC6',
  tertiary: '#131A36',
  fourth: '#131A36',
  white: '#ffffff',
  ghostWhite: '#F6F6F8',
  lightWhite: '#FFFFFF7F',
  independence: '#4A5066',
  indigo: '#242E5C',
  lavender: '#E0E0E033',
  lightYellow: '#D6D951',
  lightBrown: '#D98A13',
};

export const appColorOverrides = {
  waterloo: {
    main: appColor.waterloo,
    ultraLight: 'rgba(117, 124, 152, 0.1)',
    lighter: createLighterColor(appColor.waterloo),
  },
  black: {
    main: appColor.waterloo,
    light: 'rgba(0, 0, 0, 0.75)',
    lighter: 'rgba(0, 0, 0, 0.5)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
  },
} as const;

export const colorMap: ColorMapProps = {
  light: {
    appBackground: appColor.ghostWhite,
    popup: appColor.white,
    fields: appColor.white,
    fieldsBorder: appColor.softGray,
    lines: appColor.lightGray,
    column1: appColor.white,
    column2: appColor.ultraLightGray,
    text1: appColor.black,
    text2: appColor.black,
    paperBackground: appColor.white,
    sideNav: appColor.primary,
    chipBgColor: appColor.ultraLightGray,
  },
  dark: {
    appBackground: appColor.indigo,
    popup: appColor.midnightBlue,
    fields: appColor.spaceCadet,
    fieldsBorder: appColor.waterloo,
    lines: appColor.independence,
    column1: appColor.indigo,
    column2: appColor.organzaViolet,
    text1: appColor.white,
    text2: appColor.white,
    paperBackground: appColor.fourth,
    sideNav: appColor.fourth,
    chipBgColor: appColor.independence,
  },
};

export default appColor;
