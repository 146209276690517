import { useState, useEffect, useRef } from 'react';

export default function useLongPress(ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);
  const timerId = useRef<NodeJS.Timeout>();

  const handleMouseDown = () => {
    timerId.current = setTimeout(() => setStartLongPress(true), ms);
  };
  const handleClear = () => {
    setTimeout(() => {
      setStartLongPress(false);
    });
    clearTimeout(timerId.current);
  };

  useEffect(() => {
    return handleClear;
  }, [ms]);

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleClear,
    onMouseLeave: handleClear,
    onTouchStart: handleMouseDown,
    onTouchEnd: handleClear,
    startLongPress,
  };
}
