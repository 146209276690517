/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigRequest } from '../models/ConfigRequest';
import type { ConfigResource } from '../models/ConfigResource';
import type { ConfigsCollection } from '../models/ConfigsCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiConfigService {

    /**
     * Configs List
     * Paginated list of all configs.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param locationId
     * @param key
     * @returns ConfigsCollection Config collection response
     * @throws ApiError
     */
    public static apiConfigIndex(
        page?: number,
        pageSize?: number,
        locationId?: number,
        key?: string,
    ): CancelablePromise<ConfigsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/configs',
            query: {
                'page': page,
                'pageSize': pageSize,
                'location_id': locationId,
                'key': key,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Set Config
     * This endpoint take config data set it.
     * @param requestBody Set config request
     * @returns ConfigResource Set/Show config response
     * @throws ApiError
     */
    public static apiConfigSet(
        requestBody: ConfigRequest,
    ): CancelablePromise<ConfigResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Show Config
     * Show config.
     * @param locationId Location identifier.
     * @param key Config key.
     * @returns ConfigResource Set/Show config response
     * @throws ApiError
     */
    public static apiConfigShow(
        locationId: number,
        key: string,
    ): CancelablePromise<ConfigResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/configs/{location_id}/{key}',
            path: {
                'location_id': locationId,
                'key': key,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
