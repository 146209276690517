import React from 'react';
import { Box, styled } from '@mui/material';
import SideNav from '../side-nav';
import { DashboardProps } from './component.types';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.appBackground.main,
}));

const Body = styled(Box)({
  margin: '20px',
  overflowX: 'hidden',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const DashboardLayout = ({ children }: DashboardProps) => {
  return (
    <Container>
      <SideNav />
      <Body>{children}</Body>
    </Container>
  );
};

export default DashboardLayout;
