import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiEmployeeService } from '../../../../api/main';
import { CardHeading } from '../../../../components/admin/employee-overview/styled';
import useFetch from '../../../hooks/use-fetch';
import { useUser } from '../../../hooks/use-user';
import { formatHoursToDayAndHours } from '../../../tools/date';
import { CircularProgressWrapper } from '../timesheet/component';

const DailyRecords = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { clientProfile } = useUser();
  const getVacationsInfo = useFetch(ApiEmployeeService.apiEmployeeRemainedBreaks);
  useEffect(() => {
    if (clientProfile?.id) {
      void getVacationsInfo.call(clientProfile?.id);
    }
  }, [clientProfile?.id]);
  const {
    vacation_remained: vacations,
    day_off_remained: dayOffs,
    total_day_offs: totalDayOffs,
    total_vacations: totalVacations,
  } = (getVacationsInfo.result as Record<string, number>) || {
    vacation_remained: 0,
    day_off_remained: 0,
    total_day_offs: 0,
    total_vacations: 0,
  };
  const dayOffsValue = dayOffs || 0;
  const vacationsValue = vacations || 0;

  return (
    <>
      <CardHeading variant="h4">{t('common:vacations')}</CardHeading>

      <Stack direction="column" height="100%">
        <CircularProgressWrapper color="#55BD59">
          <Stack sx={{ color: theme.palette.gray.main }}>
            <CircularProgress value={100} variant="determinate" color={'inherit'} size={169} thickness={1} />
          </Stack>

          {getVacationsInfo.loaded && (
            <CircularProgress
              value={vacationsValue < 0 ? 0 : (vacationsValue / totalVacations) * 100}
              variant="determinate"
              color={'inherit'}
              size={171.5}
              thickness={2}
            />
          )}
          <Typography variant="body1" component="span" color="inherit" lineHeight={1.7}>
            Vacation
            <br />
            <b dangerouslySetInnerHTML={{ __html: formatHoursToDayAndHours(vacationsValue, true) }} />
          </Typography>
        </CircularProgressWrapper>
        <CircularProgressWrapper color="#EE2728" sx={{ width: '169px', height: '169px' }}>
          <Stack sx={{ color: theme.palette.gray.main }}>
            <CircularProgress value={100} variant="determinate" color={'inherit'} size={169} thickness={1} />
          </Stack>
          {getVacationsInfo.loaded && (
            <CircularProgress
              value={dayOffsValue < 0 ? 0 : (dayOffsValue / totalDayOffs) * 100}
              variant="determinate"
              color={'inherit'}
              size={171.5}
              thickness={2}
            />
          )}
          <Typography variant="body1" component="span" color="primary" lineHeight={1.7}>
            Day Offs
            <br />
            <b dangerouslySetInnerHTML={{ __html: formatHoursToDayAndHours(dayOffsValue, true) }} />
          </Typography>
        </CircularProgressWrapper>
      </Stack>
    </>
  );
};

export default DailyRecords;
