import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { Message } from 'react-hook-form';
import { isValidDate } from '../../tools/date';

interface CompareWithArgs extends MethodArgs {
  fieldName: string;
}

export const yupLocale = (t: TFunction) => {
  Yup.setLocale({
    mixed: {
      default: t('formValidation:mixed.default'),
      defined: t('formValidation:mixed.defined'),
      notOneOf: t('formValidation:mixed.notOneOf'),
      notType: t('formValidation:mixed.notType'),
      oneOf: t('formValidation:mixed.oneOf'),
      required: t('formValidation:mixed.required'),
    },
    number: {
      min: t('formValidation:number.min'),
      max: t('formValidation:number.max'),
      lessThan: t('formValidation:number.lessThan'),
      moreThan: t('formValidation:number.moreThan'),
      positive: t('formValidation:number.positive'),
      negative: t('formValidation:number.negative'),
      integer: t('formValidation:number.integer'),
    },
    string: {
      email: t('formValidation:string.email'),
      length: t('formValidation:string.length'),
      min: t('formValidation:string.min'),
      max: t('formValidation:string.max'),
      matches: t('formValidation:string.matches'),
      url: t('formValidation:string.url'),
      uuid: t('formValidation:string.uuid'),
      trim: t('formValidation:string.trim'),
      lowercase: t('formValidation:string.lowercase'),
      uppercase: t('formValidation:string.uppercase'),
    },
    boolean: {
      isValue: t('formValidation:boolean.isValue'),
    },
    array: {
      min: t('formValidation:array.min'),
      max: t('formValidation:array.max'),
    },
  });
};

interface MethodArgs {
  message?: Message;
}

export const yupConfig = (t: TFunction) => {
  Yup.addMethod(Yup.string, 'email', (args: MethodArgs) =>
    Yup.string().test('email-validate', args?.message ?? '', function (value) {
      const { path, createError } = this;
      const re =
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(String(value).toLowerCase()) || createError({ message: t('formValidation:string.email'), path });
    })
  );

  Yup.addMethod(Yup.string, 'dashedDateOnly', (args: MethodArgs) =>
    Yup.string().test('date-dashed-validate', args?.message ?? '', function (value) {
      const { path, createError } = this;
      const re = /^\d{4}-\d{2}-\d{2}$/;

      if (!value) {
        return true;
      }

      return (
        (re.test(String(value).toLowerCase()) && isValidDate(value)) ||
        createError({ message: t('formValidation:string.dashed-date'), path })
      );
    })
  );

  Yup.addMethod(Yup.string, 'compareWith', function (args: CompareWithArgs) {
    return this.test('compare-2-values', args?.message ?? '', function (value) {
      const { path, createError } = this;

      const error = createError({ message: t('formValidation:string.compare-error'), path });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const baseValue = String(this?.parent[args?.fieldName]);

      try {
        return (baseValue && String(value) === baseValue) || error;
      } catch (e) {
        return error;
      }
    });
  });

  Yup.addMethod(Yup.number, 'number', (args: MethodArgs) =>
    Yup.number().test('number-validate', args?.message ?? '', function (value: unknown) {
      const { path, createError } = this;
      const re = /^\d$/;

      if (typeof value === 'string') {
        return re.test(value) || createError({ message: t('formValidation:string.must-be-number'), path });
      }

      return false;
    })
  );
};
