import { call, put } from 'redux-saga/effects';
import { fetchClientProfile } from '../redux/client-profile';
import { UserResource } from '../../api/main';

export function* fetchClientProfileSaga(): Generator {
  yield put({ type: 'clientProfile/setLoading', payload: true });
  const result = yield call(fetchClientProfile) as unknown as UserResource;
  if (result) {
    yield put({ type: 'clientProfile/setClientProfile', payload: result });
  }
}
