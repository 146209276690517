import React, { useMemo, useRef } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { WorkHourForm, WorkHourModalFormProps } from './component.types';
import { Add, Delete } from '@mui/icons-material';
import { Box, IconButton, Stack, styled } from '@mui/material';
import InputDatePicker from '../../forms/input-date-picker';
import { differenceInMinutes, format, startOfDay, addMinutes } from 'date-fns';
import BasicTimePicker from '../../time-picker/component';
import Button from '../../button';
import Autocomplete from '../../forms/autocomplete';
import HoursPicker from '../../hours-picker';
import useErrorHandler from '../../../hooks/use-error-handler';
import { formatMinutes } from '../../../tools/date';
import CollidingBox from '../../colliding-box';

const RowBox = styled(Stack)({
  width: '100%',
  margin: '20px 0',
  '> *': {
    marginRight: '5px',
  },
  '> button': {
    alignSelf: 'start',
  },
});

const workHourFormSchema = Yup.object().shape({
  date: Yup.string().required().dashedDateOnly(),
  start_time: Yup.string().required(),
  end_time: Yup.string().required(),
  projects: Yup.array(
    Yup.object().shape({
      hours: Yup.date().required(),
      project: Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    })
  ),
});

const WorkHourModalForm = ({
  onSubmit,
  open,
  onCancel,
  title = '',
  initialData,
  projects,
  collidingInfo,
}: WorkHourModalFormProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();

  const { handleSubmit, formDebugger, control } = useForm({
    name: 'work-hour-modal-form',
    schema: workHourFormSchema,
    defaultValues: {
      start_time: initialData?.start_time,
      end_time: initialData?.end_time,
      date: initialData?.start_time ? format(new Date(initialData.start_time), 'yyyy-MM-dd') : '',
      projects: initialData?.working_hour_projects?.map(p => {
        const baseDate = startOfDay(new Date());
        return { hours: addMinutes(baseDate, Number(p.minutes)), project: p.project };
      }) || [{ hours: startOfDay(new Date()), project: undefined }],
    },
  });
  const { fields, remove, append } = useFieldArray({ control, name: 'projects' });
  const formData = useWatch({ control }) as WorkHourForm;

  const avaiableHours = useMemo(() => {
    const d1 = new Date(formData.end_time || new Date());
    const d2 = new Date(formData.start_time || new Date());
    d1.setSeconds(0);
    d2.setSeconds(0);
    d1.setMilliseconds(0);
    d2.setMilliseconds(0);

    return differenceInMinutes(d1, d2);
  }, [formData]);

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={title}
      onOkText="common:submit"
      onOKIcon={<Add />}
      width="24%"
    >
      <form
        ref={formRef}
        aria-label="vacation-modal-form"
        onSubmit={handleSubmit(
          values => {
            const baseDate = startOfDay(new Date());
            const diff =
              avaiableHours - formData.projects.reduce((acc, p) => acc + differenceInMinutes(p.hours, baseDate), 0);
            if (diff) {
              return Promise.reject(
                t('form:available-hours-error', {
                  value: formatMinutes(diff).formatted,
                })
              );
            } else return onSubmit(values as WorkHourForm);
          },
          false,
          err => {
            if (typeof err === 'string') {
              void handleError(err);
            }
          }
        )}
      >
        {formDebugger()}
        <CollidingBox data={collidingInfo} />
        <Box>
          <InputDatePicker fullWidth control={control} required name={`date`} label={t('form:cv.start-date')} />
        </Box>
        <Box>
          <BasicTimePicker
            // defaultValue={initialData?.start_time}
            control={control}
            name={'start_time'}
            label={t('form:start-time')}
            fullWidth
            required
          />
        </Box>
        <Box>
          <BasicTimePicker
            control={control}
            name={'end_time'}
            label={t('form:end-time')}
            fullWidth
            required
            minTime={new Date(formData.start_time)}
          />
        </Box>
        {fields.map((field, idx) => {
          const baseDate = startOfDay(new Date());
          const sumOfHours = formData.projects
            .filter((_, i) => i !== idx)
            .reduce((acc, p) => acc + differenceInMinutes(p.hours, baseDate), 0);
          const hoursToAdd = avaiableHours - sumOfHours;
          return (
            <RowBox direction="row" key={field.id}>
              <Autocomplete
                name={`projects[${idx}].project`}
                defaultValue={formData.projects[idx]?.project}
                control={control}
                required
                optionKeys={{
                  label: ['name'],
                  value: 'id',
                }}
                placeholder={t('form:choose-project')}
                options={projects
                  .filter(option => formData.projects.every(p => p.project?.id !== option.id))
                  .map(({ id, name }) => ({ id, name }))}
                loading={false}
                onSearch={() => {}}
                errorMessage="Please choose a project"
              />
              <HoursPicker
                maxTime={addMinutes(baseDate, hoursToAdd > 0 ? avaiableHours - sumOfHours : 0)}
                control={control}
                name={`projects[${idx}].hours`}
              />

              {/* <BasicTimePicker control={control} name={`projects[${idx}].hours`} /> */}
              <IconButton onClick={() => remove(idx)} disabled={idx === 0}>
                <Delete color={idx === 0 ? 'disabled' : 'error'} />
              </IconButton>
            </RowBox>
          );
        })}
        <Box sx={{ marginBottom: '70px' }}>
          <Button
            variant="outlined"
            color="inherit"
            hasIcon
            sx={{ marginRight: '10px' }}
            onClick={() => append({ hours: startOfDay(new Date()), project: null })}
          >
            {t('form:add-another')}
          </Button>
        </Box>
      </form>
    </ConfirmationDialog>
  );
};

export default WorkHourModalForm;
