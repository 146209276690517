/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUserResource } from '../models/AuthUserResource';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { GoogleLoginRedirectResponseSchema } from '../models/GoogleLoginRedirectResponseSchema';
import type { GoogleLoginRequestBody } from '../models/GoogleLoginRequestBody';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegistrationRequest } from '../models/RegistrationRequest';
import type { ResendEmailVerificationCodeRequest } from '../models/ResendEmailVerificationCodeRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { TokenResource } from '../models/TokenResource';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { VerifyUserRequest } from '../models/VerifyUserRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiAuthService {

    /**
     * Change password
     * By calling this end point the authenticated user can change the password.
     * @param requestBody Change password request body
     * @returns void
     * @throws ApiError
     */
    public static apiAuthPasswordChange(
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/auth/password/change',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Forgot password
     * Ask for reset password email.
     * @param requestBody Forgot password request body
     * @returns void
     * @throws ApiError
     */
    public static apiAuthPasswordForgot(
        requestBody: ForgotPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/password/forgot',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Get user
     * this end point will return information of the currently authenticated user.
     * @returns AuthUserResource User resource response
     * @throws ApiError
     */
    public static apiAuthMe(): CancelablePromise<AuthUserResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/me',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
    }

    /**
     * Update user info
     * By calling this end point the currently authenticated user might update the information.
     * @param requestBody Update user request body
     * @returns AuthUserResource User resource response
     * @throws ApiError
     */
    public static apiAuthMeUpdate(
        requestBody: UpdateUserRequest,
    ): CancelablePromise<AuthUserResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/auth/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Get access token for google login
     * Get access token for google login
     * @param requestBody Google login request body
     * @returns TokenResource Token resource response
     * @throws ApiError
     */
    public static apiAuthGoogleGetToken(
        requestBody: GoogleLoginRequestBody,
    ): CancelablePromise<TokenResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/google/get-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Redirect to google login page
     * Redirect to google login page
     * @returns GoogleLoginRedirectResponseSchema Google login redirect response
     * @throws ApiError
     */
    public static apiAuthGoogleRedirect(): CancelablePromise<GoogleLoginRedirectResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/google/redirect',
        });
    }

    /**
     * Login user
     * this end point validates user credentials and if it's ok issues a token.
     * @param requestBody Login request body
     * @returns TokenResource Token resource response
     * @throws ApiError
     */
    public static apiAuthLogin(
        requestBody: LoginRequest,
    ): CancelablePromise<TokenResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Logout user
     * this end point will revoke current token.
     * @returns void
     * @throws ApiError
     */
    public static apiAuthLogout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/auth/logout',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
    }

    /**
     * Registeration endpoint
     * To register a user this endpoint should be called.
     * @param requestBody Registration request body
     * @returns void
     * @throws ApiError
     */
    public static apiAuthRegister(
        requestBody: RegistrationRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Verify email endpoint
     * After registration is done to verify email this endpoint should be called.
     * @param requestBody Verify user request body
     * @returns TokenResource Token resource response
     * @throws ApiError
     */
    public static apiAuthRegisterVerify(
        requestBody: VerifyUserRequest,
    ): CancelablePromise<TokenResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/register/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Resend verification email
     * If user missed the verification email this endpoint might be called.
     * @param requestBody Resend email verification code request body
     * @returns void
     * @throws ApiError
     */
    public static apiAuthRegisterResend(
        requestBody: ResendEmailVerificationCodeRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/register/resend',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

    /**
     * Reset password endpoint
     * With reset password token to reset password the user should call this endpoint.
     * @param requestBody Reset password request body
     * @returns void
     * @throws ApiError
     */
    public static apiAuthPasswordReset(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/auth/password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
    }

}
