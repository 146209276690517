import React from 'react';
import { Degree, EducationPreviewProps } from './component.types';
import { PageContainer, SectionTitle } from '../styled';
import { useTranslation } from 'react-i18next';
import ListPreview from '../preview-list-item';
import { DEGREES } from '../../../../constants/server-constants';
import { COMMON } from '../../../../constants/common';
import { CVEducationDegreeResource } from '../../../../api/main';
import { IconButton, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import When from '../../when';

const EducationPreview = ({ data, onEdit, onDelete, onAdd, isLoading }: EducationPreviewProps) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      sx={{
        padding: 0,
      }}
    >
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center', marginBottom: '30px' }}>
        <SectionTitle sx={{ marginBottom: 0 }}>{t('common:education')}</SectionTitle>
        <When condition={!!onAdd}>
          <IconButton disabled={isLoading} size="small" onClick={() => onAdd?.()} aria-label="add-button">
            <AddCircleOutlineIcon />
          </IconButton>
        </When>
      </Stack>
      {Array.isArray(data) &&
        data.map((item, idx) => {
          let degree: string | number = COMMON.DASH;

          if (typeof item.degree === 'object') {
            const degreeObj = item.degree as CVEducationDegreeResource;
            degree = DEGREES[degreeObj.id as unknown as Degree];
          }

          if (typeof item.degree === 'number') {
            degree = DEGREES[item.degree as unknown as Degree];
          }

          return (
            <ListPreview
              {...(!!onEdit
                ? {
                    onEdit: () => onEdit?.(item),
                    onDelete: () => onDelete?.(item),
                  }
                : {})}
              isLoading={isLoading}
              key={`preview-list-${item?.end_date}-${item?.start_date}-${item?.field_of_study}-${item?.university}`}
              title={`${t('common:education')} ${idx + 1}`}
              subtitle1={item?.field_of_study}
              subtitle2={degree}
              content={item?.description}
              dateDesc={item?.university}
              startDate={item?.start_date}
              endDate={item?.end_date}
            />
          );
        })}
    </PageContainer>
  );
};

export default EducationPreview;
