import React, { useRef } from 'react';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { StartWorkHourForm, StartWorkHourModalProps } from './component.types';
import { Box } from '@mui/material';
import BasicTimePicker from '../../time-picker/component';
import useErrorHandler from '../../../hooks/use-error-handler';
import CollidingBox from '../../colliding-box';

const workHourFormSchema = Yup.object().shape({
  start_time: Yup.string().required(),
});

const StartWorkHourModal = ({
  onSubmit,
  open,
  onCancel,
  initialData,
  isLoading,
  collidingInfo,
}: StartWorkHourModalProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();

  const { handleSubmit, formDebugger, control, setError } = useForm({
    name: 'start-work-hour-form',
    schema: workHourFormSchema,
    defaultValues: {
      start_time: initialData?.start_time,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('form:pick-start-time')}
      onOkText="form:start-timer"
      width="24%"
      isOkLoading={isLoading}
    >
      <form
        ref={formRef}
        aria-label="vacation-modal-form"
        onSubmit={handleSubmit(
          values => {
            const { start_time: startTime } = values;
            const startDate = new Date(startTime as string);
            if (startDate.valueOf() > Date.now()) {
              setError('start_time', { message: `The start time cannot be greater than the current time.` });
              return Promise.reject();
            }
            return onSubmit(values as StartWorkHourForm);
          },
          false,
          err => {
            if (typeof err === 'string') {
              void handleError(err);
            }
          }
        )}
      >
        {formDebugger()}
        <CollidingBox data={collidingInfo} />
        <Box>
          <BasicTimePicker
            control={control}
            name={'start_time'}
            label={t('form:start-time')}
            fullWidth
            required
            maxTime={new Date()}
          />
        </Box>
      </form>
    </ConfirmationDialog>
  );
};

export default StartWorkHourModal;
