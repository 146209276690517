import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store.types';
import { TokenResource, OpenAPI } from '../../../api/main';
import jwtDecode from 'jwt-decode';
import { USERTYPE } from '../../../constants/common';
import { TokenVariables, UserState } from './user.types';

export const initialState: UserState = {
  data: null,
  userType: USERTYPE.GUEST,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<TokenResource>) => {
      const parsedToken: TokenVariables = jwtDecode(action.payload.access_token);

      state.data = {
        ...parsedToken.user,
        token: action.payload.access_token,
        user_type: USERTYPE.EMPLOYEE,
      };
      state.userType = USERTYPE.EMPLOYEE;
      OpenAPI.TOKEN = action.payload.access_token;
    },
    deleteUser: state => {
      state.data = initialState.data;
      state.userType = initialState.userType;
    },
    setUserType: (state, action: PayloadAction<UserState['userType']>) => {
      state.userType = action.payload;
    },
  },
});

export const { setUserData, deleteUser, setUserType } = userSlice.actions;

export const getUser = (state: RootState): UserState => state.user;

export default userSlice.reducer;
