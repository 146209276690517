import React from 'react';
import { ExperiencePreviewProps } from './component.types';
import { PageContainer, SectionTitle } from '../styled';
import { useTranslation } from 'react-i18next';
import ListPreview from '../preview-list-item';
import When from '../../when';
import { IconButton, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ExperiencePreview = ({ data, onEdit, onDelete, onAdd, isLoading }: ExperiencePreviewProps) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      sx={{
        padding: 0,
      }}
    >
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center', marginBottom: '30px' }}>
        <SectionTitle sx={{ marginBottom: 0 }}>{t('common:work-experience')}</SectionTitle>
        <When condition={!!onAdd}>
          <IconButton disabled={isLoading} size="small" onClick={() => onAdd?.()} aria-label="add-button">
            <AddCircleOutlineIcon />
          </IconButton>
        </When>
      </Stack>
      {Array.isArray(data) &&
        data.map((item, idx) => (
          <ListPreview
            {...(!!onEdit
              ? {
                  onEdit: () => onEdit?.(item),
                  onDelete: () => onDelete?.(item),
                }
              : {})}
            isLoading={isLoading}
            key={`preview-list-${item?.end_date}-${item?.start_date}-${item?.position}-${item?.company}`}
            title={`${t('common:experience')} ${idx + 1}`}
            subtitle1={item?.position}
            subtitle2={item?.technologies}
            content={item?.description}
            dateDesc={item?.company}
            startDate={item?.start_date}
            endDate={item?.end_date}
          />
        ))}
    </PageContainer>
  );
};

export default ExperiencePreview;
