import { memo, ReactElement } from 'react';
import { persistor, store } from '../store';

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

export type StoreProviderProps = {
  children: ReactElement | ReactElement[];
};

const StoreProvider = ({ children }: StoreProviderProps) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);

export default memo(StoreProvider);
