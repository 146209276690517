import React, { useEffect, useRef } from 'react';
import { SkillsModalFormProps } from './component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import SkillsForm, { skillsFormSchema } from '../skills-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const SkillsModalForm = <T,>({ initialData, onSubmit, open, onCancel, userType }: SkillsModalFormProps<T>) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, formDebugger, control } = useForm({
    name: 'experience-modal-form',
    schema: skillsFormSchema,
    defaultValues: {
      soft_skills: initialData?.soft_skills,
      hard_skills: initialData?.hard_skills,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  useEffect(() => {}, [open]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('form:cv.skills')}
      onOkText="common:edit"
      onOKIcon={<EditOutlinedIcon />}
    >
      <form
        ref={formRef}
        aria-label="skills-modal-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        <SkillsForm userType={userType} control={control} />
      </form>
    </ConfirmationDialog>
  );
};

export default SkillsModalForm;
