import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { storeActions } from '../index';
import { fetchClientProfileSaga } from './client-profile';

function* fillUserData(): Generator {
  yield fetchClientProfileSaga();
}

function* clearUserData() {
  yield put(storeActions.clientProfile.deleteClientProfile());
}

function* watchUser() {
  yield takeEvery('user/setUserData', fillUserData);
  yield takeLatest('user/deleteUser', clearUserData);
}

export default watchUser;
