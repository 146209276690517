import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { Box, List, ListItem, ListItemText, Stack, TextField, Typography, styled, useTheme } from '@mui/material';
import useErrorHandler from '../../../hooks/use-error-handler';
import FormItemText from '../../forms/form-item-text';
import { RemainedVacationsFormData, RemainedVacationsFormProps } from './component.types';
import Autocomplete from '../../forms/autocomplete';
import { convertDaysAndHoursToHours, formatHoursToDayAndHours } from '../../../tools/date';
import { useWatch } from 'react-hook-form';

const CircleBox = styled(Box)(({ theme }) => ({
  width: '10px',
  height: '10px',
  marginRight: '20px',
  borderRadius: '50%',
  background: theme.palette.lightGray.main,
}));

const remainedVacationsFormSchema = Yup.object().shape({
  remained_vacation_days: Yup.number().required().typeError('Invalid'),
  remained_vacation_hours: Yup.number().min(-7).max(7).required().typeError('Invalid'),
  remained_day_off_days: Yup.number().required().typeError('Invalid'),
  remained_day_off_hours: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.number().required(),
    })
    .typeError('Invalid'),
});

const RemainedVacationsForm: React.FC<RemainedVacationsFormProps> = ({
  onSubmit,
  open,
  onCancel,
  initialData,
  isLoading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();
  const { handleSubmit, formDebugger, control, trigger } = useForm({
    name: 'remained-vacations-form',
    schema: remainedVacationsFormSchema,
    defaultValues: {
      remained_vacation_days: 0,
      remained_vacation_hours: 0,
      remained_day_off_days: 0,
      remained_day_off_hours: { value: '0', label: '0' },
      ...initialData,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current && showPreview) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    } else {
      void trigger().then(setShowPreview);
    }
  };
  const formData = useWatch({
    control,
  }) as RemainedVacationsFormData;

  return (
    <ConfirmationDialog
      isOkLoading={isLoading}
      open={open}
      onClose={onCancel}
      onCancel={() => (showPreview ? setShowPreview(false) : onCancel())}
      onOk={handleOnSubmit}
      title={t('form:edit-vacations')}
      onOkText={showPreview ? 'common:confirm' : 'common:submit'}
      width="440px"
    >
      <form
        ref={formRef}
        aria-label="remained-vacations-form"
        onSubmit={handleSubmit(onSubmit, false, err => {
          if (typeof err === 'string') {
            void handleError(err);
          }
        })}
      >
        {formDebugger()}
        <Box sx={{ overflowX: 'hidden' }}>
          <Stack
            direction="row"
            sx={{
              transform: `${showPreview ? 'translateX(-100%)' : 'translateX(0)'}`,
              transition: theme.transitions.create('transform'),
              '& > *': { flexShrink: 0, width: '100%', overflow: 'hidden' },
            }}
          >
            <Stack direction="column">
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap="15px">
                <Typography marginTop="30px" fontWeight="bold">
                  Remained vacation days:
                </Typography>
                <Stack direction="row" gap="10px" sx={{ '& > div': { width: '85px' } }}>
                  <FormItemText
                    defaultValue=""
                    control={control}
                    required
                    name="remained_vacation_days"
                    label={t('form:days')}
                  >
                    <TextField placeholder={'0'} type="number" />
                  </FormItemText>
                  <FormItemText
                    defaultValue=""
                    control={control}
                    required
                    name="remained_vacation_hours"
                    label={t('form:hours')}
                  >
                    <TextField placeholder={'0'} type="number" inputProps={{ min: '0', max: '10', step: '1' }} />
                  </FormItemText>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap="15px">
                <Typography marginTop="30px" fontWeight="bold">
                  {t('table:remained-vacations.remained-day-offs')}:{' '}
                </Typography>
                <Stack direction="row" gap="10px" sx={{ '& > div': { width: '85px' } }}>
                  <FormItemText
                    defaultValue=""
                    control={control}
                    required
                    name="remained_day_off_days"
                    label={t('form:days')}
                  >
                    <TextField placeholder={'0'} type="number" />
                  </FormItemText>
                  <Autocomplete
                    defaultValue={{
                      value: String(initialData?.remained_day_off_hours.value),
                      label: String(initialData?.remained_day_off_hours.value),
                    }}
                    label={t('form:hours')}
                    name="remained_day_off_hours"
                    control={control}
                    required
                    optionKeys={{
                      label: ['label'],
                      value: 'value',
                    }}
                    placeholder=""
                    options={[
                      { value: '0', label: '0' },
                      { value: 4, label: 4 },
                    ]}
                    loading={false}
                    onSearch={() => {}}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Box>
              <Stack direction="row" alignItems="center" marginBottom="10px">
                <Typography>You are changing following values:</Typography>
              </Stack>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} aria-label="contacts">
                <ListItem>
                  <CircleBox />
                  <ListItemText
                    primary={`Remained vacation days:`}
                    secondary={`${formatHoursToDayAndHours(
                      convertDaysAndHoursToHours(
                        initialData?.remained_vacation_days || 0,
                        initialData?.remained_vacation_hours || 0
                      )
                    )} → ${formatHoursToDayAndHours(
                      convertDaysAndHoursToHours(
                        Number(formData?.remained_vacation_days) || 0,
                        Number(formData?.remained_vacation_hours) || 0
                      )
                    )}`}
                  />
                </ListItem>
                <ListItem>
                  <CircleBox />
                  <ListItemText
                    primary={`Remained day-offs:`}
                    secondary={` ${formatHoursToDayAndHours(
                      convertDaysAndHoursToHours(
                        initialData?.remained_day_off_days || 0,
                        initialData?.remained_day_off_hours.value || 0
                      )
                    )} → ${formatHoursToDayAndHours(
                      convertDaysAndHoursToHours(
                        Number(formData?.remained_day_off_days) || 0,
                        Number(formData?.remained_day_off_hours.value) || 0
                      )
                    )}`}
                  />
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Box>
      </form>
    </ConfirmationDialog>
  );
};

export default RemainedVacationsForm;
