import React, { useCallback, useEffect, useState } from 'react';
import { InputTimePickerProps, NullishString } from './component.types';
import { useController } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { debounce } from '../../tools/debounce';
import FormInputLabel from '../forms/form-input-label';
import { TimePicker } from '@mui/x-date-pickers';
import { isAfter } from 'date-fns';

const InputTimePicker = ({
  defaultValue = null,
  label = '',
  disabled = false,
  fullWidth = false,
  required = false,
  control,
  name,
  maxTime,
  minTime,
  readonly,
  endAdornment,
  onClick,
}: InputTimePickerProps) => {
  const [open, setOpen] = useState(false);
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue ?? '',
    name: name,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onChange, ref, value: fieldValue, onBlur, ...inputProps } = field;
  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  const [pickerValue, setPickerValue] = useState<NullishString>(fieldValue as string);

  const handleOnchange = debounce((newValue: NullishString) => {
    setPickerValue(newValue);
    onChange(newValue);
  });

  const handleDisable = useCallback(() => {
    if (disabled && pickerValue) {
      setPickerValue(null);
      onChange(null);
    }
  }, [disabled, onChange, pickerValue]);

  useEffect(() => {
    handleDisable();
  }, [disabled, handleDisable]);

  useEffect(() => {
    if (maxTime && pickerValue && isAfter(new Date(pickerValue), maxTime)) {
      setPickerValue(maxTime.toString());
    }
  }, [pickerValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        readOnly={readonly}
        open={open}
        onOpen={() => !disabled && !readonly && setOpen(true)}
        inputRef={ref}
        value={pickerValue}
        maxTime={maxTime}
        minTime={minTime}
        views={['hours', 'minutes']}
        ampm={false}
        disabled={disabled}
        toolbarTitle={`${htmlFor}-picker`}
        onChange={handleOnchange}
        inputFormat="HH:mm"
        onClose={() => {
          setOpen(false);
        }}
        renderInput={params => {
          const Input = (
            <TextField
              {...params}
              onBlur={() => {
                if (open) return;
                return onBlur();
              }}
              data-testid={htmlFor}
              name={name}
              onClick={() => (onClick ? onClick() : !disabled && !readonly && setOpen(true))}
              aria-label={htmlFor}
              error={invalid}
              required={required}
              helperText={!disabled && error?.message}
              fullWidth={fullWidth}
              type="date"
              InputProps={{
                ...inputProps,
                endAdornment,
              }}
            />
          );
          return label ? (
            <FormInputLabel htmlFor={htmlFor} required={required} disabled={!!disabled} label={label}>
              {Input}
            </FormInputLabel>
          ) : (
            Input
          );
        }}
      />
    </LocalizationProvider>
  );
};
export default InputTimePicker;
