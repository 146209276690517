import React from 'react';
import { ExperienceFormProps, ExperienceFormSchemaTypes, CVDetailsExperienceFormSchemaTypes } from './component.types';
import { Box } from '@mui/material';
import { Cell, Row, FormContainer } from '../styled';
import TextField from '@mui/material/TextField';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import InputDatePicker from '../../forms/input-date-picker';
import Checkbox from '../../forms/checkbox/component';
import * as Yup from 'yup';
import { useWatch } from 'react-hook-form';
import { USERTYPE } from '../../../../constants/common';

const formSchema = {
  company: Yup.string().ensure().required(),
  position: Yup.string().ensure().required(),
  technologies: Yup.string().ensure().required(),
  description: Yup.string(),
  start_date: Yup.string().ensure().dashedDateOnly().required(),
  end_date: Yup.string().when('currently_working', {
    is: true,
    otherwise: Yup.string().ensure().dashedDateOnly().required(),
    then: Yup.string().nullable(true),
  }),
  currently_working: Yup.boolean(),
};

export const experienceFormSchema: ExperienceFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape(formSchema),
  [USERTYPE.EMPLOYEE]: Yup.object().shape(formSchema),
};

export const cvDetailsExperienceFormSchema: CVDetailsExperienceFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape({
    work_experiences: Yup.array(experienceFormSchema.hr),
  }),
  [USERTYPE.EMPLOYEE]: Yup.object().shape({
    work_experiences: Yup.array(experienceFormSchema.hr),
  }),
};

const ExperienceForm = ({ prefix = '', control }: ExperienceFormProps) => {
  const { t } = useTranslation();

  const watchCurrentlyWorking = useWatch({
    control,
    name: `${prefix}currently_working`,
  }) as boolean;

  const [startDate, endDate] = useWatch({
    control,
    name: [`${prefix}start_date`, `${prefix}end_date`],
  }) as Date[];

  return (
    <FormContainer>
      <Row>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            required
            label={t('form:cv.company-name')}
            name={`${prefix}company`}
          >
            <TextField />
          </FormItemText>
        </Cell>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            required
            label={t('form:cv.position')}
            name={`${prefix}position`}
          >
            <TextField />
          </FormItemText>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            required
            name={`${prefix}technologies`}
            label={t('form:cv.technologies')}
          >
            <TextField minRows={5} multiline />
          </FormItemText>
        </Cell>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            name={`${prefix}description`}
            label={t('form:cv.description')}
          >
            <TextField minRows={5} multiline />
          </FormItemText>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <InputDatePicker
            fullWidth
            control={control}
            required
            name={`${prefix}start_date`}
            label={t('form:cv.start-date')}
            maxDate={endDate ? new Date(endDate) : new Date()}
          />
        </Cell>
        <Cell>
          <Cell>
            <InputDatePicker
              fullWidth
              control={control}
              required={!watchCurrentlyWorking}
              disabled={watchCurrentlyWorking}
              name={`${prefix}end_date`}
              label={t('form:cv.end-date')}
              minDate={startDate ? new Date(startDate) : undefined}
              maxDate={new Date()}
            />
          </Cell>
        </Cell>
      </Row>
      <Box>
        <Checkbox
          name={`${prefix}currently_working`}
          data-testid="currently_working"
          label={t('form:cv.currently-working')}
          control={control}
        />
      </Box>
    </FormContainer>
  );
};

export default ExperienceForm;
