import { useState } from 'react';
import { ApiErrorResponseType } from '../use-fetch/component.types';
import CollidingBox from '../../widgets/colliding-box';
import useErrorHandler from '../use-error-handler';

export type CollidingInfo = {
  internal_status_code: string;
  header: string;
  days: Array<{ date: string; type: string }>;
};

export const useCollidingBox = () => {
  const [collidingInfo, setCollidingInfo] = useState<CollidingInfo | null>(null);
  const handleError = useErrorHandler();

  const checkForCollding = (e: ApiErrorResponseType) => {
    if (e.body && e.body.errors && Object.values(e.body.errors).length) {
      const values = Object.values(e.body.errors);
      const info = values[0][0] as unknown as string;
      if (info.includes('internal_status_code')) {
        setCollidingInfo(JSON.parse(info) as CollidingInfo);
        return;
      }
      return handleError(e);
    } else {
      return handleError(e);
    }
  };
  const clearError = () => setCollidingInfo(null);
  return { collidingInfo, CollidingBox, clearError, checkForCollding };
};
