import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeActivityResource } from '../../../../api/main';
import { defaultColDef, VACATIONSTYPE } from '../../../../constants/report';
import { formatHoursToDayAndHours } from '../../../tools/date';
import StatusSelect from '../../status-select';
import CustomPagination from '../../table-pagination';
import { UserVacationGridProps } from './component.types';
import { CircularProgress, Stack } from '@mui/material';

const UserVacationsGrid: React.FC<UserVacationGridProps> = ({
  isLoading,
  data,
  page,
  onPageChange,
  showUser,
  handleStatusChange = () => {},
  vacationType,
}) => {
  const { t } = useTranslation();
  const Columns: GridColDef<EmployeeActivityResource & { isLoading?: boolean }>[] = [
    {
      ...defaultColDef,
      field: 'start_time',
      headerName: t('table:vacations.start-date'),
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.Y EEEE ') : '';
      },
    },
    {
      ...defaultColDef,
      field: 'end_time',
      headerName: t('table:vacations.end-date'),
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.Y') : '';
      },
    },
    {
      ...defaultColDef,
      field: 'duration',
      headerName: t('table:vacations.duration'),
      valueGetter({ row }) {
        return formatHoursToDayAndHours(row.duration_in_hours || 0);
      },
    },
    {
      ...defaultColDef,
      field: 'date',
      headerName: vacationType === VACATIONSTYPE.SpecialVacation ? 'Type' : t('table:vacations.request-date'),
      valueGetter({ row }) {
        return vacationType === VACATIONSTYPE.SpecialVacation
          ? // TODO: Use the actual data
            row.description
            ? `${row.description} vacation`
            : ''
          : format(new Date(row.created_at as string), 'dd.MM.Y');
      },
    },
    {
      ...defaultColDef,
      field: 'status',
      headerName: t('table:cvs.status.value'),
      flex: 1,
      renderCell({ row }) {
        const readonly = !showUser && (row.status?.id === 2 || row.status?.id === 3);
        return (
          <Stack direction="row" alignItems="center" width="135px" justifyContent="space-between">
            <StatusSelect
              readonly={readonly || (row.status?.id === 4 && !showUser)}
              hasCancel={!showUser && !readonly}
              value={String(row.status?.id)}
              onChange={event => handleStatusChange(event, row)}
            />
            {row.isLoading && (
              <CircularProgress color="primary" size={20} sx={{ marginTop: '-5px', marginLeft: '3px' }} />
            )}
          </Stack>
        );
      },
    },
  ];
  const DayOffColumns: GridColDef<EmployeeActivityResource>[] = [
    {
      ...defaultColDef,
      field: 'start_time',
      headerName: t('table:common.date'),
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.Y EEEE ') : '';
      },
    },

    {
      ...defaultColDef,
      field: 'duration',
      headerName: t('table:vacations.duration'),
      valueGetter({ row }) {
        return `${formatHoursToDayAndHours(row.duration_in_hours || 0)}${
          row.duration_in_hours !== 8 && row.start_time
            ? format(new Date(row.start_time), 'HH:mm') === '05:00'
              ? ' ( first part )'
              : ' ( second part )'
            : ''
        }`;
      },
    },
    Columns[3],
    {
      ...defaultColDef,
      field: 'status',
      headerName: t('table:cvs.status.value'),
      flex: 1,
      renderCell({ row }) {
        const readonly = !showUser && (row.status?.id === 2 || row.status?.id === 3);
        return (
          <StatusSelect
            readonly={readonly || (row.status?.id === 4 && !showUser)}
            hasCancel={!showUser && !readonly}
            value={String(row.status?.id)}
            onChange={event => handleStatusChange(event, row)}
          />
        );
      },
    },
  ];

  if (showUser) {
    const userCol = {
      ...defaultColDef,
      field: 'fullName',
      headerName: t('table:common.full-name'),
      valueGetter({ row }: { row: EmployeeActivityResource }) {
        return `${row.user?.first_name} ${row.user?.last_name}`;
      },
    };
    Columns.unshift(userCol);
    DayOffColumns.unshift(userCol);
  }

  return (
    <DataGrid
      key={String(vacationType)}
      loading={isLoading}
      page={page}
      onPageChange={onPageChange}
      columns={vacationType === VACATIONSTYPE.DayOffs ? DayOffColumns : Columns}
      rows={data.data || []}
      paginationMode="server"
      pageSize={data.meta?.per_page}
      rowCount={data.meta?.total}
      components={{ Pagination: CustomPagination }}
      disableSelectionOnClick
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
    />
  );
};

export default UserVacationsGrid;
