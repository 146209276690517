import { Box, CircularProgress, Stack, Typography, styled } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';

import ProfilePlaceholder from '../../../assets/img/profile-placeholder.svg';
import Button from '../button/component';
import ConfirmationDialog from '../confirmation-dialog';
import getCroppedImg, { CroppedArea, readFile } from '../../tools/cropper';
import { Edit } from '@mui/icons-material';

const ProfileImg = styled('img')({
  width: '161px',
  height: '161px',
  minWidth: '161px',
  minHeight: '161px',
  borderRadius: '50%',
});

export const UploadProfile: React.FC<{
  image?: string;
  isLoading: boolean;
  onUpload: (file: File | null) => void;
  onRemove: () => void;
}> = ({ image, onUpload, onRemove, isLoading }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const currentImageDataUrl = useRef<string | null | ArrayBuffer>(null);
  const imageDataUrl = useRef<string | null | ArrayBuffer>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixelsValue, setCroppedAreaPixelsValue] = useState<CroppedArea | null>(null);
  const [isCropperOpen, setIsCropperOpen] = useState(false);

  const toggelCropper = () => setIsCropperOpen(!isCropperOpen);

  const onCropComplete = useCallback((_: unknown, croppedAreaPixels: CroppedArea) => {
    setCroppedAreaPixelsValue(croppedAreaPixels);
  }, []);

  const onCroppedImageSave = useCallback(async () => {
    if (selectedImage && croppedAreaPixelsValue) {
      const croppedImage = (await getCroppedImg(currentImageDataUrl.current as string, croppedAreaPixelsValue)) as File;
      setSelectedImage(croppedImage);
      imageDataUrl.current = await readFile(croppedImage);
      currentImageDataUrl.current = null;
      toggelCropper();
      onUpload(croppedImage);
    }
  }, [croppedAreaPixelsValue, image]);

  const handleInputOpen = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    currentImageDataUrl.current = null;
    if (e.target.files && e.target.files[0]) {
      toggelCropper();
      currentImageDataUrl.current = await readFile(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      setZoom(1);
      setCrop({ x: 0, y: 0 });
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" alignSelf="start">
      <Box sx={{ margin: '40px 0' }}>
        <Typography variant="h1" component="p">
          Profile Photo
          <Edit sx={{ verticalAlign: 'text-top', marginLeft: '15px' }} />
        </Typography>
      </Box>
      <input type="file" ref={inputRef} hidden onInput={handleChange} disabled={isLoading} />
      <Box onClick={handleInputOpen} sx={{ position: 'relative' }}>
        {isLoading && (
          <CircularProgress
            color="secondary"
            sx={{ position: 'absolute', margin: 'auto', left: 0, top: 0, bottom: 0, right: 0 }}
          />
        )}
        <ProfileImg src={(imageDataUrl.current as string) || image || ProfilePlaceholder} alt="User Profile" />
      </Box>
      <Button disabled={isLoading} onClick={handleInputOpen} sx={{ width: '165px', marginTop: '32px' }}>
        Choose a file
      </Button>
      <Button
        disabled={!image || isLoading}
        onClick={() => {
          setSelectedImage(null);
          imageDataUrl.current = '';
          if (inputRef.current) inputRef.current.value = '';
          onRemove();
        }}
        sx={{ width: '165px', marginTop: '16px' }}
        color="error"
      >
        Remove photo
      </Button>
      <ConfirmationDialog
        title=""
        open={isCropperOpen}
        onCancel={() => {
          currentImageDataUrl.current = null;
          toggelCropper();
        }}
        onOk={onCroppedImageSave}
        onOkText="form:save"
        padding="5px"
        width="auto"
      >
        <Box width="400px" height="400px" position="relative" margin="auto">
          <Cropper
            image={currentImageDataUrl.current as string}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
          />
        </Box>
      </ConfirmationDialog>
    </Stack>
  );
};
