import React, { useEffect, useRef } from 'react';
import { CertificateModalFormProps } from './component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import CertificateForm, { certificateFormSchema } from '../certificate-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

const CertificateModalForm = <T,>({
  initialData,
  onSubmit,
  open,
  onCancel,
  userType,
}: CertificateModalFormProps<T>) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, formDebugger, control } = useForm({
    name: 'experience-modal-form',
    schema: certificateFormSchema[userType],
    defaultValues: {
      title: initialData?.title,
      institute: initialData?.institute,
      description: initialData?.description,
      link: initialData?.link,
      issue_date: initialData?.issue_date,
      expiration_date: initialData?.expiration_date,
      no_expiration: !initialData?.expiration_date,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  useEffect(() => {}, [open]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('common:certificate')}
      onOkText={initialData ? 'common:edit' : 'common:add'}
      onOKIcon={initialData ? <EditOutlinedIcon /> : <AddIcon />}
    >
      <form
        ref={formRef}
        aria-label="certificate-modal-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        <CertificateForm control={control} />
      </form>
    </ConfirmationDialog>
  );
};

export default CertificateModalForm;
