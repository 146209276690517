import enCommon from './dictionaries/en/common.json';
import fiCommon from './dictionaries/fi/common.json';
import enForm from './dictionaries/en/form.json';
import fiForm from './dictionaries/fi/form.json';
import enSymbol from './dictionaries/en/symbol.json';
import fiSymbol from './dictionaries/fi/symbol.json';
import enFormValidation from './dictionaries/en/form-validation.json';
import fiFormValidation from './dictionaries/fi/form-validation.json';
import enTable from './dictionaries/en/table.json';
import fiTable from './dictionaries/fi/table.json';

const translations = Object.freeze({
  en: {
    common: enCommon,
    form: enForm,
    symbol: enSymbol,
    formValidation: enFormValidation,
    table: enTable,
  },
  fi: {
    common: fiCommon,
    form: fiForm,
    symbol: fiSymbol,
    formValidation: fiFormValidation,
    table: fiTable,
  },
});

export default translations;
