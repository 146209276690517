import React, { useEffect, useRef } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { SelectChangeEvent, Typography } from '@mui/material';
import { ApiCvService, ChangeCVStatusRequest, CVResource, CVsCollection } from '../../../../api/main';
import CustomPagination from '../../table-pagination';
import useErrorHandler from '../../../hooks/use-error-handler';
import { CvIndexRequest, TableFilters } from './component.types';
import useFetch from '../../../hooks/use-fetch';
import useTable from '../../../hooks/use-table';
import { defaultColDef } from '../../../../constants/report';
import StatusSelect from '../../status-select';
import ConfirmationDialog from '../../confirmation-dialog';
import { appRoutes } from '../../../../providers/routes';
import ExportCvCell from '../../grid-cells/export-cv-cell';
import ActionsCell from '../../grid-cells/actions-cell';
import { ApiErrorResponseType } from '../../../hooks/use-fetch/component.types';
import { useUser } from '../../../hooks/use-user';
import { USERTYPE } from '../../../../constants/common';

const CVsGrid: React.FC<{ filters: TableFilters }> = ({ filters }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const [open, setOpen] = React.useState(false);
  const cvIDRef = useRef<number | undefined>(undefined);
  const { isHR, clientProfile, userType } = useUser();

  const { data, page, setPage, setData, pageSize, setFilters } = useTable<CVsCollection, TableFilters>({
    initialData: { meta: { total: 0 } },
    initialFilters: filters,
    initialPage: 0,
    isInQuery: true,
  });
  const getAllCVs = useFetch<CvIndexRequest, CVsCollection>(({ page: newPage, searchQuery, skillIds }) =>
    ApiCvService.apiCvIndex(newPage, pageSize, searchQuery, skillIds)
  );
  const fetchData = useRef(getAllCVs.call);
  const deleteCV = useFetch(ApiCvService.apiCvDelete);
  const changeStatus = useFetch<ChangeCVStatusRequest, CVResource>(payload =>
    ApiCvService.apiCvChangeStatus(Number(cvIDRef.current), payload)
  );
  useEffect(() => {
    setFilters(filters);
  }, [filters]);
  useEffect(() => {
    const handleSuccess = (result: CVsCollection) => setData(result);

    fetchData
      .current({
        ...filters,
        searchQuery: userType === USERTYPE.EMPLOYEE ? `${clientProfile?.email}` : filters.searchQuery || undefined,
        page: page + 1,
        pageSize,
      })
      .then(handleSuccess)
      .catch(handleError);
  }, [fetchData, filters, handleError, setData, page, pageSize]);

  const handleStatusChange = (event: SelectChangeEvent<string>, row: CVResource) => {
    const value = event?.target.value || 1;
    cvIDRef.current = row.id;
    changeStatus
      .call({ status: value as ChangeCVStatusRequest.status, comment: t('form:general-submission.success') })
      .then(() => {
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        setData({
          ...data,
          data: data.data?.map(item =>
            item.id === row.id ? { ...item, status_id: value as CVResource.status_id } : item
          ),
        });
      })
      .catch(handleError)
      .finally(() => {
        cvIDRef.current = undefined;
      });
  };

  const Columns: GridColDef<CVResource>[] = [
    {
      ...defaultColDef,
      field: 'fullName',
      headerName: t('table:common.full-name'),
      flex: 1,
      valueGetter({ row }) {
        return row.first_name ? `${row.first_name} ${row.last_name}` : `${row.user?.first_name} ${row.user?.last_name}`;
      },
    },
    {
      ...defaultColDef,
      field: 'email',
      headerName: t('table:common.email-address'),
      flex: 1,
      valueGetter({ row }) {
        return row.user?.email;
      },
    },
    {
      ...defaultColDef,
      field: 'title',
      headerName: 'Job Title',
      flex: 1,
      valueGetter({ row }) {
        return row.title;
      },
    },
    {
      ...defaultColDef,
      field: 'export',
      headerName: t('table:cvs.export'),
      flex: 1,
      renderCell({ row }) {
        return <ExportCvCell id={Number(row.id)} disabled={!isHR && row.status_id !== CVResource.status_id._2} />;
      },
    },
    {
      ...defaultColDef,
      field: 'status',
      headerName: t('table:cvs.status.value'),
      flex: 1,
      renderCell({ row }) {
        return (
          <StatusSelect
            showCancel={false}
            readonly={!isHR}
            onChange={event => handleStatusChange(event, row)}
            value={String(row.status_id)}
          />
        );
      },
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        const cvSingleRoute = appRoutes.cvSingle.replace(/:cvId/, String(row.id));
        const isEditDisabled = row.status_id === CVResource.status_id._2 && !isHR;
        return (
          <ActionsCell
            isDeleteDisabled={!isHR && isEditDisabled}
            isEditDisabled={isEditDisabled}
            editRoute={cvSingleRoute}
            handleDelete={() => {
              setOpen(true);
              cvIDRef.current = row.id;
            }}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <DataGrid
        loading={getAllCVs.loading}
        page={page}
        onPageChange={setPage}
        columns={Columns}
        rows={data.data || []}
        paginationMode="server"
        pageSize={data.meta?.per_page}
        rowCount={data.meta?.total}
        components={{ Pagination: CustomPagination }}
        disableSelectionOnClick
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        columnBuffer={5}
      />
      <ConfirmationDialog
        width="30%"
        padding="10px"
        onOk={() => {
          if (cvIDRef.current) {
            deleteCV
              .call(cvIDRef.current)
              .then(() => {
                enqueueSnackbar(t('form:general-submission.success'), {
                  variant: 'success',
                });
                setOpen(false);
                setData({
                  ...data,
                  data: data.data?.filter(item => item.id !== cvIDRef.current),
                });
              })
              .catch((err: ApiErrorResponseType) => {
                setOpen(false);
                void handleError(err);
              });
          }
        }}
        onCancel={() => {
          setOpen(false);
          cvIDRef.current = undefined;
        }}
        title=""
        open={open}
      >
        <Typography variant="h4" paragraph>
          {t('form:delete-confirm')}
        </Typography>
      </ConfirmationDialog>
    </React.Fragment>
  );
};
export default CVsGrid;
