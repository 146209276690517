import React, { memo, useState } from 'react';
import { PreviewListItemProps } from './component.types';
import { ListText, ListTitle, ListWrapper, Status } from '../styled';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { COMMON } from '../../../../constants/common';
import { getYear } from '../../../tools/date';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import When from '../../when';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../confirmation-dialog';

const PreviewListItem = ({
  title,
  subtitle1,
  subtitle2,
  content,
  dateDesc,
  startDate,
  endDate,
  onEdit,
  onDelete,
  isLoading = false,
}: PreviewListItemProps) => {
  const { t } = useTranslation();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  return (
    <ListWrapper key={`preview-list-${title}-${subtitle1}-${subtitle2}-${startDate}-${endDate ?? ''}`}>
      <ConfirmationDialog
        open={openConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        onOk={() => {
          onDelete?.();
          setOpenConfirmationModal(false);
        }}
        title={t('common:are-you-sure')}
        width="inherit"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ListTitle>{title}</ListTitle>
        <When condition={!!onEdit}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            sx={{ margin: '0 10px' }}
          >
            <IconButton disabled={isLoading} size="small" onClick={onEdit} aria-label="edit-button">
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={isLoading}
              size="small"
              onClick={() => setOpenConfirmationModal(true)}
              aria-label="delete-button"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Stack>
        </When>
      </Box>
      <Box>
        <Status>
          {startDate ? getYear(startDate) : COMMON.DASH}
          {endDate ? `${COMMON.DASH}${getYear(endDate)}` : ''}
        </Status>
        <ListText>{dateDesc}</ListText>
      </Box>
      <ListTitle>{subtitle1}</ListTitle>
      <ListTitle>{subtitle2}</ListTitle>
      <ListText>{content}</ListText>
    </ListWrapper>
  );
};

export default memo(PreviewListItem);
