import React, { memo, useEffect, useRef } from 'react';
import { ProfileInformationModalFormProps } from './component.types';
import TextField from '@mui/material/TextField';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useForm from '../../../hooks/use-form';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Cell, Row } from '../styled';
import ConfirmationDialog from '../../confirmation-dialog';
import { profileInformationFormSchema } from '../profile-information-form';
import { cvInformationFormSchema } from '../cv-information-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useUser } from '../../../hooks/use-user';

const ProfileInformationModalForm = ({
  initialData,
  onSubmit,
  open,
  onCancel,
  userType,
}: ProfileInformationModalFormProps) => {
  const { t } = useTranslation('form');
  const formRef = useRef<HTMLFormElement | null>(null);
  const { isHR } = useUser();

  const { handleSubmit, formDebugger, control } = useForm({
    name: 'profile-modal-form',
    schema: Yup.object().shape({
      ...profileInformationFormSchema[userType].fields,
      ...cvInformationFormSchema[userType].fields,
    }),
    defaultValues: {
      first_name: initialData?.first_name,
      last_name: initialData?.last_name,
      introduction: initialData?.introduction,
      title: initialData?.title,
    },
  });

  useEffect(() => {}, [open]);

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('form:profile-information')}
      onOkText="common:edit"
      onOKIcon={<EditOutlinedIcon />}
    >
      <form
        aria-label="profile-information-modal-form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        <Row>
          <Cell>
            <FormItemText defaultValue="" control={control} disabled={!isHR} name="first_name" label={t('first-name')}>
              <TextField />
            </FormItemText>
          </Cell>
          <Cell>
            <FormItemText defaultValue="" control={control} disabled={!isHR} name="last_name" label={t('last-name')}>
              <TextField />
            </FormItemText>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <FormItemText defaultValue="" control={control} name="title" label={t('job-title')}>
              <TextField />
            </FormItemText>
          </Cell>
          <Cell>
            <div></div>
          </Cell>
        </Row>
        <Row>
          <FormItemText defaultValue="" control={control} name="introduction" label={t('common:about')}>
            <TextField minRows={5} multiline type="text" />
          </FormItemText>
        </Row>
      </form>
    </ConfirmationDialog>
  );
};

export default memo(ProfileInformationModalForm);
