import React, { Children } from 'react';
import { ChooseProps } from './component.types';
import { when } from '../../tools/when';
import When, { WhenProps } from '../when';

const Choose = ({ children }: ChooseProps) => {
  let iterative = true;
  const results: WhenProps['children'][] = [];

  Children.forEach(children, child => {
    if (iterative && React.isValidElement(child) && child.type === When) {
      if (when(child.props.condition as unknown as boolean, child.props.unless as unknown as boolean)) {
        results.push(child);
        iterative = false;
      }
    }
  });

  if (results.length) {
    return <>{results}</>;
  }
  return <>{null}</>;
};

export default Choose;
