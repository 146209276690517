import { MenuItem, Box, styled, Select, Stack } from '@mui/material';
import { CV_STATUSES_OPTIONS } from '../../../constants/server-constants';
import { StatusSelectProps } from './component.types';

const StatusBox = styled(Box)<{ status: number }>(({ theme, status }) => {
  const BGs: Record<number, string> = {
    1: theme.palette.yellow.main,
    2: theme.palette.green.main,
    3: theme.palette.red.main,
    4: theme.palette.midGray.main,
  };
  return {
    width: '10px',
    height: '10px',
    marginRight: '8px',
    borderRadius: '2px',
    background: BGs[status],
  };
});

const CancelOption = { value: 4, label: 'Cancelled' };
const StatusSelect: React.FC<StatusSelectProps> = ({
  value,
  onChange = () => {},
  readonly,
  hasCancel,
  showCancel = true,
}) => {
  return (
    <Select
      readOnly={readonly}
      IconComponent={readonly ? () => null : undefined}
      value={value}
      onChange={onChange}
      variant="standard"
      sx={{
        '&::before,&::after': {
          display: 'none',
        },
      }}
      MenuProps={{
        sx: {
          '* li:first-child': {
            display: 'none',
          },
        },
      }}
    >
      {CV_STATUSES_OPTIONS.slice(0, hasCancel ? 1 : CV_STATUSES_OPTIONS.length).map((option, idx) => (
        <MenuItem key={option.value} value={option.value} disabled={idx === 0}>
          <Stack direction="row" alignItems="center">
            <StatusBox status={option.value} data-testid="status-box" />
            {option.label}
          </Stack>
        </MenuItem>
      ))}
      {showCancel && (
        <MenuItem key={CancelOption.value} value={CancelOption.value}>
          <Stack direction="row" alignItems="center">
            <StatusBox status={CancelOption.value} data-testid="status-box" />
            {CancelOption.label}
          </Stack>
        </MenuItem>
      )}
    </Select>
  );
};
export default StatusSelect;
