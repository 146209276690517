import React, { memo, useCallback, useRef } from 'react';
import { InputSkillsProps, SkillsRequest } from './component.types';
import { SkillsCollection, ApiSkillService } from '../../../../api/main';
import { ApiErrorType } from '../../../hooks/use-fetch/component.types';
import useFetch from '../../../hooks/use-fetch';
import Autocomplete from '../autocomplete';
import { useWatch } from 'react-hook-form';
import { StyledChip } from '../../cv/styled';
import { Skills } from '../../cv/skills-form';
import { SKILLTYPE } from '../../../../constants/common';

const latestOptions = {
  [SKILLTYPE.SOFT]: [] as Skills[],
  [SKILLTYPE.HARD]: [] as Skills[],
};
const InputSkills = ({
  name: inputName,
  control,
  label,
  placeholder,
  required = false,
  disabled = false,
  defaultValue = undefined,
  skillType,
}: InputSkillsProps) => {
  const skillValues = useWatch({
    name: inputName,
    control,
  }) as Skills[];

  const optionsRef = useRef<Skills[]>(skillValues);
  const locationRequest = ({ page, pageSize, name, selectedType }: SkillsRequest) =>
    ApiSkillService.apiSkillIndex(page, pageSize, selectedType as number, name);
  const locationApi = useFetch<SkillsRequest, SkillsCollection>(locationRequest);

  const onSearch = useCallback(
    (searchValue: string) => {
      if (searchValue) {
        locationApi
          .call({ name: searchValue, page: 1, pageSize: 50, selectedType: skillType })
          .then(result => {
            if (result?.data) {
              optionsRef.current = result.data as Skills[];
              latestOptions[skillType] = result.data as Skills[];
            }
            return Promise.resolve(result);
          })
          .catch((e: ApiErrorType) => {
            optionsRef.current = [];
            return Promise.reject(e);
          });
      }
    },
    [locationApi, skillType]
  );

  return (
    <Autocomplete
      multiple
      name={inputName}
      label={label}
      control={control}
      required={required}
      disabled={disabled}
      options={optionsRef.current}
      loading={locationApi.loading}
      defaultValue={defaultValue ? defaultValue : undefined}
      placeholder={placeholder ?? undefined}
      onSearch={onSearch}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => <StyledChip label={option.name} {...getTagProps({ index })} />)
      }
    />
  );
};

export default memo(InputSkills);
