import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { PickersDay } from '@mui/x-date-pickers';
import { format, isSameDay, isWeekend, startOfYear, endOfYear, startOfMonth, endOfMonth } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import {
  AggregatedEmployeeActivityCollection,
  ApiEmployeeActivityService,
  ApiHolidayService,
  HolidayResource,
  HolidaysCollection,
} from '../../../../api/main';
import { EmployeeWorkingHoursIndexRequest } from '../../../../components/admin/employee-overview/component.types';
import appColor from '../../../../styles/themes/app-colors';
import useErrorHandler from '../../../hooks/use-error-handler';
import useFetch from '../../../hooks/use-fetch';
import { useUser } from '../../../hooks/use-user';
import HolidayListModal from '../../vacations/holiday-list-modal';
import CalendarPickerBox from '../calendar-picker-box';
import { HolidayItems } from './component.types';

const VacationsColors = {
  holiday: appColor.yellow,
  vacation: '#55BD59',
  'day-off': '#55BD59',
  'sick-leave': appColor.red,
};
type VacationsColorName = keyof typeof VacationsColors;

const HolidayLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  marginBottom: '15px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '-2px',
    right: '-2px',
    height: '3px',
    backgroundColor: theme.palette.yellow.main,
  },
}));

const DayPickerWrapper = styled(Box)<{ active: boolean; vacationName: VacationsColorName }>(
  ({ active, vacationName }) => ({
    position: 'relative',
    '&::after': {
      content: active ? '""' : 'none',
      position: 'absolute',
      bottom: '0',
      left: '10px',
      right: '10px',
      borderBottom: `4px dotted ${VacationsColors[vacationName]}`,
    },
  })
);
const DayTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<{ vacationName: VacationsColorName }>(({ vacationName }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: VacationsColors[vacationName],
    color: 'black',
  },
}));
const UserCalendar = () => {
  const theme = useTheme();
  const handleError = useErrorHandler();
  const { clientProfile } = useUser();
  const [calendarKey, setCalendarKey] = useState(Math.random());
  const [date, setDate] = useState<Date | null>(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const getEmployeeWorkingHours = useFetch<EmployeeWorkingHoursIndexRequest, AggregatedEmployeeActivityCollection>(
    ({ from, to, userId }) => ApiEmployeeActivityService.apiEmployeeActivityAggregated(Number(userId), from, to)
  );

  const getHolidays = useFetch<{ location_id?: number }, HolidaysCollection>(({ location_id }) => {
    const now = new Date();
    const from = startOfYear(now);
    const to = endOfYear(now);
    return ApiHolidayService.apiHolidayIndex(
      1,
      200,
      location_id,
      undefined,
      format(from, 'yyyy-MM-dd'),
      format(to, 'yyyy-MM-dd')
    );
  });

  const holidayItems = useMemo(() => {
    const items = (getHolidays.result as HolidaysCollection)?.data || (getHolidays.result as HolidayResource[]);
    return getHolidays.result
      ? (items || [])
          ?.sort((a, b) => new Date(a.date || new Date()).valueOf() - new Date(b.date || new Date()).valueOf())
          .reduce((acc, val) => {
            const itemDate = new Date(val.date || new Date());
            const month = format(itemDate, 'LLLL');
            const day = format(itemDate, 'd');
            const item = { value: day, title: String(val.name) };
            acc[month] = acc[month] ? [...acc[month], item] : [item];
            return acc;
          }, {} as HolidayItems)
      : {};
  }, [getHolidays.result]);

  useEffect(() => {
    getHolidays.call({ location_id: clientProfile?.location?.id }).catch(handleError);
  }, [clientProfile?.location?.id]);

  useEffect(() => {
    if (clientProfile?.id && date) {
      const from = startOfMonth(date);
      const to = endOfMonth(date);
      getEmployeeWorkingHours
        .call({ userId: Number(clientProfile?.id), from: format(from, 'Y-MM-dd'), to: format(to, 'Y-MM-dd') })
        .catch(handleError);
    }
  }, [clientProfile?.location?.id, date]);

  const holidays = useMemo(() => {
    const items = (getHolidays.result as HolidaysCollection)?.data || (getHolidays.result as HolidayResource[]);
    return getHolidays.result
      ? items?.map(item => ({
          date: new Date(item.date || new Date()),
          name: item.name,
        }))
      : [];
  }, [getHolidays.result]);

  const activites = useMemo(() => {
    const items = (getEmployeeWorkingHours.result as AggregatedEmployeeActivityCollection)?.data;
    return items?.filter(item => item.type === 'day-off' || item.type === 'vacation' || item.type === 'sick-leave');
  }, [getEmployeeWorkingHours.result]);

  return (
    <Stack justifyContent="space-between" alignItems="flex-start" sx={{ height: '100%' }}>
      <CalendarPickerBox
        loading={getEmployeeWorkingHours.loading}
        key={calendarKey}
        views={['day', 'month']}
        date={date}
        maxDate={endOfYear(new Date())}
        minDate={startOfYear(new Date())}
        onMonthChange={value => setDate(value)}
        onChange={value => {
          setDate(value);
          setCalendarKey(Math.random());
        }}
        renderDay={(day, _, props) => {
          const holidaysList = holidays?.filter(d => isSameDay(day, d.date));
          const vacation = activites?.find(d => isSameDay(day, new Date(d.date || 0)));
          const vacationName = holidaysList.length ? 'holiday' : (vacation?.type as VacationsColorName);
          const styles = { color: isWeekend(new Date(day)) ? theme.palette.pink.main : undefined };
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const vacationDescription = (vacation as any)?.description;

          return (
            <DayPickerWrapper active={Boolean(holidaysList.length) || Boolean(vacation)} vacationName={vacationName}>
              {Boolean(holidaysList.length || vacation) ? (
                <DayTooltip
                  title={
                    vacationDescription
                      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        `${vacationDescription} vacation`
                      : holidaysList.map(h => h.name).join(', ') || vacation?.type || ''
                  }
                  vacationName={vacationName}
                >
                  <PickersDay {...props} style={styles} />
                </DayTooltip>
              ) : (
                <PickersDay {...props} style={styles} />
              )}
            </DayPickerWrapper>
          );
        }}
      />
      <HolidayLink onClick={() => holidays.length && setIsOpen(true)}>Holidays</HolidayLink>
      <HolidayListModal
        title={`${clientProfile?.location?.name} Holidays`}
        data={holidayItems || {}}
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};
export default UserCalendar;
