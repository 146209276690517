/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChangeCVStatusRequest = {
    /**
     * 1 for Pendinf, 2 for Approved and 3 for rejected
     */
    status: ChangeCVStatusRequest.status;
    comment: string;
};

export namespace ChangeCVStatusRequest {

    /**
     * 1 for Pendinf, 2 for Approved and 3 for rejected
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}

