/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SkillRequest = {
    name: string;
    /**
     * 1 for Soft and 2 for Hard
     */
    type: SkillRequest.type;
};

export namespace SkillRequest {

    /**
     * 1 for Soft and 2 for Hard
     */
    export enum type {
        '_1' = 1,
        '_2' = 2,
    }


}

