/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetEndTimeRequest } from '../models/SetEndTimeRequest';
import type { SetStartTimeRequest } from '../models/SetStartTimeRequest';
import type { WorkingHourRequest } from '../models/WorkingHourRequest';
import type { WorkingHourResource } from '../models/WorkingHourResource';
import type { WorkingHoursCollection } from '../models/WorkingHoursCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiWorkingHourService {

    /**
     * Working Hours List
     * Paginated list of all working hours.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param userId
     * @param locationId
     * @param projectId
     * @param from
     * @param to
     * @returns WorkingHoursCollection Working hour collection response
     * @throws ApiError
     */
    public static apiWorkingHourIndex(
        page?: number,
        pageSize?: number,
        userId?: number,
        locationId?: number,
        projectId?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<WorkingHoursCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/working-hours',
            query: {
                'page': page,
                'pageSize': pageSize,
                'user_id': userId,
                'location_id': locationId,
                'project_id': projectId,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Working Hour
     * This endpoint take working hour data and create it.
     * @param requestBody Create working hour request
     * @returns WorkingHourResource Start/end/create/update working hour response
     * @throws ApiError
     */
    public static apiWorkingHourCreate(
        requestBody: WorkingHourRequest,
    ): CancelablePromise<WorkingHourResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/working-hours',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Working Hour
     * This endpoint take working hour identifier and delete it.
     * @param workingHourId Working hour identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiWorkingHourDelete(
        workingHourId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/working-hours/{working_hour_id}',
            path: {
                'working_hour_id': workingHourId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Update Working Hour
     * This endpoint take working hour data and update it.
     * @param workingHourId Working hour identifier.
     * @param requestBody Create working hour request
     * @returns void
     * @throws ApiError
     */
    public static apiWorkingHourUpodate(
        workingHourId: number,
        requestBody: WorkingHourRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/working-hours/{working_hour_id}',
            path: {
                'working_hour_id': workingHourId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Export Working Hours
     * Export working hours.
     * @param userId
     * @param locationId
     * @param projectId
     * @param from
     * @param to
     * @returns void
     * @throws ApiError
     */
    public static apiWorkingHourExport(
        userId?: number,
        locationId?: number,
        projectId?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/working-hours/export',
            query: {
                'user_id': userId,
                'location_id': locationId,
                'project_id': projectId,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Open Working Hours
     * This endpoint returns open working hour for the current user at current time
     * @returns WorkingHourResource Start/end/create/update working hour response
     * @throws ApiError
     */
    public static apiWorkingHourOpen(): CancelablePromise<WorkingHourResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/working-hours/open',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Get Working Hours Statistics
     * This endpoint take user identifier and return statistics about his working hours.
     * @returns any Return total working hours
     * @throws ApiError
     */
    public static apiWorkingHourStatistics(): CancelablePromise<{
        today_working_day_hours?: number;
        today_total_working_hours?: number;
        week_working_day_hours?: number;
        week_total_working_hours?: number;
        month_working_day_hours?: number;
        month_total_working_hours?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/working-hours/statistics',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Get Total Working Hours
     *
     * * This endpoint take user identifier, start and end date and thern return total working hours of employee.
     * @param userId
     * @param startDate
     * @param endDate
     * @returns any Return total working hours
     * @throws ApiError
     */
    public static apiWorkingHourTotal(
        userId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<{
        total_working_hours?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/working-hours/total',
            query: {
                'user_id': userId,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * End Working Hour
     * This endpoint take working hour identifier and end it.
     * @param employeeActivity employee activity identifier.
     * @param requestBody Set end time request
     * @returns WorkingHourResource Start/end/create/update working hour response
     * @throws ApiError
     */
    public static apiWorkingHourEnd(
        employeeActivity: number,
        requestBody: SetEndTimeRequest,
    ): CancelablePromise<WorkingHourResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/working-hours/{employee_activity}/end',
            path: {
                'employee_activity': employeeActivity,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Start Working Hour
     * This endpoint take start time and create working hour.
     * @param requestBody Set start time request
     * @returns WorkingHourResource Start/end/create/update working hour response
     * @throws ApiError
     */
    public static apiWorkingHourStart(
        requestBody: SetStartTimeRequest,
    ): CancelablePromise<WorkingHourResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/working-hours/start',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
