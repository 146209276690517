import { Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiWorkingHourService, SetEndTimeRequest, WorkingHourResource } from '../../../../api/main';
import { CardHeading, CardItemValueText } from '../../../../components/admin/employee-overview/styled';
import { TimerInfo } from '../../../../components/client/dashboard/component';
import { useCollidingBox } from '../../../hooks/use-colliding-box';
import useFetch from '../../../hooks/use-fetch';
import { formatCountDown, makeSameDate } from '../../../tools/date';
import Button from '../../button';
import FinishWorkHourModal from '../finish-work-hour-modal';
import { FinishWorkHourForm } from '../finish-work-hour-modal/component.types';
import StartWorkHourModal from '../start-work-hour-modal';
import { StartWorkHourForm } from '../start-work-hour-modal/component.types';

const PunchInBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '10px',
  padding: '10px',
  minHeight: '65px',
  '> *': {
    margin: '0',
  },
}));
export const CircularProgressWrapper = styled(Box)<{ color?: string }>(({ theme, color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '110px',
  height: '110px',
  margin: '30px auto',
  color: color || theme.palette.gray.main,
  '> *': {
    position: 'absolute',
    '&:last-child': {
      color: theme.palette.text1.main,
      textAlign: 'center',
    },
  },
}));

const TimeSheet: React.FC<{ handleChange: (value: number) => void; onTimerUpdate: (timerInfo: TimerInfo) => void }> = ({
  handleChange,
  onTimerUpdate,
}) => {
  const { t } = useTranslation();
  const { clearError, collidingInfo, checkForCollding } = useCollidingBox();
  const activityId = useRef<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState(new Date());
  const setStartWorkingHour = useFetch(ApiWorkingHourService.apiWorkingHourStart);
  const setEndWorkingHour = useFetch<SetEndTimeRequest, WorkingHourResource>(payload =>
    ApiWorkingHourService.apiWorkingHourEnd(Number(activityId.current), payload)
  );
  const getStartTime = useFetch(ApiWorkingHourService.apiWorkingHourOpen);

  const actualValue = useMemo(() => {
    return startTime instanceof Date ? differenceInMinutes(elapsedTime, startTime) / 60 : 0;
  }, [elapsedTime, startTime]);

  useEffect(() => {
    if (startTime) onTimerUpdate({ currentHours: actualValue, startTime: startTime });
  }, [actualValue, startTime]);

  useEffect(() => {
    getStartTime
      .call()
      .then(data => {
        if (data.id && data.start_time) {
          activityId.current = data.id;
          const start = new Date(data.start_time);
          setStartTime(start);
          setElapsedTime(makeSameDate({ start: start.toString(), end: new Date().toString() }));
        }
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (startTime)
      intervalId = setInterval(() => {
        setElapsedTime(() => makeSameDate({ start: startTime.toString(), end: new Date().toString() }));
      }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [startTime]);

  const handleStartEnd = () => {
    setIsOpen(true);
  };

  const handleStart = ({ start_time }: StartWorkHourForm) => {
    const startDate = new Date(start_time);
    clearError();
    return setStartWorkingHour
      .call({ start_time: format(startDate, 'yyyy-MM-dd HH:mm') })
      .then(data => {
        activityId.current = Number(data.id);
        setStartTime(startDate);
        setElapsedTime(new Date());
        setIsOpen(false);
      })
      .catch(checkForCollding);
  };
  const handleFinish = (values: FinishWorkHourForm) => {
    const { finish_time: finishTime, projects } = values;
    clearError();
    if (startTime) {
      const finishDate = makeSameDate({ start: startTime.toString(), end: finishTime });
      return setEndWorkingHour
        .call({
          end_time: format(finishDate, 'Y-MM-dd HH:mm'),
          projects: projects.map(p => ({
            id: Number(p.project.id),
            hours: format(p.hours, 'HH:mm'),
          })),
        })
        .then(() => {
          setStartTime(null);
          setElapsedTime(finishDate);
          handleChange(Math.random());
          setIsOpen(false);
          onTimerUpdate({ currentHours: 0, startTime: new Date() });
        })
        .catch(checkForCollding);
    }
  };

  return (
    <>
      <FinishWorkHourModal
        key={'Finish' + String(isOpen && Boolean(startTime))}
        initialData={{
          finish_time: makeSameDate({ start: startTime?.toString(), end: new Date().toString() }).toString(),
          start_time: startTime?.toString() || '',
        }}
        onSubmit={handleFinish}
        onCancel={() => {
          setIsOpen(false);
          clearError();
        }}
        open={isOpen && Boolean(startTime)}
        isLoading={setEndWorkingHour.loading}
        collidingInfo={collidingInfo}
      />
      <StartWorkHourModal
        key={'Start' + String(isOpen && Boolean(!startTime))}
        initialData={{
          start_time: startTime?.toString() || new Date().toString(),
        }}
        onSubmit={handleStart}
        onCancel={() => {
          setIsOpen(false);
          clearError();
        }}
        open={isOpen && Boolean(!startTime)}
        isLoading={setStartWorkingHour.loading}
        collidingInfo={collidingInfo}
      />
      <CardHeading variant="h4">{t('common:timesheet')}</CardHeading>
      <PunchInBox>
        <Typography variant="subtitle2" component="span">
          {t('common:started-at')}
        </Typography>
        <CardItemValueText sx={{ marginLeft: '0' }}>
          {startTime ? format(startTime, 'EEE, do LLL Y HH:mm aa') : format(new Date(), 'EEE, do LLL Y')}
        </CardItemValueText>
      </PunchInBox>

      <Stack justifyContent="center" alignContent="center">
        <CircularProgressWrapper>
          <CircularProgress
            value={100}
            variant="determinate"
            size={110}
            color={actualValue >= 16 ? 'error' : actualValue >= 8 ? 'primary' : 'inherit'}
          />
          <CircularProgress
            value={((actualValue % 8) / 8) * 100}
            variant="determinate"
            size={110}
            color={actualValue >= 8 ? 'error' : 'primary'}
          />
          <Typography variant="h2" component="span" color="primary" sx={{ opacity: startTime ? 1 : 0.5 }}>
            {startTime ? formatCountDown({ start: startTime, end: elapsedTime }) : '00:00:00'}
          </Typography>
        </CircularProgressWrapper>
        <Button onClick={handleStartEnd} sx={{ margin: 'auto' }}>
          {startTime ? t('common:finish') : t('common:start')}
        </Button>
      </Stack>
    </>
  );
};

export default TimeSheet;
