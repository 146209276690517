import { Add, Remove } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { debounce } from '../../tools/debounce';
import FormInputLabel from '../forms/form-input-label';
import { TimePicker } from '@mui/x-date-pickers';
import { addHours, addMinutes, format, isAfter, startOfDay, max } from 'date-fns';
import { Box, IconButton, Paper, Stack, styled, TextField, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import { InputTimePickerProps, NullishString } from '../time-picker/component.types';
import useLongPress from '../../hooks/use-long-press';

const CHANGE_SPEED = 20;

const TimeChooserBox = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '100%',
  height: '110px',
  flexShrink: 0,

  display: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.shadows[2],
  zIndex: 5,
  '> *': {
    border: `1px solid ${theme.palette.fieldsBorder.main}`,
    margin: '0 2px',
  },
}));

type PlusMinusInfo = { type: string; amount: number };

const HoursPicker: React.FC<InputTimePickerProps> = ({
  defaultValue = null,
  label = '',
  disabled = false,
  fullWidth = false,
  required = false,
  control,
  name,
  maxTime,
  minTime,
  readonly,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const { startLongPress: startIncrementing, ...plusEventHandlers } = useLongPress(100);
  const { startLongPress: startDecrementing, ...minusEventHandlers } = useLongPress(100);
  const intervalId = useRef<ReturnType<typeof setInterval>>();
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue ?? '',
    name: name,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onChange, ref, value: fieldValue, onBlur, ...inputProps } = field;
  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  const [pickerValue, setPickerValue] = useState<NullishString>(fieldValue as string);

  const handleOnchange = debounce((newValue: NullishString) => {
    if (Number.isNaN(newValue?.valueOf())) {
      setPickerValue(defaultValue);
      onChange(defaultValue);
    } else {
      setPickerValue(newValue);
      onChange(newValue);
    }
  });

  const handlePlusMinus = ({ type, amount }: PlusMinusInfo) => {
    if (pickerValue && maxTime) {
      const baseDate = startOfDay(new Date());
      const dateValue = new Date(pickerValue);
      let newValue = type === 'minutes' ? addMinutes(dateValue, amount) : addHours(dateValue, amount);
      if (isAfter(new Date(newValue), maxTime)) {
        newValue = maxTime;
      }
      newValue = max([newValue, baseDate]);
      setPickerValue(() => newValue.toString());
      onChange(newValue);
    }
  };

  const handleDisable = useCallback(() => {
    if (disabled && pickerValue) {
      setPickerValue(null);
      onChange(null);
    }
  }, [disabled, onChange, pickerValue]);

  useEffect(() => {
    handleDisable();
  }, [disabled, handleDisable]);

  useEffect(() => {
    if (maxTime && pickerValue && isAfter(new Date(pickerValue), maxTime)) {
      setPickerValue(maxTime.toString());
    }
  }, [pickerValue]);

  useEffect(() => {
    if (startIncrementing) {
      intervalId.current = setInterval(() => {
        handlePlusMinus({ type: 'minutes', amount: 1 });
      }, CHANGE_SPEED);
    }
    return () => clearInterval(intervalId.current as unknown as number);
  }, [startIncrementing, pickerValue]);

  useEffect(() => {
    if (startDecrementing) {
      intervalId.current = setInterval(() => {
        handlePlusMinus({ type: 'minutes', amount: -1 });
      }, CHANGE_SPEED);
    }
    return () => clearInterval(intervalId.current as unknown as number);
  }, [startDecrementing, pickerValue]);

  return (
    <Box onMouseLeave={() => setOpen(false)}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          readOnly={readonly}
          onOpen={() => !disabled && !readonly && setOpen(true)}
          inputRef={ref}
          value={pickerValue}
          maxTime={maxTime}
          minTime={minTime}
          views={['hours', 'minutes']}
          ampm={false}
          disabled={disabled}
          toolbarTitle={`${htmlFor}-picker`}
          onChange={handleOnchange}
          inputFormat="HH:mm"
          onClose={() => {
            setOpen(false);
          }}
          renderInput={params => {
            const Input = (
              <TextField
                {...params}
                onBlur={() => {
                  if (open) return;
                  return onBlur();
                }}
                data-testid={htmlFor}
                name={name}
                onClick={() => (onClick ? onClick() : !disabled && !readonly && setOpen(true))}
                aria-label={htmlFor}
                error={invalid}
                required={required}
                helperText={error?.message ? 'Hours is invalid' : ''}
                fullWidth={fullWidth}
                type="date"
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <TimeChooserBox
                      sx={{
                        display: open ? 'flex' : 'none',
                      }}
                    >
                      <Stack>
                        <IconButton
                          onClick={() => {
                            handlePlusMinus({ type: 'hours', amount: 1 });
                          }}
                        >
                          <Add />
                        </IconButton>
                        <Typography variant="body1" textAlign="center">
                          {pickerValue && format(new Date(pickerValue), 'HH')}
                        </Typography>

                        <IconButton
                          onClick={() => {
                            handlePlusMinus({ type: 'hours', amount: -1 });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </Stack>
                      :
                      <Stack>
                        <IconButton
                          onClick={() => {
                            if (!startIncrementing) handlePlusMinus({ type: 'minutes', amount: 1 });
                          }}
                          {...plusEventHandlers}
                        >
                          <Add />
                        </IconButton>
                        <Typography variant="body1" textAlign="center">
                          {pickerValue && format(new Date(pickerValue), 'mm')}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            if (!startDecrementing) handlePlusMinus({ type: 'minutes', amount: -1 });
                          }}
                          {...minusEventHandlers}
                        >
                          <Remove />
                        </IconButton>
                      </Stack>
                    </TimeChooserBox>
                  ),
                }}
              />
            );
            return label ? (
              <FormInputLabel htmlFor={htmlFor} required={required} disabled={!!disabled} label={label}>
                {Input}
              </FormInputLabel>
            ) : (
              Input
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default HoursPicker;
