import React, { useRef } from 'react';
import { ArrowDropDown, CalendarMonth, Clear } from '@mui/icons-material';
import GlobalStyles from '@mui/material/GlobalStyles';
import Litepicker from 'litepicker';
import { styled, Box, useTheme, MenuItem, Stack } from '@mui/material';
import When from '../when';
import { DateArgType, DateRangePickerProps } from './component.types';

const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.fieldsBorder.main}`,
  padding: '0px 15px',
  '& > svg': {
    flexShrink: 0,
  },
}));

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  onClear = () => {},
  startDate,
  endDate,
  children,
  label,
}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isMounted = useRef(false);
  const pickerRef = useRef<Litepicker | null>(null);

  const handleClick = () => {
    pickerRef.current?.show();
  };

  React.useEffect(() => {
    if (ref.current && !isMounted.current) {
      isMounted.current = false;
      pickerRef.current = new Litepicker({
        element: ref.current,
        singleMode: false,
        zIndex: 4,
        startDate: Date.now(),
        endDate,
      });
    }
    pickerRef.current?.on(
      'selected',
      ({ dateInstance: d1 }: { dateInstance: DateArgType }, { dateInstance: d2 }: { dateInstance: DateArgType }) => {
        onChange(d1, d2);
      }
    );
    isMounted.current = true;
  }, [startDate, endDate, onChange]);

  return (
    <DateRangeBox ref={ref}>
      <GlobalStyles
        styles={{
          ':root': {
            '--litepicker-is-today-color': theme.palette.warning.main,
            '--litepicker-is-start-color-bg': theme.palette.primary.main,
            '--litepicker-is-end-color-bg': theme.palette.primary.main,
            '--litepicker-is-in-range-color': theme.palette.primary.ultraLight,
            '--litepicker-container-months-color-bg': theme.palette.appBackground.main,
            '--litepicker-day-width': '42px',
            '--litepicker-container-months-box-shadow-color': 'none',
            '--litepicker-month-header-color': theme.palette.text1.main,
            '--litepicker-month-weekday-color': theme.palette.text1.light,
            '--litepicker-day-color': theme.palette.text1.main,
          },
          '.litepicker': {
            marginTop: '5px',
            fontSize: '14px',
          },
        }}
      />
      <CalendarMonth onClick={handleClick} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <MenuItem onClick={handleClick} sx={{ flex: '1' }}>
          {children ? children : label}
        </MenuItem>
        <When condition={Boolean(children)}>
          <Clear
            onClick={() => {
              onClear();
              pickerRef.current?.clearSelection();
            }}
          />
        </When>
        <When condition={!Boolean(children)}>
          <ArrowDropDown onClick={handleClick} />
        </When>
      </Stack>
    </DateRangeBox>
  );
};

export default DateRangePicker;
