/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmployeeActivityStatusRequest } from '../models/ChangeEmployeeActivityStatusRequest';
import type { DayOffRequest } from '../models/DayOffRequest';
import type { EmployeeActivitiesCollection } from '../models/EmployeeActivitiesCollection';
import type { EmployeeActivityResource } from '../models/EmployeeActivityResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiDayOffService {

    /**
     * Change Day Off Status
     * This endpoint take day off identifier and change its status.
     * @param dayOffId Day off identifier.
     * @param requestBody change employee activity status request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiDayOffChangeStatus(
        dayOffId: number,
        requestBody: ChangeEmployeeActivityStatusRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/day-offs/{day_off_id}/change-status',
            path: {
                'day_off_id': dayOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Day Offs List
     * Paginated list of all dayOffs.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param userId
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @param order Can be ASC or DESC
     * @param orderBy
     * @returns EmployeeActivitiesCollection employee activity collection response
     * @throws ApiError
     */
    public static apiDayOffIndex(
        page?: number,
        pageSize?: number,
        userId?: number,
        status?: number,
        from?: string,
        to?: string,
        order?: string,
        orderBy?: string,
    ): CancelablePromise<EmployeeActivitiesCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/day-offs',
            query: {
                'page': page,
                'pageSize': pageSize,
                'user_id': userId,
                'status': status,
                'from': from,
                'to': to,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Day Off
     * This endpoint take day off data and create it.
     * @param requestBody Api create day off request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiDayOffCreate(
        requestBody: DayOffRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/day-offs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Resets day off capacity of all employees to whole-year day off amount
     * @returns any success.
     * @throws ApiError
     */
    public static apiDayOffResetCapacity(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/day-offs/reset-yearly-capacity',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
    }

}
