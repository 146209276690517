import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  UserResource,
  ApiEmployeeService,
  UserCollection,
  ProjectCollection,
  UserProjectLogCollection,
} from '../../../../api/main';
import CustomPagination from '../../table-pagination';
import { useTranslation } from 'react-i18next';
import useErrorHandler from '../../../hooks/use-error-handler';
import { EmployeesRequest, TableFilters } from './component.types';
import useFetch from '../../../hooks/use-fetch';
import useTable from '../../../hooks/use-table';
import { defaultColDef } from '../../../../constants/report';
import { CircularProgress, Divider, IconButton, Stack } from '@mui/material';
import { appRoutes } from '../../../../providers/routes';
import { Link } from 'react-router-dom';
import { Edit, WatchLater } from '@mui/icons-material';
import { ReactComponent as ProjectsIcon } from '../../../../assets/icons/projects.svg';
import EmployeeProjectsModal from '../../employee/employee-projects-modal/component';

const EmployeesGrid: React.FC<{ filters: TableFilters }> = ({ filters }) => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const [selectedEmployee, setSelectedEmployee] = useState<UserResource | undefined>(undefined);

  const { data, page, setPage, setData, pageSize, setFilters } = useTable<UserCollection, TableFilters>({
    initialData: { meta: { total: 0 } },
    initialFilters: {},
    initialPage: 0,
    isInQuery: true,
  });
  const getEmployees = useFetch<EmployeesRequest, UserCollection>(({ page: newPage, status, query }) =>
    ApiEmployeeService.apiEmployeeIndex(query, status, newPage, pageSize)
  );
  const fetchData = useRef(getEmployees.call);
  const fetchAssignedProjeccts = useFetch(ApiEmployeeService.apiEmployeeAssingedProjects);
  const fetchLagacyProjects = useFetch(ApiEmployeeService.apiEmployeeProjectHistories);

  const fetchEmployeeProjects = async (id: number) =>
    Promise.all([fetchAssignedProjeccts.call(Number(id)), fetchLagacyProjects.call(Number(id))]);

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  useEffect(() => {
    const handleSuccess = (result: UserCollection) => setData(result);
    fetchData
      .current({
        ...filters,
        query: filters.query || undefined,
        status: filters.status?.toLowerCase() || undefined,
        page: page + 1,
        pageSize,
      })
      .then(handleSuccess)
      .catch(handleError);
  }, [fetchData, filters, handleError, setData, page, pageSize]);

  const Columns: GridColDef<UserResource>[] = [
    {
      ...defaultColDef,
      field: 'fullName',
      headerName: t('table:common.full-name'),
      flex: 1,
      valueGetter({ row }) {
        return `${row.first_name} ${row.last_name}`;
      },
      renderCell({ value, row }) {
        const employeesSingleOverview = appRoutes.employeesSingleOverview.replace(/:userId/, String(row.id));
        return (
          <Link to={employeesSingleOverview} style={{ cursor: 'pointer' }}>
            {value}
          </Link>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'email',
      headerName: t('table:common.email-address'),
      flex: 1,
      valueGetter({ row }) {
        return row.email;
      },
    },
    {
      ...defaultColDef,
      field: 'location',
      headerName: t('table:common.location'),
      valueGetter({ row }) {
        return row.location?.name;
      },
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        const employeeProfileRoute = appRoutes.employeesSingle.replace(/:userId/, String(row.id));
        const employeesSingleOverview = appRoutes.employeesSingleOverview.replace(/:userId/, String(row.id));
        return (
          <Stack direction="row">
            <Link to={employeeProfileRoute}>
              <IconButton>
                <Edit color="info" />
              </IconButton>
            </Link>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <Link to={`${employeesSingleOverview}?add=true`}>
              <IconButton>
                <WatchLater color="info" />
              </IconButton>
            </Link>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <IconButton
              color="info"
              onClick={() => {
                if (row.id) {
                  setSelectedEmployee(row);
                  void fetchEmployeeProjects(row.id);
                }
              }}
            >
              {(fetchAssignedProjeccts.loading || fetchLagacyProjects.loading) && selectedEmployee?.id === row.id ? (
                <CircularProgress color="primary" size={20} sx={{ marginTop: '-5px', marginLeft: '3px' }} />
              ) : (
                <ProjectsIcon />
              )}
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <EmployeeProjectsModal
        data={
          fetchAssignedProjeccts.result && fetchLagacyProjects.result
            ? [
                fetchAssignedProjeccts.result as ProjectCollection['data'],
                fetchLagacyProjects.result as UserProjectLogCollection['data'],
              ]
            : undefined
        }
        open={Boolean(selectedEmployee && fetchAssignedProjeccts.loaded && fetchLagacyProjects.loaded)}
        onClose={() => setSelectedEmployee(undefined)}
      />
      <DataGrid
        loading={getEmployees.loading}
        page={page}
        onPageChange={setPage}
        columns={Columns}
        rows={data.data || []}
        paginationMode="server"
        pageSize={data.meta?.per_page}
        rowCount={data.meta?.total}
        components={{ Pagination: CustomPagination }}
        disableSelectionOnClick
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        columnBuffer={5}
      />
    </>
  );
};

export default EmployeesGrid;
