import React from 'react';
import { Paper, styled, Theme } from '@mui/material';

import lightLogo from '../../../../assets/logos/light-logo.svg';
import { ReactComponent as ArrowLeftLight } from '../../../../assets/icons/arrow-left-linear-light.svg';
import Button from '../../button';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../../../providers/routes';
import ToggleThemeSwitch from '../toggle-theme-switch';
import { PageHeaderProps } from './component.types';

const Container = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 82,
  padding: '30px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.sideNav.main,
}));

const PageHeader = ({ onBack, backTitle = '' }: PageHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button
        sx={(theme: Theme) => ({
          color: theme.palette.white.main,
          padding: 0,
        })}
        href={appRoutes.cvList}
        variant="text"
        onClick={onBack}
        startIcon={<ArrowLeftLight />}
      >
        {backTitle || t('common:go-back')}
      </Button>
      {process.env.NODE_ENV === 'development' ? <ToggleThemeSwitch /> : null}
      <img src={lightLogo} alt="devolon-logo" />
    </Container>
  );
};

export default PageHeader;
