import React, { useState } from 'react';
import { MenuItemProps, LinkProps } from './component.types';
import {
  AccordionProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  styled,
  AccordionSummaryProps,
  Typography,
  Box,
  AccordionDetails,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    minWidth: '160px',
    background: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)({
  backgroundColor: 'transparent',
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '-0.5rem',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  a: {
    paddingLeft: '0',
  },
});

export const Container = styled(Link, {
  shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isOpen',
})<LinkProps>(({ isActive, isOpen, theme }) => ({
  width: '100%',
  height: 46,
  padding: '14px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: isOpen ? '30px' : '10px',
  boxSizing: 'border-box',
  margin: '3px 0',
  color: theme.palette.white.main,
  backgroundColor: 'transparent',
  overflow: 'hidden',
  ...(isActive && {
    backgroundColor: theme.palette.lavender.main,
  }),
  transition: theme.transitions.create(['background-color'], {
    duration: 500,
  }),
  cursor: 'pointer',
}));

const MenuItem = ({ item, isActive = false, isOpen = false }: MenuItemProps) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(
    Boolean(item.children && item.children.some(ch => ch.href === location.pathname))
  );
  return item.children && isOpen ? (
    <Accordion
      key={`${item.href}-${item.label}`}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={isOpen ? <ArrowRight sx={{ fontSize: '1.5rem', color: '#FFF' }} /> : undefined}
      >
        <MenuItem item={{ label: item.label, icon: item.icon, href: item.href }} isOpen={isOpen} />
      </AccordionSummary>
      <AccordionDetails>
        {item.children.map(childNav => (
          <MenuItem
            key={childNav.href}
            isActive={location.pathname === childNav.href}
            item={childNav}
            isOpen={isOpen}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  ) : (
    <Container
      to={item.href || ''}
      isActive={!!isActive || Boolean(item.children && item.children.some(ch => ch.href === location.pathname))}
      isOpen={isOpen}
      sx={{ paddingLeft: item.children && isOpen ? '0' : 'auto' }}
    >
      <Box
        sx={{
          width: 17,
          height: 17,
          minWidth: 17,
          minHeight: 17,
          marginRight: '18px',
        }}
      >
        {item.icon}
      </Box>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
        }}
        variant="subtitle2"
      >
        {item.label}
      </Typography>
    </Container>
  );
};

export default MenuItem;
