import { createContext } from 'react';

type InitDataType = {
  mode: string;
  setMode: (props: string) => void;
};

const initData = {
  mode: 'light',
  setMode: () => null,
};

export const StyleProviderContext = createContext(initData as InitDataType);
