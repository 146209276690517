import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { EmployeeWithTimeOffDetailsResource } from '../../../../api/main';
import { defaultColDef } from '../../../../constants/report';
import CustomPagination from '../../table-pagination';
import { VacationsGridProps } from './component.types';
import { IconButton, Stack } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { formatHoursToDayAndHours } from '../../../tools/date';

const VacationsGrid: React.FC<VacationsGridProps> = ({ data, onPageChange, isLoading, page, onSort, handleEdit }) => {
  const { t } = useTranslation();

  const Columns: GridColDef<EmployeeWithTimeOffDetailsResource>[] = [
    {
      ...defaultColDef,
      field: 'fullName',
      headerName: t('table:common.full-name'),
      valueGetter({ row }) {
        return `${row.first_name} ${row.last_name}`;
      },
    },
    {
      ...defaultColDef,
      field: 'remained_vacation_hours',
      headerName: t('table:remained-vacations.remained'),
      sortable: true,
      valueFormatter({ value }) {
        return value ? formatHoursToDayAndHours(Number(value)) : '0 days 0 hours';
      },
    },
    {
      ...defaultColDef,
      field: 'total_vacation_hours',
      headerName: t('table:remained-vacations.total-vacations'),
      valueFormatter({ value }) {
        return value ? formatHoursToDayAndHours(Number(value)) : '0 days 0 hours';
      },
    },
    {
      ...defaultColDef,
      field: 'remained_day_off_hours',
      headerName: t('table:remained-vacations.remained-day-offs'),
      sortable: true,
      valueFormatter({ value }) {
        return value ? formatHoursToDayAndHours(Number(value)) : '0 days 0 hours';
      },
    },
    // {
    //   ...defaultColDef,
    //   field: 'total_used_sick_leave_hours',
    //   headerName: t('table:remained-vacations.total-sick-leaves'),
    //   sortable: true,
    //   valueFormatter({ value }) {
    //     return value ? formatHoursToDayAndHours(Number(value)) : '0 days 0 hours';
    //   },
    // },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        return (
          <Stack direction="row">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit color="info" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <DataGrid
      loading={isLoading}
      page={page}
      pageSize={data?.meta?.per_page}
      onPageChange={onPageChange}
      onSortModelChange={onSort}
      paginationMode="server"
      sortingMode="server"
      columns={Columns}
      rows={data?.data || []}
      rowCount={data?.meta?.total}
      components={{ Pagination: CustomPagination }}
      disableSelectionOnClick
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
    />
  );
};

export default VacationsGrid;
