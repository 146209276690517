import React from 'react';
import { ApiErrorResponseType } from '../../../utils/hooks/use-fetch/component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import Meta from '../../../utils/widgets/meta/component';
import Paper from '@mui/material/Paper';
import { styled, Typography } from '@mui/material';
import FormItemText from '../../../utils/widgets/forms/form-item-text';
import TextField from '@mui/material/TextField';
import Button from '../../../utils/widgets/button';
import { useTranslation } from 'react-i18next';
import useForm from '../../../utils/hooks/use-form';
import useFetch from '../../../utils/hooks/use-fetch';
import { ForgotPasswordRequest } from '../../../api/main';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { appRoutes } from '../../../providers/routes';
import { useNavigate } from 'react-router-dom';
import { ApiAuthService } from '../../../api/main/services/ApiAuthService';

const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: '30vw',
  maxWidth: '440px',
  padding: '40px',
});

const ForgetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'forget-password',
    schema: forgetPasswordSchema,
  });
  const { control } = formProps;
  const forgetPasswordApi = useFetch<ForgotPasswordRequest, void>(ApiAuthService.apiAuthPasswordForgot);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data: ForgotPasswordRequest) => {
    return forgetPasswordApi
      .call(data)
      .then(result => {
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        navigate(appRoutes.auth.verifyEmail);
        return Promise.resolve(result);
      })
      .catch((e: ApiErrorResponseType) => {
        let message = t('form:general-submission.failed');

        if (e?.body?.message) {
          message = e.body.message;
        }

        enqueueSnackbar(message, {
          variant: 'error',
        });
        return Promise.reject(e);
      });
  };

  return (
    <form aria-label="forget-password" onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      {formDebugger()}
      <Meta>
        <title>
          {t('common:app-name')} | {t('common:auth.forget-password.title')}
        </title>
      </Meta>
      <StyledPaper>
        <Typography sx={{ marginBottom: '40px' }} variant="h2" component="p">
          {t('common:auth.forget-password.title')}
        </Typography>
        <FormItemText defaultValue="" control={control} required name="email" label={t('form:email-address')}>
          <TextField type="email" />
        </FormItemText>
        <Button
          sx={{
            marginTop: '40px',
          }}
          loading={forgetPasswordApi.loading}
          type="submit"
        >
          {t('form:forget-password.submit-btn')}
        </Button>
      </StyledPaper>
    </form>
  );
};

export default ForgetPassword;
