import React, { useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import darkLogo from '../../../../../assets/logos/dark-logo.svg';
import lightLogo from '../../../../../assets/logos/light-logo.svg';
import ToggleThemeSwitch from '../../../layouts/toggle-theme-switch';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { appRoutes } from '../../../../../providers/routes';
import HeaderNavigation from '../../../layouts/header-navigation';

const logo = {
  light: darkLogo,
  dark: lightLogo,
};

const StyledContainer = styled(Box)({
  width: 'calc(100vw - 100px)',
  minHeight: 50,
  left: 0,
  top: 40,
  zIndex: 1,
  position: 'fixed',
  margin: '0 50px',
  display: 'flex',
  alignItems: 'center',
});

const Header = () => {
  const theme = useTheme();
  const { t } = useTranslation('form');
  const location = useLocation();

  const activeNav = useMemo(() => {
    let defaultVariants;
    if (location.pathname === appRoutes.auth.login) {
      defaultVariants = appRoutes.auth.login;
    } else if ([appRoutes.auth.verifyEmail, appRoutes.auth.register].includes(location.pathname)) {
      defaultVariants = appRoutes.auth.register;
    }
    return defaultVariants;
  }, [location]);

  const navs = [
    {
      href: appRoutes.auth.login,
      label: t('login'),
    },
    {
      href: appRoutes.auth.register,
      label: t('register'),
    },
  ];

  return (
    <StyledContainer>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <img src={logo[theme.palette.mode]} alt="devolon-logo" />
      </Box>
      <HeaderNavigation activeNav={activeNav} navs={navs} />
      <ToggleThemeSwitch />
    </StyledContainer>
  );
};

export default Header;
