/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmployeeActivityStatusRequest } from '../models/ChangeEmployeeActivityStatusRequest';
import type { EmployeeActivitiesCollection } from '../models/EmployeeActivitiesCollection';
import type { EmployeeActivityResource } from '../models/EmployeeActivityResource';
import type { SickLeaveRequest } from '../models/SickLeaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSickLeaveService {

    /**
     * Change Sick Leave Status
     * This endpoint take sick leave identifier and change its status.
     * @param sickLeaveId Sick leave identifier.
     * @param requestBody change employee activity status request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiSickLeaveChangeStatus(
        sickLeaveId: number,
        requestBody: ChangeEmployeeActivityStatusRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/sick-leaves/{sick_leave_id}/change-status',
            path: {
                'sick_leave_id': sickLeaveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Sick Leaves List
     * Paginated list of all sick leaves.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param userId
     * @param locationId
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @param order Can be ASC or DESC
     * @param orderBy
     * @returns EmployeeActivitiesCollection employee activity collection response
     * @throws ApiError
     */
    public static apiSickLeaveIndex(
        page?: number,
        pageSize?: number,
        userId?: number,
        locationId?: number,
        status?: number,
        from?: string,
        to?: string,
        order?: string,
        orderBy?: string,
    ): CancelablePromise<EmployeeActivitiesCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sick-leaves',
            query: {
                'page': page,
                'pageSize': pageSize,
                'user_id': userId,
                'location_id': locationId,
                'status': status,
                'from': from,
                'to': to,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Sick Leave
     * This endpoint take sick leave data and create it.
     * @param requestBody create sick leave request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiSickLeaveCreate(
        requestBody: SickLeaveRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sick-leaves',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
