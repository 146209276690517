/* eslint-disable @typescript-eslint/no-unused-vars */
/**
this is auto generate file
---- don't try to edit ---
-> for more information look at the constant generator
*/

export const DEGREES = Object.freeze({
  '1': 'Undergraduate Degrees',
  '2': 'Professional Certificate',
  '3': 'Bachelor Degree',
  '4': 'Master Degree',
  '5': 'Doctoral Degree',
});

export const DEGREES_OPTIONS = Object.freeze([
  { label: 'Undergraduate Degrees', value: 1 },
  { label: 'Professional Certificate', value: 2 },
  { label: 'Bachelor Degree', value: 3 },
  { label: 'Master Degree', value: 4 },
  { label: 'Doctoral Degree', value: 5 },
]);

export const CV_STATUSES = Object.freeze({ '1': 'Pending', '2': 'Approved', '3': 'Rejected' });

export const CV_STATUSES_OPTIONS = Object.freeze([
  { label: 'Pending', value: 1 },
  { label: 'Approved', value: 2 },
  { label: 'Rejected', value: 3 },
]);

export const PROJECT_STATUSES = Object.freeze({ ONGOING: 'ONGOING', CLOSED: 'CLOSED' });

export const PROJECT_STATUSES_OPTIONS = Object.freeze([
  { label: 'ONGOING', value: 'ONGOING' },
  { label: 'CLOSED', value: 'CLOSED' },
]);

export const EMPLOYEE_ROLES = Object.freeze({
  PM: 'PM',
  'Technical PM': 'Technical PM',
  Developer: 'Developer',
  'UI/UX designer': 'UI/UX designer',
  'QA Engineer': 'QA Engineer',
  CTO: 'CTO',
  'Technical Lead': 'Technical Lead',
  Architect: 'Architect',
  'Head of HR': 'Head of HR',
  'HR Generalist': 'HR Generalist',
  'Marketing Manager': 'Marketing Manager',
});

export const EMPLOYEE_ROLES_OPTIONS = Object.freeze([
  { label: 'PM', value: 'PM' },
  { label: 'Technical PM', value: 'Technical PM' },
  { label: 'Developer', value: 'Developer' },
  { label: 'UI/UX designer', value: 'UI/UX designer' },
  { label: 'QA Engineer', value: 'QA Engineer' },
  { label: 'CTO', value: 'CTO' },
  { label: 'Technical Lead', value: 'Technical Lead' },
  { label: 'Architect', value: 'Architect' },
  { label: 'Head of HR', value: 'Head of HR' },
  { label: 'HR Generalist', value: 'HR Generalist' },
  { label: 'Marketing Manager', value: 'Marketing Manager' },
]);
