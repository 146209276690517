import { Box, InputLabel, styled } from '@mui/material';
import { FormInputLabelProps } from './component.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const StyledInputLabel = styled(InputLabel)(({ theme, disabled }) => ({
  width: '100%',
  position: 'relative',
  marginBottom: '10px',
  ...theme.typography.subtitle2,
  color: disabled ? 'black !important' : ` ${theme.palette.text1.main}`,
}));

const FormInputLabel = ({ label, disabled = false, required = false, htmlFor, children }: FormInputLabelProps) => {
  const { t } = useTranslation('symbol');

  return (
    <Box
      sx={{
        margin: '7px 0',
        width: '100%',
      }}
    >
      <StyledInputLabel disabled={disabled} htmlFor={htmlFor}>
        {label}
        {`${required ? t('required') : ''}`}
      </StyledInputLabel>
      {children}
    </Box>
  );
};

export default FormInputLabel;
