import React, { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { WatchLater } from '@mui/icons-material';
import { ApiWorkingHourService, WorkingHourResource, WorkingHoursCollection } from '../../../../api/main';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { getDateDuration } from '../../../tools/date';
import useErrorHandler from '../../../hooks/use-error-handler';
import useTable from '../../../hooks/use-table';
import { TableFilters, WorkingHoursGridProps, WorkingHoursRequest } from './component.types';
import CustomPagination from '../../table-pagination';
import { useTranslation } from 'react-i18next';
import { defaultColDef } from '../../../../constants/report';
import { format } from 'date-fns';
import When from '../../when';
import useFetch from '../../../hooks/use-fetch';
import { useUser } from '../../../hooks/use-user';
import { USERTYPE } from '../../../../constants/common';

const TimeColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: '8px',
  },
});

const WorkingHoursGrid: React.FC<WorkingHoursGridProps> = ({
  filters,
  hasPagination = true,
  pageSize: pSize,
  sendUserId,
}) => {
  const { userType, clientProfile } = useUser();
  const { t } = useTranslation();

  const handleError = useErrorHandler();
  const { data, page, setPage, setData, pageSize } = useTable<WorkingHoursCollection, TableFilters>({
    initialData: { meta: { total: 0 } },
    initialFilters: {},
    initialPage: 0,
  });
  const getAdminWorkingHours = useFetch<WorkingHoursRequest, WorkingHoursCollection>(
    ({ page: newPage, userId, locationId, projectId, from, to }) => {
      return ApiWorkingHourService.apiWorkingHourIndex(
        newPage,
        pSize || pageSize,
        userId,
        locationId,
        projectId,
        from,
        to
      );
    }
  );

  useEffect(() => {
    const handleSuccess = (result: WorkingHoursCollection) => setData(result);
    if (sendUserId && !clientProfile?.id) return;

    getAdminWorkingHours
      .call({
        page: page + 1,
        ...filters,
        from: filters.from as string,
        to: filters.to as string,
        userId:
          Number(filters.userId) ||
          ((userType === USERTYPE.HR && !sendUserId ? undefined : clientProfile?.id) as number),
      })
      .then(handleSuccess)
      .catch(handleError);
  }, [page, filters, handleError, setData, clientProfile?.id]);

  const Columns: GridColDef<WorkingHourResource>[] = [
    {
      ...defaultColDef,
      field: 'date',
      headerName: t('table:common.date'),
      minWidth: 110,
      flex: 1.5,
      valueGetter({ row }) {
        return `${format(new Date(row.start_time as string), 'dd.MM.Y EEEE')}`;
      },
    },
    {
      ...defaultColDef,
      field: 'start_time',
      headerName: t('table:working-hours.start-time'),
      renderCell({ value }) {
        const date = new Date(value as string);
        return (
          <When condition={Boolean(value)}>
            <TimeColumnBox>
              <WatchLater color="disabled" />
              {format(date, 'HH:mm')}
            </TimeColumnBox>
          </When>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'end_time',
      headerName: t('table:working-hours.finish-time'),
      renderCell({ value }) {
        const date = new Date(value as string);
        return (
          <When condition={Boolean(value)}>
            <TimeColumnBox>
              <WatchLater color="disabled" />
              {format(date, 'HH:mm')}
            </TimeColumnBox>
          </When>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'duration',
      headerName: t('table:common.duration'),
      valueGetter({ row }) {
        return getDateDuration({
          end: row.end_time || row.start_time,
          start: row.start_time,
        });
      },
    },
    {
      ...defaultColDef,
      field: 'location',
      headerName: t('table:common.location'),
      valueGetter({ row }) {
        return row.user?.location?.name;
      },
    },
  ];
  const firstColumn: GridColDef<WorkingHourResource> = {
    ...defaultColDef,
    field: 'fullName',
    headerName: t('table:common.full-name'),
    valueGetter({ row }) {
      return `${row.user?.first_name} ${row.user?.last_name}`;
    },
  };
  return (
    <DataGrid
      loading={sendUserId ? !clientProfile?.id || getAdminWorkingHours.loading : getAdminWorkingHours.loading}
      page={page}
      onPageChange={setPage}
      columns={hasPagination ? [firstColumn, ...Columns] : Columns.slice(0, Columns.length - 1)}
      rows={data.data || []}
      paginationMode="server"
      hideFooterPagination={!hasPagination}
      pageSize={data.meta?.per_page}
      rowCount={data.meta?.total}
      components={{ Pagination: CustomPagination }}
      disableSelectionOnClick
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      columnBuffer={8}
    />
  );
};

export default WorkingHoursGrid;
