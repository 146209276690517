import { Delete, Edit } from '@mui/icons-material';
import { Divider, IconButton, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { ActionsCellProps } from './component.types';

const ActionsCell: React.FC<ActionsCellProps> = ({
  editRoute,
  handleDelete = () => {},
  isEditDisabled = false,
  isDeleteDisabled = false,
}) => {
  return (
    <Stack direction="row">
      <Link to={isEditDisabled ? '' : editRoute}>
        <IconButton disabled={isEditDisabled}>
          <Edit color={isEditDisabled ? 'disabled' : 'info'} />
        </IconButton>
      </Link>
      <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
      <IconButton onClick={handleDelete} disabled={isDeleteDisabled}>
        <Delete color={isDeleteDisabled ? 'disabled' : 'error'} />
      </IconButton>
    </Stack>
  );
};
export default ActionsCell;
