import React, { useRef } from 'react';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { Box, Stack, TextField } from '@mui/material';
import InputDatePicker from '../../forms/input-date-picker';
import useErrorHandler from '../../../hooks/use-error-handler';
import FormItemText from '../../forms/form-item-text';
import { HolidayForm, HolidayModalFormProps } from './component.types';
import InputLocation from '../../forms/input-location';
import { HOLIDAY_HOURS } from '../../../../constants/common';

const holidayFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  start_time: Yup.string().ensure().dashedDateOnly().required(),
  location: Yup.object().shape({
    id: Yup.number().required(),
    name: Yup.string().required(),
  }),
  hours: Yup.number().min(1).max(8).required(),
});

const HolidayModalForm: React.FC<HolidayModalFormProps> = ({ onSubmit, open, onCancel, title = '', initialData }) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();
  const { handleSubmit, formDebugger, control } = useForm({
    name: 'holiday-form-modal',
    schema: holidayFormSchema,
    defaultValues: {
      ...initialData,
      start_time: initialData?.date,
      hours: HOLIDAY_HOURS.find(h => initialData?.hours === h.value)?.value,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={title}
      onOkText="common:save"
      width="46%"
    >
      <form
        ref={formRef}
        aria-label="create-holiday-form"
        onSubmit={handleSubmit(
          values => {
            values.hours = { label: '', value: values.hours as number };
            return onSubmit(values as HolidayForm);
          },
          false,
          err => {
            if (typeof err === 'string') {
              void handleError(err);
            }
          }
        )}
      >
        {formDebugger()}
        <Stack direction="row">
          <FormItemText defaultValue="" control={control} required name="name" label={t('form:name')}>
            <TextField type="text" />
          </FormItemText>
          <Box sx={{ width: '20px' }} />
          <InputLocation control={control} name="location" label={t('form:location')} />
        </Stack>
        <Stack direction="row">
          <InputDatePicker fullWidth control={control} required name="start_time" label={t('form:cv.start-date')} />
          <Box sx={{ width: '20px' }} />
          <FormItemText
            defaultValue=""
            control={control}
            required
            name="hours"
            label={t('form:duration')}
            min={1}
            max={8}
          >
            <TextField placeholder={t('form:hours')} type="number" />
          </FormItemText>
        </Stack>
      </form>
    </ConfirmationDialog>
  );
};

export default HolidayModalForm;
