/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SkillResource = {
    id?: number;
    name?: string;
    type?: SkillResource.type;
    /**
     * Initial creation timestamp
     */
    created_at?: string;
    /**
     * Last update timestamp
     */
    updated_at?: string;
};

export namespace SkillResource {

    export enum type {
        SOFT = 'Soft',
        HARD = 'hard',
    }


}

