import React, { memo } from 'react';
import { AuthLayoutProps } from './component.types';
import Layout from './layout';
import Header from './header';

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Layout>
      <Header />
      <div>{children}</div>
    </Layout>
  );
};

export default memo(AuthLayout);
