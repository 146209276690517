import { WhenProps, WhenReturn } from './component.types';
import { when } from '../../tools/when';

const renderer = (cb: WhenProps['render']) => {
  if (typeof cb === 'function') {
    return cb();
  }
  return null;
};

const When = ({ condition = false, render = null, children = null, unless = false }: WhenProps): WhenReturn => {
  if (when(condition, unless) && render) {
    return renderer(render);
  }

  if (when(condition, unless) && children) {
    return children;
  }

  return null;
};
export default When;
