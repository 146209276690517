import React from 'react';
import { ApiErrorResponseType } from '../../../utils/hooks/use-fetch/component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import Meta from '../../../utils/widgets/meta/component';
import Paper from '@mui/material/Paper';
import { styled, Typography } from '@mui/material';
import FormItemText from '../../../utils/widgets/forms/form-item-text';
import TextField from '@mui/material/TextField';
import Button from '../../../utils/widgets/button';
import { useTranslation } from 'react-i18next';
import useForm from '../../../utils/hooks/use-form';
import useFetch from '../../../utils/hooks/use-fetch';
import { ApiAuthService, ResetPasswordRequest } from '../../../api/main';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import InputPassword from '../../../utils/widgets/forms/input-password/component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes } from '../../../providers/routes';

const restPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(8).max(50).required(),
  confirm_password: Yup.string().compareWith({
    fieldName: 'password',
  }),
});

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: '30vw',
  maxWidth: '440px',
  padding: '40px',
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string | undefined;
  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'rest-password',
    schema: restPasswordSchema,
  });
  const { control } = formProps;
  const restPasswordApi = useFetch<ResetPasswordRequest, void>(ApiAuthService.apiAuthPasswordReset);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = (data: ResetPasswordRequest) => {
    if (token) {
      return restPasswordApi
        .call({ ...data, token })
        .then(result => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          navigate(appRoutes.auth.login);
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorResponseType) => {
          let message = t('form:general-submission.failed');

          if (e?.body?.message) {
            message = e.body.message;
          }

          enqueueSnackbar(message, {
            variant: 'error',
          });
          return Promise.reject(e);
        });
    }
  };

  return (
    <form aria-label="rest-password" onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      {formDebugger()}
      <Meta>
        <title>
          {t('common:app-name')} | {t('common:auth.reset-password.title')}
        </title>
      </Meta>
      <StyledPaper>
        <Typography sx={{ marginBottom: '40px' }} variant="h2" component="p">
          {t('common:auth.reset-password.title')}
        </Typography>
        <FormItemText defaultValue="" control={control} required name="email" label={t('form:email-address')}>
          <TextField type="email" />
        </FormItemText>

        <FormItemText defaultValue="" control={control} required label={t('form:password')} name="password">
          <InputPassword />
        </FormItemText>

        <FormItemText
          defaultValue=""
          control={control}
          required
          label={t('form:confirm-password')}
          name="confirm_password"
        >
          <InputPassword />
        </FormItemText>

        <Button
          sx={{
            marginTop: '40px',
          }}
          loading={restPasswordApi.loading}
          type="submit"
        >
          {t('common:save')}
        </Button>
      </StyledPaper>
    </form>
  );
};

export default ResetPassword;
