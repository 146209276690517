import React, { FC, memo } from 'react';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import FormItemText from '../../../utils/widgets/forms/form-item-text';
import useForm from '../../../utils/hooks/use-form';
import { ApiAuthService, RegistrationRequest } from '../../../api/main';
import useFetch from '../../../utils/hooks/use-fetch';
import InputPassword from '../../../utils/widgets/forms/input-password';
import { Box, Typography, useTheme } from '@mui/material';
import Button from '../../../utils/widgets/button';
import { appRoutes } from '../../../providers/routes';
import Checkbox from '../../../utils/widgets/forms/checkbox';
import { LOCALSTORAGEKEYS } from '../../../constants/local-storage-keys';
import { useSnackbar } from 'notistack';
import { ApiErrorResponseType } from '../../../utils/hooks/use-fetch/component.types';
import { Link, useNavigate } from 'react-router-dom';
import Meta from '../../../utils/widgets/meta/component';
import InputLocation from '../../../utils/widgets/forms/input-location';
import { RegisterSchema } from './component.types';

const registerSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  first_name: Yup.string().ensure().required(),
  last_name: Yup.string().ensure().required(),
  password: Yup.string().min(8).max(50).required(),
  location: Yup.object().shape({
    id: Yup.number().required(),
    name: Yup.string().required(),
  }),
  terms: Yup.boolean().oneOf([true]),
});

const Register: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'register',
    schema: registerSchema,
  });
  const { control } = formProps;

  const registerApi = useFetch<RegistrationRequest, void>(ApiAuthService.apiAuthRegister);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = (data: RegisterSchema) => {
    const { termsAccepted, location, ...otherValues } = data;

    return registerApi
      .call({
        location_id: location.id,
        ...otherValues,
      })
      .then((result: unknown) => {
        if (termsAccepted) {
          localStorage.setItem(
            `${process.env.REACT_APP_LOCAL_STORAGE_KEY}${LOCALSTORAGEKEYS.USER}`,
            JSON.stringify(result)
          );
        }
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        navigate(appRoutes.auth.verifyEmail);
        return Promise.resolve(result);
      })
      .catch((e: ApiErrorResponseType) => {
        let message = t('form:general-submission.failed');

        if (e?.body?.message) {
          message = e.body.message;
        }

        enqueueSnackbar(message, {
          variant: 'error',
        });
        return Promise.reject(e);
      });
  };

  return (
    <Box
      sx={{
        transform: 'translateY(170px)',
        padding: '100px 0',
        [theme.breakpoints.up('xl')]: {
          transform: 'unset',
        },
      }}
    >
      <form aria-label="register" onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        {formDebugger()}
        <Meta>
          <title>
            {t('common:app-name')} | {t('common:auth.registration.title')}
          </title>
        </Meta>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '30vw',
            maxWidth: '440px',
            padding: '40px',
            position: 'relative',
          }}
        >
          <Typography sx={{ marginBottom: '40px' }} variant="h2" component="p">
            {t('common:auth.registration.title')}
          </Typography>
          <FormItemText defaultValue="" control={control} required name="email" label={t('form:email-address')}>
            <TextField type="email" />
          </FormItemText>

          <FormItemText defaultValue="" control={control} required name="first_name" label={t('form:first-name')}>
            <TextField type="test" />
          </FormItemText>

          <FormItemText defaultValue="" control={control} required name="last_name" label={t('form:last-name')}>
            <TextField type="test" />
          </FormItemText>

          <InputLocation control={control} required name="location" label={t('form:location')} />

          <FormItemText defaultValue="" control={control} required label={t('form:password')} name="password">
            <InputPassword />
          </FormItemText>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '40px',
            }}
          >
            <Checkbox
              name="terms"
              data-testid="inline-link"
              label={
                <Trans
                  i18nKey="form:terms"
                  components={{
                    b: <b />,
                    a: <Link to="/terms" style={{ textDecoration: 'underline' }} />,
                  }}
                />
              }
              control={control}
            />
          </Box>
          <Button loading={registerApi.loading} type="submit">
            {t('form:register')}
          </Button>
        </Paper>
      </form>
    </Box>
  );
};

export default memo(Register);
