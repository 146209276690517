import { Tabs, Tab, useTheme, styled } from '@mui/material';

const TabBox = styled(Tab)(({ theme }) => ({
  color: theme.palette.text1.main,
}));

const TabView: React.FC<{
  onChange: (event: React.SyntheticEvent, value: any) => void;
  value: unknown;
  tabs: string[];
}> = ({ onChange, value, tabs }) => {
  const theme = useTheme();
  return (
    <Tabs
      onChange={onChange}
      value={value}
      sx={{
        background: theme.palette.waterloo.ultraLight,
        minHeight: '42px',
        borderRadius: '20px',
        '.MuiTabs-indicator': {
          height: '100%',
          borderRadius: '20px',
          background: '#AC4FC6',
        },
        '& .Mui-selected.Mui-selected': {
          color: theme.palette.white.main,
          background: 'transparent',
          position: 'relative',
          zIndex: 100,
        },
        '& .MuiButtonBase-root': {
          minHeight: '42px',
        },
      }}
    >
      {tabs.map(tab => (
        <TabBox key={tab} label={tab} />
      ))}
    </Tabs>
  );
};

export default TabView;
