/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SkillRequest } from '../models/SkillRequest';
import type { SkillResource } from '../models/SkillResource';
import type { SkillsCollection } from '../models/SkillsCollection';
import type { UserCollection } from '../models/UserCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSkillService {

    /**
     * Skills List
     * Paginated list of all skills.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param type 1 for Soft and 2 for Hard
     * @param query
     * @param order Can be ASC or DESC
     * @param orderBy
     * @returns SkillsCollection Skill collection response
     * @throws ApiError
     */
    public static apiSkillIndex(
        page?: number,
        pageSize?: number,
        type?: number,
        query?: string,
        order?: string,
        orderBy?: string,
    ): CancelablePromise<SkillsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/skills',
            query: {
                'page': page,
                'pageSize': pageSize,
                'type': type,
                'query': query,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Skill
     * This endpoint take skill data and create it.
     * @param requestBody create/update skill request
     * @returns SkillResource Create/update skill response
     * @throws ApiError
     */
    public static apiSkillCreate(
        requestBody: SkillRequest,
    ): CancelablePromise<SkillResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/skills',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Skill (Softly)
     * This endpoint take skill identifier and delete it softly.
     * @param skillId Skill identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiSkillDelete(
        skillId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/skills/{skill_id}',
            path: {
                'skill_id': skillId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                409: `Skill cannot be deleted`,
            },
        });
    }

    /**
     * Update Skill
     * This endpoint take skill data and update it.
     * @param skillId Skill identifier.
     * @param requestBody create/update skill request
     * @returns SkillResource Create/update skill response
     * @throws ApiError
     */
    public static apiSkillUpdate(
        skillId: number,
        requestBody: SkillRequest,
    ): CancelablePromise<SkillResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/skills/{skill_id}',
            path: {
                'skill_id': skillId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Skill users List
     * List of a skill users.
     * @param skillId Skill identifier.
     * @returns UserCollection User collection response
     * @throws ApiError
     */
    public static apiSkillUsers(
        skillId: number,
    ): CancelablePromise<UserCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/skills/{skill_id}/users',
            path: {
                'skill_id': skillId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
