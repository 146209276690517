/*  eslint-disable @typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument */

import React from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  FieldErrors,
  useForm as useReactHookForm,
} from 'react-hook-form';
import { ApiSubmissionError, UseFormProps, UseFormReturn } from './component.types';

const useForm = ({ name, schema, ...configs }: UseFormProps): UseFormReturn => {
  const { handleSubmit: handleSubmitForm, ...methods } = useReactHookForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    ...configs,
  });

  const { setError, reset: resetFormMethod } = methods;

  const resetForm = (data: FieldValues): void => {
    let formData: FieldValues = {};
    try {
      formData = Object.entries(data).reduce((accelerator, item) => {
        const field = item;
        if (typeof field[0] === 'boolean') {
          field[1] = false;
        } else if (Array.isArray(field[0])) {
          field[1] = [];
        } else {
          field[1] = undefined;
        }
        return { ...accelerator, field };
      }, {});

      resetFormMethod(formData);
    } catch (e) {
      resetFormMethod(formData);
    }
  };

  const handleError = (onError: SubmitErrorHandler<FieldValues>) => (error: FieldErrors) => {
    onError(error);
    return error;
  };

  const formDebugger = () => <DevTool control={methods.control} />;

  const handleSubmission =
    (
      submit = (values: FieldValues) => Promise.resolve(values),
      reset = false,
      onError: SubmitErrorHandler<FieldValues> = () => {}
    ): SubmitHandler<FieldValues> =>
    (data: FieldValues) => {
      submit(data)
        .then((formResponse: object) => {
          if (reset) {
            resetForm(data);
          }
          return Promise.resolve(formResponse);
        })
        .catch((e: ApiSubmissionError) => {
          if (e?.body?.errors) {
            Object.entries(e.body.errors).forEach(fieldError => {
              fieldError[1].forEach(errorMessage => {
                setError(fieldError[0], {
                  message: errorMessage as unknown as string,
                  type: 'server',
                });
              });
            });
          }
          onError(e as FieldValues);
          return Promise.reject(e);
        });
    };

  return {
    formDebugger,
    handleSubmit: (onSubmit: SubmitHandler<FieldValues>, reset = false, onError = () => {}) =>
      handleSubmitForm(handleSubmission(onSubmit, reset, onError), handleError(onError)),
    ...methods,
  };
};

export default useForm;
