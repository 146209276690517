import { Box, Paper, styled, Typography } from '@mui/material';
import { THEME } from '../../../constants/theme';

export const CardBox = styled(Paper)(({ theme }) => ({
  padding: '20px',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '10px',
}));
export const CardHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === THEME.LIGHT ? theme.palette.secondary.main : theme.palette.text1.main,
}));
export const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text1.main,
}));
export const CardItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '16px 0',
  whiteSpace: 'nowrap',
});

export const CardItemValueText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === THEME.LIGHT ? theme.palette.text1.lighter : theme.palette.text1.main,
  marginLeft: '8px',
  marginTop: '1.5px',
}));
export const IconBox = styled(Box)({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '42px',
  height: '42px',
  marginRight: '8px',
});
