/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationRequest } from '../models/LocationRequest';
import type { LocationResource } from '../models/LocationResource';
import type { LocationsCollection } from '../models/LocationsCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiLocationService {

    /**
     * Locations List
     * List of all locations.
     * @param name
     * @returns LocationsCollection Api location collection response
     * @throws ApiError
     */
    public static apiLocationIndex(
        name?: string,
    ): CancelablePromise<LocationsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/locations',
            query: {
                'name': name,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Location
     * This endpoint take location data and create it.
     * @param requestBody Create/Update location request
     * @returns LocationResource Api create/update location response
     * @throws ApiError
     */
    public static apiLocationCreate(
        requestBody: LocationRequest,
    ): CancelablePromise<LocationResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Location
     * This endpoint take location identifier and delete it.
     * @param locationId Location identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiLocationDelete(
        locationId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                409: `Location cannot be deleted`,
            },
        });
    }

    /**
     * Update Location
     * This endpoint take location data and update it.
     * @param locationId Location identifier.
     * @param requestBody Create/Update location request
     * @returns LocationResource Api create/update location response
     * @throws ApiError
     */
    public static apiLocationUpdate(
        locationId: number,
        requestBody: LocationRequest,
    ): CancelablePromise<LocationResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
