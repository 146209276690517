import i18n from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { i18nextPlugin as translationCheck } from 'translation-check';
import { initReactI18next } from 'react-i18next';

import translations from './translations';
import { yupConfig, yupLocale } from '../utils/widgets/forms/yup.setting';

const defaultNS = ['common', 'form'];

const defaultLocale = localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(i18nextBrowserLanguageDetector)
  // plugin to check missed translated keys to see locale files use this url [host]?showtranslations
  .use(translationCheck)
  // react plugin
  .use(initReactI18next);

i18n.on('languageChanged', lng => {
  // update Yup language after i18t language changed
  yupLocale(i18n.t);
  yupConfig(i18n.t);
  // To force the lang attribute on the html tag on locale change for accessibility
  document.documentElement.setAttribute('lang', lng);
});

// initialize i18n
void i18n.init(
  {
    detection: {
      // we could add cookie too
      order: ['localStorage'],
      // keys or params to lookup language from back links
      lookupQuerystring: 'lng',
      lookupLocalStorage: process.env.REACT_APP_LANG_KEY,
      // cache user language on
      caches: ['localStorage'],
      // languages to not persist (cookie, localStorage)
      excludeCacheFor: ['some-languages'],
      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },
    fallbackLng: defaultLocale, // same fallback as the initial user.locale
    debug: false,
    // have a common namespace used around the full app
    ns: defaultNS,
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
    // we init with resources
    resources: translations,
  },
  (_, t) => {
    // After i18t's initialized, set up Yup language
    yupLocale(t);
    yupConfig(t);
  }
);
