import React, { memo, useCallback, useRef } from 'react';
import { InputLocationProps, LocationRequest } from './component.types';
import { LocationResource, LocationsCollection, ApiLocationService } from '../../../../api/main';
import { ApiErrorType } from '../../../hooks/use-fetch/component.types';
import useFetch from '../../../hooks/use-fetch';
import Autocomplete from '../autocomplete';

let latestOptions: LocationResource[] = [];
const InputLocation = ({
  name: inputName,
  control,
  label,
  placeholder,
  required = false,
  disabled = false,
  defaultValue = undefined,
}: InputLocationProps) => {
  const optionsRef = useRef<LocationResource[]>(latestOptions);
  const locationRequest = ({ name }: LocationRequest) => ApiLocationService.apiLocationIndex(name);
  const locationApi = useFetch<LocationRequest, LocationsCollection>(locationRequest);

  const onSearch = useCallback(
    (searchValue: string) => {
      const name = searchValue ? { name: searchValue } : {};
      locationApi
        .call({ ...name })
        .then((result: LocationsCollection) => {
          if (result?.data) {
            optionsRef.current = result.data;
            latestOptions = result.data;
          }
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorType) => {
          optionsRef.current = [];
          return Promise.reject(e);
        });
    },
    [locationApi]
  );

  return (
    <Autocomplete
      name={inputName}
      label={label}
      control={control}
      required={required}
      disabled={disabled}
      options={optionsRef.current}
      loading={locationApi.loading}
      defaultValue={defaultValue ? defaultValue : undefined}
      placeholder={placeholder ?? undefined}
      onSearch={onSearch}
    />
  );
};

export default memo(InputLocation);
