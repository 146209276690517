import { Edit } from '@mui/icons-material';
import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { EMPLOYEE_STATUS, ROLES } from '../../../../constants/common';
import useForm from '../../../hooks/use-form';
import { useUser } from '../../../hooks/use-user';
import { phoneRegExp } from '../../../tools/matchers';
import Button from '../../button';
import Autocomplete from '../../forms/autocomplete';

import FormItemText from '../../forms/form-item-text';
import InputLocation from '../../forms/input-location';
import When from '../../when';
import { EmployeeProfileFormProps } from './component.types';
import InputDatePicker from '../../forms/input-date-picker';

export const InputGroup = styled(Stack)({
  flexDirection: 'row',
  '> div': {
    marginRight: '20px',
    minWidth: '300px',
    maxWidth: '300px',
  },
});

const EmployeeProfileForm: React.FC<EmployeeProfileFormProps> = ({ data, onSubmit, userId, isLoading }) => {
  const { isHR, isOwner } = useUser(userId);
  const { t } = useTranslation();
  const updateProfileSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    first_name: Yup.string().ensure().required(),
    last_name: Yup.string().ensure().required(),
    phone: Yup.string()
      .optional()
      .matches(phoneRegExp, { message: t('form:invalid-phone'), excludeEmptyString: true })
      .nullable(true),
    location: Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    }),
    is_admin: Yup.boolean(),
    role: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),

    slack_id: Yup.string().optional().nullable(true),
    birthday: Yup.string().nullable(true),
  });

  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'employee-edit-form',
    schema: updateProfileSchema,
  });
  const { control } = formProps;

  return (
    <form aria-label="employee-edit-form" onSubmit={handleSubmit(onSubmit)}>
      {formDebugger()}

      <Box sx={{ marginBottom: '40px' }}>
        <Typography variant="h1" component="p">
          {t('form:profile-information')}
          <Edit sx={{ verticalAlign: 'text-top', marginLeft: '15px' }} />
        </Typography>
      </Box>
      <InputGroup>
        <FormItemText
          defaultValue={data?.first_name}
          control={control}
          required
          name="first_name"
          label={t('form:first-name')}
        >
          <TextField type="text" />
        </FormItemText>

        <FormItemText
          defaultValue={data?.last_name}
          control={control}
          required
          name="last_name"
          label={t('form:last-name')}
        >
          <TextField type="text" />
        </FormItemText>
        <Autocomplete
          disabled={!isHR || isOwner}
          defaultValue={ROLES.find(r => r.value === data?.role?.name)}
          label={t('form:role')}
          name="role"
          control={control}
          required
          optionKeys={{
            label: ['label'],
            value: 'value',
          }}
          placeholder={t('form:choose-user-role')}
          options={ROLES}
          loading={false}
          onSearch={() => {}}
        />
      </InputGroup>
      <InputGroup>
        <FormItemText
          defaultValue={data?.email}
          control={control}
          required
          name="email"
          label={t('form:email-address')}
        >
          <TextField type="email" disabled />
        </FormItemText>
        <FormItemText defaultValue={data?.phone} control={control} name="phone" label={t('form:phone')}>
          <TextField type="text" />
        </FormItemText>
        <InputLocation
          required
          defaultValue={data?.location}
          control={control}
          name="location"
          label={t('form:location')}
          disabled={!isHR || isOwner}
        />
      </InputGroup>
      <InputGroup>
        <FormItemText defaultValue={data?.slack_id} control={control} name="slack_id" label={t('form:slack-id')}>
          <TextField type="text" placeholder={t('form:slack-id')} />
        </FormItemText>
        <InputDatePicker
          defaultValue={data?.birthday}
          control={control}
          name={'birthday'}
          label={t('form:birthdate')}
          fullWidth
        />

        <InputDatePicker
          disabled={!isHR || isOwner}
          defaultValue={data?.start_date}
          control={control}
          name={'start_date'}
          label={t('form:start-date')}
          fullWidth
        />
      </InputGroup>

      <When condition={isHR && !isOwner}>
        <Box>
          <Box sx={{ margin: '40px 0' }}>
            <Typography variant="h1" component="p">
              {t('form:manage-employee')}
            </Typography>
          </Box>
          {isHR && (
            <Stack direction="row" sx={{ marginBottom: '35px', width: '300px' }}>
              <Autocomplete
                defaultValue={EMPLOYEE_STATUS.find(r => r.value === data?.status?.id)}
                label={'Employee status'}
                name="status"
                control={control}
                required
                optionKeys={{
                  label: ['label'],
                  value: 'value',
                }}
                placeholder={'Choose employee status'}
                options={EMPLOYEE_STATUS}
                loading={false}
                onSearch={() => {}}
              />
            </Stack>
          )}
        </Box>
      </When>

      {/*
       */}
      <Button loading={isLoading} type="submit" sx={{ margin: '40px 0' }}>
        {t('form:submit')}
      </Button>
    </form>
  );
};

export default EmployeeProfileForm;
