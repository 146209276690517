import React, { createRef, memo, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

import Slide from '@mui/material/Slide';

export type NotificationProps = {
  children: ReactElement | ReactElement[];
};

const NotificationProvider = ({ children }: NotificationProps) => {
  const notistackRef = createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      TransitionComponent={Slide as React.ComponentType}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      iconVariant={{
        error: <ErrorIcon style={{ marginRight: 10 }} />,
        info: <InfoIcon style={{ marginRight: 10 }} />,
        success: <CheckCircleIcon style={{ marginRight: 10 }} />,
        warning: <WarningIcon style={{ marginRight: 10 }} />,
      }}
      maxSnack={5}
      ref={notistackRef}
      action={key => (
        <IconButton onClick={onClickDismiss(key)} aria-label="close snackbar">
          <CancelSharpIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default memo(NotificationProvider);
