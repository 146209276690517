import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiErrorResponseType } from '../use-fetch/component.types';

type UseErrorHanlder = {
  messageText?: string;
};

const useErrorHandler = ({ messageText = '' }: UseErrorHanlder = {}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useRef((e: ApiErrorResponseType & { isCancelled?: boolean }) => {
    if (e.isCancelled) {
      return;
    }

    let message = messageText || t('form:general-submission.failed');
    if (typeof e === 'string') {
      message = e;
    } else if (e.body && e.body.errors && Object.values(e.body.errors).length) {
      const values = Object.values(e.body.errors);
      message = values[0][0];
    } else if (e?.body?.message) {
      message = e.body.message;
    }

    enqueueSnackbar(message, {
      variant: 'error',
    });
    return Promise.reject(e);
  });

  return handleError.current;
};

export default useErrorHandler;
