/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmployeeActivityStatusRequest } from '../models/ChangeEmployeeActivityStatusRequest';
import type { EmployeeActivitiesCollection } from '../models/EmployeeActivitiesCollection';
import type { EmployeeActivityResource } from '../models/EmployeeActivityResource';
import type { VacationRequest } from '../models/VacationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiVacationService {

    /**
     * Change Vacation Status
     * This endpoint take vacation identifier and change its status.
     * @param vacationId Vacation identifier.
     * @param requestBody change employee activity status request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiVacationChangeStatus(
        vacationId: number,
        requestBody: ChangeEmployeeActivityStatusRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vacations/{vacation_id}/change-status',
            path: {
                'vacation_id': vacationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Vacations List
     * Paginated list of all vacations.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param userId
     * @param locationId
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @param order Can be ASC or DESC
     * @param orderBy
     * @returns EmployeeActivitiesCollection employee activity collection response
     * @throws ApiError
     */
    public static apiVacationIndex(
        page?: number,
        pageSize?: number,
        userId?: number,
        locationId?: number,
        status?: number,
        from?: string,
        to?: string,
        order?: string,
        orderBy?: string,
    ): CancelablePromise<EmployeeActivitiesCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vacations',
            query: {
                'page': page,
                'pageSize': pageSize,
                'user_id': userId,
                'location_id': locationId,
                'status': status,
                'from': from,
                'to': to,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Vacation
     * This endpoint take vacation data and create it.
     * @param requestBody App create vacation request
     * @returns EmployeeActivityResource employee activity response
     * @throws ApiError
     */
    public static apiVacationCreate(
        requestBody: VacationRequest,
    ): CancelablePromise<EmployeeActivityResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vacations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Adds previous month's vacation to vacation bank for all users
     * @returns any success.
     * @throws ApiError
     */
    public static apiVacationUpdateCapacity(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vacations/update-monthly-capacity',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
    }

}
