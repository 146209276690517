import { Box, Dialog, DialogContent, Stack, styled, Typography } from '@mui/material';
import { CloseButton } from '../../confirmation-dialog/component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { HolidaListModalProps, HolidayItemProps } from './component.types';

const DayBox = styled(Typography)(({ theme }) => ({
  width: '24px',
  height: '24px',
  position: 'relative',
  backgroundColor: theme.palette.yellow.main,
  color: theme.palette.white.main,
  textAlign: 'center',
  marginRight: '11px',
  verticalAlign: 'middle',
}));

const HolidayItem: React.FC<HolidayItemProps> = ({ item: { month, days } }) => (
  <Box sx={{ marginBottom: '20px' }}>
    <Typography variant="h3" sx={{ marginBottom: '16px' }}>
      {month}
    </Typography>
    {days.map(day => (
      <Stack key={day.title} direction="row" alignItems="center" sx={{ marginBottom: '10px' }}>
        <DayBox variant="subtitle2">{day.value}</DayBox>
        <Typography variant="subtitle2" fontWeight="normal">
          {day.title}
        </Typography>
      </Stack>
    ))}
  </Box>
);

const HolidayListModal: React.FC<HolidaListModalProps> = ({ open, onCancel, data, title }) => {
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { minWidth: '340px' } }} maxWidth="md" open={open} onClose={onCancel}>
      <CloseButton onClick={onCancel} aria-label="close-modal">
        <CloseOutlinedIcon />
      </CloseButton>
      <DialogContent sx={{ margin: '30px 0', padding: '0 40px' }}>
        <Typography variant="h1" sx={{ margin: '40px 0' }}>
          {title}
        </Typography>
        {Object.entries(data).map(([month, item]) => (
          <HolidayItem key={month} item={{ month, days: item }} />
        ))}
      </DialogContent>
    </Dialog>
  );
};
export default HolidayListModal;
