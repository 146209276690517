import { Box, LinearProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import { isToday } from 'date-fns';
import { useEffect, useRef } from 'react';
import { ApiEmployeeService, ApiWorkingHourService, EmployeeOverviewResource } from '../../../../api/main';
import { CardHeading, CardItemValueText } from '../../../../components/admin/employee-overview/styled';
import { TimerInfo } from '../../../../components/client/dashboard/component';
import useErrorHandler from '../../../hooks/use-error-handler';
import useFetch from '../../../hooks/use-fetch';
import { useUser } from '../../../hooks/use-user';
import { formatMinutes } from '../../../tools/date';
import { StatisticsType } from './component.typs';

const RowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '10px',
  padding: '10px',
  marginBottom: '10px',
}));
const LinearProgressWrapper = styled(Box)(({ color }) => ({
  '> *': {
    color: color,
  },
}));

type StatisticRowProps = {
  percent: number;
  value?: number;
  color: string;
  total?: number;
  label: string;
};
const StatisticRow: React.FC<StatisticRowProps> = ({ percent, color, label, value, total }) => {
  return (
    <RowBox>
      <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '8px' }}>
        <Typography variant="subtitle2" component="span">
          {label}
        </Typography>
        <div>
          {total !== undefined && (
            <>
              <Typography variant="subtitle2" component="span" sx={{ marginRight: '8px' }}>
                {formatMinutes(total * 60).formatted}
              </Typography>
            </>
          )}
          {value !== undefined && (
            <>
              /
              <Typography variant="subtitle2" component="span">
                <CardItemValueText>
                  {value}
                  {value > 1 ? 'hrs' : 'hr'}
                </CardItemValueText>
              </Typography>
            </>
          )}
        </div>
      </Stack>
      <LinearProgressWrapper color={color}>
        <LinearProgress
          variant="determinate"
          value={Math.min(100, percent)}
          color="inherit"
          sx={{ height: '6px', borderRadius: '10px' }}
        />
      </LinearProgressWrapper>
    </RowBox>
  );
};

const Statistics: React.FC<{ timerInfo: TimerInfo }> = ({ timerInfo: { currentHours, startTime } }) => {
  const theme = useTheme();
  const { clientProfile } = useUser();
  const handleError = useErrorHandler();
  const getData = useFetch(ApiWorkingHourService.apiWorkingHourStatistics);
  const getEmployeeOverview = useFetch<{ id: number }, EmployeeOverviewResource>(({ id }) =>
    ApiEmployeeService.apiEmployeeOverview(id, new Date().getMonth() + 1, new Date().getFullYear())
  );

  useEffect(() => {}, []);

  const data = useRef<StatisticsType>();
  const statisticsItems = [
    {
      label: 'Today',
      value: Number(data.current?.today_working_day_hours),
      total: Number(data.current?.today_total_working_hours) + (isToday(startTime) ? currentHours : 0),
      color: theme.palette.green.main,
    },
    {
      label: 'This Week',
      value: Number(data.current?.week_working_day_hours),
      total: Number(data.current?.week_total_working_hours) + currentHours,
      color: theme.palette.purple.main,
    },
    {
      label: 'This Month',
      value: Number(data.current?.month_working_day_hours),
      total: Number(data.current?.month_total_working_hours) + currentHours,
      color: theme.palette.primary.main,
    },
    {
      label: 'Remaining',
      value: Number(data.current?.month_working_day_hours),
      total:
        Number(data.current?.month_working_day_hours) - Number(data.current?.month_total_working_hours) - currentHours,
      color: theme.palette.primary.main,
    },
    {
      label: 'Balance',
      total: (getEmployeeOverview.result as EmployeeOverviewResource)?.balance,
      color: theme.palette.red.main,
    },
  ];
  useEffect(() => {
    if (clientProfile?.id && !getEmployeeOverview.loading && !getEmployeeOverview.loaded)
      getEmployeeOverview.call({ id: Number(clientProfile?.id) }).catch(handleError);
    getData
      .call()
      .then(result => (data.current = result))
      .catch(handleError);
  }, [clientProfile?.id]);
  return (
    <>
      <CardHeading variant="h4">Statistics</CardHeading>
      {data.current &&
        statisticsItems.map(item => (
          <StatisticRow
            key={item.label}
            value={item.value}
            total={item.total}
            color={item.color}
            label={item.label}
            percent={item.total ? (item.total / (item.value || 0)) * 100 : 0}
          />
        ))}
    </>
  );
};

export default Statistics;
