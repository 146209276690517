import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, MenuItem } from '@mui/material';
import DashboardLayout from '../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../utils/widgets/layouts/user-page-header';
import SearchInputBox from '../../../utils/widgets/search-input-box';
import { debounce } from '../../../utils/tools/debounce';
import EmployeesGrid from '../../../utils/widgets/grids/employees-grid';
import CustomSelect from '../../../utils/widgets/forms/custom-select';
import { EMPLOYEESTYPE } from '../../../constants/common';
import { GridHeader, StyledPaper } from '../../../utils/widgets/layouts/reports-layout';
import { useSearchParams } from 'react-router-dom';

const EmployeesList = () => {
  const [query] = useSearchParams();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Record<string, string | undefined>>(() => {
    const queryFilters = query.get('filters') as string;
    if (queryFilters) return JSON.parse(queryFilters) as Record<string, string | undefined>;
    return { status: 'Active' };
  });

  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, query: event.target.value }));
  }, 300);

  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:employees')} />
      <StyledPaper>
        <GridHeader>
          <SearchInputBox onChange={debouncedChangeHandler} defaultValue={filters.query} />
          <CustomSelect
            value={filters.status || ''}
            onChange={e => setFilters({ ...filters, status: e.target.value || undefined })}
          >
            <MenuItem value="">All employees</MenuItem>
            {Object.values(EMPLOYEESTYPE).map(option => (
              <MenuItem key={option} value={option}>
                {t('form:report.filter-employees-option', { value: option })}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridHeader>
        <Divider sx={{ margin: '30px 0' }} />
        <EmployeesGrid filters={filters} />
      </StyledPaper>
    </DashboardLayout>
  );
};

export default EmployeesList;
