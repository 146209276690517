/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeOverviewResource } from '../models/EmployeeOverviewResource';
import type { EmployeeProfilePhotoResource } from '../models/EmployeeProfilePhotoResource';
import type { EmployeeWithTimeOffDetailsCollection } from '../models/EmployeeWithTimeOffDetailsCollection';
import type { ProjectCollection } from '../models/ProjectCollection';
import type { UpdateEmployeeActivityBankRequestBody } from '../models/UpdateEmployeeActivityBankRequestBody';
import type { UpdateEmployeePhotoRequest } from '../models/UpdateEmployeePhotoRequest';
import type { UserCollection } from '../models/UserCollection';
import type { UserProjectLogCollection } from '../models/UserProjectLogCollection';
import type { UserRequest } from '../models/UserRequest';
import type { UserResource } from '../models/UserResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiEmployeeService {

    /**
     * Upload Employee's photo
     * This endpoint is made for updating Employee's photo
     * @param userId User identifier.
     * @param formData Api Update Employee Profile Photo
     * @returns EmployeeProfilePhotoResource Employee Profile Photo Response
     * @throws ApiError
     */
    public static apiEmployeeUploadProfilePhoto(
        userId: number,
        formData: UpdateEmployeePhotoRequest,
    ): CancelablePromise<EmployeeProfilePhotoResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/employees/{user_id}/profile-photo',
            path: {
                'user_id': userId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Employee's photo
     * This endpoint is deleting Employee's photo
     * @param userId User identifier.
     * @returns any response with no content
     * @throws ApiError
     */
    public static apiEmployeeDeleteProfilePhoto(
        userId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/employees/{user_id}/profile-photo',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * List employee assinged projects
     * This endpoint returns list of employee assinged projects
     * @param userId User identifier.
     * @returns ProjectCollection project collection response
     * @throws ApiError
     */
    public static apiEmployeeAssingedProjects(
        userId: number,
    ): CancelablePromise<ProjectCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}/assigned-projects',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * List employees
     * This endpoint returns employee's vacations, dayoffs, sick leaves and special vacations.
     * @param userId User identifier.
     * @param monthNumber
     * @param yearNumber
     * @param locationId
     * @returns UserCollection User collection response
     * @throws ApiError
     */
    public static apiEmployeeNonWorkedDaysIndex(
        userId: number,
        monthNumber: number,
        yearNumber: number,
        locationId?: number,
    ): CancelablePromise<UserCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}/non-worked-days',
            path: {
                'user_id': userId,
            },
            query: {
                'month_number': monthNumber,
                'year_number': yearNumber,
                'location_id': locationId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * List employee project histories
     * This endpoint returns list of employee project histories
     * @param userId User identifier.
     * @returns UserProjectLogCollection project collection response
     * @throws ApiError
     */
    public static apiEmployeeProjectHistories(
        userId: number,
    ): CancelablePromise<UserProjectLogCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}/project-histories',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Employee remained breaks
     * Get employee remained breaks.
     * @param userId User identifier.
     * @returns any Employee remained breaks array.
     * @throws ApiError
     */
    public static apiEmployeeRemainedBreaks(
        userId: number,
    ): CancelablePromise<{
        vacation_remained?: number;
        day_off_remained?: number;
        sick_leave_remained?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}/remained-breaks',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * List employees
     * This endpoint returns a paginated list of employees.
     * @param query
     * @param status Filter by employee status. it can be `Active` or `Archived`
     * @param page
     * @param pageSize
     * @returns UserCollection User collection response
     * @throws ApiError
     */
    public static apiEmployeeIndex(
        query?: string,
        status?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<UserCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees',
            query: {
                'query': query,
                'status': status,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Time-off list
     * List employees with their time-off details
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param query
     * @param status Filter by employee status. it can be `1` for Active or `2` for Archived
     * @param order Can be ASC or DESC
     * @param orderBy
     * @returns EmployeeWithTimeOffDetailsCollection employee with time-off details collection response
     * @throws ApiError
     */
    public static apiEmployeesListWithTimeOffs(
        page?: number,
        pageSize?: number,
        query?: string,
        status?: string,
        order?: string,
        orderBy?: string,
    ): CancelablePromise<EmployeeWithTimeOffDetailsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/time-off-details',
            query: {
                'page': page,
                'pageSize': pageSize,
                'query': query,
                'status': status,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Employee Overview
     * This endpoint returns employee overview.
     * @param userId User identifier.
     * @param monthNumber
     * @param yearNumber
     * @returns EmployeeOverviewResource Employee overview response
     * @throws ApiError
     */
    public static apiEmployeeOverview(
        userId: number,
        monthNumber: number,
        yearNumber: number,
    ): CancelablePromise<EmployeeOverviewResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}/overview',
            path: {
                'user_id': userId,
            },
            query: {
                'month_number': monthNumber,
                'year_number': yearNumber,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Show Employee
     * This endpoint returns employee information.
     * @param userId User identifier.
     * @returns UserResource Api user resource response
     * @throws ApiError
     */
    public static apiEmployeeShow(
        userId: number,
    ): CancelablePromise<UserResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Update Employee
     * By calling this endpoint admin can update employee data.
     * @param userId User identifier.
     * @param requestBody Api update Employee request
     * @returns UserResource Api user resource response
     * @throws ApiError
     */
    public static apiEmployeeUpdate(
        userId: number,
        requestBody: UserRequest,
    ): CancelablePromise<UserResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/employees/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Update activity banks
     * Update activity banks
     * @param userId User identifier.
     * @param requestBody Api update activity-bank request
     * @param query
     * @returns any response with no content
     * @throws ApiError
     */
    public static apiUpdateEmployeeActivityBanks(
        userId: number,
        requestBody: UpdateEmployeeActivityBankRequestBody,
        query?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/employees/{user_id}/activity-banks',
            path: {
                'user_id': userId,
            },
            query: {
                'query': query,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
