import { format, formatDuration, intervalToDuration, isValid } from 'date-fns';
type StartEndArgs = { start?: string | Date; end?: string | Date };

export const getDateOnlyDashed = (value: string | null) => {
  if (!value) {
    return value;
  }

  try {
    return format(new Date(value), 'yyyy-MM-dd');
  } catch (e) {
    return value;
  }
};
export const getDateTimeDashed = (value: string | null) => {
  if (!value) {
    return value;
  }

  try {
    return format(new Date(value), 'yyyy-MM-dd HH:mm');
  } catch (e) {
    return value;
  }
};

export const getYear = (value: string) => {
  try {
    return format(new Date(value), 'yyyy');
  } catch (e) {
    return value;
  }
};

export const isValidDate = (value?: string) => {
  if (!value) {
    return false;
  }

  try {
    return isValid(new Date(value));
  } catch (e) {
    return false;
  }
};

export const getDateDuration = ({ start = '', end = '' }: StartEndArgs) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  startDate.setSeconds(0);
  endDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setMilliseconds(0);
  return formatDuration(intervalToDuration({ end: endDate, start: startDate }));
};
export const formatMinutes = (value: number) => {
  const hours = Math.floor(Math.abs(Number(value.toFixed(4)) / 60));
  const minutes = Math.abs(Math.floor(Number(value.toFixed(4)) % 60));
  return {
    hours,
    minutes,
    formatted: `${value < 0 ? '-' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`,
  };
};

export const makeSameDate = ({ start = '', end = '' }: StartEndArgs) => {
  const a = new Date(start);
  const b = new Date(end);

  if (b.getDate() > a.getDate() || b.getMonth() > a.getMonth() || b.getFullYear() > a.getFullYear()) {
    b.setHours(23);
    b.setMinutes(59);
    b.setSeconds(59);
  }
  b.setFullYear(a.getFullYear());
  b.setMonth(a.getMonth());
  b.setDate(a.getDate());
  return b;
};
export const formatCountDown = ({
  start,
  end,
  showSeconds = true,
}: {
  start: Date;
  end: Date;
  showSeconds?: boolean;
}) => {
  const formatPart = (part: number) => String(part).padStart(2, '0');
  const time = new Date(end.valueOf() - start.valueOf());
  const hours = formatPart(time.getUTCHours());
  const minutes = formatPart(time.getUTCMinutes());
  const seconds = formatPart(time.getUTCSeconds());
  return `${hours}:${minutes}${showSeconds ? `:${seconds}` : ''}`;
};

export const formatHoursToDayAndHours = (hours: number, hasBr?: boolean) => {
  const days = Math.floor(Math.abs(hours) / 8) * (hours < 0 ? -1 : 1);
  const minutes = (hours % 8) * 60;
  const formattedHours = formatMinutes(minutes).hours * (!days && hours < 0 ? -1 : 1);
  const daysPart = days ? `${days} day${days === 1 ? '' : 's'}` : '';
  const hoursPart = `${formattedHours} hour${formattedHours === 1 ? '' : 's'}`;
  return `${daysPart}${minutes ? `${hasBr && daysPart ? '</br>' : ''} ${hoursPart}` : ''}` || '0 day';
};

export const convertDaysAndHoursToHours = (days: number, hours: number) => {
  return days * 8 + (days < 0 ? -hours : hours);
};

export const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${format(date, 'MM')}-${format(date, 'dd')}`;
};
