import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { AutocompleteProps, DefaultOption } from './component.types';
import FormInputLabel from '../form-input-label';
import TextField from '@mui/material/TextField';
import { AutocompleteInputChangeReason } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/CloseSharp';
import useConstructor from '../../../hooks/use-constructor';
import { debounce } from '../../../tools/debounce';

const Autocomplete = <T extends Partial<DefaultOption>>({
  name,
  label = '',
  placeholder = 'form:autocomplete.type-to-search',
  options = [],
  optionKeys = {
    label: 'name',
    value: 'id',
  },
  loading,
  multiple,
  onSearch,
  defaultValue,
  renderTags,
  disabled = false,
  required = false,
  control,
  errorMessage = '',
  hasSpinner = true,
}: AutocompleteProps<T>) => {
  const resetValue = multiple ? [] : null;
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue ?? resetValue,
    name: name,
  });
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { onChange, ...inputProps } = field;
  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  const [autocompleteValue, setAutocompleteValue] = useState<T | T[] | null>(inputProps.value as T);

  const getLabel = useCallback(
    (option: T) => {
      if (Array.isArray(optionKeys.label)) {
        return Object.entries(option)
          .map(item => optionKeys.label.includes(item[0]) && item[1])
          .filter(Boolean)
          .join(' ');
      }
      return String(option[optionKeys.label]);
    },
    [optionKeys.label]
  );

  const isOptionEqualToValue = (option: T, selectedValue: T) => {
    if (typeof option !== 'string' && typeof selectedValue !== 'string') {
      return option.id === selectedValue.id;
    }
    return option === selectedValue;
  };

  const getOptionLabel = (option: T | string): string => {
    if (typeof option === 'string') {
      return option;
    }

    return getLabel(option);
  };

  const fillInput = useCallback(
    (value: T | T[]) => {
      if (Array.isArray(value)) {
        onChange(value);
        setAutocompleteValue(value);
      } else if (value?.[optionKeys.value]) {
        void onSearch(getLabel(value));
        onChange(value);
        setAutocompleteValue(value);
      }
    },
    [getLabel, onChange, onSearch, optionKeys.value]
  );

  useConstructor({
    callBack: () =>
      defaultValue &&
      setTimeout(() => {
        fillInput(defaultValue);
      }, 1),
  });

  const handleOnSearch = debounce((e, searchValue: null | string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset') {
      void onSearch(searchValue ?? '');
    }
  }, 300);

  const mergedOptionsWithValues = useMemo(() => {
    if (Array.isArray(autocompleteValue)) {
      const currentValues = autocompleteValue;
      const currentValuesIds = currentValues.map(item => item[optionKeys.value]);
      return [...options.filter(item => !currentValuesIds.includes(item[optionKeys.value])), ...currentValues];
    }

    if (typeof autocompleteValue === 'string') {
      return [...new Set([...options, autocompleteValue])] as T[];
    }

    return options;
  }, [autocompleteValue, optionKeys.value, options]);

  useEffect(() => {
    if (inputProps.value && !autocompleteValue) {
      fillInput(inputProps.value as T);
    }
  }, [autocompleteValue, fillInput, inputProps.value]);

  return (
    <MuiAutocomplete
      className={multiple ? 'autocomplete-multiline' : ''}
      id={htmlFor}
      data-testid={`${htmlFor}-test-id`}
      fullWidth
      multiple={!!multiple}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={mergedOptionsWithValues}
      loading={loading}
      disabled={disabled}
      clearIcon={<ClearIcon fontSize="small" />}
      onChange={(e, selectedValue, reason) => {
        if (selectedValue) {
          onChange(selectedValue as T);
          setAutocompleteValue(selectedValue as T);
        } else if (reason === 'clear') {
          onChange('');
          setAutocompleteValue(null);
        }
      }}
      value={autocompleteValue}
      onInputChange={handleOnSearch}
      defaultValue={defaultValue}
      {...(renderTags ? { renderTags } : {})}
      renderInput={params => {
        const Input = (
          <TextField
            {...params}
            aria-label={htmlFor}
            error={invalid}
            required={required}
            placeholder={multiple && autocompleteValue?.length ? '' : t(placeholder)}
            sx={{
              height: multiple ? 'auto' : 'inherit',

              '* input': {
                padding: '9.4px 14px !important',
                '-webkit-text-fill-color': disabled ? 'black !important' : 'unset',
                paddingLeft: '20px',
                marginBottom: '-4px',
              },
            }}
            {...inputProps}
            helperText={error?.message ? errorMessage || error?.message : error?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && hasSpinner ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ marginRight: '3px', marginTop: '-3px !important' }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
        return label ? (
          <FormInputLabel htmlFor={htmlFor} disabled={disabled} required={required} label={label}>
            {Input}
          </FormInputLabel>
        ) : (
          Input
        );
      }}
    />
  );
};

export default memo(Autocomplete);
