import { Box, styled, Paper, Typography } from '@mui/material';
import { THEME } from '../../../constants/theme';

export const AvatarWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: 100,
  borderBottom: `1px solid ${theme.palette.lavender.lighter}`,
  display: 'flex',
  alignItems: 'center',
  padding: '0 17px',
  boxSizing: 'border-box',
  marginBottom: '10px',
  // overflow: 'hidden',
  '& > *:first-of-type': {
    cursor: 'pointer',
    padding: '20px 0',
  },
  '& > *:hover > :last-of-type': {
    display: 'initial',
  },
}));
export const AvatarOverlay = styled(Paper)(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  left: '18px',
  top: '77px',
  minWidth: '230px',
  padding: '20px',
  zIndex: 99,
  a: {
    marginTop: '10px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '> * > :nth-child(1)': {
    color: theme.palette.black.main,
  },
}));
export const OverlaySubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === THEME.LIGHT ? theme.palette.text1.lighter : theme.palette.text1.main,
  marginBottom: '20px',
}));
