import React, { memo, useEffect } from 'react';
import { PageLoaderProps } from './component.types';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Choose from '../choose';
import When from '../when';

const PageLoader = ({ children, isLoading }: PageLoaderProps) => {
  useEffect(() => {}, [isLoading]);

  return (
    <Choose>
      <When condition={isLoading}>
        <Backdrop sx={{ color: '#fff', zIndex: 100 }} open>
          <div aria-label="loading">
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </When>
      <When unless>{children}</When>
    </Choose>
  );
};

export default memo(PageLoader);
