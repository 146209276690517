import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../utils/widgets/layouts/page-header';
import PageContainer from '../../../utils/widgets/layouts/page-container';
import { PageBody, PageWrapper } from '../../../utils/widgets/cv/styled';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import {
  UserResource,
  ApiEmployeeService,
  ApiUserService,
  ChangeUserPasswordRequest,
  UserRequest,
  UpdateEmployeePhotoRequest,
  EmployeeProfilePhotoResource,
} from '../../../api/main';

import useFetch from '../../../utils/hooks/use-fetch';
import PageLoader from '../../../utils/widgets/page-loader';
import { useSnackbar } from 'notistack';
import useErrorHandler from '../../../utils/hooks/use-error-handler';
import ChangePasswordForm from '../../../utils/widgets/employee/change-password-form';
import EmployeeProfileForm from '../../../utils/widgets/employee/profile-form';
import { OptionType } from '../../../utils/widgets/users-select/component.types';
import { UploadProfile } from '../../../utils/widgets/upload-profile';
import { useAppDispatch } from '../../../store/hooks';
import { storeActions } from '../../../store';

const EmployeeProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [profileImage, setProfileImage] = useState<string | undefined>(undefined);
  const handleError = useErrorHandler();
  const getEmployee = useFetch(ApiEmployeeService.apiEmployeeShow);
  const updateEmployee = useFetch<UserRequest, UserResource>(payload =>
    ApiEmployeeService.apiEmployeeUpdate(Number(userId), payload)
  );

  const changeEmployeePassword = useFetch<ChangeUserPasswordRequest, void>(payload =>
    ApiUserService.apiUserChangePassword(Number(userId), payload)
  );
  const employeeData = useMemo(() => {
    return getEmployee.loaded ? (getEmployee.result as UserResource) : null;
  }, [getEmployee.loaded, getEmployee.result]);

  const uploadProfilePhoto = useFetch<
    { userId: number; formData: UpdateEmployeePhotoRequest },
    EmployeeProfilePhotoResource
  >(({ formData, userId: id }) => ApiEmployeeService.apiEmployeeUploadProfilePhoto(id, formData));
  const removeProfilePhoto = useFetch(ApiEmployeeService.apiEmployeeDeleteProfilePhoto);

  useEffect(() => {
    if (!getEmployee.loaded && !getEmployee.loading) {
      void getEmployee.call(Number(userId));
    }
  }, [getEmployee, userId]);

  useEffect(() => {
    if (employeeData) {
      setProfileImage(employeeData?.profile_photo);
    }
  }, [employeeData]);

  const onSubmit = (payload: UserRequest & Pick<UserResource, 'location'>) => {
    const { slack_id: slackID } = payload;
    return updateEmployee
      .call({
        ...payload,
        start_date: payload.start_date || undefined,
        slack_id: slackID || null,
        status: (payload.status as unknown as { value: number })?.value || undefined,
        location_id: payload.location?.id,
        phone: payload.phone || null,
        role: String((payload.role as unknown as OptionType).value),
        birthday: payload.birthday,
      } as UserRequest)
      .then(() => {
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        return Promise.resolve(payload);
      })
      .catch(handleError);
  };
  const onChangePassword = (payload: FieldValues) =>
    changeEmployeePassword
      .call({ new_password: (payload as ChangeUserPasswordRequest).new_password })
      .then(() => {
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        return Promise.resolve(payload);
      })
      .catch(handleError);

  return (
    <PageContainer>
      <PageHeader onBack={() => navigate(-1)} />
      <PageBody>
        <PageWrapper>
          <PageLoader isLoading={!employeeData}>
            <>
              <EmployeeProfileForm onSubmit={onSubmit} data={employeeData} isLoading={updateEmployee.loading} />
              <Divider />
              <Stack direction="row" sx={{ ' > *': { flexGrow: 1 } }}>
                <ChangePasswordForm onSubmit={onChangePassword} isLoading={changeEmployeePassword.loading} />
                <Divider orientation="vertical" sx={{ flexGrow: '0' }} />

                <UploadProfile
                  image={profileImage ?? employeeData?.profile_photo}
                  onUpload={image => {
                    if (image) {
                      uploadProfilePhoto
                        .call({
                          formData: { photo: image, file: image } as UpdateEmployeePhotoRequest,
                          userId: Number(userId || employeeData?.id),
                        })
                        .then(data => {
                          setProfileImage(data.url);

                          if (!userId) {
                            dispatch(
                              storeActions.clientProfile.setClientProfile({ ...employeeData, profile_photo: data.url })
                            );
                          }
                        })
                        .catch(handleError);
                    }
                  }}
                  onRemove={() => {
                    removeProfilePhoto
                      .call(Number(userId || employeeData?.id))
                      .then(() => {
                        setProfileImage('');
                        if (!userId)
                          dispatch(storeActions.clientProfile.setClientProfile({ ...employeeData, profile_photo: '' }));
                      })
                      .catch(handleError);
                  }}
                  isLoading={uploadProfilePhoto.loading}
                />
              </Stack>
            </>
          </PageLoader>
        </PageWrapper>
      </PageBody>
    </PageContainer>
  );
};

export default EmployeeProfilePage;
