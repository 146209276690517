import { Pagination } from '@mui/material';
import { useGridApiContext, useGridSelector, gridPageSelector, gridPageCountSelector } from '@mui/x-data-grid';

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      sx={{
        marginRight: 'auto',
      }}
      count={pageCount}
      page={page + 1}
      onChange={(_, value) => {
        apiRef.current.setPage(value - 1);
      }}
      variant="outlined"
      shape="rounded"
    />
  );
};

export default CustomPagination;
