import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Paper, SelectChangeEvent, Stack, styled, Typography } from '@mui/material';
import DashboardLayout from '../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../utils/widgets/layouts/user-page-header';
import Button from '../../utils/widgets/button';
import { Add } from '@mui/icons-material';
import useErrorHandler from '../../utils/hooks/use-error-handler';
import { VACATIONSTYPE } from '../../constants/report';
import { useParams } from 'react-router-dom';
import { FiltersBox, GridHeader, StyledPaper } from '../../utils/widgets/layouts/reports-layout';
import DateRangePicker from '../../utils/widgets/date-range-picker';
import When from '../../utils/widgets/when';
import { addMinutes, format } from 'date-fns';
import VacationsTabs from '../../utils/widgets/vacations/vacations-tabs';
import UserVacationsGrid from '../../utils/widgets/grids/user-vacations-grid';
import {
  EmployeeActivityResource,
  ChangeEmployeeActivityStatusRequest,
  EmployeeActivitiesCollection,
  ApiDayOffService,
  ApiSickLeaveService,
  ApiVacationService,
  HolidayResource,
  HolidayRequest,
  ApiEmployeeService,
  ApiSpecialVacationService,
} from '../../api/main';
import useTable from '../../utils/hooks/use-table';
import useFetch from '../../utils/hooks/use-fetch';
import {
  AdminVacaionCreateRequest,
  AdminVacationChangeStatusRequest,
  VacationRequest,
  TableFilters,
} from './component.types';
import VacationModalForm from '../../utils/widgets/vacations/vacation-modal-form';
import { useSnackbar } from 'notistack';
import UserHolidaysGrid from '../../utils/widgets/grids/user-holidays-grid';
import { ButtonText } from '../admin/employee-overview/styled';
import HolidayModalForm from '../../utils/widgets/vacations/holiday-modal-form';
import { HolidayForm } from '../../utils/widgets/vacations/holiday-modal-form/component.types';
import { useUser } from '../../utils/hooks/use-user';
import { USERTYPE } from '../../constants/common';
import { ApiHolidayService } from '../../api/main/services/ApiHolidayService';
import { HolidaysCollection } from '../../api/main/models/HolidaysCollection';
import UsersSelect from '../../utils/widgets/users-select';
import LocationSelect from '../../utils/widgets/location-select';
import { debounce } from '../../utils/tools/debounce';
import SearchInputBox from '../../utils/widgets/search-input-box';
import { OptionType } from '../../utils/widgets/users-select/component.types';
import Info from '@mui/icons-material/Info';
import { useCollidingBox } from '../../utils/hooks/use-colliding-box';
import ConfirmationDialog from '../../utils/widgets/confirmation-dialog';
import { formatHoursToDayAndHours } from '../../utils/tools/date';

const DayOffAlertBox = styled(Paper)({
  position: 'absolute',
  top: '100%',
  left: '0',
  display: 'none',
  flexDirection: 'column',
  padding: '20px',
  zIndex: 99,
});

const VacationsPage = () => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const { clearError, checkForCollding, collidingInfo } = useCollidingBox();
  const { enqueueSnackbar } = useSnackbar();
  const { vacationType = VACATIONSTYPE.Index } = useParams<{ vacationType: VACATIONSTYPE }>();
  const { userType, clientProfile, isHR } = useUser();
  const { data, page, setPage, setData, pageSize, filters, setFilters } = useTable<
    EmployeeActivitiesCollection,
    TableFilters
  >({
    initialData: { meta: { total: 0 } },
    initialFilters: { userId: null },
    initialPage: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<HolidayResource | null>(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [capacityInfo, setCapacityInfo] = useState<{
    fullName: string;
    exceedDays: number;
    status: ChangeEmployeeActivityStatusRequest.status;
    id: number;
  } | null>(null);
  // Data getters
  const getVacations = useFetch<VacationRequest, EmployeeActivitiesCollection>(({ page: newPage, userId, from, to }) =>
    ApiVacationService.apiVacationIndex(newPage, pageSize, userId, undefined, undefined, from, to, 'DESC', 'start_time')
  );
  const getSpecialVacations = useFetch<VacationRequest, EmployeeActivitiesCollection>(
    ({ page: newPage, userId, from, to }) =>
      ApiSpecialVacationService.specialVacationIndex(newPage, pageSize, userId, undefined, from, to)
  );
  const getDayOffs = useFetch<VacationRequest, EmployeeActivitiesCollection>(({ page: newPage, userId, from, to }) =>
    ApiDayOffService.apiDayOffIndex(newPage, pageSize, userId, undefined, from, to, 'DESC', 'start_time')
  );
  const getSickLeave = useFetch<VacationRequest, EmployeeActivitiesCollection>(({ page: newPage, userId, from, to }) =>
    ApiSickLeaveService.apiSickLeaveIndex(
      newPage,
      pageSize,
      userId,
      undefined,
      undefined,
      from,
      to,
      'DESC',
      'start_time'
    )
  );
  const getHolidays = useFetch<VacationRequest, HolidaysCollection>(({ page: newPage, locationId, name, from, to }) =>
    ApiHolidayService.apiHolidayIndex(newPage, pageSize, locationId, name, from, to)
  );
  // Create enpoints
  const addVacation = useFetch(ApiVacationService.apiVacationCreate);
  const addSpecialVacation = useFetch(ApiSpecialVacationService.specialVacationCreate);
  const addDayOff = useFetch(ApiDayOffService.apiDayOffCreate);
  const addSickLeave = useFetch(ApiSickLeaveService.apiSickLeaveCreate);
  const addHoliday = useFetch(ApiHolidayService.apiHolidayCreate);
  const editHoliday = useFetch<HolidayRequest & { id?: number }, HolidayResource>(({ id, ...payload }) =>
    ApiHolidayService.apiHolidayUpdate(Number(id), payload)
  );

  // Change statues endpoints
  const changeVacationStatus = useFetch<AdminVacationChangeStatusRequest, EmployeeActivityResource>(
    ({ vacationId, status }) => ApiVacationService.apiVacationChangeStatus(vacationId, { status })
  );
  const changeSpecialVacationStatus = useFetch<AdminVacationChangeStatusRequest, EmployeeActivityResource>(
    ({ vacationId, status }) => ApiSpecialVacationService.specialVacationChangeStatus(vacationId, { status })
  );
  const changeDayOffStatus = useFetch<AdminVacationChangeStatusRequest, EmployeeActivityResource>(
    ({ vacationId, status }) => ApiDayOffService.apiDayOffChangeStatus(vacationId, { status })
  );
  const changeSickLiveStatus = useFetch<AdminVacationChangeStatusRequest, EmployeeActivityResource>(
    ({ vacationId, status }) => ApiSickLeaveService.apiSickLeaveChangeStatus(vacationId, { status })
  );

  const getVacationsInfo = useFetch(ApiEmployeeService.apiEmployeeRemainedBreaks);
  const dayOffRemained = (getVacationsInfo.result as Record<string, number>)?.day_off_remained;

  const fetchDataMap = {
    [VACATIONSTYPE.Index]: getVacations,
    [VACATIONSTYPE.DayOffs]: getDayOffs,
    [VACATIONSTYPE.SickLeave]: getSickLeave,
    [VACATIONSTYPE.Holidays]: getHolidays,
    [VACATIONSTYPE.EditHoliday]: getHolidays,
    [VACATIONSTYPE.SpecialVacation]: getSpecialVacations,
  };
  const addMap = {
    [VACATIONSTYPE.Index]: addVacation,
    [VACATIONSTYPE.DayOffs]: addDayOff,
    [VACATIONSTYPE.SickLeave]: addSickLeave,
    [VACATIONSTYPE.Holidays]: addHoliday,
    [VACATIONSTYPE.EditHoliday]: editHoliday,
    [VACATIONSTYPE.SpecialVacation]: addSpecialVacation,
  };
  const changeStatusMap = {
    [VACATIONSTYPE.Index]: changeVacationStatus,
    [VACATIONSTYPE.DayOffs]: changeDayOffStatus,
    [VACATIONSTYPE.SickLeave]: changeSickLiveStatus,
    [VACATIONSTYPE.Holidays]: changeVacationStatus,
    [VACATIONSTYPE.EditHoliday]: changeVacationStatus,
    [VACATIONSTYPE.SpecialVacation]: changeSpecialVacationStatus,
  };

  const handleSubmit = (formData: AdminVacaionCreateRequest & HolidayForm) => {
    const isDayOff = vacationType === VACATIONSTYPE.DayOffs;
    const startDate = new Date(formData.start_time);
    const partOfDayValue = formData.part_of_day?.value || 8 * 60;
    const dateFormat = isDayOff ? 'Y-MM-dd HH:mm' : 'Y-MM-dd';
    const startTime = format(addMinutes(startDate, partOfDayValue), dateFormat);
    const description = formData.special_vacation_type?.label;
    clearError();
    return addMap[selectedItem ? VACATIONSTYPE.EditHoliday : vacationType]
      .call({
        start_time: startTime,
        end_time: format(
          new Date(
            vacationType === VACATIONSTYPE.DayOffs
              ? addMinutes(startDate, Number(formData.hours.value * 60) + partOfDayValue)
              : formData.end_time || new Date().toString()
          ),
          dateFormat
        ),
        description,
        user_id: formData.employee?.id || Number(clientProfile?.id),
        date: startTime,
        hours: formData.hours?.value,
        location_id: formData.location?.id,
        name: formData.name,
        id: selectedItem?.id,
      })
      .then(item => {
        setFilters({ ...filters });
        setIsOpen(false);
        setIsEditOpen(false);
        setSelectedItem(null);
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        return Promise.resolve(item);
      })
      .catch(checkForCollding);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>, row: EmployeeActivityResource) => {
    const value = event?.target.value || 1;
    const changeStatus = () =>
      changeStatusMap[vacationType]
        .call({ status: value as ChangeEmployeeActivityStatusRequest.status, vacationId: Number(row.id) })
        .then(result => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });

          setData({
            ...data,
            data: data.data?.map(item => (item.id === row.id ? result : item)),
          });
        })
        .catch(handleError);

    if ((value as number) === 2 && vacationType === VACATIONSTYPE.Index) {
      setData({
        ...data,
        data: data.data?.map(item => (item.id === row.id ? { ...item, isLoading: true } : item)),
      });
      getVacationsInfo
        .call(Number(row.user?.id))
        .then(info => {
          const { vacation_remained: remained = 0 } = info;
          const requestedHours = row.duration_in_hours || 0;
          const isPending = row.status?.id === 1;
          const actualRemained = remained + (isPending ? requestedHours : 0);
          if (actualRemained - requestedHours < 0) {
            setCapacityInfo({
              fullName: `${row.user?.first_name} ${row.user?.last_name}`,
              exceedDays: Math.abs(actualRemained - requestedHours),
              status: 2,
              id: Number(row.id),
            });
            return;
          }
          return changeStatus();
        })
        .catch(handleError)
        .finally(() => {
          setData(d => ({
            ...d,
            data: d.data?.map(item => (item.id === row.id ? { ...item, isLoading: false } : item)),
          }));
        });
    } else {
      return changeStatus();
    }
  };

  const buttonText: Record<VACATIONSTYPE, string> = {
    [VACATIONSTYPE.Index]: isHR ? t('form:buttons.add-vacation') : t('form:buttons.request-vacation'),
    [VACATIONSTYPE.DayOffs]: isHR ? t('form:buttons.add-day-offs') : t('form:buttons.request-day-offs'),
    [VACATIONSTYPE.SickLeave]: isHR ? t('form:buttons.add-sick-leave') : t('form:buttons.request-sick-leave'),
    [VACATIONSTYPE.Holidays]: t('form:buttons.add-holiday'),
    [VACATIONSTYPE.EditHoliday]: t('form:buttons.edit-holiday'),
    [VACATIONSTYPE.SpecialVacation]: t('form:buttons.add-special-vacation'),
  };

  useEffect(() => {
    const handleSuccess = (result: EmployeeActivitiesCollection) => setData(result);

    fetchDataMap[vacationType]
      .call({
        page: page + 1,
        ...filters,
        from: filters.from as string,
        to: filters.to as string,
        locationId: isHR ? (filters.locationId ? Number(filters.locationId) : undefined) : clientProfile?.location?.id,
        userId: !isHR
          ? clientProfile?.id
          : filters.userId
          ? (filters.userId as unknown as OptionType).value
          : undefined,
        name: filters.name || undefined,
      })
      .then(handleSuccess)
      .catch(handleError);
    return () => {
      fetchDataMap[vacationType].cancel();
    };
  }, [page, filters, setData, vacationType]);

  useEffect(() => {
    if (clientProfile?.id && !isHR && !getVacationsInfo.loading) {
      void getVacationsInfo.call(clientProfile?.id);
    }
  }, [clientProfile?.id, data, !isHR]);

  const handleEdit = (item: HolidayResource) => {
    setSelectedItem(item);
    setIsEditOpen(true);
  };

  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, name: event.target.value }));
  }, 300);

  const DayOffButton = () => (
    <Stack
      direction="row"
      justifyContent="flex-end"
      position="relative"
      sx={{ '&:hover > :last-child': { display: 'flex' } }}
    >
      <Button
        variant="outlined"
        color="inherit"
        hasIcon
        onClick={() => setIsOpen(true)}
        loading={getVacationsInfo.loading}
        disabled={dayOffRemained <= 0}
      >
        <Stack direction="row" alignItems="center">
          {userType === USERTYPE.HR && <Add sx={{ marginRight: '5px' }} />}
          <Info color="error" sx={{ marginRight: '5px' }} />
          <ButtonText>{buttonText[vacationType]}</ButtonText>
        </Stack>
      </Button>
      <DayOffAlertBox>
        <Typography fontWeight="bold" marginBottom="16px">
          Out off capacity
        </Typography>
        <Typography>You can't request as you have no more remained day-offs.</Typography>
      </DayOffAlertBox>
    </Stack>
  );
  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:vacations')}>
        <VacationsTabs
          onChange={() => {
            setData({});
            setFilters({ userId: null });
          }}
        />
      </UserPageHeader>
      <StyledPaper>
        <When condition={userType === USERTYPE.HR || vacationType !== VACATIONSTYPE.Holidays}>
          <>
            <GridHeader>
              {isHR && vacationType === VACATIONSTYPE.Holidays ? (
                <SearchInputBox onChange={debouncedChangeHandler} />
              ) : (
                <div />
              )}
              <FiltersBox>
                {!isHR && vacationType === VACATIONSTYPE.DayOffs && dayOffRemained <= 0 ? (
                  <DayOffButton />
                ) : (
                  <Button
                    variant="outlined"
                    color="inherit"
                    hasIcon
                    onClick={() => setIsOpen(true)}
                    loading={getVacationsInfo.loading}
                  >
                    <Stack direction="row" alignItems="center">
                      {userType === USERTYPE.HR && <Add sx={{ marginRight: '5px' }} />}
                      <ButtonText>{buttonText[vacationType]}</ButtonText>
                    </Stack>
                  </Button>
                )}
                {isHR && vacationType === VACATIONSTYPE.Holidays && (
                  <>
                    <DateRangePicker
                      label="Filter by date"
                      onClear={() => setFilters({ ...filters, from: undefined, to: undefined })}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setFilters({
                            ...filters,
                            from: format(new Date(startDate?.toString()), 'yyyy-MM-dd'),
                            to: format(new Date(endDate?.toString()), 'yyyy-MM-dd'),
                          });
                      }}
                    >
                      {filters.from && filters.to
                        ? `${format(new Date(filters.from), 'MMM dd')} - ${format(new Date(filters.to), 'MMM dd')}`
                        : ''}
                    </DateRangePicker>
                    <LocationSelect
                      onChange={event => setFilters({ ...filters, locationId: event.target.value })}
                      value={filters.locationId || ''}
                    />
                  </>
                )}
                {isHR && vacationType !== VACATIONSTYPE.Holidays && (
                  <>
                    <UsersSelect
                      value={filters.userId as unknown as OptionType}
                      onChange={value => {
                        setFilters({ ...filters, userId: (value as unknown as string) || undefined });
                      }}
                    />
                    <DateRangePicker
                      key={vacationType}
                      label="Filter by date"
                      onClear={() => setFilters({ ...filters, from: undefined, to: undefined })}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setFilters({
                            ...filters,
                            from: format(new Date(startDate?.toString()), 'yyyy-MM-dd'),
                            to: format(new Date(endDate?.toString()), 'yyyy-MM-dd'),
                          });
                      }}
                    >
                      {filters.from && filters.to
                        ? `${format(new Date(filters.from), 'MMM dd')} - ${format(new Date(filters.to), 'MMM dd')}`
                        : ''}
                    </DateRangePicker>
                  </>
                )}
              </FiltersBox>
            </GridHeader>
            <Divider sx={{ margin: '30px 0' }} />
          </>
        </When>
        <When condition={vacationType !== VACATIONSTYPE.Holidays}>
          <UserVacationsGrid
            vacationType={vacationType}
            showUser={userType === USERTYPE.HR}
            page={page}
            onPageChange={setPage}
            data={data}
            isLoading={
              getVacations.loading || getSpecialVacations.loading || getDayOffs.loading || getSickLeave.loading
            }
            handleStatusChange={handleStatusChange}
          />
        </When>
        {vacationType !== VACATIONSTYPE.Holidays ? (
          <VacationModalForm
            key={String(vacationType) + String(isOpen)}
            title={buttonText[vacationType]}
            vacationType={vacationType}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsOpen(false);
              clearError();
            }}
            open={isOpen}
            dayOffRemained={vacationType === VACATIONSTYPE.DayOffs ? dayOffRemained : undefined}
            collidingInfo={collidingInfo}
          />
        ) : (
          <HolidayModalForm
            key={String(vacationType) + String(isOpen) + String(isEditOpen)}
            title={selectedItem ? t('form:buttons.edit-holiday') : buttonText[vacationType]}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsOpen(false);
              setIsEditOpen(false);
            }}
            open={isOpen || isEditOpen}
            initialData={selectedItem || undefined}
          />
        )}

        <When condition={vacationType === VACATIONSTYPE.Holidays}>
          <UserHolidaysGrid
            page={page}
            onPageChange={setPage}
            data={data}
            isLoading={getHolidays.loading}
            handleDelete={() => {
              setFilters({ ...filters });
            }}
            handleEdit={handleEdit}
          />
        </When>
        <ConfirmationDialog
          width="30%"
          padding="10px"
          onOkText="Approve Anyway"
          onOk={() => {
            if (capacityInfo) {
              const { status, id } = capacityInfo;
              changeStatusMap[vacationType]
                .call({ status, vacationId: id })
                .then(result => {
                  enqueueSnackbar(t('form:general-submission.success'), {
                    variant: 'success',
                  });
                  setData({
                    ...data,
                    data: data.data?.map(item => (item.id === id ? result : item)),
                  });
                  setCapacityInfo(null);
                })
                .catch(handleError);
            }
          }}
          onCancel={() => {
            setCapacityInfo(null);
          }}
          title="Vacation Capacity Exceed"
          open={Boolean(capacityInfo)}
        >
          <Typography variant="h4" fontWeight="normal">
            Vacation capacity for {capacityInfo?.fullName} is exceeded by{' '}
            {capacityInfo?.exceedDays && formatHoursToDayAndHours(capacityInfo?.exceedDays)}.
          </Typography>
        </ConfirmationDialog>
      </StyledPaper>
    </DashboardLayout>
  );
};

export default VacationsPage;
