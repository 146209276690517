import React, { memo, useContext } from 'react';
import { styled, Switch } from '@mui/material';

import sunLightBg from '../../../../assets/icons/sun-light-bg.svg';
import moonLightBg from '../../../../assets/icons/moon-light-bg.svg';
import { StyleProviderContext } from '../../../../styles';
import { THEME } from '../../../../constants/theme';

const ToggleSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '&:after': {
    content: `url(${moonLightBg}) url(${sunLightBg})`,
    width: 0,
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    color: theme.palette.yellow.main,
    transitionDuration: '300ms',
    '& span': {
      backgroundImage: `url(${sunLightBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '&.Mui-checked': {
      '& span': {
        backgroundImage: `url(${moonLightBg})`,
      },
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === THEME.LIGHT ? theme.palette.gray.main : theme.palette.lightWhite.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === THEME.LIGHT ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === THEME.LIGHT ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === THEME.LIGHT ? theme.palette.lines.main : theme.palette.lightWhite.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ToggleThemeSwitch = () => {
  const { mode, setMode } = useContext(StyleProviderContext);

  return (
    <ToggleSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      data-testid="toggle-theme"
      checked={mode !== THEME.LIGHT}
      onChange={() => setMode(mode === THEME.LIGHT ? THEME.DARK : THEME.LIGHT)}
    />
  );
};

export default memo(ToggleThemeSwitch);
