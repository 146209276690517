import './i18n';
import React, { FC, memo, ReactElement, useEffect } from 'react';
import { NotificationProvider, StoreProvider, StyleProvider } from './providers';
import Meta from './utils/widgets/meta';
import { useTranslation } from 'react-i18next';
import { purgeStoredState } from 'redux-persist';
import { persistConfig } from './store';
import { LOCALSTORAGEKEYS } from './constants/local-storage-keys';
import pkg from '../package.json';

type AppConfigsProp = {
  children: ReactElement | ReactElement[];
};

/* TODO-qp::
 *    1) prefetch assets
 *    5) encrypt persist store
 * */

const AppConfigs: FC<AppConfigsProp> = ({ children }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const storedVersion = localStorage.getItem(LOCALSTORAGEKEYS.APP_VERSION);
    if (storedVersion !== pkg.version) {
      purgeStoredState(persistConfig);
      window.location.reload();
    }
    localStorage.setItem(LOCALSTORAGEKEYS.APP_VERSION, pkg.version);
  }, []);

  return (
    <StoreProvider>
      <Meta>
        <title>{t('common:app-name')}</title>
        <meta name="description" content="Parent component" />
      </Meta>
      <StyleProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </StyleProvider>
    </StoreProvider>
  );
};

export default memo(AppConfigs);
