import { useEffect, useRef } from 'react';
import { styled, Box, Autocomplete, TextField, createFilterOptions } from '@mui/material';

import { ApiProjectService, AdminProjectResource } from '../../../api/main';
import useErrorHandler from '../../hooks/use-error-handler';
import useFetch from '../../hooks/use-fetch';
import { OptionType, ProjectsSelectProps } from './component.types';
import RenderBasedOnTheme from '../render-based-on-theme';
import { ReactComponent as FilterIconLight } from '../../../assets/icons/filter-light.svg';
import { ReactComponent as FilterIconDark } from '../../../assets/icons/filter-dark.svg';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (option: OptionType) => option.label,
});

const StyledSelect = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.fieldsBorder.main}`,
  padding: '0px 15px',
  paddingRight: '5px',
  overflow: 'hidden',
  minWidth: '225px',
  minHeight: '42px',
  '& > svg': {
    flexShrink: 0,
  },
  '.MuiAutocomplete-popper': {
    width: 'auto !important',
  },
}));

const ProjectsSelect: React.FC<ProjectsSelectProps> = ({ value, onChange }) => {
  const handleError = useErrorHandler();
  const getAllProjects = useFetch(() => ApiProjectService.apiProjectIndex(1, 100000));
  const projectsOptionsRef = useRef<AdminProjectResource[]>([]);
  useEffect(() => {
    if (!getAllProjects.loaded && !getAllProjects.loading) {
      getAllProjects
        .call()
        .then(({ data = [] }) => {
          projectsOptionsRef.current = data;
        })
        .catch(handleError);
    }
  }, [handleError]);

  return (
    <StyledSelect>
      <RenderBasedOnTheme dark={<FilterIconDark />} light={<FilterIconLight />} />
      <Autocomplete
        filterOptions={filterOptions}
        value={value}
        onChange={(e, val) => {
          onChange(val);
        }}
        disablePortal
        id="combo-box-demo"
        renderOption={(props, option: OptionType) => {
          return (
            <li {...props} key={`${option.value}${option.label}`}>
              {option.label}
            </li>
          );
        }}
        options={projectsOptionsRef.current.map(item => ({
          value: item.id,
          label: String(item.name),
        }))}
        sx={{
          width: '90%',
          input: {
            margin: 0,
          },
          '.MuiOutlinedInput-root.MuiOutlinedInput-root': {
            padding: '0px !important',
            paddingRight: '47px !important',
          },
        }}
        renderInput={params => (
          <TextField
            placeholder={'All Project'}
            {...params}
            sx={{
              fieldset: {
                display: 'none',
              },
            }}
          />
        )}
      />
    </StyledSelect>
  );
};
export default ProjectsSelect;
