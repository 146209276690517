import { Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { USERTYPE } from '../../../../constants/common';
import { storeActions } from '../../../../store';
import { useAppSelector } from '../../../../store/hooks';
import useForm from '../../../hooks/use-form';
import Button from '../../button';
import FormItemText from '../../forms/form-item-text';
import InputPassword from '../../forms/input-password';
import When from '../../when';
import { ChangePasswordFormProps } from './component.types';

const ChangePasswordFrom: React.FC<ChangePasswordFormProps> = ({ onSubmit, isLoading }) => {
  const user = useAppSelector(storeActions.user.getUser);
  const isHR = user.userType === USERTYPE.HR;
  const { t } = useTranslation();
  const passwordRule = Yup.string().min(8).max(50);
  const changePasswordSchema = Yup.object({
    old_password: isHR ? passwordRule : passwordRule.required(),
    new_password: Yup.string().min(8).max(50).required(),
    passwordConfirmation: Yup.string().compareWith({
      fieldName: 'new_password',
    }),
  });
  const { handleSubmit, control } = useForm({
    name: 'change-password',
    schema: changePasswordSchema,
  });
  return (
    <form aria-label="employee-change-password" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ margin: '40px 0' }}>
        <Typography variant="h1" component="p">
          {t('form:change-password')}
          <Edit sx={{ verticalAlign: 'text-top', marginLeft: '15px' }} />
        </Typography>
      </Box>
      <Box sx={{ width: '300px' }}>
        <When condition={!isHR}>
          <FormItemText defaultValue="" control={control} required label={t('form:old-password')} name="old_password">
            <InputPassword />
          </FormItemText>
        </When>
        <FormItemText defaultValue="" control={control} required label={t('form:new-password')} name="new_password">
          <InputPassword />
        </FormItemText>
        <FormItemText
          defaultValue=""
          control={control}
          required
          label={t('form:confirm-new-password')}
          name="passwordConfirmation"
        >
          <InputPassword />
        </FormItemText>
        <Button loading={isLoading} type="submit" sx={{ margin: '40px 0' }}>
          {t('form:change')}
        </Button>
      </Box>
    </form>
  );
};

export default ChangePasswordFrom;
