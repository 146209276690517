/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChangeEmployeeActivityStatusRequest = {
    /**
     * 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     */
    status: ChangeEmployeeActivityStatusRequest.status;
};

export namespace ChangeEmployeeActivityStatusRequest {

    /**
     * 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

