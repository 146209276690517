import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import DashboardLayout from '../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../utils/widgets/layouts/user-page-header';
import SearchInputBox from '../../../utils/widgets/search-input-box';
import { debounce } from '../../../utils/tools/debounce';
import Button from '../../../utils/widgets/button';
import { Add } from '@mui/icons-material';
import { GridHeader, StyledPaper } from '../../../utils/widgets/layouts/reports-layout';
import ProjectsDetailedGrid from '../../../utils/widgets/grids/projects-detailed-grid';
import ProjectViewModal from '../../../utils/widgets/projects/project-view-modal';
import { AdminProjectResource, ApiProjectService, ProjectRequest } from '../../../api/main';
import CreateProjectModal from '../../../utils/widgets/projects/create-project-modal';
import useFetch from '../../../utils/hooks/use-fetch';
import useErrorHandler from '../../../utils/hooks/use-error-handler';
import { CreateProjectForm } from '../../../utils/widgets/projects/create-project-modal/component.types';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { ButtonText } from '../employee-overview/styled';

const Projects = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isProjectViewModalOpen, setIsProjectViewModalOpen] = useState(false);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
  const [filters, setFilters] = useState<Record<string, string | undefined>>({});
  const projectItem = useRef<AdminProjectResource | undefined>(undefined);
  const handleError = useErrorHandler();
  const createProject = useFetch(ApiProjectService.apiProjectCreate);
  const updateProject = useFetch<ProjectRequest, AdminProjectResource>(payload =>
    ApiProjectService.apiProjectUpdate(Number(projectItem.current?.id), payload)
  );

  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, searchQuery: event.target.value }));
  }, 300);
  const handleEdit = (item: AdminProjectResource) => {
    setIsCreateProjectModalOpen(true);
    projectItem.current = item;
  };
  const handleView = (item: AdminProjectResource) => {
    setIsProjectViewModalOpen(true);
    projectItem.current = item;
  };
  const handleSubmit = (values: CreateProjectForm) => {
    const { start_date: startDate, end_date: endDate, target, name, status, is_public: isPublic } = values;

    return (projectItem.current ? updateProject : createProject)
      .call({
        name: name,
        target: target || (null as unknown as number),
        start_date: format(new Date(startDate), 'Y-MM-dd'),
        end_date: endDate ? format(new Date(endDate), 'Y-MM-dd') : (null as unknown as string),
        status: status.value as ProjectRequest.status,
        is_public: isPublic,
      })
      .then(() => {
        setFilters({ ...filters });
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
      })
      .catch(handleError);
  };
  const forceUpdateData = () => setFilters({ ...filters });
  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:projects')} />
      <StyledPaper>
        <GridHeader>
          <SearchInputBox onChange={debouncedChangeHandler} />
          <Button
            variant="outlined"
            color="inherit"
            hasIcon
            onClick={() => {
              setIsCreateProjectModalOpen(true);
            }}
          >
            <Stack direction="row" alignItems="center">
              <Add sx={{ marginRight: '5px' }} />
              <ButtonText>{t('common:create-project')}</ButtonText>
            </Stack>
          </Button>
        </GridHeader>
        <Divider sx={{ margin: '30px 0' }} />
        <ProjectsDetailedGrid filters={filters} handleEdit={handleEdit} handleView={handleView} />
        <ProjectViewModal
          open={isProjectViewModalOpen}
          onClose={() => {
            setIsProjectViewModalOpen(false);
            projectItem.current = undefined;
          }}
          data={projectItem.current}
        />
        <CreateProjectModal
          isLoading={createProject.loading || updateProject.loading}
          onUpdate={forceUpdateData}
          key={String(isCreateProjectModalOpen) + String(isProjectViewModalOpen)}
          initialData={projectItem.current}
          open={isCreateProjectModalOpen}
          title={projectItem.current ? t('common:edit-project') : t('common:create-project')}
          onCancel={() => {
            setIsCreateProjectModalOpen(false);
            projectItem.current = undefined;
          }}
          onSubmit={handleSubmit}
        />
      </StyledPaper>
    </DashboardLayout>
  );
};

export default Projects;
