/* eslint-disable @typescript-eslint/naming-convention */

import React, { useMemo } from 'react';
import useFetch from '../../utils/hooks/use-fetch';
import { ApiCvService, CVRequest, CVResource } from '../../api/main';
import { useNavigate, useParams } from 'react-router-dom';
import useConstructor from '../../utils/hooks/use-constructor';
import PageHeader from '../../utils/widgets/layouts/page-header';
import { appRoutes } from '../../providers/routes';
import PreviewCV from '../../utils/widgets/cv/preview-cv';
import PageContainer from '../../utils/widgets/layouts/page-container';
import { PageBody, PageWrapper } from '../../utils/widgets/cv/styled';
import { EducationForm } from '../../utils/widgets/cv/education-form/component.types';
import { ExperienceForm } from '../../utils/widgets/cv/experience-form/component.types';
import { CertificationForm } from '../../utils/widgets/cv/certificate-form/component.types';
import { LocationType } from '../../utils/widgets/forms/input-location/component.types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ApiErrorResponseType } from '../../utils/hooks/use-fetch/component.types';
import { CategorizedSkills } from '../../utils/widgets/cv/skills-form';
import { USERTYPE } from '../../constants/common';
import PageLoader from '../../utils/widgets/page-loader';
import { useUser } from '../../utils/hooks/use-user';

type HandleSubmit = Omit<CVRequest, 'location, skills'> & { location: LocationType; skills: CategorizedSkills };

const CvSingle = () => {
  const { isHR } = useUser();
  const { t } = useTranslation();
  const { cvId } = useParams();
  const getCvApi = useFetch(() => ApiCvService.apiCvShow(Number(cvId)));
  const updateCvApi = useFetch<CVRequest, CVResource>(data => ApiCvService.apiCvUpdate(Number(cvId), data));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useConstructor({
    callBack: () => getCvApi.call(),
  });

  const onBack = () => {
    navigate(appRoutes.cvList);
  };

  const cvData = useMemo(() => {
    if (getCvApi.loaded) {
      const {
        work_experiences: experiences,
        educations,
        certifications,
        hard_skills,
        soft_skills,
        id,
        status_id,
        ...otherValues
      } = getCvApi.result as CVResource;

      return {
        cvID: id,
        statusId: status_id as number,
        experiences,
        educations,
        certifications,
        profileInformation: {
          first_name: otherValues.first_name || otherValues.user?.first_name || '',
          last_name: otherValues.last_name || otherValues.user?.last_name || '',
          email: otherValues.user?.email || '',
        },
        skills: {
          hard_skills: hard_skills?.map(item => item.skill),
          soft_skills: soft_skills?.map(item => item.skill),
        },
        cvInformation: {
          introduction: otherValues.introduction,
          title: otherValues.title,
        },
      };
    }
    return {
      cvID: undefined,
      statusId: undefined,
      experiences: undefined,
      skills: undefined,
      educations: undefined,
      certifications: undefined,
      profileInformation: undefined,
      cvInformation: undefined,
    };
  }, [getCvApi.loaded, getCvApi.result]);

  const handleSubmit = ({ skills, ...values }: HandleSubmit) => {
    const softSkillsId = skills.soft_skills.map(skill => skill.id);
    const hardSkillsId = skills.hard_skills.map(skill => skill.id);

    return updateCvApi
      .call({
        ...values,
        location_id: values?.location?.id,
        skills_ids: [...softSkillsId, ...hardSkillsId],
      })
      .then(async response => {
        enqueueSnackbar(t('form:general-submission.success'), {
          variant: 'success',
        });
        await getCvApi.call();
        return Promise.resolve(response);
      })
      .catch(async (e: ApiErrorResponseType) => {
        let message = t('form:general-submission.failed');

        if (e?.body?.message) {
          message = e.body.message;
        }

        enqueueSnackbar(message, {
          variant: 'error',
        });
        await getCvApi.call();
        return Promise.reject(e);
      });
  };

  return (
    <PageContainer>
      <PageHeader onBack={onBack} backTitle={t('common:back-to-cvs')} />
      <PageBody>
        <PageWrapper>
          <PageLoader isLoading={!getCvApi.loaded}>
            <PreviewCV
              cvId={cvData.cvID}
              cvStatus={cvData.statusId}
              editable={cvData.statusId === 1 || cvData.statusId === 3 || isHR}
              userType={isHR ? USERTYPE.HR : USERTYPE.EMPLOYEE}
              isLoading={updateCvApi.loading || getCvApi.loading}
              onUpdate={values => handleSubmit(values as HandleSubmit)}
              skills={cvData.skills as CategorizedSkills | undefined}
              experiences={cvData.experiences as ExperienceForm[] | undefined}
              educations={cvData.educations as EducationForm[] | undefined}
              certifications={cvData.certifications as CertificationForm[] | undefined}
              profileInformation={cvData.profileInformation}
              cvInformation={cvData.cvInformation}
            />
          </PageLoader>
        </PageWrapper>
      </PageBody>
    </PageContainer>
  );
};

export default CvSingle;
