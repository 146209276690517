import React, { memo } from 'react';
import { DynamicFormProps } from './component.types';
import ExpandableCard from '../../expandable-card';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack } from '@mui/material';
import Button from '../../button';
import { ReactComponent as PlusIconLight } from '../../../../assets/icons/plus-light.svg';
import { ReactComponent as PlusIconDark } from '../../../../assets/icons/plus-dark.svg';
import { ReactComponent as CloseIconDark } from '../../../../assets/icons/close-dark.svg';
import { ReactComponent as CloseIconLight } from '../../../../assets/icons/close-light.svg';
import RenderBasedOnTheme from '../../render-based-on-theme';

const DynamicForm = ({ control, title, name, initialData, render }: DynamicFormProps) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
    <div aria-label={name}>
      <Stack
        spacing={2}
        sx={{
          margin: '60px 0',
          alignItems: 'flex-end',
        }}
      >
        {fields.map((field, index) => (
          <ExpandableCard
            initialState={true}
            key={field.id}
            actions={[
              <IconButton aria-label={`remove-btn-${t(title)}-${index}`} onClick={() => remove(index)}>
                <RenderBasedOnTheme light={<CloseIconDark />} dark={<CloseIconLight />} />
              </IconButton>,
            ]}
            title={`${t(title)} ${index + 1}`}
          >
            {render({
              prefix: `${name}.${index}.`,
            })}
          </ExpandableCard>
        ))}
        <div>
          <Button
            onClick={() => append(initialData)}
            rounded={false}
            variant="outlined"
            color="inherit"
            disableRipple
            startIcon={<RenderBasedOnTheme light={<PlusIconDark />} dark={<PlusIconLight />} />}
          >
            {t('common:add')}
          </Button>
        </div>
      </Stack>
    </div>
  );
};

export default memo(DynamicForm);
