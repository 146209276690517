import React from 'react';
import { ApiErrorResponseType } from '../../../utils/hooks/use-fetch/component.types';
import Paper from '@mui/material/Paper';
import { styled, Typography } from '@mui/material';
import Button from '../../../utils/widgets/button';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../utils/hooks/use-fetch';
import { ApiAuthService, TokenResource, VerifyUserRequest } from '../../../api/main';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes } from '../../../providers/routes';
import { storeActions } from '../../../store';
import { useAppDispatch } from '../../../store/hooks';

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: '30vw',
  maxWidth: '440px',
  padding: '40px',
});

const VerifyEmail = () => {
  const { t } = useTranslation();

  const verifyEmailApi = useFetch<VerifyUserRequest, TokenResource>(ApiAuthService.apiAuthRegisterVerify);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token') as string | undefined;
  const dispatch = useAppDispatch();

  const onVerify = () => {
    if (token) {
      verifyEmailApi
        .call({ token })
        .then(result => {
          enqueueSnackbar(t('form:general-submission.success'), {
            variant: 'success',
          });
          dispatch(storeActions.user.setUserData(result));
          navigate(appRoutes.dashboard);
        })
        .catch((e: ApiErrorResponseType) => {
          let message = t('form:general-submission.failed');

          if (e?.body?.message) {
            message = e.body.message;
          }

          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
    }
  };

  return (
    <StyledPaper>
      {!token ? (
        <>
          <Typography gutterBottom variant="h2" mb={4} align="center" component="p">
            {t('form:verify-email.email-sent')}
          </Typography>
          <Typography variant="body1" align="center" component="p">
            {t('form:verify-email.check-mailbox')}
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ marginBottom: '20px' }} variant="h2" component="p">
            {t('common:auth.verify-email.title')}
          </Typography>
          <Typography variant="body1" component="p">
            {t('form:verify-email.text')}
          </Typography>
          <Button
            sx={{
              marginTop: '40px',
            }}
            loading={verifyEmailApi.loading}
            onClick={onVerify}
          >
            {t('form:verify-email.submit-btn')}
          </Button>
        </>
      )}
    </StyledPaper>
  );
};

export default VerifyEmail;
