import { USERTYPE } from '../../../constants/common';
import { storeActions } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export function useUser(userID?: number) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(storeActions.user.getUser);
  const clientProfile = useAppSelector(storeActions.clientProfile.getClientProfile);
  const userType = user.userType;
  const isHR = userType === USERTYPE.HR;
  const isPM = userType === USERTYPE.PM;
  const isOwner = userID === clientProfile?.id;
  const roleName = clientProfile?.role?.name;
  const canSwitch = clientProfile?.role?.name === 'hr' || clientProfile?.role?.name === 'pm';
  const switchUserType = () => {
    dispatch(storeActions.user.setUserType(isHR || isPM ? USERTYPE.EMPLOYEE : (roleName as USERTYPE)));
  };
  return { user, userType, isHR, isPM, clientProfile, isOwner, roleName, switchUserType, canSwitch };
}
