import React, { memo, useContext } from 'react';
import Button from '../../utils/widgets/button';
import { Box, Checkbox, FormControlLabel, Paper, styled } from '@mui/material';
import { StyleProviderContext } from '../../styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FormItemText from '../../utils/widgets/forms/form-item-text';
import TextField from '@mui/material/TextField';
import useForm from '../../utils/hooks/use-form';
import * as Yup from 'yup';
import InputPassword from '../../utils/widgets/forms/input-password';

const StyledPaper = styled(Paper)(() => ({
  maxWidth: 1200,
  margin: '20px auto',
  padding: 20,
}));

const ItemWrapper = styled(Box)(() => ({
  padding: 10,
  margin: 10,
  display: 'inline-block',
}));

const loginSchema = Yup.object().shape({
  // password: Yup.string().min(8).max(50).required(),
  password: Yup.string().nullable(),
  email: Yup.string().email().required(),
});

const UiKit = () => {
  const { t } = useTranslation();
  const { mode, setMode } = useContext(StyleProviderContext);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'login',
    schema: loginSchema,
  });
  const { control } = formProps;
  const CheckboxLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => {
          setMode(mode === 'light' ? 'dark' : 'light');
          enqueueSnackbar(t('common:app-mode-changed'), {
            variant: 'success',
          });
        }}
      >
        {t('common:change-theme')}
      </Button>
      <StyledPaper>
        <ItemWrapper>
          <Button variant="text">Text primary</Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="text" loading>
            Text primary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="text" color="secondary">
            Text secondary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="text" color="secondary" loading>
            Text secondary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="text" disabled>
            Text disabled
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="text" color="secondary" disabled>
            Text secondary disabled
          </Button>
        </ItemWrapper>
      </StyledPaper>
      <StyledPaper>
        <ItemWrapper>
          <Button variant="contained">Contained primary</Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="contained" loading>
            Contained primary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="contained" color="secondary">
            Contained secondary sd
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="contained" color="secondary" loading>
            Contained secondary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="contained" disabled>
            Contained primary disabled
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="contained" color="secondary" disabled>
            Contained secondary disabled
          </Button>
        </ItemWrapper>
      </StyledPaper>
      <StyledPaper>
        <ItemWrapper>
          <Button variant="outlined">Outlined primary</Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="outlined" loading>
            Outlined primary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="outlined" color="secondary">
            Outlined secondary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="outlined" color="secondary" loading>
            Outlined secondary
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="outlined" disabled>
            Outlined disabled
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button variant="outlined" color="secondary" disabled>
            Outlined secondary disabled
          </Button>
        </ItemWrapper>
      </StyledPaper>
      <StyledPaper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox {...CheckboxLabel} defaultChecked />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox {...CheckboxLabel} />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox {...CheckboxLabel} disabled />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox {...CheckboxLabel} disabled checked />} label="Label" />
        </ItemWrapper>
      </StyledPaper>
      <StyledPaper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox size="small" {...CheckboxLabel} defaultChecked />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox size="small" {...CheckboxLabel} />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox size="small" {...CheckboxLabel} disabled />} label="Label" />
        </ItemWrapper>
        <ItemWrapper>
          <FormControlLabel control={<Checkbox size="small" {...CheckboxLabel} disabled checked />} label="Label" />
        </ItemWrapper>
      </StyledPaper>
      <StyledPaper>
        <ItemWrapper>
          <FormItemText required defaultValue="" control={control} name="email" label={t('common:email')}>
            <TextField type="email" variant="outlined" />
          </FormItemText>
        </ItemWrapper>
        <ItemWrapper>
          <FormItemText defaultValue="" label={t('common:password')} control={control} name="password">
            <InputPassword variant="outlined" focused />
          </FormItemText>
        </ItemWrapper>
        <ItemWrapper>
          <FormItemText defaultValue="" label={t('common:disabled')} control={control} name="disabled">
            <TextField type="disabled" variant="outlined" disabled />
          </FormItemText>
        </ItemWrapper>
      </StyledPaper>
    </div>
  );
};

export default memo(UiKit);
