import { GridColDef } from '@mui/x-data-grid';

export enum REPORTSTYPE {
  Index = '',
  Vacations = 'vacations',
  Projects = 'projects',
}
export enum VACATIONSTYPE {
  Index = '',
  DayOffs = 'day-offs',
  SickLeave = 'sick-leave',
  Holidays = 'holidays',
  EditHoliday = 'edit-holiday',
  SpecialVacation = 'special-vacation',
}
export const defaultColDef: GridColDef = { sortable: false, flex: 1, field: '' };
