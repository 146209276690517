import { Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../providers/routes';
import { useUser } from '../../hooks/use-user';
import Avatar from '../users/avatar';
import When from '../when';
import { AvatarOverlay, AvatarWrapper, OverlaySubText } from './styled';

const ProfileImg = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
});

const UserInfo: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { clientProfile, isHR, isPM, roleName, user, switchUserType, canSwitch } = useUser();

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <AvatarWrapper>
      <Stack direction="row" alignItems="center" margin="auto" key={String(clientProfile?.profile_photo)}>
        <Stack alignItems="center" margin="auto" width="100%">
          {clientProfile?.profile_photo && isOpen ? (
            <ProfileImg src={clientProfile?.profile_photo} />
          ) : (
            <Avatar isOpen={isOpen} />
          )}
          <Typography variant="h5" component="span" sx={{ marginTop: '10px', color: theme.palette.white.main }}>
            {(isOpen && clientProfile?.first_name) || ''}
          </Typography>
        </Stack>

        <AvatarOverlay>
          <Stack>
            <Typography variant="subtitle2" component="span" sx={{ marginTop: '18px' }}>
              {t('table:common.full-name')}
            </Typography>
            <OverlaySubText>
              {user.data?.first_name} {user.data?.last_name}
            </OverlaySubText>
            <When condition={Boolean(clientProfile?.phone)}>
              <>
                <Typography variant="subtitle2" component="span">
                  {t('common:phone-number')}
                </Typography>
                <OverlaySubText>{clientProfile?.phone}</OverlaySubText>
              </>
            </When>
            <Typography variant="subtitle2" component="span">
              {t('common:email')}
            </Typography>
            <OverlaySubText>{clientProfile?.email}</OverlaySubText>
            <Divider />
            <When condition={canSwitch}>
              <Link to="" onClick={() => switchUserType()}>
                <Typography variant="subtitle2" component="span">
                  {isPM || isHR
                    ? t('switch-to-user-account')
                    : t('switch-to-role-account', { role: roleName?.toUpperCase() })}
                </Typography>
              </Link>
            </When>
            <Link to={appRoutes.profile}>
              <Typography variant="subtitle2" component="span">
                {t('profile-page')}
              </Typography>
            </Link>
          </Stack>
        </AvatarOverlay>
      </Stack>
    </AvatarWrapper>
  );
};

export default UserInfo;
