/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserRequest = {
    first_name?: string;
    last_name?: string;
    email?: string;
    /**
     * User location identifier.
     */
    location_id?: number;
    /**
     * 1 for Active and 2 for Archived
     */
    status?: UserRequest.status;
    phone?: string;
    role?: string;
    slack_id?: string;
    birthday?: string;
    start_date?: string;
};

export namespace UserRequest {

    /**
     * 1 for Active and 2 for Archived
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
    }


}

