import appColor, { appColorOverrides, colorMap } from './app-colors';
import { createMuiColor } from '../../utils/tools/styles/create-mui-color';
import globalOverridesStyle from './global-overrides-style';
import deepmerge from 'deepmerge';

const lightThemeOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderColor: colorMap.light.fieldsBorder,
        color: appColor.midBlack,
      },
      text: {
        color: colorMap.light.text1,
        '&.Mui-disabled': {
          color: appColor.white,
          backgroundColor: appColor.waterloo,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&:not(.Mui-checked) .MuiSvgIcon-root': {
          '& path': {
            fill: appColor.lightGray,
          },
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& fieldset': {
          backgroundColor: colorMap.light.fields,
          borderColor: colorMap.light.fieldsBorder,
          zIndex: 1,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: colorMap.light.paperBackground,
      },
    },
  },
};

export const light = {
  status: {
    active: appColor.primary,
  },
  palette: {
    mode: 'light',
    ...createMuiColor(appColor),
    ...appColorOverrides,
    ...createMuiColor(colorMap.light),
  },
  components: {
    ...deepmerge(globalOverridesStyle, lightThemeOverrides),
  },
};

export default light;
