import { useRef } from 'react';
import { UseConstructorTypes } from './component.types';

const useConstructor = ({ callBack }: UseConstructorTypes) => {
  const hasBeenCalled = useRef(false);

  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

export default useConstructor;
