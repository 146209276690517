import { Delete, Edit } from '@mui/icons-material';
import { Divider, IconButton, Paper, Stack, styled, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiHolidayService, HolidayResource } from '../../../../api/main';
import { defaultColDef } from '../../../../constants/report';
import { THEME } from '../../../../constants/theme';
import useErrorHandler from '../../../hooks/use-error-handler';
import useFetch from '../../../hooks/use-fetch';
import { useUser } from '../../../hooks/use-user';
import ConfirmationDialog from '../../confirmation-dialog';
import CustomPagination from '../../table-pagination';
import { UserHolidayGridProps } from './component.types';

const CardBox = styled(Paper)(({ theme }) => ({
  padding: '20px',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '10px',
}));
const CardHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === THEME.LIGHT ? theme.palette.secondary.main : theme.palette.text1.main,
  marginBottom: '20px',
  display: 'inline-block',
}));

const UserHolidaysGrid: React.FC<UserHolidayGridProps> = ({
  isLoading,
  data,
  hasWrapper,
  onPageChange,
  page,
  handleDelete = () => {},
  handleEdit = () => {},
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const itemIdRef = useRef<number | undefined>(undefined);
  const deleteItem = useFetch(ApiHolidayService.apiHolidayDelete);
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const { isHR } = useUser();

  const Columns: GridColDef<HolidayResource>[] = [
    {
      ...defaultColDef,
      field: 'date',
      headerName: t('table:holidays.date'),
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.Y') : '';
      },
    },
    {
      ...defaultColDef,
      field: 'day',
      headerName: t('table:holidays.day'),
      valueGetter({ row }) {
        return row.date && format(new Date(row.date), 'EEEE');
      },
    },
    {
      ...defaultColDef,
      field: 'name',
      headerName: t('table:holidays.holiday-name'),
    },
    {
      ...defaultColDef,
      field: 'location',
      headerName: t('table:holidays.location'),
      valueGetter({ row }) {
        return row.location?.name;
      },
    },
    {
      ...defaultColDef,
      field: 'hours',
      headerName: t('table:holidays.reduced-hours'),
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        return (
          <Stack direction="row">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit color="info" />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <IconButton
              onClick={() => {
                itemIdRef.current = row.id;
                setOpen(true);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const Grid = (
    <>
      <DataGrid
        page={page}
        onPageChange={onPageChange}
        loading={isLoading}
        columns={isHR ? Columns : Columns.slice(0, Columns.length - 1)}
        rows={Array.isArray(data) ? data : data.data || []}
        paginationMode="server"
        pageSize={data.meta?.per_page}
        rowCount={data.data?.length}
        disableSelectionOnClick
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        columnBuffer={4}
        components={{ Pagination: CustomPagination }}
      />
      <ConfirmationDialog
        width="30%"
        padding="10px"
        onOk={() => {
          if (itemIdRef.current) {
            deleteItem
              .call(itemIdRef.current)
              .then(() => {
                enqueueSnackbar(t('form:general-submission.success'), {
                  variant: 'success',
                });
                handleDelete(itemIdRef.current as number);
              })
              .catch(handleError)
              .finally(() => {
                setOpen(false);
                itemIdRef.current = undefined;
              });
          }
        }}
        onCancel={() => {
          setOpen(false);
          itemIdRef.current = undefined;
        }}
        title=""
        open={open}
      >
        <Typography variant="h4" paragraph>
          {t('form:delete-confirm')}
        </Typography>
      </ConfirmationDialog>
    </>
  );
  return hasWrapper ? (
    <CardBox>
      <CardHeading variant="h4">{t('table:holidays.public-holidays')}</CardHeading>
      {Grid}
    </CardBox>
  ) : (
    Grid
  );
};

export default UserHolidaysGrid;
