import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack } from '@mui/material';
import DashboardLayout from '../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../utils/widgets/layouts/user-page-header';
import CVsGrid from '../../utils/widgets/grids/cvs-grid';
import SearchInputBox from '../../utils/widgets/search-input-box';
import { debounce } from '../../utils/tools/debounce';
import { appRoutes } from '../../providers/routes';
import Button from '../../utils/widgets/button';
import { Add } from '@mui/icons-material';
import { GridHeader, StyledPaper } from '../../utils/widgets/layouts/reports-layout';
import { useUser } from '../../utils/hooks/use-user';
import { useSearchParams } from 'react-router-dom';
import InputSkills from '../../utils/widgets/forms/input-skills';
import { SKILLTYPE, USERTYPE } from '../../constants/common';
import { LoggedInUserTypes } from '../../store/redux/user/user.types';
import * as Yup from 'yup';
import useForm from '../../utils/hooks/use-form';
import { skillSchema } from '../../utils/widgets/cv/skills-form/component';
import { useWatch } from 'react-hook-form';
import { SkillResource } from '../../api/main';

const CVsList = () => {
  const [query] = useSearchParams();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Record<string, string | undefined>>(() => {
    const queryFilters = query.get('filters') as string;
    if (queryFilters) return JSON.parse(queryFilters) as Record<string, string | undefined>;
    return {};
  });
  const { isHR, userType: type } = useUser();
  const userType = (type === USERTYPE.PM ? USERTYPE.EMPLOYEE : type) as LoggedInUserTypes;
  const { control } = useForm({
    name: 'hard-skill-form',
    schema: Yup.object().shape({
      hard_skills: Yup.array(skillSchema),
    }),
  });

  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, searchQuery: event.target.value }));
  }, 300);

  const skills = useWatch({
    control,
    name: 'hard_skills',
  }) as Array<SkillResource>;

  useEffect(() => {
    if (skills?.length) {
      setFilters({ ...filters, skillIds: skills.map(s => s.id).join(',') });
    }
  }, [skills]);
  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:cvs')} />
      <StyledPaper>
        <GridHeader>
          {isHR ? (
            <>
              <SearchInputBox
                onChange={debouncedChangeHandler}
                defaultValue={filters.searchQuery || ''}
                placeholder="Employee name"
              />
            </>
          ) : (
            <div />
          )}

          <Button variant="outlined" color="inherit" hasIcon href={appRoutes.newCv}>
            <Stack direction="row" alignItems="center">
              <Add sx={{ marginRight: '5px' }} />
              {t('common:add-cv')}
            </Stack>
          </Button>
        </GridHeader>
        <Box
          sx={{
            marginTop: '10px',
            width: '100%',
            borderRadius: '50px',
            '.MuiInputBase-root': { borderRadius: '20px !important' },
            '[name=hard_skills]': {
              marginBottom: '-4px !important',
              minHeight: '32px',
              paddingLeft: '16px !important',
            },
          }}
        >
          <InputSkills
            placeholder="Select hard skills to filter"
            label=""
            userType={userType}
            control={control}
            name="hard_skills"
            skillType={SKILLTYPE.HARD}
          />
        </Box>
        <Divider sx={{ margin: '30px 0' }} />
        <CVsGrid filters={filters} />
      </StyledPaper>
    </DashboardLayout>
  );
};

export default CVsList;
