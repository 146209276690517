import React, { useRef, useState } from 'react';
import { PreviewCVProps, ArrayForms, UpdateAnIndexOfList } from './component.types';
import { Divider, PageContainer } from '../styled';
import ProfileInformationPreview from '../profile-information-preview';
import ExperiencePreview from '../experience-preview';
import EducationPreview from '../education-preview';
import CertificatePreview from '../certificate-preview';
import When from '../../when';
import ProfileInformationModalForm, { ProfileInformationModalFormValues } from '../profile-information-modal-form';
import Choose from '../../choose';
import EducationModalForm from '../education-modal-form';
import { EducationForm } from '../education-form/component.types';
import { ExperienceForm } from '../experience-form/component.types';
import ExperienceModalForm from '../experience-modal-form';
import CertificateModalForm from '../certificate-modal-form';
import { CertificationForm } from '../certificate-form/component.types';
import SkillsPreview from '../skills-preview';
import { CategorizedSkills } from '../skills-form';
import SkillsModalForm from '../skills-modal-form';
import { PreviewExperienceForm } from '../experience-preview/component.types';
import Button from '../../button';
import { useTranslation } from 'react-i18next';
import usePDFGenerator from '../../../hooks/use-pdf-generator';
import useConstructor from '../../../hooks/use-constructor';
import { CVEducationDegreeResource } from '../../../../api/main';
import { PreviewEducationForm } from '../education-preview/component.types';
import { USERTYPE } from '../../../../constants/common';

const PreviewCV = ({
  profileInformation,
  experiences,
  educations: initialEducations,
  certifications,
  cvInformation,
  skills,
  editable = false,
  onUpdate = () => {},
  userType,
  isLoading,
  cvStatus,
  cvId,
}: PreviewCVProps) => {
  const educations = initialEducations?.map(({ degree: itemDegree, ...otherValues }) => {
    let degree = itemDegree;

    if (typeof itemDegree === 'object') {
      const degreeObj = itemDegree as CVEducationDegreeResource;
      degree = degreeObj.id;
    }

    return {
      ...otherValues,
      degree,
    };
  }) as PreviewEducationForm[];

  let newValues = {
    work_experiences: experiences,
    educations,
    certifications,
    skills,
    ...cvInformation,
    ...profileInformation,
  };
  const { t } = useTranslation();
  const { pdfUri, getPDFUri, loading: pdfGeneratorLoading } = usePDFGenerator();
  const [activeForm, setActiveForm] = useState<string | undefined>(undefined);
  const activeFormDataRef = useRef<unknown>();

  const handleEdit =
    <T,>(formKey: string) =>
    (data?: T) => {
      setActiveForm(formKey);
      activeFormDataRef.current = data;
    };

  const handleDelete = (formKey: string) => (selectedIem: ArrayForms) => {
    switch (formKey) {
      case 'experiences':
        if (Array.isArray(newValues.work_experiences)) {
          newValues.work_experiences = newValues.work_experiences.filter(item => item.id !== selectedIem.id);
        }
        break;
      case 'educations':
        if (Array.isArray(newValues.educations)) {
          newValues.educations = newValues.educations.filter(item => item.id !== selectedIem.id);
        }
        break;
      case 'certifications':
        if (Array.isArray(newValues.certifications)) {
          newValues.certifications = newValues.certifications.filter(item => item.id !== selectedIem.id);
        }
        break;
    }

    return onUpdate(newValues);
  };

  const updateAnIndexOfList = <T,>(list: UpdateAnIndexOfList<T>[], values: UpdateAnIndexOfList<T>) => {
    const selectedItem = activeFormDataRef.current as UpdateAnIndexOfList<T>;
    return list.reduce<T[]>((acc, item): T[] => {
      if (item.id === selectedItem.id) {
        return [...acc, values];
      }
      return [...acc, item];
    }, [] as T[]);
  };

  const addNewItem = <T,>(list: UpdateAnIndexOfList<T>[], values: UpdateAnIndexOfList<T>) => {
    return [...list, values];
  };

  const updateArrayFormsActions = {
    experiences: (values: unknown) => {
      if (Array.isArray(newValues.work_experiences) && activeFormDataRef.current) {
        newValues.work_experiences = updateAnIndexOfList(newValues.work_experiences, values as PreviewExperienceForm);
      } else {
        newValues.work_experiences = addNewItem(newValues.work_experiences || [], values as PreviewExperienceForm);
      }
    },
    educations: (values: unknown) => {
      if (Array.isArray(newValues.educations) && activeFormDataRef.current) {
        newValues.educations = updateAnIndexOfList(newValues.educations, values as EducationForm);
      } else {
        newValues.educations = addNewItem(newValues.educations || [], values as EducationForm);
      }
    },
    certifications: (values: unknown) => {
      if (Array.isArray(newValues.certifications) && activeFormDataRef.current) {
        newValues.certifications = updateAnIndexOfList(newValues.certifications, values as CertificationForm);
      } else {
        newValues.certifications = addNewItem(newValues.certifications || [], values as CertificationForm);
      }
    },
  };

  const handleUpdateArrayForms = (formKey: keyof typeof updateArrayFormsActions) => (values: unknown) => {
    updateArrayFormsActions[formKey](values);
    setActiveForm(undefined);
    activeFormDataRef.current = undefined;
    return onUpdate(newValues);
  };

  const handleUpdateSkill = (values: CategorizedSkills) => {
    newValues.skills = values;
    setActiveForm(undefined);
    activeFormDataRef.current = undefined;
    return onUpdate(newValues);
  };

  const handleUpdateProfileInformation = (values: ProfileInformationModalFormValues) => {
    newValues = {
      ...newValues,
      ...values,
    };
    setActiveForm(undefined);
    activeFormDataRef.current = undefined;
    return onUpdate(newValues);
  };

  useConstructor({
    callBack: () => {
      if (cvId && !pdfGeneratorLoading && !pdfUri) {
        void getPDFUri(cvId);
      }
    },
  });
  const isPreviewPDFVisible = !!cvId && userType === USERTYPE.HR;

  return (
    <PageContainer aria-label="preview-cv-board">
      <ProfileInformationPreview
        isLoading={isLoading}
        {...(editable ? { onEdit: handleEdit('profileInformation') } : {})}
        data={{ ...cvInformation, ...profileInformation }}
      />
      <Divider />
      <ExperiencePreview
        {...(editable
          ? {
              onAdd: () => handleEdit('experiences')(),
              onEdit: handleEdit('experiences'),
              onDelete: handleDelete('experiences'),
            }
          : {})}
        isLoading={isLoading}
        data={experiences}
      />
      <Divider />
      <EducationPreview
        {...(editable
          ? {
              onAdd: () => handleEdit('educations')(),
              onEdit: handleEdit('educations'),
              onDelete: handleDelete('educations'),
            }
          : {})}
        data={educations}
        isLoading={isLoading}
      />
      <Divider />
      <CertificatePreview
        {...(editable
          ? {
              onAdd: () => handleEdit('certifications')(),
              onEdit: handleEdit('certifications'),
              onDelete: handleDelete('certifications'),
            }
          : {})}
        data={certifications}
        isLoading={isLoading}
      />
      <Divider />
      <SkillsPreview {...(editable ? { onEdit: handleEdit('skills') } : {})} isLoading={isLoading} data={skills} />
      <Choose>
        <When condition={cvStatus === 2}>
          <a href={pdfUri} download target="_blank" rel="noopener noreferrer">
            <Button disabled={!pdfUri} loading={pdfGeneratorLoading}>
              {t('common:export-pdf')}
            </Button>
          </a>
        </When>
        <When condition={isPreviewPDFVisible}>
          <a href={pdfUri} target="_blank" rel="noopener noreferrer">
            <Button>{t('common:preview-pdf')}</Button>
          </a>
        </When>
      </Choose>
      <Choose>
        <When condition={activeForm === 'profileInformation'}>
          <ProfileInformationModalForm
            userType={userType}
            open={activeForm === 'profileInformation'}
            onSubmit={handleUpdateProfileInformation}
            onCancel={() => setActiveForm(undefined)}
            initialData={{ ...cvInformation, ...profileInformation }}
          />
        </When>
        <When condition={activeForm === 'experiences'}>
          <ExperienceModalForm
            userType={userType}
            open={activeForm === 'experiences'}
            onSubmit={(formValues: ExperienceForm) => handleUpdateArrayForms('experiences')(formValues)}
            onCancel={() => setActiveForm(undefined)}
            initialData={activeFormDataRef.current as ExperienceForm}
          />
        </When>
        <When condition={activeForm === 'educations'}>
          <EducationModalForm
            userType={userType}
            open={activeForm === 'educations'}
            onSubmit={(formValues: EducationForm) => handleUpdateArrayForms('educations')(formValues)}
            onCancel={() => setActiveForm(undefined)}
            initialData={activeFormDataRef.current as EducationForm}
          />
        </When>
        <When condition={activeForm === 'certifications'}>
          <CertificateModalForm
            userType={userType}
            open={activeForm === 'certifications'}
            onSubmit={(formValues: CertificationForm) => handleUpdateArrayForms('certifications')(formValues)}
            onCancel={() => setActiveForm(undefined)}
            initialData={activeFormDataRef.current as CertificationForm}
          />
        </When>
        <When condition={activeForm === 'skills'}>
          <SkillsModalForm
            userType={userType}
            open={activeForm === 'skills'}
            onSubmit={(formValues: CategorizedSkills) => handleUpdateSkill(formValues)}
            onCancel={() => setActiveForm(undefined)}
            initialData={activeFormDataRef.current as CategorizedSkills}
          />
        </When>
      </Choose>
    </PageContainer>
  );
};

export default PreviewCV;
