import React from 'react';
import { EducationFormProps, EducationFormSchemaTypes, CVDetailsEducationFormSchemaTypes } from './component.types';
import { Box } from '@mui/material';
import { Cell, Row, FormContainer } from '../styled';
import TextField from '@mui/material/TextField';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import InputDatePicker from '../../forms/input-date-picker';
import Checkbox from '../../forms/checkbox/component';
import * as Yup from 'yup';
import { useWatch } from 'react-hook-form';
import InputEducationDegree from '../../forms/input-education-degree';
import { USERTYPE } from '../../../../constants/common';

const formSchema = {
  university: Yup.string().ensure().required(),
  field_of_study: Yup.string().ensure().required(),
  description: Yup.string(),
  start_date: Yup.string().ensure().dashedDateOnly().required(),
  degree: Yup.number().required(),
  end_date: Yup.string().when('currently_studying', {
    is: true,
    otherwise: Yup.string().ensure().dashedDateOnly().required(),
    then: Yup.string().nullable(true),
  }),
  currently_studying: Yup.boolean(),
};

export const educationFormSchema: EducationFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape(formSchema),
  [USERTYPE.EMPLOYEE]: Yup.object().shape(formSchema),
};

export const cvDetailsEducationFormSchema: CVDetailsEducationFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape({
    educations: Yup.array(educationFormSchema.hr),
  }),
  [USERTYPE.EMPLOYEE]: Yup.object().shape({
    educations: Yup.array(educationFormSchema.hr),
  }),
};

const EducationForm = ({ prefix = '', control }: EducationFormProps) => {
  const { t } = useTranslation();

  const watchCurrentlyStudying = useWatch({
    control,
    name: `${prefix}currently_studying`,
  }) as boolean;

  const [startDate, endDate] = useWatch({
    control,
    name: [`${prefix}start_date`, `${prefix}end_date`],
  }) as Date[];

  return (
    <FormContainer>
      <Row>
        <FormItemText
          defaultValue=""
          control={control}
          required
          label={t('form:cv.university-name')}
          name={`${prefix}university`}
        >
          <TextField />
        </FormItemText>
      </Row>
      <Row>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            required
            label={t('form:cv.faculty-name')}
            name={`${prefix}field_of_study`}
          >
            <TextField />
          </FormItemText>
        </Cell>
        <Cell>
          <InputEducationDegree control={control} required name={`${prefix}degree`} label={t('form:cv.degree')} />
        </Cell>
      </Row>
      <Row>
        <FormItemText defaultValue="" control={control} label={t('form:cv.description')} name={`${prefix}description`}>
          <TextField multiline minRows={5} />
        </FormItemText>
      </Row>
      <Row>
        <Cell>
          <InputDatePicker
            fullWidth
            defaultValue=""
            control={control}
            required
            name={`${prefix}start_date`}
            label={t('form:cv.start-date')}
            maxDate={endDate ? new Date(endDate) : new Date()}
          />
        </Cell>
        <Cell>
          <Cell>
            <InputDatePicker
              fullWidth
              control={control}
              defaultValue=""
              required={!watchCurrentlyStudying}
              disabled={watchCurrentlyStudying}
              name={`${prefix}end_date`}
              label={t('form:cv.end-date')}
              minDate={startDate ? new Date(startDate) : undefined}
              maxDate={new Date()}
            />
          </Cell>
        </Cell>
      </Row>
      <Box>
        <Checkbox
          name={`${prefix}currently_studying`}
          data-testid="currently_studying"
          label={t('form:cv.currently-studying')}
          control={control}
        />
      </Box>
    </FormContainer>
  );
};

export default EducationForm;
