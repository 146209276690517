import React, { memo } from 'react';
import { InputEducationDegreeProps } from './component.types';
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import FormInputLabel from '../form-input-label';
import When from '../../when';
import { DEGREES_OPTIONS } from '../../../../constants/server-constants';

const InputEducationDegree = ({
  name,
  control,
  label,
  placeholder,
  required = false,
  fullWidth = true,
  disabled = false,
  defaultValue = undefined,
}: InputEducationDegreeProps) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue ?? '',
    name,
  });

  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  return (
    <FormInputLabel disabled={!!disabled} htmlFor={htmlFor} required={required} label={label}>
      <FormControl fullWidth={fullWidth} error={invalid}>
        <Select
          disabled={!!disabled}
          fullWidth={fullWidth}
          placeholder={placeholder}
          required={required}
          id={htmlFor}
          data-testid={htmlFor}
          {...field}
        >
          {DEGREES_OPTIONS.map(item => (
            <MenuItem key={JSON.stringify(item)} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <When condition={invalid}>
          <FormHelperText>{error?.message}</FormHelperText>
        </When>
      </FormControl>
    </FormInputLabel>
  );
};

export default memo(InputEducationDegree);
