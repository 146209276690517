import { PaletteOptions } from '@mui/material/styles/createPalette';
import appColor, { colorMap } from '../../../styles/themes/app-colors';

type Accelerator = Partial<PaletteOptions>;

type Color = [string, string];

export const createMuiColor = (colors: typeof appColor | typeof colorMap.dark | typeof colorMap.light): Accelerator => {
  return Object.entries(colors).reduce((accelerator: Accelerator, color: Color): Accelerator => {
    return {
      ...accelerator,
      [color[0]]: {
        main: color[1],
        light: `${color[1]}BF`, // .25
        lighter: `${color[1]}7F`, // .50
        ultraLight: `${color[1]}14`, // .80
      },
    };
  }, {});
};
