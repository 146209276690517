import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppConfigs from './app-configs';
import { RouterProvider } from './providers';

function App() {
  return (
    <BrowserRouter>
      <AppConfigs>
        <RouterProvider />
      </AppConfigs>
    </BrowserRouter>
  );
}

export default App;
