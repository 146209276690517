import React, { memo, useMemo, useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material';

const InputPassword = ({ variant = 'outlined', ...otherProps }: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const sx = useMemo(
    () => ({
      color: theme.palette.fieldsBorder.main,
    }),
    [theme]
  );

  return (
    <TextField
      variant={variant}
      type={showPassword ? 'text' : 'password'}
      {...otherProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(prevState => !prevState)}
              edge="end"
            >
              {showPassword ? <VisibilityOff sx={sx} /> : <Visibility sx={sx} />}
            </IconButton>
          </InputAdornment>
        ),
        ...otherProps.InputProps,
      }}
    />
  );
};

export default memo(InputPassword);
