import React from 'react';
import { RenderBasedOnThemeProps } from './component.types';
import { useTheme } from '@mui/material';
import { THEME } from '../../../constants/theme';
import When from '../when';
import Choose from '../choose';

const RenderBasedOnTheme = ({ dark, light }: RenderBasedOnThemeProps) => {
  const theme = useTheme();
  return (
    <Choose>
      <When condition={theme.palette.mode === THEME.LIGHT}>{light}</When>
      <When unless>{dark}</When>
    </Choose>
  );
};

export default RenderBasedOnTheme;
