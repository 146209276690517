import { useRef, useState } from 'react';
import { ApiErrorType, StatusText, UseFetch, UseFetchProps } from './component.types';
import { OpenAPI } from '../../../api/main';
import { useAppSelector } from '../../../store/hooks';
import { storeActions } from '../../../store';

if (process.env.NODE_ENV !== 'test')
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL ?? 'https://x7a5vxh5da.execute-api.eu-central-1.amazonaws.com';

const useFetch = <T, P>(fetch: UseFetchProps<T, P>): UseFetch<T, P> => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [statusText, setStatusText] = useState<StatusText>(undefined);
  const [result, setResult] = useState<P | ApiErrorType | undefined>();
  const user = useAppSelector(storeActions.user.getUser);
  const cancelRef = useRef(() => {});

  if (user.data?.token) {
    OpenAPI.TOKEN = user.data?.token;
  } else {
    OpenAPI.TOKEN = undefined;
  }

  const handleResponseStatuses = (res: P | ApiErrorType, isSuccess = false) => {
    setResult(res);
    setLoaded(isSuccess);
    setLoading(false);
    setStatusText(isSuccess ? 'success' : 'fail');
  };

  const callRef = useRef(function call(...args: T[]) {
    return new Promise<P>((resolve: (result: P) => void, reject: (result: ApiErrorType) => void) => {
      setLoading(true);
      setLoaded(false);
      const fetcher = fetch(...args);
      cancelRef.current = fetcher.cancel.bind(fetcher);
      return fetcher
        .then((data: unknown) => {
          handleResponseStatuses(data as P, true);
          resolve(data as P);
        })
        .catch((error: ApiErrorType): void => {
          handleResponseStatuses(error);
          reject(error);
        });
    });
  });

  return {
    call: callRef.current,
    result,
    statusText,
    loaded,
    loading,
    cancel: cancelRef.current,
  };
};

export default useFetch;
