import React, { useEffect, useRef } from 'react';
import { EducationModalFormProps } from './component.types';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import EducationForm, { educationFormSchema } from '../education-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

const EducationModalForm = <T,>({ initialData, onSubmit, open, onCancel, userType }: EducationModalFormProps<T>) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { handleSubmit, formDebugger, control } = useForm({
    name: 'experience-modal-form',
    schema: educationFormSchema[userType],
    defaultValues: {
      university: initialData?.university,
      field_of_study: initialData?.field_of_study,
      description: initialData?.description || '',
      start_date: initialData?.start_date,
      degree: 1, //initialData?.degree?.id, // TODO-ap:: fix after backend's change
      end_date: initialData?.end_date,
      currently_studying: !!initialData?.currently_studying,
    },
  });

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  useEffect(() => {}, [open]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      title={t('common:education')}
      onOkText={initialData ? 'common:edit' : 'common:add'}
      onOKIcon={initialData ? <EditOutlinedIcon /> : <AddIcon />}
    >
      <form
        ref={formRef}
        aria-label="education-modal-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      >
        {formDebugger()}
        <EducationForm control={control} />
      </form>
    </ConfirmationDialog>
  );
};

export default EducationModalForm;
