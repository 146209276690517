import React from 'react';
import { useController } from 'react-hook-form';
import { FormItemTextProps } from './component.types';
import FormInputLabel from '../form-input-label';

const FormItemText = ({
  name,
  control,
  label = '',
  disabled = false,
  required = false,
  defaultValue = undefined,
  children,
  min,
  max,
}: FormItemTextProps) => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });

  const { ref, ...inputProps } = field;
  const { invalid, error } = fieldState;

  const htmlFor = `input-${name}`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const input = React.cloneElement(children, {
    variant: 'outlined',
    fullWidth: true,
    required: required,
    disabled: disabled,
    ...children.props,
    InputProps: { inputProps: { ...inputProps, min, max }, ...children.props.InputProps },
    id: htmlFor,
    'aria-label': htmlFor,
    error: invalid,
    helperText: error?.message,
    inputRef: ref,
  });

  return label ? (
    <FormInputLabel htmlFor={htmlFor} disabled={children.props.disabled || disabled} required={required} label={label}>
      {input}
    </FormInputLabel>
  ) : (
    input
  );
};

export default FormItemText;
