import { Box, Dialog, Stack } from '@mui/material';
import React from 'react';
import { CloseButton } from '../../confirmation-dialog/component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CardHeading } from '../../../../components/admin/employee-overview/styled';
import { format } from 'date-fns';
import { EmployeeProjectsModalProps } from './component.types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CustomPagination from '../../table-pagination';
import { ProjectResource, UserProjectLogResource } from '../../../../api/main';
import { defaultColDef } from '../../../../constants/report';

const isProjectResouce = (item: ProjectResource | UserProjectLogResource): item is ProjectResource => 'name' in item;

const EmployeeProjectsModal: React.FC<EmployeeProjectsModalProps> = ({ data, open, onClose }) => {
  const [currentProjects = [], lagacyProjects = []] = data || [[], []];
  const Columns: GridColDef<ProjectResource | UserProjectLogResource>[] = [
    {
      ...defaultColDef,
      field: 'name',
      headerName: 'Name',
      valueGetter({ row }) {
        return isProjectResouce(row) ? row.name : row.project?.name;
      },
    },
    {
      ...defaultColDef,
      field: 'position',
      headerName: 'position',
      valueGetter({ row }) {
        return isProjectResouce(row) ? row.user?.position : row.position;
      },
    },
    {
      ...defaultColDef,
      field: 'from',
      headerName: 'From date',
      valueGetter({ row }) {
        return format(new Date((isProjectResouce(row) ? row.created_at : row.start_at) || ''), 'dd.MM.yyyy');
      },
    },
    {
      ...defaultColDef,
      field: 'to',
      headerName: 'To date',
      valueGetter({ row }) {
        return isProjectResouce(row) ? 'Continued' : format(new Date(row.end_at || ''), 'dd.MM.yyyy');
      },
    },
  ];
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { padding: '40px', position: 'relative', maxHeight: '80%', minWidth: '650px' } }}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <CloseButton onClick={onClose} aria-label="close-modal">
        <CloseOutlinedIcon />
      </CloseButton>

      <Stack direction="column" gap="20px">
        <CardHeading variant="h4" marginBottom="20px">
          Projects
        </CardHeading>
        <Box height={400}>
          <DataGrid
            columns={Columns}
            rows={[...currentProjects, ...lagacyProjects].map(p => ({ ...p, id: Math.random() }))}
            rowCount={currentProjects.length + lagacyProjects.length}
            components={{ Pagination: CustomPagination }}
            disableSelectionOnClick
            hideFooter
            getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          />
        </Box>
      </Stack>
    </Dialog>
  );
};
export default EmployeeProjectsModal;
