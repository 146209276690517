import React from 'react';
import { HeaderNavigationProps } from './component.types';
import Button from '../../button';
import { Box, styled } from '@mui/material';

const StyledButtonWrapper = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.lines.main,
    borderRadius: '20px',
    marginRight: '30px',
  };
});

const HeaderNavigation = ({ navs = [], activeNav = undefined }: HeaderNavigationProps) => {
  return (
    <StyledButtonWrapper>
      {navs.map(nav => (
        <Button
          key={`header-navigation-nav-${nav.href}-${nav.label}`}
          variant={activeNav === nav.href ? 'contained' : 'text'}
          href={nav.href}
          color="secondary"
        >
          {nav.label}
        </Button>
      ))}
    </StyledButtonWrapper>
  );
};

export default HeaderNavigation;
