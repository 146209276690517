import { Box, ButtonProps, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: theme.palette.sideNav.main,
  position: 'relative',
  transition: theme.transitions.create(['width'], {
    duration: 300,
  }),
}));

export const Body = styled(Box)({
  flex: 1,
  width: '100%',
  position: 'relative',
  flexDirection: 'column',
  padding: '12px 8px',
  display: 'flex',
  boxSizing: 'border-box',
  overflow: 'hidden',
  marginTop: '16px',
});

export const Footer = styled(Box)({
  width: '100%',
  padding: '12px 8px',
  display: 'flex',
  boxSizing: 'border-box',
  overflow: 'hidden',
});

interface ToggleButtonProps extends ButtonProps {
  isOpen: boolean;
}

export const ToggleButton = styled(Box, {
  shouldForwardProp: prop => prop !== 'isOpen',
})<ToggleButtonProps>(({ isOpen, theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '100%',
  transform: 'translate(-15%,-50%)',
  width: 18,
  path: {
    fill: theme.palette.sideNav.main,
  },
  cursor: 'pointer',
  '&:before': {
    content: isOpen ? '"<"' : '">"',
    position: 'absolute',
    color: theme.palette.white.main,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-60%)',
    lineHeight: '10px',
    width: 10,
    height: 10,
  },
}));
