import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { storeActions } from '../../../store';
import { appRoutes } from '../../../providers/routes';

import { UserGuardProps } from './component.typs';
import { USERTYPE } from '../../../constants/common';
import { useUser } from '../../../utils/hooks/use-user';

const UserGuard = ({ userTypes }: UserGuardProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const user = useAppSelector(storeActions.user.getUser);
  const { clientProfile } = useUser();
  const roleSearchParam = searchParams.get('role');

  useEffect(() => {
    if (userTypes && userTypes.includes(user.userType)) {
      const role = roleSearchParam;
      const roleName = clientProfile?.role?.name;

      setSearchParams(searchParams);
      if (role === roleName) {
        dispatch(storeActions.user.setUserType(role as USERTYPE));
      }
      return () => searchParams.delete('role');
    }
  }, [clientProfile?.role?.name]);

  if (userTypes ? !userTypes.includes(user.userType) : user.userType === USERTYPE.GUEST) {
    return (
      <Navigate
        replace
        to={appRoutes.auth.login}
        state={{ nextPathName: `${location.pathname}${roleSearchParam ? `?role=${roleSearchParam}` : ''}` }}
      />
    );
  }

  return <Outlet key={user.userType} />;
};

export default UserGuard;
