import { appColorOverrides, appColor } from './app-colors';

const globalOverridesStyle = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        padding: '10px 30px',
        height: 42,
        textTransform: 'capitalize',
        fontWeight: 400,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: 42,
        '&.MuiOutlinedInput-root': {
          borderRadius: '2px',
          padding: '6px !important',
        },
        '&.Mui-disabled': {
          opacity: '0.65',
        },
        '& input, .MuiChip-root, .MuiInputBase-input': {
          zIndex: 2,
        },
        '&.MuiInputBase-multiline, &.MuiAutocomplete-inputRoot': {
          height: 'auto',
        },
        '&:autofill': {
          backgroundColor: 'transparent',
        },
        '& input:not(.MuiAutocomplete-input)': {
          maxHeight: 0,
          maxWidth: '92%',
        },
      },
    },
  },

  MuiInputAdornment: {
    styleOverrides: {
      root: {
        zIndex: 2,
        marginRight: '8px',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontWeight: 300,
        fontSize: 12,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        borderRadius: '5px',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& input': {
          marginTop: '-5px',
        },
        '.MuiAutocomplete-endAdornment': {
          zIndex: 2,
        },
        '.MuiCircularProgress-root': {
          zIndex: 2,
          marginTop: '-5px',
        },
        '.MuiAutocomplete-popupIndicator': {
          color: 'inherit',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        // textTransform: 'lowercase',
      },
      paragraph: {
        marginBottom: 'unset',
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: '0',
        borderRadius: '0',
        '.MuiDataGrid-columnHeaders': {
          border: '0',
          background: appColorOverrides.waterloo.ultraLight,
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-cell': {
          border: '0',
        },
        '.MuiDataGrid-row.odd': {
          background: appColor.gray,
        },
        '.MuiDataGrid-row.odd:hover': {
          background: appColor.softGray,
        },
        '.MuiDataGrid-editInputCell input': {
          maxHeight: 'initial !important',
        },
      },
    },
  },
};

export default globalOverridesStyle;
