/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectRequest = {
    name: string;
    start_date: string;
    end_date?: string;
    target: number;
    status?: ProjectRequest.status;
    is_public?: boolean;
};

export namespace ProjectRequest {

    export enum status {
        ONGOING = 'ONGOING',
        CLOSED = 'CLOSED',
    }


}

