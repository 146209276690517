import { styled, TextField, Box, TextFieldProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';

const SearchBox = styled(Box)({
  position: 'relative',
  minWidth: '330px',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
    width: '100%',
    paddingRight: '35px',
    borderRadius: '50px',
  },
});
const SearchIcon = styled(Search)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: '20px',
  margin: 'auto 0',
  zIndex: 1,
});

const SearchInputBox: React.FC<TextFieldProps & { showIcon?: boolean }> = ({ showIcon = true, ...props }) => {
  return (
    <SearchBox>
      <TextField {...props} sx={{ borderRadius: '50px' }} />
      {showIcon && <SearchIcon />}
    </SearchBox>
  );
};

export default SearchInputBox;
