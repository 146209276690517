let timeout: NodeJS.Timeout | null = null;

// function debounce(func: (...args: any[]) => void, wait: number, immediate = false) {
//   return function (this: typeof func) {
//     // eslint-disable-next-line prefer-rest-params
//     const args = [...arguments] as [];
//     const later = () => {
//       timeout = null;
//       if (!immediate) func.apply(this, args);
//     };
//     const callNow = immediate && !timeout;
//     if (timeout) {
//       clearTimeout(timeout);
//     }
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(this, args);
//   };
// }

export const debounce = (func: (...args: any[]) => void, delay = 300): typeof func =>
  function (this: typeof func) {
    // eslint-disable-next-line prefer-rest-params
    const args = [...arguments] as [];
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func.apply(this, args), delay);
  };

// export default debounce;
