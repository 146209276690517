/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CVEducationRequest = {
    start_date: string;
    currently_studying: boolean;
    end_date?: string;
    university: string;
    field_of_study: string;
    /**
     * 1 for Diploma, 2 for Higher Diploma, 3 for Bachelor, 4 for Master and 5 for Doctoral
     */
    degree: CVEducationRequest.degree;
    description?: string;
};

export namespace CVEducationRequest {

    /**
     * 1 for Diploma, 2 for Higher Diploma, 3 for Bachelor, 4 for Master and 5 for Doctoral
     */
    export enum degree {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
    }


}

