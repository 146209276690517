import React, { useMemo } from 'react';
import { Box, styled } from '@mui/material';
import CreateCV from '../../utils/widgets/cv/create-cv';
import useFetch from '../../utils/hooks/use-fetch';
import { UserResource, CVRequest, CVResource, ApiCvService, ApiEmployeeService } from '../../api/main';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { appRoutes } from '../../providers/routes';
import { ApiErrorResponseType } from '../../utils/hooks/use-fetch/component.types';
import { useTranslation } from 'react-i18next';
import { AminCreateCVSubmissionData, UpdateUser } from './component.types';
import { LocationType } from '../../utils/widgets/forms/input-location/component.types';
import { useUser } from '../../utils/hooks/use-user';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const CreateCVPage = () => {
  const { isHR, clientProfile } = useUser();
  const createCvApi = useFetch<CVRequest & { user_id?: number }, CVResource>(({ user_id, ...body }) =>
    ApiCvService.apiCvCreate({ ...body, user_id: user_id })
  );
  const updateUser = ({ userId, data }: UpdateUser) => ApiEmployeeService.apiEmployeeUpdate(userId, data);
  const updateUserDataApi = useFetch<UpdateUser, UserResource>(updateUser);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialData = useMemo(() => {
    if (!clientProfile) {
      return {};
    }

    return {
      profileInformation: {
        firstName: clientProfile.first_name,
        lastName: clientProfile.last_name,
      },
      contactDetails: {
        ...(clientProfile.location ? { location: clientProfile.location as LocationType } : {}),
      },
    };
  }, [clientProfile]);

  const onSubmit = async ({
    location,
    first_name,
    last_name,
    soft_skills,
    hard_skills,
    employee,
    noEmployee,
    ...data
  }: AminCreateCVSubmissionData) => {
    const softSkillsId = Array.isArray(soft_skills) ? soft_skills.map(skill => skill.id) : [];
    const hardSkillsId = Array.isArray(hard_skills) ? hard_skills.map(skill => skill.id) : [];

    if (isHR && employee?.id) {
      await updateUserDataApi
        .call({
          userId: employee?.id,
          data: {
            first_name,
            last_name,
          },
        })
        .then(result => {
          enqueueSnackbar(t('form:update-profile.success'), {
            variant: 'success',
          });
          return Promise.resolve(result);
        })
        .catch((e: ApiErrorResponseType) => {
          let message = t('form:update-profile.failed');

          if (e?.body?.message) {
            message = e.body.message;
          }

          enqueueSnackbar(message, {
            variant: 'error',
          });
          return Promise.reject(e);
        });
    }
    return createCvApi
      .call({
        ...data,
        ...(noEmployee ? { first_name, last_name } : {}),
        user_id: isHR ? employee?.id : clientProfile?.id,
        location_id: location?.id,
        skills_ids: [...softSkillsId, ...hardSkillsId],
      })
      .then(result => {
        enqueueSnackbar(t('form:create-cv.success'), {
          variant: 'success',
        });
        navigate(appRoutes.cvList, { replace: true });
        return Promise.resolve(result);
      })
      .catch((e: ApiErrorResponseType) => {
        let message = t('form:create-cv.failed');

        if (e?.body?.message) {
          message = e.body.message;
        }

        enqueueSnackbar(message, {
          variant: 'error',
        });
        return Promise.reject(e);
      });
  };

  return (
    <Container>
      <CreateCV initialData={isHR ? undefined : initialData} loading={createCvApi.loading} onCreate={onSubmit} />
    </Container>
  );
};

export default CreateCVPage;
