const sharedTheme = {
  typography: {
    htmlFontSize: 18,
    fontFamily: 'Poppins , sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: 22,
    },
    h2: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 20,
    },
    h3: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 18,
    },
    h4: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
    },
    h5: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 14,
    },
    h6: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 12,
    },
    subtitle1: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
    },
    subtitle2: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22px',
    },
    body1: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 14,
    },
    body2: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    caption: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
    },
    overline: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 14,
    },
  },
};

export default sharedTheme;
