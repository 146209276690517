import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, CircularProgress, Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material';
import DashboardLayout from '../../../utils/widgets/layouts/dashboard-layout';
import UserPageHeader from '../../../utils/widgets/layouts/user-page-header';
import { StyledPaper } from '../../../utils/widgets/layouts/reports-layout';

import useFetch from '../../../utils/hooks/use-fetch';
import useErrorHandler from '../../../utils/hooks/use-error-handler';
import MonthRangePicker from '../../../utils/widgets/month-range-picker/component';
import ProjectsSelect from '../../../utils/widgets/projects-select/component';
import { OptionType } from '../../../utils/widgets/users-select/component.types';
import { format, eachMonthOfInterval, differenceInMonths, endOfMonth } from 'date-fns';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import {
  ApiProjectService,
  ProjectWorkLogDetailsResource,
  ProjectsWorkLogCollection,
  SingleProjectWorkLogDetailsResource,
} from '../../../api/main';
import { defaultColDef } from '../../../constants/report';
import { People } from '@mui/icons-material';
import useTable from '../../../utils/hooks/use-table';
import { CloseButton } from '../../../utils/widgets/confirmation-dialog/component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button from '../../../utils/widgets/button';
import { SingleProjectWorkLogDetailsResourceRow } from './component.types';

const ProjectsReport = () => {
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const getProjectsWorklog = useFetch<
    { from: Date | null; to: Date | null; project: OptionType | null },
    ProjectsWorkLogCollection
  >(({ from, to, project }) => {
    const start = from ? format(new Date(from?.toString()), 'yyyy-MM-dd') : undefined;
    const end = to ? format(new Date(to?.toString()), 'yyyy-MM-dd') : undefined;
    return ApiProjectService.apiProjectsWorkLogs(
      start,
      end,
      project ? (project as unknown as OptionType).value : undefined
    );
  });

  const selectedWorkLogId = useRef<number | undefined>();

  const getSingleProjectWorklog = useFetch<
    { from: Date | null; to: Date | null; project: OptionType | null },
    SingleProjectWorkLogDetailsResource
  >(({ from, to, project }) => {
    const start = from ? format(new Date(from?.toString()), 'yyyy-MM-dd') : undefined;
    const modifiedTo = to ? format(endOfMonth(new Date(to)), 'yyyy-MM-dd') : undefined;
    return ApiProjectService.apiSingleProjectWorkLogs(
      Number((project as unknown as OptionType).value),
      start,
      modifiedTo
    );
  });
  const { data, setData, filters, setFilters } = useTable<
    ProjectsWorkLogCollection['data'],
    { from: Date | null; to: Date | null; project: OptionType | null }
  >({
    initialData: [],
    initialFilters: { from: null, to: null, project: null },
    initialPage: 0,
    isInQuery: true,
  });

  const handleError = useErrorHandler();

  const handleSubmit = () => {
    if (!filters.project && filters.from && filters.to) {
      const months = differenceInMonths(new Date(filters.to), new Date(filters.from));
      if (months >= 12) {
        setError(`You can't select more than 12 month for all projects`);
        return;
      }
    }
    const handleSuccess = (result: ProjectsWorkLogCollection) => setData(result as ProjectsWorkLogCollection['data']);
    if ((filters.from && filters.to) || filters.project) {
      const modifiedTo = filters.to ? endOfMonth(new Date(filters.to)) : null;
      getProjectsWorklog
        .call(
          filters.to && filters.from
            ? { ...filters, to: modifiedTo }
            : { project: filters.project, to: null, from: null }
        )
        .then(handleSuccess)
        .catch(handleError);
    }
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    setError('');
  }, [filters]);

  // Transform the ugly data
  const workLogUsers = useMemo(() => {
    const workLogsData = (getSingleProjectWorklog.result as SingleProjectWorkLogDetailsResource) || { work_logs: [] };
    const workLogMonths = new Set<Date>();
    const actualData = workLogsData.work_logs?.map(({ dates, ...rest }) => {
      return {
        ...rest,
        dates: dates?.map(({ logged_hours, date }) => {
          const m = format(new Date(date || ''), 'MMMM yyyy');
          workLogMonths.add(new Date(date || ''));
          return {
            date: m,
            logged_hours,
          };
        }),
      };
    });
    return {
      actualData,
      workLogMonths: [
        ...new Set(
          [...workLogMonths]
            .sort((a, b) => new Date(a || '').valueOf() - new Date(b || '').valueOf())
            .map(d => format(new Date(d || ''), 'MMMM yyyy'))
        ),
      ],
    };
  }, [open]);

  const months = useMemo(() => {
    return filters.from && filters.to
      ? eachMonthOfInterval({ start: new Date(filters.from), end: new Date(filters.to) }).map(d =>
          format(d, 'MMMM yyyy')
        )
      : [];
  }, [getProjectsWorklog.loading]);

  const dynamicColumns: GridColDef<ProjectWorkLogDetailsResource>[] = useMemo(
    () =>
      months.length
        ? months.map(m => {
            return {
              ...defaultColDef,
              field: m,
              headerName: m,
              minWidth: 120,
              valueGetter({ row }) {
                const logs = row.work_logs?.map(log => ({
                  m: format(new Date(log.date || ''), 'MMMM yyyy'),
                  hours: log.logged_hours,
                }));
                return logs?.find(l => l.m === m)?.hours?.toFixed(1) || 0;
              },
            };
          })
        : filters.project && data?.length
        ? data[0].work_logs
            ?.sort((a, b) => new Date(a.date || '').valueOf() - new Date(b.date || '').valueOf())
            .map(w => {
              const m = format(new Date(w.date || ''), 'MMMM yyyy');
              return {
                ...defaultColDef,
                field: m,
                headerName: m,

                minWidth: 120,
                valueGetter() {
                  return w.logged_hours?.toFixed(1) || 0;
                },
              };
            }) || []
        : [],
    [months, data]
  );

  const dynamicColumns2: GridColDef<SingleProjectWorkLogDetailsResourceRow>[] = useMemo(
    () =>
      (filters.project && !filters.to && !filters.from ? workLogUsers.workLogMonths : months).map(m => {
        return {
          ...defaultColDef,
          field: m,
          headerName: m,
          minWidth: 140,
          valueGetter({ row }) {
            return row.dates?.find(l => l.date === m)?.logged_hours?.toFixed(1) || 0;
          },
        };
      }),
    [months, getSingleProjectWorklog.result]
  );

  const Columns: GridColDef<ProjectWorkLogDetailsResource>[] = [
    {
      ...defaultColDef,
      field: 'name',
      headerName: 'Project name',
      minWidth: 200,
    },
    {
      ...defaultColDef,
      field: 'total_logged_hours',
      headerName: 'Logged hours',

      minWidth: 110,
      valueFormatter({ value }) {
        return value ? Number(value).toFixed(1) : '';
      },
    },
    ...dynamicColumns,

    {
      ...defaultColDef,
      field: 'actions',
      headerName: '',
      minWidth: 100,

      renderCell({ row }) {
        return [
          <GridActionsCellItem
            icon={
              getSingleProjectWorklog.loading && selectedWorkLogId.current === row.id ? (
                <CircularProgress color="primary" size={20} sx={{ marginTop: '-5px', marginLeft: '3px' }} />
              ) : (
                <People />
              )
            }
            label="People"
            className="textPrimary"
            onClick={() => {
              selectedWorkLogId.current = row.id;
              void getSingleProjectWorklog
                .call(
                  filters.to && filters.from
                    ? { ...filters, project: { value: row.id, label: row.name || '' } }
                    : { project: { value: row.id, label: row.name || '' }, to: null, from: null }
                )
                .then(() => {
                  setOpen(true);
                })
                .finally(() => {
                  selectedWorkLogId.current = undefined;
                });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const ProjectEmployessColumns: GridColDef<SingleProjectWorkLogDetailsResourceRow>[] = [
    {
      ...defaultColDef,
      field: 'name',
      headerName: 'Employee',
      minWidth: 200,
    },
    {
      ...defaultColDef,
      field: 'total_hours',
      headerName: 'Hours',
      minWidth: 100,
      valueFormatter({ value }) {
        return value ? Number(value).toFixed(1) : '';
      },
    },
    {
      ...defaultColDef,
      field: 'position',
      headerName: 'Role',

      minWidth: 100,
      valueFormatter({ value }) {
        return value ? String(value) : '';
      },
    },
    ...dynamicColumns2,
  ];

  return (
    <DashboardLayout>
      <UserPageHeader title={t('common:projects')} />
      <StyledPaper>
        <Stack direction="row" alignItems="center" justifyItems="center" gap={2}>
          {/* <Typography variant="h5">From</Typography> */}
          <MonthRangePicker
            views={['year', 'month']}
            onChange={() => {}}
            onMonthChange={value => {
              if (value) {
                setFilters({ ...filters, from: value });
              }
            }}
            onClear={() => setFilters({ ...filters, from: null })}
            value={filters.from ? new Date(filters.from) : null}
            renderInput={() => <div />}
          >
            {filters.from ? format(new Date(filters.from), 'MMMM yyyy') : 'From'}
          </MonthRangePicker>
          {/* <Typography variant="h5">To</Typography> */}
          <MonthRangePicker
            views={['year', 'month']}
            onChange={() => {}}
            onMonthChange={value => {
              if (value) {
                setFilters({ ...filters, to: value });
              }
            }}
            onClear={() => setFilters({ ...filters, to: null })}
            minDate={filters.from ? filters.from : undefined}
            value={filters.to}
            renderInput={() => <div />}
          >
            {filters.to ? format(new Date(filters.to), 'MMMM yyyy') : 'To'}
          </MonthRangePicker>
          <ProjectsSelect
            value={filters.project as unknown as OptionType}
            onChange={value => {
              setFilters({ ...filters, project: value || null });
            }}
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            hasIcon
            disabled={
              (!filters.from && !filters.to && !filters.project) ||
              Boolean(filters.from && !filters.to) ||
              Boolean(filters.to && !filters.from) ||
              Boolean(error)
            }
            loading={getProjectsWorklog.loading}
          >
            <Stack direction="row" alignItems="center">
              Submit
            </Stack>
          </Button>
        </Stack>
        {error && (
          <Alert sx={{ marginTop: '16px' }} severity="error">
            {error}
          </Alert>
        )}
        <Divider sx={{ margin: '30px 0' }} />
        {data?.length ? (
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            columns={Columns}
            rows={data}
            disableSelectionOnClick
            loading={getProjectsWorklog.loading}
            hideFooter
            getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          />
        ) : getProjectsWorklog.loaded && data?.length === 0 ? (
          <Typography variant="h4" sx={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
            No Result
          </Typography>
        ) : null}
        <Dialog
          sx={{ '& .MuiDialog-paper': { minWidth: '80vw', minHeight: '60vh' } }}
          maxWidth="md"
          open={open}
          onClose={() => setOpen(false)}
        >
          <CloseButton onClick={() => setOpen(false)} aria-label="close-modal">
            <CloseOutlinedIcon />
          </CloseButton>
          <DialogContent sx={{ margin: '70px 10px 0', padding: '0 10px', height: '100%', display: 'flex' }}>
            <DataGrid
              disableColumnFilter
              disableColumnMenu
              key={String(open)}
              sx={{ height: '59vh' }}
              columns={ProjectEmployessColumns}
              rows={workLogUsers.actualData || []}
              disableSelectionOnClick
              loading={getSingleProjectWorklog.loading}
              hideFooter
              getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            />
          </DialogContent>
        </Dialog>
      </StyledPaper>
    </DashboardLayout>
  );
};

export default ProjectsReport;
