/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeCVStatusRequest } from '../models/ChangeCVStatusRequest';
import type { CVRequest } from '../models/CVRequest';
import type { CVResource } from '../models/CVResource';
import type { CVsCollection } from '../models/CVsCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiCvService {

    /**
     * CVs List
     * Paginated list of cvs.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param searchQuery
     * @param skillIds Comma separated list of skill ids
     * @returns CVsCollection cv collection response
     * @throws ApiError
     */
    public static apiCvIndex(
        page?: number,
        pageSize?: number,
        searchQuery?: string,
        skillIds?: string,
    ): CancelablePromise<CVsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cvs',
            query: {
                'page': page,
                'pageSize': pageSize,
                'search_query': searchQuery,
                'skill_ids': skillIds,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create CV
     * This endpoint take cv data and create it for the given user.
     * @param requestBody create/update cv request
     * @returns CVResource create/update cv response
     * @throws ApiError
     */
    public static apiCvCreate(
        requestBody: CVRequest,
    ): CancelablePromise<CVResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/cvs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Change CV Status
     * This endpoint change cv status.
     * @param cvId CV identifier.
     * @param requestBody change cv status request
     * @returns CVResource create/update cv response
     * @throws ApiError
     */
    public static apiCvChangeStatus(
        cvId: number,
        requestBody: ChangeCVStatusRequest,
    ): CancelablePromise<CVResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/cvs/change-status/{cv_id}',
            path: {
                'cv_id': cvId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Show CV
     * This endpoint take cv identifier and show it.
     * @param cvId CV identifier.
     * @returns CVResource create/update cv response
     * @throws ApiError
     */
    public static apiCvShow(
        cvId: number,
    ): CancelablePromise<CVResource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cvs/{cv_id}',
            path: {
                'cv_id': cvId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Delete CV
     * This endpoint take cv identifier and delete it.
     * @param cvId CV identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiCvDelete(
        cvId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/cvs/{cv_id}',
            path: {
                'cv_id': cvId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Update CV
     * This endpoint take cv data and update it.
     * @param cvId CV identifier.
     * @param requestBody create/update cv request
     * @returns CVResource create/update cv response
     * @throws ApiError
     */
    public static apiCvUpdate(
        cvId: number,
        requestBody: CVRequest,
    ): CancelablePromise<CVResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/cvs/{cv_id}',
            path: {
                'cv_id': cvId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Generate CV PDF
     * This endpoint take cv identifier and generate its pdf.
     * @param cvId CV identifier.
     * @returns any Return pdf uri.
     * @throws ApiError
     */
    public static apiCvGeneratePdf(
        cvId: number,
    ): CancelablePromise<{
        uri?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cvs/generate-pdf/{cv_id}',
            path: {
                'cv_id': cvId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * CVs List
     * Paginated user cv.
     * @param userId User identifier.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param searchQuery
     * @returns CVsCollection cv collection response
     * @throws ApiError
     */
    public static apiCvGetUserCv(
        userId: number,
        page?: number,
        pageSize?: number,
        searchQuery?: string,
    ): CancelablePromise<CVsCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cvs/users/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'page': page,
                'pageSize': pageSize,
                'search_query': searchQuery,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
