import React, { useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import ConfirmationDialog from '../../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/use-form';
import { FinishWorkHourForm, FinishWorkHourModalFormProps } from './component.types';
import { Delete } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, styled, Typography } from '@mui/material';
import { addMinutes, differenceInMinutes, isSameDay, startOfDay } from 'date-fns';
import BasicTimePicker from '../../time-picker/component';
import Button from '../../button';
import Autocomplete from '../../forms/autocomplete';
import HoursPicker from '../../hours-picker';
import useErrorHandler from '../../../hooks/use-error-handler';
import { formatMinutes } from '../../../tools/date';
import useFetch from '../../../hooks/use-fetch';
import { ApiProjectService, AdminProjectResource } from '../../../../api/main';
import { CardHeading } from '../../../../components/admin/employee-overview/styled';
import CollidingBox from '../../colliding-box/component';

const RowBox = styled(Stack)({
  width: '100%',
  margin: '20px 0',
  '> *': {
    marginRight: '5px',
  },
  '> button': {
    alignSelf: 'start',
  },
});
const WorkedHoursBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  border: `0.5px solid ${theme.palette.lines.main}`,
  borderRadius: '2px',
  padding: '10px',
  '> *': {
    margin: '0',
  },
}));

const workHourFormSchema = Yup.object().shape({
  finish_time: Yup.date().required(),
  projects: Yup.array(
    Yup.object().shape({
      hours: Yup.date().required(),
      project: Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      }),
    })
  ),
});

const FinishWorkHourModalForm = ({
  onSubmit,
  open,
  onCancel,
  initialData,
  isLoading,
  collidingInfo,
}: FinishWorkHourModalFormProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleError = useErrorHandler();
  const getAllProjects = useFetch(ApiProjectService.appProjectAssigned);
  const projectsOptionsRef = useRef<AdminProjectResource[]>([]);

  const { handleSubmit, formDebugger, control, setValue, setError } = useForm({
    name: 'work-hour-modal-form',
    schema: workHourFormSchema,
    defaultValues: {
      finish_time: initialData?.finish_time,
      projects: [{ hours: startOfDay(new Date()) }],
    },
  });
  const { fields, remove, append } = useFieldArray({ control, name: 'projects' });
  const formData = useWatch({ control }) as FinishWorkHourForm;

  const avaiableHours = useMemo(() => {
    const d1 = new Date(formData.finish_time || new Date());
    const d2 = new Date(initialData?.start_time || new Date());
    if (Number.isNaN(d1.valueOf())) return 0;
    d1.setSeconds(0);
    d2.setSeconds(0);
    d1.setMilliseconds(0);
    d2.setMilliseconds(0);

    return differenceInMinutes(d1, d2);
  }, [formData]);

  useEffect(() => {
    const baseDate = startOfDay(new Date());
    setValue('projects', [{ hours: addMinutes(baseDate, avaiableHours) }]);
  }, [formData.finish_time]);

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  useEffect(() => {
    if (!projectsOptionsRef.current.length) {
      getAllProjects
        .call()
        .then(({ data = [] }) => {
          projectsOptionsRef.current = data.map(p => ({ ...p, users: [] }));
        })
        .catch(handleError);
    }
  }, [handleError]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onOk={handleOnSubmit}
      isOkLoading={isLoading}
      title={''}
      onOkText="common:submit"
      width="24%"
    >
      <form
        ref={formRef}
        aria-label="vacation-modal-form"
        onSubmit={handleSubmit(
          values => {
            const baseDate = startOfDay(new Date());
            const diff =
              avaiableHours - formData.projects.reduce((acc, p) => acc + differenceInMinutes(p.hours, baseDate), 0);
            const { finish_time: endTime } = values;
            const endDate = new Date(endTime as string);

            if (endDate.valueOf() > Date.now()) {
              setError('finish_time', { message: `The finish time cannot be greater than the current time.` });
              return;
            }
            if (diff) {
              return Promise.reject(
                t('form:available-hours-error', {
                  value: formatMinutes(diff).formatted,
                })
              );
            } else return onSubmit(values as FinishWorkHourForm);
          },
          false,
          err => {
            if (typeof err === 'string') {
              void handleError(err);
            }
          }
        )}
      >
        {formDebugger()}
        <CollidingBox data={collidingInfo} />
        <Box>
          <BasicTimePicker
            defaultValue={initialData?.start_time}
            control={control}
            name={'start_time'}
            label={t('form:start-time')}
            fullWidth
            readonly
          />
        </Box>
        <Box>
          <BasicTimePicker
            control={control}
            name={'finish_time'}
            label={t('form:finish-time')}
            fullWidth
            required
            minTime={new Date(initialData?.start_time || new Date())}
            maxTime={
              isSameDay(new Date(initialData?.finish_time || new Date()), new Date(new Date())) ? new Date() : undefined
            }
          />
        </Box>
        <Divider sx={{ margin: '30px 0' }} />
        <WorkedHoursBox>
          <CardHeading variant="h4">{t('form:worked-hours')}</CardHeading>
          <Typography variant="subtitle2" component="span">
            {formatMinutes(avaiableHours).formatted}
          </Typography>
        </WorkedHoursBox>
        {fields.map((field, idx) => {
          const baseDate = startOfDay(new Date());
          const sumOfHours = formData.projects
            .filter((_, i) => i !== idx)
            .reduce((acc, p) => acc + differenceInMinutes(p.hours, baseDate), 0);

          return (
            <RowBox direction="row" key={field.id}>
              <Autocomplete
                name={`projects[${idx}].project`}
                control={control}
                optionKeys={{
                  label: ['name'],
                  value: 'id',
                }}
                placeholder={t('form:choose-project')}
                options={projectsOptionsRef.current
                  .filter(option => formData.projects.every(p => p.project?.id !== option.id))
                  .map(({ id, name }) => ({ id, name }))}
                loading={false}
                onSearch={() => {}}
                errorMessage="Please choose a project"
              />

              <HoursPicker
                key={formData.finish_time}
                maxTime={addMinutes(baseDate, avaiableHours - sumOfHours)}
                control={control}
                name={`projects[${idx}].hours`}
              />

              <IconButton onClick={() => remove(idx)} disabled={idx === 0}>
                <Delete color={idx === 0 ? 'disabled' : 'error'} />
              </IconButton>
            </RowBox>
          );
        })}

        <Box sx={{ marginBottom: '70px' }}>
          <Button
            loading={isLoading}
            variant="outlined"
            color="inherit"
            hasIcon
            sx={{ marginRight: '10px' }}
            onClick={() => {
              const baseDate = startOfDay(new Date());
              append({ hours: baseDate, project: null });
            }}
          >
            {t('form:add-another')}
          </Button>
        </Box>
      </form>
    </ConfirmationDialog>
  );
};

export default FinishWorkHourModalForm;
