import { Box, styled } from '@mui/material';

const PageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '20px',
});

export default PageContainer;
