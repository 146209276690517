import { Box, styled, Typography, Divider as MuiDivider, Stack, Paper, Chip } from '@mui/material';
import { THEME } from '../../../constants/theme';
import PageContainer from '../layouts/page-container';

const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '1010px',
});

const FormWrapper = styled(Box)({
  padding: '40px 90px 100px',
});

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const PageBody = styled(Paper)({
  padding: '100px 10vw',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const Divider = styled(MuiDivider)({
  margin: '80px 0',
  width: '120%',
  transform: 'translate(-10%,0)',
});

const SectionTitle = styled(Typography)({
  marginBottom: '30px',
  display: 'block',
});

SectionTitle.defaultProps = {
  variant: 'h2',
  paragraph: true,
};

const Row = styled(Stack)({});

Row.defaultProps = {
  spacing: 2,
  direction: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
};

const Cell = styled(Box)({
  flex: 1,
  flexDirection: 'column',
});

const ActionsWrapper = styled(Stack)({
  marginTop: '60px',
});

ActionsWrapper.defaultProps = {
  spacing: 2,
  direction: 'row',
};

const Label = styled(Typography)(({ theme }) => {
  const {
    palette: { mode, black, white },
  } = theme;

  return {
    marginRight: '8px',
    color: mode === THEME.LIGHT ? black.lighter : white.main,
  };
});

Label.defaultProps = {
  variant: 'body1',
};

const ListWrapper = styled(Stack)({});

ListWrapper.defaultProps = {
  spacing: 2,
  margin: '20px 0',
  width: '100%',
};

const ListTitle = styled(Typography)({
  display: 'block',
});

ListTitle.defaultProps = {
  variant: 'subtitle2',
  paragraph: true,
};

const ListText = styled(Typography)(({ theme }) => {
  const {
    palette: { mode, black, white },
  } = theme;

  return {
    display: 'inline-block',
    color: mode === THEME.LIGHT ? black.light : white.main,
  };
});

ListText.defaultProps = {
  variant: 'body1',
  paragraph: true,
};

const Status = styled(Typography)(({ theme }) => {
  const {
    palette: { secondary, text1, white, mode },
  } = theme;

  const mainColor = mode === THEME.LIGHT ? secondary.main : text1.main;
  const borderColor = mode === THEME.LIGHT ? secondary.lighter : text1.main;
  const altColor = mode === THEME.LIGHT ? secondary.ultraLight : white.ultraLight;

  return {
    color: mainColor,
    backgroundColor: altColor,
    padding: '2px 10px',
    border: `0.5px solid ${borderColor}`,
    borderRadius: '3px',
    marginRight: '14px',
  };
});

Status.defaultProps = {
  variant: 'body1',
  paragraph: true,
};

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.chipBgColor.main,
  ...theme.typography.body1,
}));

StyledChip.defaultProps = {
  variant: 'outlined',
};

export {
  SectionTitle,
  Divider,
  ActionsWrapper,
  PageContainer,
  FormContainer,
  FormWrapper,
  Row,
  Cell,
  PageWrapper,
  PageBody,
  Label,
  ListWrapper,
  ListTitle,
  ListText,
  Status,
  StyledChip,
};
