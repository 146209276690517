import { useRef } from 'react';
import ConfirmationDialog from '../../confirmation-dialog';
import { CreateSkillFormValues, CreateSkillModalProps } from './component.types';
import * as Yup from 'yup';
import useForm from '../../../hooks/use-form';
import useErrorHandler from '../../../hooks/use-error-handler';
import FormItemText from '../../forms/form-item-text';
import { TextField } from '@mui/material';
import Autocomplete from '../../forms/autocomplete';
import { SKILL_TYPES } from '../../../../constants/common';

const CreateSkillModal: React.FC<CreateSkillModalProps> = ({ initialData, onCancel, onSubmit, open, loading }) => {
  const handleError = useErrorHandler();
  const { handleSubmit, formDebugger, control } = useForm({
    name: 'edit-skill-name',
    schema: Yup.object().shape({
      skill_name: Yup.string().required(),
      skill_type: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
    }),
  });
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };
  return (
    <ConfirmationDialog
      width="30%"
      padding="10px"
      onOk={() => {
        handleOnSubmit();
      }}
      onCancel={onCancel}
      title={initialData ? 'Edit Skill' : 'Create Skill'}
      isOkLoading={loading}
      open={open}
    >
      <>
        {formDebugger()}
        <form
          ref={formRef}
          aria-label="create-proejct-form"
          onSubmit={handleSubmit(
            values => {
              return onSubmit(values as CreateSkillFormValues);
            },
            false,
            err => {
              if (typeof err === 'string') {
                void handleError(err);
              }
            }
          )}
        >
          <FormItemText
            defaultValue={initialData?.name}
            control={control}
            required
            name="skill_name"
            label="Skill name"
          >
            <TextField type="text" />
          </FormItemText>
          <Autocomplete
            label="Skill type"
            name="skill_type"
            defaultValue={initialData ? SKILL_TYPES.find(s => s.label === initialData?.type) : undefined}
            control={control}
            required
            optionKeys={{
              label: ['label'],
              value: 'value',
            }}
            placeholder="Choose a type"
            options={SKILL_TYPES}
            loading={false}
            onSearch={() => {}}
          />
        </form>
      </>
    </ConfirmationDialog>
  );
};

export default CreateSkillModal;
