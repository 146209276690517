export enum COMMON {
  NODATA = ' - ',
  DASH = '-',
  SLASH = '/',
  COLON = ':',
}

export enum PAGINATION {
  PAGE_SIZE = 17,
}

export enum SKILLTYPE {
  SOFT = '1',
  HARD = '2',
}

export enum USERTYPE {
  EMPLOYEE = 'employee',
  PM = 'pm',
  HR = 'hr',
  GUEST = 'guest',
}

export enum EMPLOYEESTYPE {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
  GIVING_BIRTH = 'Giving birth leave',
  CHILD_CARE_LEAVE = 'Child care leave',
}

export enum USERTYPEMAP {
  admin = 'Admin',
  normal = 'User',
  guest = 'User',
}
export const HOURS = [
  { value: 4, label: '4 hours' },
  { value: 8, label: '8 hours' },
];
export const HOLIDAY_HOURS = [
  { value: 1, label: '1 hour' },
  { value: 2, label: '2 hours' },
  { value: 3, label: '3 hours' },
  { value: 4, label: '4 hours' },
  { value: 5, label: '5 hours' },
  { value: 6, label: '6 hours' },
  { value: 7, label: '7 hours' },
  { value: 8, label: '8 hours' },
];
export const PART_OF_DAY = [
  { value: 5 * 60, label: 'First half' },
  { value: 15.5 * 60, label: 'Second half' },
];
export const SPECIAL_VACATION_TYPES = [
  { value: 1, label: 'Birthday' },
  { value: 2, label: 'Loss of relatives' },
  { value: 2, label: 'Wedding' },
];

export const ROLES = [
  { value: 'employee', label: 'Employee' },
  { value: 'hr', label: 'HR' },
  { value: 'pm', label: 'PM' },
];
export const EMPLOYEE_STATUS = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Archived' },
  { value: 3, label: 'Giving Birth Leave' },
  { value: 4, label: 'Child Care Leave' },
];

export const SKILL_TYPES = [
  { value: 1, label: 'Soft' },
  { value: 2, label: 'Hard' },
];
export const IS_PROD = process.env.NODE_ENV === 'production';
