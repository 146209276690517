import React from 'react';
import { SkillsFormProps, SkillsFormSchemaTypes } from './component.types';
import { Cell, FormContainer } from '../styled';
import { Paper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COMMON, SKILLTYPE } from '../../../../constants/common';
import InputSkills from '../../forms/input-skills';
import * as Yup from 'yup';

const Wrapper = styled(Paper)(({ theme }) => ({
  margin: '20px 0',
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.chipBgColor.main,
}));

export const skillSchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().ensure().required(),
  type: Yup.string().ensure().required(),
  created_at: Yup.string().ensure().required(),
  updated_at: Yup.string().ensure().required(),
});

export const skillsFormSchema: SkillsFormSchemaTypes = Yup.object().shape({
  hard_skills: Yup.array(skillSchema).min(2),
  soft_skills: Yup.array(skillSchema).min(2),
});

const SkillsForm = ({ control, userType }: SkillsFormProps) => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <Cell>
        <Wrapper>
          <InputSkills
            userType={userType}
            control={control}
            name="hard_skills"
            label={`${t('form:cv.core-competencies')}${COMMON.COLON}`}
            skillType={SKILLTYPE.HARD}
          />
        </Wrapper>
        <Wrapper>
          <InputSkills
            userType={userType}
            control={control}
            name="soft_skills"
            label={`${t('form:cv.soft-skills')}${COMMON.COLON}`}
            skillType={SKILLTYPE.SOFT}
          />
        </Wrapper>
      </Cell>
    </FormContainer>
  );
};

export default SkillsForm;
