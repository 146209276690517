import { useEffect } from 'react';
import { storeActions } from '../../../store';
import { useAppDispatch } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../providers/routes';

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(storeActions.user.deleteUser());
    navigate(appRoutes.auth.login);
  }, [dispatch, navigate]);

  return null;
};

export default Logout;
