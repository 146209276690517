import React, { useMemo, useRef, useState } from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { Delete, Edit, WatchLater } from '@mui/icons-material';
import { ApiWorkingHourService } from '../../../../api/main';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { getDateDuration } from '../../../tools/date';
import { useTranslation } from 'react-i18next';
import { defaultColDef } from '../../../../constants/report';
import { addMinutes, format, isSameDay } from 'date-fns';
import { Divider, IconButton, Stack, Typography, Switch } from '@mui/material';
import { EmployeeMonthlyWorkingHoursProps, WorkingHourResourceClient } from './component.types';
import ConfirmationDialog from '../../confirmation-dialog';
import { useSnackbar } from 'notistack';
import useFetch from '../../../hooks/use-fetch';
import useErrorHandler from '../../../hooks/use-error-handler';
import { useUser } from '../../../hooks/use-user';
import appColor from '../../../../styles/themes/app-colors';
import { IS_PROD } from '../../../../constants/common';

const ActivityMap: Record<string, { color: string; title: string; bgColor: string }> = {
  'day-off': { title: 'day off', color: appColor.green, bgColor: '#1ECD261A' },
  'missed-day': { title: 'Missed Day', color: appColor.red, bgColor: '#EE27281A' },
  vacation: { title: 'Vacation', color: appColor.green, bgColor: '#1ECD261A' },
  'sick-leave': { title: 'Sick leave', color: appColor.yellow, bgColor: '#EE27281A' },
  holiday: { title: 'Holiday', color: '#AC6CFF', bgColor: '#AC6CFF1A' },
};

const TimeColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: '8px',
  },
});

const ActivitesGrid: React.FC<EmployeeMonthlyWorkingHoursProps> = ({
  data: rawData,
  handleDelete,
  handleEdit,
  isLoading,
}) => {
  const { isHR } = useUser();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const itemIdRef = useRef<number | string | undefined>(undefined);
  const deleteItem = useFetch(ApiWorkingHourService.apiWorkingHourDelete);
  const [tableTheme, setTableTheme] = useState('V1');

  const handleError = useErrorHandler();
  const data: WorkingHourResourceClient[] = useMemo(() => {
    let lastRowClassName = 'even';
    return (
      rawData.data
        ?.map((item, idx) => {
          const id = `${item.id}${Math.random()}${item.date}${item.type}` as unknown as number;
          let rowClassName = tableTheme === 'V2' ? String(item.type) : lastRowClassName === 'even' ? 'odd' : 'even';
          if (tableTheme === 'V1') {
            const rowDate = new Date(item.date || '');
            const prevRowDate = new Date(
              (rawData.data && rawData.data[idx - 1] && rawData.data[idx - 1].date) || item.date || ''
            );

            if (isSameDay(prevRowDate, rowDate)) {
              rowClassName = lastRowClassName;
            }
          }
          lastRowClassName = rowClassName;
          return {
            ...item,
            project: item.working_hour_projects?.length
              ? {
                  project: {
                    name: `</br>${item.working_hour_projects
                      .map(project => {
                        const hoursValue = Number(project.minutes);
                        const duration = getDateDuration({
                          end: addMinutes(new Date(), hoursValue),
                          start: new Date(),
                        });
                        return `${project.project?.name} / ${duration}`;
                      })
                      .join('</br></br>')}</br></br>`,
                    id: Math.random(),
                  },
                  minutes: '0',
                }
              : null,
            rowClassName,
            id,
            _id: item.id,
          };
        })
        .flat() || []
    );
  }, [rawData, tableTheme]);

  const Columns: GridColDef<WorkingHourResourceClient>[] = [
    {
      ...defaultColDef,
      field: 'date',
      headerName: t('table:common.date'),
      renderCell({ row }) {
        const { type } = row;
        const { color } = ActivityMap[type as string] || {};
        return (
          <Box sx={tableTheme === 'V1' ? { color } : {}}>
            {format(new Date((row.date as string).replace(' ', 'T')), 'd EEEE')}
          </Box>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'type',
      headerName: t('table:monthly-overview.activity'),

      renderCell({ row }) {
        const { type, project, hours } = row;
        const { color } = ActivityMap[type as string] || {};
        const HalfFullString = Number(hours) === 4 ? 'Half ' : type === 'day-off' ? 'Full ' : '';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const vacationDescription = (row as any)?.description as string;
        return (
          <Box className={gridClasses.cellContent} sx={tableTheme === 'V1' ? { color } : {}}>
            {color ? (
              vacationDescription ? (
                `${vacationDescription} vacation`
              ) : (
                '' || `${type !== 'missed-day' ? HalfFullString : ''}${ActivityMap[type as string].title}`
              )
            ) : (
              <div dangerouslySetInnerHTML={{ __html: project?.project?.name || '' }} />
            )}
          </Box>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'start_time',
      headerName: t('table:working-hours.start-time'),
      renderCell({ value, row }) {
        const isHalfDayOff = row.type === 'day-off' && row.hours === 4;
        return (
          <TimeColumnBox>
            <WatchLater color="disabled" />
            {value && !isHalfDayOff ? format(new Date(value as string), 'HH:mm') : '-'}
          </TimeColumnBox>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'end_time',
      headerName: t('table:working-hours.finish-time'),

      renderCell({ value, row }) {
        const isHalfDayOff = row.type === 'day-off' && row.hours === 4;
        return (
          <TimeColumnBox>
            <WatchLater color="disabled" />
            {value && !isHalfDayOff ? format(new Date(value as string), 'HH:mm') : '-'}
          </TimeColumnBox>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'duration',
      headerName: t('table:common.duration'),
      width: 50,
      valueGetter({ row }) {
        const { hours } = row;
        const hoursValue = Number(hours) * 60 || 0;
        return getDateDuration({
          end: addMinutes(new Date(), hoursValue),
          start: new Date(),
        });
      },
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        return row.type === 'work-log' ? (
          <Stack direction="row">
            <IconButton
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { _id } = row;
                handleEdit({ ...row, id: _id });
              }}
            >
              <Edit color="info" />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <IconButton
              onClick={() => {
                itemIdRef.current = Number(row._id);
                setOpen(true);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Stack>
        ) : null;
      },
    },
  ];
  return (
    <>
      {!IS_PROD && <Switch onChange={() => setTableTheme(tableTheme === 'V1' ? 'V2' : 'V1')} />}
      <DataGrid
        getRowHeight={row => {
          return row.model.project ? 'auto' : undefined;
        }}
        sx={
          tableTheme === 'V2'
            ? {
                '.MuiDataGrid-row.holiday': {
                  background: ActivityMap.holiday.bgColor,
                },
                '.MuiDataGrid-row.day-off': {
                  background: ActivityMap['day-off'].bgColor,
                },

                '.MuiDataGrid-row.sick-leave': {
                  background: ActivityMap['sick-leave'].bgColor,
                },
                '.MuiDataGrid-row.vacation': {
                  background: ActivityMap.vacation.bgColor,
                },
                '.MuiDataGrid-row.missed-day': {
                  background: ActivityMap['missed-day'].bgColor,
                },
                '.MuiDataGrid-row:hover': {
                  filter: 'brightness(60%)',
                },
              }
            : {}
        }
        columns={Columns.slice(0, isHR ? Columns.length : Columns.length - 1)}
        rows={data}
        hideFooterPagination
        rowCount={data.length}
        disableSelectionOnClick
        getRowClassName={({ row }) => {
          return row.rowClassName;
        }}
        columnBuffer={8}
        loading={isLoading}
      />
      <ConfirmationDialog
        width="30%"
        padding="10px"
        onOk={() => {
          if (itemIdRef.current) {
            deleteItem
              .call(Number(itemIdRef.current))
              .then(() => {
                enqueueSnackbar(t('form:general-submission.success'), {
                  variant: 'success',
                });
                handleDelete(itemIdRef.current as number);
              })
              .catch(handleError)
              .finally(() => {
                setOpen(false);
                itemIdRef.current = undefined;
              });
          }
        }}
        onCancel={() => {
          setOpen(false);
          itemIdRef.current = undefined;
        }}
        title=""
        open={open}
      >
        <Typography variant="h4" paragraph>
          {t('form:delete-confirm')}
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default ActivitesGrid;
