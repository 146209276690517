import { memo } from 'react';
import { ProfileInformationPreviewProps } from './component.types';
import { IconButton, Typography } from '@mui/material';
import { Cell, Label, PageContainer, Row, SectionTitle } from '../styled';
import { useTranslation } from 'react-i18next';
import { COMMON } from '../../../../constants/common';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import When from '../../when';

const ProfileInformationPreview = ({ data, onEdit, isLoading = false }: Partial<ProfileInformationPreviewProps>) => {
  const { t } = useTranslation();

  return (
    <PageContainer
      sx={{
        padding: 0,
      }}
    >
      <Row>
        <Cell
          sx={{
            flex: 1.5,
          }}
        >
          <SectionTitle>
            {data?.first_name && data?.last_name ? [data.first_name, data.last_name].join(' ') : COMMON.NODATA}
            <When condition={!!onEdit}>
              <IconButton disabled={isLoading} onClick={onEdit} sx={{ margin: '0 10px' }}>
                <EditOutlinedIcon />
              </IconButton>
            </When>
          </SectionTitle>
        </Cell>
        <Cell>
          <Label>{t('form:email-address')}:</Label>
          <Typography variant="body1">{data?.email ? data?.email : COMMON.NODATA}</Typography>
        </Cell>
      </Row>
      <Row>
        <Cell
          sx={{
            flex: 1.5,
          }}
        >
          <Label>{data?.title ? data?.title : COMMON.NODATA}</Label>
        </Cell>
      </Row>
      <Row
        sx={{
          marginTop: '30px',
        }}
      >
        <Cell>
          <SectionTitle>{t('common:about')}:</SectionTitle>
          <Typography variant="body1"> {data?.introduction ? data?.introduction : COMMON.NODATA} </Typography>
        </Cell>
      </Row>
    </PageContainer>
  );
};

export default memo(ProfileInformationPreview);
