/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyAnnualHolidaysRequest } from '../models/CopyAnnualHolidaysRequest';
import type { HolidayRequest } from '../models/HolidayRequest';
import type { HolidayResource } from '../models/HolidayResource';
import type { HolidaysCollection } from '../models/HolidaysCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiHolidayService {

    /**
     * Copy Holidays from one year to another
     * @param requestBody Copy annual holidays request
     * @returns void
     * @throws ApiError
     */
    public static apiHolidayCopy(
        requestBody: CopyAnnualHolidaysRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/holidays/copy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Holidays List
     * Paginated list of all holidays.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param locationId
     * @param name
     * @param from
     * @param to
     * @returns HolidaysCollection Holiday collection response
     * @throws ApiError
     */
    public static apiHolidayIndex(
        page?: number,
        pageSize?: number,
        locationId?: number,
        name?: string,
        from?: string,
        to?: string,
    ): CancelablePromise<HolidaysCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/holidays',
            query: {
                'page': page,
                'pageSize': pageSize,
                'location_id': locationId,
                'name': name,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Create Holiday
     * This endpoint take holiday data and create it.
     * @param requestBody Create/update holiday request
     * @returns HolidayResource Create/update holiday response
     * @throws ApiError
     */
    public static apiHolidayCreate(
        requestBody: HolidayRequest,
    ): CancelablePromise<HolidayResource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/holidays',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Delete Holiday
     * This endpoint take holiday identifier and delete it.
     * @param holidayId Holiday identifier.
     * @returns void
     * @throws ApiError
     */
    public static apiHolidayDelete(
        holidayId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/holidays/{holiday_id}',
            path: {
                'holiday_id': holidayId,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
    }

    /**
     * Update Holiday
     * This endpoint take holiday data and update it.
     * @param holidayId Holiday identifier.
     * @param requestBody Create/update holiday request
     * @returns HolidayResource Create/update holiday response
     * @throws ApiError
     */
    public static apiHolidayUpdate(
        holidayId: number,
        requestBody: HolidayRequest,
    ): CancelablePromise<HolidayResource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/holidays/{holiday_id}',
            path: {
                'holiday_id': holidayId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
