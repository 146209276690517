import React from 'react';
import { StepperProps, StepProps } from './component.types';

import Box from '@mui/material/Box';
import { styled, Typography } from '@mui/material';
import { THEME } from '../../../../constants/theme';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Step = styled(Box, {
  shouldForwardProp: propName => propName !== 'isActive',
})<StepProps>(({ theme, isActive = false }) => {
  const {
    palette: { mode, lightBlack, secondary, lavender, text1 },
  } = theme;

  const isLight = mode === THEME.LIGHT;

  const borderWidth = 1.5;
  let bgColors = 'transparent';
  let borderColor = isLight ? lightBlack.main : lavender.main;
  let color = isLight ? lightBlack.main : lavender.main;

  if (isActive) {
    borderColor = isLight ? secondary.main : lavender.main;
    bgColors = isLight ? secondary.main : lavender.main;
    color = isLight ? borderColor : text1.main;
  }

  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '33.2%',
    margin: '5px 20px',
    height: '70px',
    borderBottom: `${borderWidth}px solid ${borderColor}`,
    color: color,
    '&:before': {
      content: '""',
      width: 20,
      height: 20,
      minWidth: 20,
      minHeight: 20,
      borderRadius: '50%',
      border: `${borderWidth}px solid ${borderColor}`,
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: bgColors,
      transform: 'translate(-30px, 50%)',
    },
  };
});

const Stepper = ({ steps, active }: StepperProps) => {
  const { t } = useTranslation();

  return (
    <Container sx={{}}>
      {steps.map((label, index) => (
        <Step aria-label={t(label)} key={label} isCompleted={index + 1 < active} isActive={index + 1 === active}>
          <Typography variant="button">{t(label)}</Typography>
        </Step>
      ))}
    </Container>
  );
};

export default Stepper;
