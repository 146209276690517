import React from 'react';
import { LayoutProps } from './component.types';
import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material';
import darkBackgroundImage from '../../../../../assets/backgrounds/auth-background-dark.svg';
import lightBackgroundImage from '../../../../../assets/backgrounds/auth-background-light.svg';

const backgroundImage = {
  dark: darkBackgroundImage,
  light: lightBackgroundImage,
};

const StyledContainer = styled(Box)<BoxProps>({
  width: '100vw',
  height: '100vh',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  overFlow: 'hidden',
});

const StyledBackground = styled('img')({
  width: '100vw',
  height: '100vh',
  left: 0,
  bottom: 0,
  zIndex: -1,
  position: 'fixed',
  objectFit: 'cover',
});

const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();

  return (
    <StyledContainer>
      {children}
      <StyledBackground src={backgroundImage[theme.palette.mode]} alt="auth-background-image" />
    </StyledContainer>
  );
};

export default Layout;
