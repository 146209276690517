/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeUserPasswordRequest } from '../models/ChangeUserPasswordRequest';
import type { UserCollection } from '../models/UserCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiUserService {

    /**
     * Change User Password
     * By calling this endpoint can change user password.
     * @param userId User identifier.
     * @param requestBody Change user password request
     * @returns void
     * @throws ApiError
     */
    public static apiUserChangePassword(
        userId: number,
        requestBody: ChangeUserPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{user_id}/change-password',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Get users list
     * this end point will return all users infomrmation.
     * @param perPage Number of elements per page.
     * @param page Page number.
     * @param query Search query over users.
     * @returns UserCollection User collection response
     * @throws ApiError
     */
    public static apiUserList(
        perPage?: number,
        page?: number,
        query?: string,
    ): CancelablePromise<UserCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Frobidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
    }

}
