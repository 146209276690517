import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import { ThunkDispatch } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from './store.types';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = (): ThunkDispatch<RootState, unknown, any> => useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
