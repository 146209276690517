/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserResource } from './UserResource';

export type EmployeeActivityResource = {
    id?: number;
    user?: UserResource;
    status?: {
        id?: number;
        title?: string;
    };
    start_time?: string;
    end_time?: string;
    type?: EmployeeActivityResource.type;
    description?: string;
    duration_in_hours?: number;
    /**
     * Initial creation timestamp
     */
    created_at?: string;
    /**
     * Last update timestamp
     */
    updated_at?: string;
};

export namespace EmployeeActivityResource {

    export enum type {
        WORK = 'Work',
        VACATION = 'Vacation',
        DAY_OFF = 'Day Off',
        SICK_LEAVE = 'Sick Leave',
        SPECIAL_VACATION = 'Special Vacation',
    }


}

