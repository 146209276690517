import React, { useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled, Box, Typography } from '@mui/material';
import { DateYearMonthPickerProps } from './component.types';
import { enGB } from 'date-fns/locale';

import { DatePicker } from '@mui/x-date-pickers';
import { CloseButton } from '../confirmation-dialog/component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.fieldsBorder.main}`,
  padding: '0px 15px',
  position: 'relative',
  '& > *': {
    flexShrink: 0,
  },
}));
const PickerTrigger = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  left: 0,
  right: 0,
  top: 0,
  boxSizing: 'border-box',

  bottom: 0,
  '*': {
    width: '100%',
    height: '100%',
    opacity: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
  },
});
// const PickerInput = styled(TextField)({})

const MonthRangePicker: React.FC<DateYearMonthPickerProps<Date, Date>> = ({ children, ...props }) => {
  const ref = useRef(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DateRangeBox ref={ref} sx={{ minHeight: '44px', minWidth: '200px' }}>
        {Boolean(props.value) && (
          <CloseButton
            onClick={() => props.onClear()}
            sx={{ transform: 'initial', top: '3px', right: '77%', zIndex: 999 }}
          >
            <CloseOutlinedIcon />
          </CloseButton>
        )}
        <DatePicker
          {...props}
          label="Year and Month"
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div ref={inputRef} style={{ width: '100%', height: '100%' }}>
              <Typography
                variant="subtitle2"
                component="span"
                sx={{ display: 'inline-block', textAlign: 'center', width: '100%', height: '100%' }}
              >
                {children || inputProps?.value}
              </Typography>
              <PickerTrigger>{InputProps?.endAdornment}</PickerTrigger>
            </div>
          )}
        />
      </DateRangeBox>
    </LocalizationProvider>
  );
};

export default MonthRangePicker;
