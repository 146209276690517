import styled from '@emotion/styled';
import { Box, Button, Paper } from '@mui/material';

export const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: '20px',
  padding: '30px',
  boxSizing: 'border-box',
});

export const GridHeader = styled(Box)({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '100%',
});

export const FiltersBox = styled(Box)({
  display: 'flex',
  minHeight: '42px',
  '& > *': {
    margin: '0 5px',
    '&:not(a):not(button)': {
      width: '210px',
    },
  },
});

export const ExportButton = styled(Button)({
  position: 'absolute',
  height: '48px',
  width: '48px',
  minWidth: '48px',
  boxSizing: 'border-box',
  borderRadius: '50%',
  right: '5px',
  bottom: '7px',
  padding: 0,
  transform: 'translate(-50%,-50%)',
});
