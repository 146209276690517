import { FileDownload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useErrorHandler from '../../../hooks/use-error-handler';
import usePDFGenerator from '../../../hooks/use-pdf-generator';
import { ExportCVCellProps } from './component.types';

const ExportCVCell: React.FC<ExportCVCellProps> = ({ id, disabled }) => {
  const { t } = useTranslation();
  const { getPDFUri, loading } = usePDFGenerator();
  const handleError = useErrorHandler();
  return (
    <LoadingButton
      disabled={disabled}
      loading={loading}
      sx={{
        padding: 0,
        '&.Mui-disabled': {
          color: 'unset',
          background: 'unset',
          opacity: 0.5,
        },
      }}
      variant="text"
      onClick={() => {
        getPDFUri(Number(id))
          .then(uri => {
            window.open(uri);
          })
          .catch(handleError);
      }}
    >
      <Stack direction="row" alignItems="center">
        <FileDownload sx={{ marginRight: '5px' }} />
        {t('table:cvs.export')}{' '}
      </Stack>
    </LoadingButton>
  );
};
export default ExportCVCell;
