import React from 'react';
import { AvatarProps } from './component.types';
import { styled, Avatar as MuiAvatar } from '@mui/material';

import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const StyledAvatar = styled(MuiAvatar)<AvatarProps>(({ theme, bgColor, isOpen }) => ({
  width: isOpen ? '65px' : '30px',
  minWidth: isOpen ? '65px' : '30px',
  minHidth: isOpen ? '65px' : '30px',
  height: isOpen ? '65px' : '30px',
  backgroundColor: bgColor || theme.palette.lightWhite.main,
}));

const Avatar = ({ bgColor, ...props }: AvatarProps) => (
  <StyledAvatar aria-label="user-avatar" {...props} bgColor={bgColor || ''}>
    <PersonRoundedIcon
      data-testid="avatar-default-icon"
      sx={{
        width: props.isOpen ? 65 : 40,
        height: props.isOpen ? 65 : 40,
        marginTop: '4px',
      }}
    />
  </StyledAvatar>
);

export default Avatar;
