/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProjectResource = {
    id?: number;
    name?: string;
    start_date?: string;
    end_date?: string;
    target?: number;
    status?: ProjectResource.status;
    minutes?: number;
    total_logged_hours?: number;
    is_public?: boolean;
    user?: {
        position?: string;
        created_at?: string;
    };
    /**
     * Initial creation timestamp
     */
    created_at?: string;
    /**
     * Last update timestamp
     */
    updated_at?: string;
};

export namespace ProjectResource {

    export enum status {
        ONGOING = 'ONGOING',
        CLOSED = 'CLOSED',
    }


}

