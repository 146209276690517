import React from 'react';
import { CVInformationFormProps, CVInformationFormSchemaTypes } from './component.types';
import { Box, styled, TextField } from '@mui/material';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { USERTYPE } from '../../../../constants/common';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const cvInformationFormSchema: CVInformationFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape({
    introduction: Yup.string().ensure().required().min(1),
  }),
  [USERTYPE.EMPLOYEE]: Yup.object().shape({
    introduction: Yup.string().ensure().required().min(1),
  }),
};

const CVInformationForm = ({ control }: CVInformationFormProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <FormItemText defaultValue="" control={control} required name="introduction" label={t('common:about')}>
        <TextField minRows={10} multiline type="text" />
      </FormItemText>
    </Container>
  );
};

export default CVInformationForm;
