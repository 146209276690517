import React, { memo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from '../components/auth/login';
import Register from '../components/auth/register';
import AuthGuard from '../components/auth/guard';
import UiKit from '../components/ui-kit';
import ForgetPassword from '../components/auth/forget-password';
import ResetPassword from '../components/auth/reset-password';
import VerifyEmail from '../components/auth/verify-email';
import UserGuard from '../components/user/user-guard';
import CvSingle from '../components/cv-single';
import CreateCV from '../components/create-cv';
import { USERTYPE } from '../constants/common';
import Logout from '../components/user/logout';
import CVSList from '../components/cv-list';
import EmployeesList from '../components/admin/employees';
import EmployeeProfilePage from '../components/admin/employee';
import UserProfilePage from '../components/profile';
import VacationsPage from '../components/vacations';
import EmployeeOverview from '../components/admin/employee-overview';
import Projects from '../components/admin/projects';
import ClientDashboard from '../components/client/dashboard/component';
import VacationsReports from '../components/admin/reports/vacations/component';
import { Skills } from '../components/skills';
import ProjectsReport from '../components/admin/projects-reports/component';

export const appRoutes = Object.freeze({
  auth: {
    login: '/auth',
    register: '/auth/register',
    forgetPassword: '/auth/forget-password',
    resetPassword: '/auth/reset-password',
    verifyEmail: '/auth/verify-email',
    authGoogleCallback: '/auth/google/callback',
  },
  cvList: '/cvs',
  cvSingle: '/cvs/:cvId',
  cvPreviewPDF: '/cvs/:cvId/pdf',
  newCv: '/cvs/new',
  skills: '/skills',
  vacations: '/vacations',
  vacationsType: '/vacations/:vacationType',
  dashboard: '/dashboard',
  profile: '/profile',
  reports: '/reports',
  reportsType: '/reports/:reportType',
  employees: '/employees',
  employeesSingle: '/employees/:userId',
  employeesSingleOverview: '/employees/:userId/overview',
  employeeOverview: '/overview',
  projects: '/projects',
  projectsReport: '/projects-report',
  landing: '/',
  logout: '/logout',
  kit: '/kit',
});
/* TODO-qp::
 *   1) add 404 page and route
 *   2) add 5xx page and route
 * */
const RouterProvider = () =>
  useRoutes([
    {
      path: '/',
      children: [
        {
          index: true,
          element: <Navigate replace to={appRoutes.dashboard} />,
        },
        {
          path: '',
          element: <UserGuard userTypes={[USERTYPE.EMPLOYEE, USERTYPE.PM, USERTYPE.HR]} />,
          children: [
            { path: appRoutes.dashboard, element: <ClientDashboard /> },
            {
              path: appRoutes.profile,
              element: <UserProfilePage />,
            },
          ],
        },
        {
          path: '',
          element: <UserGuard userTypes={[USERTYPE.PM, USERTYPE.HR]} />,
          children: [
            {
              path: appRoutes.projects,
              element: <Projects />,
            },
          ],
        },
        {
          path: '',
          element: <UserGuard userTypes={[USERTYPE.EMPLOYEE, USERTYPE.HR]} />,
          children: [
            { path: appRoutes.cvList, element: <CVSList /> },
            { path: appRoutes.cvSingle, element: <CvSingle /> },
            { path: appRoutes.newCv, element: <CreateCV /> },
            { path: appRoutes.employeeOverview, element: <EmployeeOverview /> },
            {
              path: appRoutes.vacationsType,
              element: <VacationsPage />,
            },
            {
              path: appRoutes.vacations,
              element: <VacationsPage />,
            },
          ],
        },
        {
          path: '',
          element: <UserGuard userTypes={[USERTYPE.HR]} />,
          children: [
            {
              path: appRoutes.employees,
              element: <EmployeesList />,
            },
            {
              path: appRoutes.employeesSingle,
              element: <EmployeeProfilePage />,
            },
            {
              path: appRoutes.employeesSingleOverview,
              element: <EmployeeOverview />,
            },

            {
              path: appRoutes.reportsType,
              element: <VacationsReports />,
            },
            {
              path: appRoutes.projectsReport,
              element: <ProjectsReport />,
            },
            {
              path: appRoutes.skills,
              element: <Skills />,
            },
          ],
        },
        {
          path: appRoutes.auth.login,
          element: <AuthGuard />,
          children: [
            {
              path: '',
              element: <Login />,
            },
            { path: appRoutes.auth.register, element: <Register /> },
            { path: appRoutes.auth.verifyEmail, element: <VerifyEmail /> },
            { path: appRoutes.auth.forgetPassword, element: <ForgetPassword /> },
            { path: appRoutes.auth.resetPassword, element: <ResetPassword /> },
          ],
        },
      ],
    },
    {
      path: appRoutes.kit,
      element: <UiKit />,
    },
    {
      path: appRoutes.logout,
      element: <Logout />,
    },
  ]);

export default memo(RouterProvider);
