/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregatedEmployeeActivityCollection } from '../models/AggregatedEmployeeActivityCollection';
import type { EmployeeActivitiesCollection } from '../models/EmployeeActivitiesCollection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiEmployeeActivityService {

    /**
     * Employe Activities Export
     * Export all employee activities.
     * @param userId
     * @param locationId
     * @param type 1 for Work, 2 for Vacation, 3 for Day Off, 4 for Sick Leave and 5 for Special Vacation
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @returns void
     * @throws ApiError
     */
    public static apiEmployeeActivityExport(
        userId?: number,
        locationId?: number,
        type?: number,
        status?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employee-activities/export',
            query: {
                'user_id': userId,
                'location_id': locationId,
                'type': type,
                'status': status,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Aggregated activities
     * This returns all activites in a time slot
     * @param userId
     * @param startDate
     * @param endDate
     * @returns AggregatedEmployeeActivityCollection Holiday collection response
     * @throws ApiError
     */
    public static apiEmployeeActivityAggregated(
        userId?: number,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<AggregatedEmployeeActivityCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employee-activities/aggregated',
            query: {
                'user_id': userId,
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

    /**
     * Employe Activities List
     * Paginated list of all employee activities.
     * @param page Page number.
     * @param pageSize Maximum number of elements in each page.
     * @param type 1 for Work, 2 for Vacation, 3 for Day Off, 4 for Sick Leave and 5 for Special Vacation
     * @param status 1 for Pending, 2 for Approved, 3 for Rejected and 4 for canceled
     * @param from required if to is set
     * @param to required if from is set
     * @returns EmployeeActivitiesCollection employee activity collection response
     * @throws ApiError
     */
    public static apiEmployeeActivityIndex(
        page?: number,
        pageSize?: number,
        type?: number,
        status?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<EmployeeActivitiesCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employee-activities',
            query: {
                'page': page,
                'pageSize': pageSize,
                'type': type,
                'status': status,
                'from': from,
                'to': to,
            },
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable entity.`,
            },
        });
    }

}
