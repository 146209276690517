import React, { useState, Children, ReactElement, cloneElement } from 'react';
import { ExpandableCardProps, HeaderProps } from './component.types';
import { Box, Collapse, IconButton, IconButtonProps, Paper, Stack, styled, Typography } from '@mui/material';

import { ReactComponent as PlusIconLight } from '../../../assets/icons/plus-light.svg';
import { ReactComponent as PlusIconDark } from '../../../assets/icons/plus-dark.svg';
import { ReactComponent as MinusIconLight } from '../../../assets/icons/minus-light.svg';
import { ReactComponent as MinusIconDark } from '../../../assets/icons/minus-dark.svg';

import { THEME } from '../../../constants/theme';
import RenderBasedOnTheme from '../render-based-on-theme';
import When from '../when';

const Container = styled(Paper)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: '10px',
});

const Header = styled(Box, {
  shouldForwardProp: prop => prop !== 'isExpanded',
})<HeaderProps>(({ theme, isExpanded }) => ({
  position: 'relative',
  height: '60px',
  padding: '20px 25px',
  backgroundColor: theme.palette.waterloo?.ultraLight,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: !isExpanded ? '10px' : '10px 10px 0 0',
  transition: theme.transitions.create(['border-radius'], {
    duration: 200,
  }),
}));

const ToggleBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  height: '26px',
  width: '26px',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.mode === THEME.LIGHT ? theme.palette.black.ultraLight : theme.palette.white.main}`,
  backgroundColor: theme.palette.mode === THEME.LIGHT ? theme.palette.white.main : theme.palette.organzaViolet.main,
  left: 0,
  top: '50%',
  padding: 0,
  transform: 'translate(-50%,-50%)',
}));
const ExpandableCard = ({ children, title, actions, initialState = false, ...otherProps }: ExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState(initialState);

  return (
    <Container {...otherProps}>
      <Header isExpanded={isExpanded}>
        <Typography
          sx={{
            marginLeft: '15px',
          }}
          variant="subtitle2"
        >
          {title}
        </Typography>
        <ToggleBtn aria-label={`toggle-${title}`} onClick={() => setIsExpanded(prevState => !prevState)}>
          {isExpanded ? (
            <RenderBasedOnTheme light={<MinusIconDark />} dark={<MinusIconLight />} />
          ) : (
            <RenderBasedOnTheme light={<PlusIconDark />} dark={<PlusIconLight />} />
          )}
        </ToggleBtn>
        <When
          condition={actions && !!actions?.length}
          render={() => (
            <Stack sx={{ flexDirection: 'row' }} spacing={1}>
              {Array.isArray(actions) &&
                Children.map(actions, (act: ReactElement<IconButtonProps>, idx) =>
                  cloneElement(act, { ...act.props, key: `action-${title}-${idx}` })
                )}
            </Stack>
          )}
        />
      </Header>
      <Collapse in={isExpanded} timeout="auto">
        <Box
          aria-label={`${title}-body`}
          sx={{
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Container>
  );
};

export default ExpandableCard;
