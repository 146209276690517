import { Box, Dialog, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CloseButton } from '../../confirmation-dialog/component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ProjectViewModalProps } from './component.types';
import { CardHeading, CardItemValueText } from '../../../../components/admin/employee-overview/styled';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import When from '../../when';
import { ProjectUser } from '../create-project-modal/component.types';

const RowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '15px 20px',
  borderRadius: '5px',
  border: `0.5px solid ${theme.palette.lightGray.main}`,
  minWidth: '200px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ProjectViewModal: React.FC<ProjectViewModalProps> = ({ data, open, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { padding: '40px', position: 'relative', maxHeight: '80%' } }}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <CloseButton onClick={onClose} aria-label="close-modal">
        <CloseOutlinedIcon />
      </CloseButton>
      <Stack direction="column" gap="20px">
        <Stack>
          <CardHeading variant="h4" marginBottom="20px">
            Project Status
          </CardHeading>
          <Box display="grid" gridTemplateColumns="repeat(2,1fr)" gap="10px">
            <RowBox>
              <Typography variant="subtitle2" component="span">
                {t('table:projects.name')}
              </Typography>
              <CardItemValueText variant="body1">{data?.name}</CardItemValueText>
            </RowBox>
            <RowBox>
              <Typography variant="subtitle2" component="span">
                {t('table:common.start-date')}
              </Typography>
              <CardItemValueText variant="body1">
                {data?.start_date ? format(new Date(data.start_date), 'dd.mm.yyyy') : ''}
              </CardItemValueText>
            </RowBox>
            <RowBox>
              <Typography variant="subtitle2" component="span">
                {t('table:common.end-date')}
              </Typography>
              <CardItemValueText variant="body1">
                {data?.end_date ? format(new Date(data.end_date), 'dd.mm.yyyy') : ''}
              </CardItemValueText>
            </RowBox>
            <RowBox>
              <Typography variant="subtitle2" component="span">
                {t('table:projects.target-hours')}
              </Typography>
              <CardItemValueText variant="body1">{data?.target}</CardItemValueText>
            </RowBox>
            <RowBox>
              <Typography variant="subtitle2" component="span">
                {t('table:cvs.status.value')}
              </Typography>
              <CardItemValueText variant="body1">{data?.status}</CardItemValueText>
            </RowBox>
          </Box>
        </Stack>

        <When condition={Boolean(data?.users?.length)}>
          <Stack flexGrow={2}>
            <CardHeading variant="h4" marginBottom="20px">
              Assigned Employees
            </CardHeading>
            {data?.users?.map(item => (
              <RowBox key={item.id} justifyContent="space-between" marginBottom="10px">
                <Typography variant="subtitle2" component="span" minWidth={150}>
                  {item.user?.first_name} {item.user?.last_name}
                </Typography>
                <Stack gap={8} flexDirection="row" alignItems="center">
                  <CardItemValueText variant="body1" sx={{ color: theme.palette.red.main }}>
                    {(item.user as ProjectUser).status.id === 2 ? 'Archived' : ''}
                  </CardItemValueText>
                  <CardItemValueText variant="body1">{item.position}</CardItemValueText>
                  <Typography variant="subtitle2" component="span" whiteSpace="nowrap">
                    From
                    <CardItemValueText variant="body1">
                      {format(new Date(item.created_at || ''), 'dd.MM.yyyy')}
                    </CardItemValueText>
                  </Typography>
                </Stack>
              </RowBox>
            ))}
          </Stack>
        </When>
        <When condition={Boolean(data?.user_history_logs?.length)}>
          <Stack flexGrow={2}>
            <CardHeading variant="h4" marginBottom="20px">
              Former Employees
            </CardHeading>
            {data?.user_history_logs?.map((item, idx) => (
              <RowBox key={String(item.user?.id) + String(idx)} justifyContent="space-between" marginBottom="10px">
                <Typography variant="subtitle2" component="span" minWidth={150}>
                  {item.user?.first_name} {item.user?.last_name}
                </Typography>
                <Stack gap={8} flexDirection="row" alignItems="center">
                  <CardItemValueText variant="body1" sx={{ color: theme.palette.red.main }}>
                    {(item.user as ProjectUser).status.id === 2 ? 'Archived' : ''}
                  </CardItemValueText>
                  <CardItemValueText variant="body1">{(item as { position: string }).position}</CardItemValueText>
                  {item.start_at && (
                    <Typography variant="subtitle2" component="span" whiteSpace="nowrap">
                      From
                      <CardItemValueText variant="body1">
                        {format(new Date(item.start_at || ''), 'dd.MM.yyyy')}
                      </CardItemValueText>
                    </Typography>
                  )}
                  {item.end_at && (
                    <Typography variant="subtitle2" component="span" whiteSpace="nowrap">
                      To
                      <CardItemValueText variant="body1">
                        {format(new Date(item.end_at || ''), 'dd.MM.yyyy')}
                      </CardItemValueText>
                    </Typography>
                  )}
                </Stack>
              </RowBox>
            ))}
          </Stack>
        </When>

        <Box sx={{ width: '40px' }} />
      </Stack>
    </Dialog>
  );
};
export default ProjectViewModal;
