import React, { useEffect, useRef } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AdminProjectResource, AdminProjectsCollection, ApiProjectService, OpenAPI } from '../../../../api/main';
import { request as __request } from '../../../../api/main/core/request';
import CustomPagination from '../../table-pagination';
import { useTranslation } from 'react-i18next';
import useErrorHandler from '../../../hooks/use-error-handler';
import { ProjectsGridProps, TableFilters } from './component.types';
import useFetch from '../../../hooks/use-fetch';
import useTable from '../../../hooks/use-table';
import { defaultColDef } from '../../../../constants/report';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import ConfirmationDialog from '../../confirmation-dialog';
import { useSnackbar } from 'notistack';
import { ApiErrorResponseType } from '../../../hooks/use-fetch/component.types';
import { format } from 'date-fns';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { ProjectsRequest } from '../../employee/workhour-modal-form/component.types';

const ProjectsDetailedGrid: React.FC<ProjectsGridProps> = ({
  filters,
  handleEdit = () => {},
  handleView = () => {},
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHandler();
  const [open, setOpen] = React.useState(false);

  const { data, page, setPage, setData, pageSize } = useTable<AdminProjectsCollection, TableFilters>({
    initialData: { meta: { total: 0 } },
    initialFilters: {},
    initialPage: 0,
  });
  const getAdminProjects = useFetch<ProjectsRequest, AdminProjectsCollection>(({ page: newPage, name }) => {
    return __request(OpenAPI, {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `/api/projects?page=${newPage}${
        name ? `&name=${name}` : ''
      }&pageSize=10&sort[0][by]=status&sort[0][dir]=asc&sort[1][by]=created_at&sort[1][dir]=desc`,
      errors: {
        401: `Unauthorized, the user is unauthenticated.`,
        422: `Unprocessable entity.`,
      },
    });
  });
  const fetchData = useRef(getAdminProjects.call);
  const deleteProject = useFetch(ApiProjectService.apiProjectDelete);
  const projectIDRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    const handleSuccess = (result: AdminProjectsCollection) => setData(result);
    fetchData
      .current({
        ...filters,
        name: filters.searchQuery || undefined,
        page: page + 1,
        pageSize,
      })
      .then(handleSuccess)
      .catch(handleError);
  }, [fetchData, filters, handleError, setData, page, pageSize]);

  const Columns: GridColDef<AdminProjectResource>[] = [
    {
      ...defaultColDef,
      field: 'name',
      headerName: t('table:projects.name'),
      flex: 1,
    },
    {
      ...defaultColDef,
      field: 'start_date',
      headerName: t('table:common.start-date'),
      flex: 1,
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.yyyy') : '';
      },
    },
    {
      ...defaultColDef,
      field: 'end_date',
      headerName: t('table:common.end-date'),
      flex: 1,
      valueFormatter({ value }) {
        return value ? format(new Date(value as string), 'dd.MM.yyyy') : '';
      },
    },
    {
      ...defaultColDef,
      field: 'target',
      headerName: t('table:projects.target-hours'),
      flex: 1,
    },
    {
      ...defaultColDef,
      field: 'status',
      headerName: t('table:cvs.status.value'),
      flex: 1,
    },
    {
      ...defaultColDef,
      field: 'total_logged_hours',
      headerName: 'Logged hours',
      flex: 1,
    },
    {
      ...defaultColDef,
      field: 'is_public',
      headerName: 'Public',
      flex: 1,
      type: 'boolean',
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: t('table:common.actions'),
      flex: 1,
      renderCell({ row }) {
        return (
          <Stack direction="row">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit color="info" />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <IconButton
              onClick={() => {
                projectIDRef.current = row.id;
                setOpen(true);
              }}
            >
              <Delete color="error" />
            </IconButton>
            <Divider orientation="vertical" flexItem sx={{ margin: '2.5px' }} />
            <IconButton onClick={() => handleView(row)}>
              <Visibility color="info" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <DataGrid
        loading={getAdminProjects.loading}
        page={page}
        onPageChange={setPage}
        columns={Columns}
        rows={data.data || []}
        paginationMode="server"
        pageSize={data.meta?.per_page}
        rowCount={data.meta?.total}
        components={{ Pagination: CustomPagination }}
        disableSelectionOnClick
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        columnBuffer={6}
        disableColumnMenu
      />
      <ConfirmationDialog
        width="30%"
        padding="10px"
        onOk={() => {
          if (projectIDRef.current) {
            deleteProject
              .call(projectIDRef.current)
              .then(() => {
                enqueueSnackbar(t('form:general-submission.success'), {
                  variant: 'success',
                });
                setOpen(false);
                setData({
                  ...data,
                  data: data.data?.filter(item => item.id !== projectIDRef.current),
                });
              })
              .catch((err: ApiErrorResponseType) => {
                setOpen(false);
                void handleError(err);
              });
          }
        }}
        onCancel={() => {
          setOpen(false);
        }}
        title=""
        open={open}
      >
        <Typography variant="h4" paragraph>
          {t('form:delete-confirm')}
        </Typography>
      </ConfirmationDialog>
    </React.Fragment>
  );
};
export default ProjectsDetailedGrid;
