import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { VACATIONSTYPE } from '../../../../constants/report';
import { appRoutes } from '../../../../providers/routes';
import TabView from '../../tab-view';
import { useUser } from '../../../hooks/use-user';

const VacationsTabs: React.FC<{ onChange: () => void }> = ({ onChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vacationType = VACATIONSTYPE.Index } = useParams<{ vacationType: VACATIONSTYPE }>();
  const { isHR } = useUser();

  const VACATIONSTYPEMap = {
    0: VACATIONSTYPE.Index,
    1: VACATIONSTYPE.DayOffs,
    2: VACATIONSTYPE.SickLeave,
    3: VACATIONSTYPE.Holidays,
    4: VACATIONSTYPE.SpecialVacation,
    5: VACATIONSTYPE.EditHoliday,
    [VACATIONSTYPE.Index]: 0,
    [VACATIONSTYPE.DayOffs]: 1,
    [VACATIONSTYPE.SickLeave]: 2,
    [VACATIONSTYPE.Holidays]: 3,
    [VACATIONSTYPE.SpecialVacation]: 4,
    [VACATIONSTYPE.EditHoliday]: 5,
  };
  if (isHR) {
    VACATIONSTYPEMap[3] = VACATIONSTYPE.SpecialVacation;
    VACATIONSTYPEMap[VACATIONSTYPE.Holidays] = 4;
    VACATIONSTYPEMap[4] = VACATIONSTYPE.Holidays;
    VACATIONSTYPEMap[VACATIONSTYPE.SpecialVacation] = 3;
  }
  const handleChange = (event: React.SyntheticEvent, value: VACATIONSTYPE) => {
    onChange();
    navigate(`${appRoutes.vacations}/${String(VACATIONSTYPEMap[value])}`, { replace: true });
  };

  const TABS = [t('common:vacations'), t('common:day-offs'), t('common:sick-leave'), t('common:holidays')];
  const HR_TABS = [
    t('common:vacations'),
    t('common:day-offs'),
    t('common:sick-leave'),
    t('common:special-vacation'),
    t('common:holidays'),
  ];
  return (
    <TabView onChange={handleChange} value={VACATIONSTYPEMap[vacationType]} tabs={isHR ? HR_TABS : TABS}></TabView>
  );
};

export default VacationsTabs;
