import { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../utils/widgets/layouts/dashboard-layout';
import { GridHeader, StyledPaper } from '../../utils/widgets/layouts/reports-layout';
import UserPageHeader from '../../utils/widgets/layouts/user-page-header';
import { debounce } from '../../utils/tools/debounce';
import SearchInputBox from '../../utils/widgets/search-input-box';
import useTable from '../../utils/hooks/use-table';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { defaultColDef } from '../../constants/report';
import {
  Dialog,
  DialogContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit, People } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CustomPagination from '../../utils/widgets/table-pagination';
import ConfirmationDialog from '../../utils/widgets/confirmation-dialog';
import { useTranslation } from 'react-i18next';
import useFetch from '../../utils/hooks/use-fetch/component';
import { ApiSkillService, SkillRequest, SkillResource, SkillsCollection, UserCollection } from '../../api/main';
import { SkillsRequest } from './component.types';
import useErrorHandler from '../../utils/hooks/use-error-handler';
import Button from '../../utils/widgets/button';
import CreateSkillModal from '../../utils/widgets/skills/create-skill-modal/component';
import { CreateSkillFormValues } from '../../utils/widgets/skills/create-skill-modal/component.types';
import CustomSelect from '../../utils/widgets/forms/custom-select';
import { SKILL_TYPES } from '../../constants/common';
import { CloseButton } from '../../utils/widgets/confirmation-dialog/component';

export const Skills = () => {
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isPeopleModalOpen, setIsPeopleModalOpen] = useState(false);
  const itemIdRef = useRef<(SkillResource & { users_count?: number }) | undefined>(undefined);
  const getSkills = useFetch<SkillsRequest, SkillsCollection>(({ page, pageSize, query, type }) =>
    ApiSkillService.apiSkillIndex(page, pageSize, type, query, 'ASC', 'name')
  );
  const getSkillUsers = useFetch(ApiSkillService.apiSkillUsers);
  const createSkill = useFetch(ApiSkillService.apiSkillCreate);
  const updateSkill = useFetch<SkillRequest & { id: number }, SkillResource>(({ id, name, type }) =>
    ApiSkillService.apiSkillUpdate(id, { name, type })
  );

  const deleteSkill = useFetch(ApiSkillService.apiSkillDelete);
  const { data, page, setPage, setData, pageSize, filters, setFilters } = useTable<
    SkillsCollection,
    Record<string, string | undefined>
  >({
    initialData: { meta: { total: 0 } },
    initialFilters: { type: '' },
    initialPage: 0,
    isInQuery: true,
  });
  const debouncedChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(oldFilters => ({ ...oldFilters, query: event.target.value }));
  }, 300);

  useEffect(() => {
    const handleSuccess = (result: SkillsCollection) => setData(result);
    getSkills
      .call({
        page: page + 1,
        pageSize,
        query: filters.query ? filters.query : undefined,
        type: filters.type ? (filters.type as unknown as SkillRequest.type) : undefined,
      })
      .then(handleSuccess)
      .then(() => {
        itemIdRef.current = undefined;
      })
      .catch(handleError);
  }, [page, filters]);

  const handleSubmit = (values: CreateSkillFormValues) => {
    const { skill_type: type, skill_name: name } = values;
    if (itemIdRef.current) {
      return updateSkill
        .call({ id: itemIdRef.current.id as number, name, type: type.value })
        .then(() => {
          setFilters({ ...filters });
          setIsEditOpen(false);
          itemIdRef.current = undefined;
        })
        .catch(handleError);
    } else {
      return createSkill
        .call({ name, type: type.value })
        .then(() => {
          setFilters({ ...filters });
          setIsEditOpen(false);
        })
        .catch(handleError);
    }
  };
  const handleCancel = () => {
    setIsEditOpen(false);
    itemIdRef.current = undefined;
  };

  const Columns: GridColDef<SkillResource>[] = [
    {
      ...defaultColDef,
      field: 'name',
      headerName: 'Name',
      editable: true,
    },
    {
      ...defaultColDef,
      field: 'users_count',
      headerName: 'Experts',
    },
    {
      ...defaultColDef,
      field: 'type',
      headerName: 'Type',
    },
    {
      ...defaultColDef,
      field: 'actions',
      headerName: 'Actions',

      renderCell({ row }) {
        return [
          <GridActionsCellItem
            icon={<People />}
            label="People"
            className="textPrimary"
            onClick={() => {
              setIsPeopleModalOpen(true);
              void getSkillUsers.call(row.id as number);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              setIsEditOpen(true);
              itemIdRef.current = row;
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              setOpen(true);
              itemIdRef.current = row;
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DashboardLayout>
      <UserPageHeader title="Skills" />
      <StyledPaper>
        <GridHeader>
          <SearchInputBox onChange={debouncedChangeHandler} defaultValue={filters.query} />

          <Stack direction="row">
            <CustomSelect
              value={filters.type || ''}
              onChange={e => setFilters({ ...filters, type: e.target.value || undefined })}
            >
              <MenuItem value="">All Types</MenuItem>
              {SKILL_TYPES.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
            <Button
              sx={{ marginLeft: '8px' }}
              variant="outlined"
              color="inherit"
              hasIcon
              onClick={() => setIsEditOpen(true)}
            >
              <Stack direction="row" alignItems="center">
                <Add sx={{ marginRight: '5px' }} />
                Add Skill
              </Stack>
            </Button>
          </Stack>
        </GridHeader>
        <Divider sx={{ margin: '30px 0' }} />
        <DataGrid
          page={page}
          onPageChange={setPage}
          columns={Columns}
          rows={data.data || []}
          paginationMode="server"
          pageSize={data.meta?.per_page}
          rowCount={data.meta?.total}
          components={{ Pagination: CustomPagination }}
          disableSelectionOnClick
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          loading={getSkills.loading}
        />
        <CreateSkillModal
          loading={updateSkill.loading || createSkill.loading}
          key={String(isEditOpen)}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          open={isEditOpen}
          initialData={itemIdRef.current}
        />

        <ConfirmationDialog
          width="30%"
          padding="10px"
          onOk={() => {
            if (itemIdRef.current) {
              deleteSkill
                .call(itemIdRef.current.id as number)
                .then(() => {
                  setFilters({ ...filters });
                  setOpen(false);
                })
                .catch(handleError);
            }
          }}
          onCancel={() => {
            setOpen(false);
            void Promise.resolve().then(() => {
              itemIdRef.current = undefined;
            });
          }}
          title=""
          open={open}
        >
          <Typography variant="h4" paragraph>
            {itemIdRef.current && itemIdRef.current.users_count
              ? `${itemIdRef.current.users_count} employees have this skill in their CVs, are you sure about delete?`
              : t('form:delete-confirm')}
          </Typography>
        </ConfirmationDialog>
        <Dialog
          sx={{ '& .MuiDialog-paper': { minWidth: '340px' } }}
          maxWidth="md"
          open={getSkillUsers.loaded && isPeopleModalOpen}
          onClose={() => setIsPeopleModalOpen(false)}
        >
          <CloseButton onClick={() => setIsPeopleModalOpen(false)} aria-label="close-modal">
            <CloseOutlinedIcon />
          </CloseButton>
          <DialogContent sx={{ margin: '30px 0', padding: '0 40px' }}>
            <Stack direction="row" alignItems="center" marginBottom="10px">
              <Typography variant="h4">Users that have this skill</Typography>
            </Stack>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} aria-label="contacts">
              {(getSkillUsers.result as UserCollection['data'])?.map(user => {
                const fullName = `${user.first_name} ${user.last_name}`;
                return (
                  <ListItem key={user.id}>
                    <ListItemText
                      primary={fullName}
                      secondary={
                        <Link href={`/cvs?filters=${JSON.stringify({ searchQuery: fullName })}`}>
                          <Typography variant="subtitle2" component="span" sx={{ cursor: 'pointer' }}>
                            Go to CVS
                          </Typography>
                        </Link>
                      }
                    />
                  </ListItem>
                );
              })}
              {(getSkillUsers.result as UserCollection['data'])?.length === 0 ? (
                <Typography variant="body1" component="span">
                  There is no user
                </Typography>
              ) : (
                ''
              )}
            </List>
          </DialogContent>
        </Dialog>
      </StyledPaper>
    </DashboardLayout>
  );
};
