import { Info } from '@mui/icons-material';
import { Box, List, ListItem, ListItemText, Stack, styled, Typography } from '@mui/material';
import { CollidingInfo } from '../../hooks/use-colliding-box';

const CircleBox = styled(Box)(({ theme }) => ({
  width: '10px',
  height: '10px',
  marginRight: '20px',
  borderRadius: '50%',
  background: theme.palette.lightGray.main,
}));

const CollidingBox: React.FC<{ data: CollidingInfo | null }> = ({ data }) => {
  return data ? (
    <Box>
      <Stack direction="row" alignItems="center" marginBottom="10px">
        <Info color="error" sx={{ marginRight: '10px' }} />
        <Typography color="error">{data.header}</Typography>
      </Stack>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} aria-label="contacts">
        {data.days.map(day => (
          <ListItem key={day.date}>
            <CircleBox />
            <ListItemText primary={`${day.date}, ${day.type}`} />
          </ListItem>
        ))}
      </List>
    </Box>
  ) : null;
};

export default CollidingBox;
