import React from 'react';
import {
  CertificateFormProps,
  CertificateFormSchemaTypes,
  CVDetailsCertificateFormSchemaTypes,
} from './component.types';
import { Box } from '@mui/material';
import { Cell, Row, FormContainer } from '../styled';
import TextField from '@mui/material/TextField';
import FormItemText from '../../forms/form-item-text';
import { useTranslation } from 'react-i18next';
import InputDatePicker from '../../forms/input-date-picker';
import Checkbox from '../../forms/checkbox/component';
import * as Yup from 'yup';
import { useWatch } from 'react-hook-form';
import { USERTYPE } from '../../../../constants/common';

const formSchema = {
  title: Yup.string().ensure().required(),
  institute: Yup.string().required(),
  description: Yup.string(),
  link: Yup.string().url(),
  issue_date: Yup.string().ensure().dashedDateOnly().required(),
  expiration_date: Yup.string().when('no_expiration', {
    is: true,
    then: Yup.string().nullable(true),
    otherwise: Yup.string().ensure().dashedDateOnly().required(),
  }),
  no_expiration: Yup.boolean(),
};

export const certificateFormSchema: CertificateFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape(formSchema),
  [USERTYPE.EMPLOYEE]: Yup.object().shape(formSchema),
};

export const cvDetailsCertificateFormSchema: CVDetailsCertificateFormSchemaTypes = {
  [USERTYPE.HR]: Yup.object().shape({
    certifications: Yup.array(certificateFormSchema.hr).ensure(),
  }),
  [USERTYPE.EMPLOYEE]: Yup.object().shape({
    certifications: Yup.array(certificateFormSchema.hr).ensure(),
  }),
};

const CertificateForm = ({ prefix = '', control }: CertificateFormProps) => {
  const { t } = useTranslation();

  const watchNoExpiration = useWatch({
    control,
    name: `${prefix}no_expiration`,
  }) as boolean;

  const [issueDate, expirationDate] = useWatch({
    control,
    name: [`${prefix}issue_date`, `${prefix}expiration_date`],
  }) as Date[];

  return (
    <FormContainer>
      <Row>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            required
            label={t('form:cv.certification-name')}
            name={`${prefix}title`}
          >
            <TextField />
          </FormItemText>
        </Cell>
        <Cell>
          <FormItemText
            defaultValue=""
            control={control}
            label={t('form:cv.company-name')}
            name={`${prefix}institute`}
            required
          >
            <TextField />
          </FormItemText>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <FormItemText defaultValue="" control={control} label={t('form:cv.credential-url')} name={`${prefix}link`}>
            <TextField type="url" />
          </FormItemText>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <InputDatePicker
            fullWidth
            control={control}
            required
            name={`${prefix}issue_date`}
            label={t('form:cv.start-date')}
            maxDate={expirationDate ? new Date(expirationDate) : new Date()}
          />
        </Cell>
        <Cell>
          <Cell>
            <InputDatePicker
              fullWidth
              control={control}
              required={!watchNoExpiration}
              disabled={watchNoExpiration}
              name={`${prefix}expiration_date`}
              label={t('form:cv.end-date')}
              minDate={issueDate ? new Date(issueDate) : undefined}
              maxDate={new Date()}
            />
          </Cell>
        </Cell>
      </Row>
      <Box>
        <Checkbox
          name={`${prefix}no_expiration`}
          data-testid={`${prefix}no_expiration`}
          label={t('form:cv.no-credential--expiration')}
          control={control}
        />
      </Box>
      <Row>
        <FormItemText defaultValue="" control={control} label={t('form:cv.description')} name={`${prefix}description`}>
          <TextField multiline minRows={5} />
        </FormItemText>
      </Row>
    </FormContainer>
  );
};

export default CertificateForm;
