import React, { memo } from 'react';
import { CheckboxProps } from './component.types';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Checkbox = ({
  name,
  control,
  label,
  required = false,
  defaultValue = false,
  disabled = false,
}: CheckboxProps) => {
  const { t } = useTranslation();
  const { field, fieldState, formState } = useController({
    control,
    defaultValue,
    name,
  });

  const { ref, ...inputProps } = field;
  const { invalid } = fieldState;
  const htmlFor = `input-${name}`;

  return (
    <FormControlLabel
      name={name}
      checked={defaultValue}
      componentsProps={{
        typography: {
          color: invalid ? 'error' : 'default',
        },
      }}
      htmlFor={htmlFor}
      control={
        <MuiCheckbox
          ref={ref}
          id={htmlFor}
          aria-label={htmlFor}
          required={required}
          disabled={disabled}
          {...formState}
          inputProps={inputProps}
          checked={!!field.value}
          onChange={e => field.onChange(e.target.checked)}
        />
      }
      label={[label, required ? t('required') : '']}
    />
  );
};

export default memo(Checkbox);
