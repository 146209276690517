import React from 'react';
import { ConfirmationDialogProps } from './component.types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '../button';
import { useTranslation } from 'react-i18next';
import { IconButton, styled, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import When from '../when';

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(-20px,20px)',
});

const ConfirmationDialog = ({
  onCancel,
  onClose,
  onCancelText,
  onOk,
  onOkText,
  title,
  children,
  open,
  onOKIcon,
  width = '80%',
  maxWidth,
  padding = '40px',
  isOkLoading,
  isOkDisabled,
  showActions = true,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width, padding, position: 'relative', minWidth: '400px', maxWidth } }}
      maxWidth="md"
      open={open}
      onClose={onClose || onCancel}
    >
      <CloseButton onClick={onClose || onCancel} aria-label="close-modal">
        <CloseOutlinedIcon />
      </CloseButton>
      <When condition={Boolean(title)}>
        <DialogTitle>
          <Typography variant="h2" paragraph aria-label={`${title}-dialog`}>
            {title}
          </Typography>
        </DialogTitle>
      </When>
      <When condition={!!children}>
        <DialogContent sx={{ margin: '30px 0' }}>{children}</DialogContent>
      </When>
      {showActions && (
        <DialogActions>
          <Button autoFocus variant="outlined" color="inherit" onClick={onCancel}>
            {t(onCancelText ?? 'common:cancel')}
          </Button>
          <Button startIcon={onOKIcon} onClick={onOk} loading={isOkLoading} disabled={isOkDisabled}>
            {t(onOkText ?? 'common:confirm')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
